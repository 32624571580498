import { DateTime } from 'luxon'

// TODO: handle case where due date is in the past
export const getTimeDifference = (time: Date, isPast: boolean) => {
  if (!time) {
    return undefined
  }
  time = new Date(time)
  const now = DateTime.local()
  const timePoint = DateTime.fromJSDate(time)

  const diff = isPast ? now.diff(timePoint) : timePoint.diff(now)

  const diffInDays = Math.floor(diff.as('days')) - 1
  const diffInHours = Math.floor(diff.as('hours'))

  const timeCondition = isPast ? time.getTime() < Date.now() : time.getTime() > Date.now()
  if (!isPast && diffInDays < 0) {
    return 'Now'
  }

  if (timeCondition) {
    if (diffInDays >= 0) {
      return `${diffInDays}d`
    }
    return diffInHours ? `${diffInHours}h` : 'Now'
  }
  return ''
}
