import { isFunction } from 'lodash'
import { useEffect, useState } from 'react'

import Button from '@athena/component/atom/button'
import Checkbox from '@athena/component/atom/checkbox'

import { ClickMatrixProps } from './interface'

export const ClickMatrix = ({
  'data-test': dataTest = '',
  onConfirm,
  title,
  subtitle = '',
  options,
  selected,
  onSelect,
}: ClickMatrixProps) => {
  const [checkedValues, setCheckedValues] = useState<Record<string, boolean>>({})

  useEffect(() => {
    const newChecked = selected.reduce((acc, curr) => ({ ...acc, [curr]: true }), {})
    setCheckedValues(newChecked)
  }, [selected])

  return (
    <div className="flex flex-col mx-10 gap-8 mb-8 w-fit" data-test={`${dataTest}.container`}>
      <div className="flex flex-col">
        <span className="font-semibold text-2xl">{title}</span>
        {subtitle ? <span className="text-base">{subtitle}</span> : null}
      </div>
      <div className="grid grid-rows-30 sm:grid-rows-30 lg:grid-rows-20 xl:grid-rows-15 2xl:grid-rows-12 3xl:grid-rows-10 grid-flow-col justify-start gap-5">
        {options.map(({ value, label }) => (
          <div key={value} className="col-span-1 w-[220px]">
            <Checkbox
              data-test={`${dataTest}.checkbox-${value}`}
              label={label}
              value={Boolean(checkedValues[value])}
              onChange={(isChecked: boolean) => {
                isFunction(onSelect) && onSelect(value, isChecked)
                setCheckedValues((_options) => ({ ..._options, [label]: isChecked }))
              }}
            />
          </div>
        ))}
      </div>
      <Button basicOutline data-test={`${dataTest}.button`} onClick={() => onConfirm()}>
        Apply
      </Button>
    </div>
  )
}
