import classnames from 'classnames'
import { isFunction } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import Button, { ButtonFill, ButtonSize } from '@athena/component/atom/button'
import Icon from '@athena/component/atom/icon'

import InputWrapper from './input-wrapper'
import Props, { WrapperProps } from './interface'
import Style from './style.module.scss'

const cx = classnames.bind(Style)

const Input = ({
  customClasses = '',
  'data-test': dataTest = 'input',
  buttonText = '',
  id = '',
  disabled = false,
  readonly = false,
  help = '',
  icon,
  buttonIcon,
  label = '',
  placeholder = '',
  invalid = false,
  value = '',
  active = false,
  suffix = '',
  onButtonClick,
  onChange,
  onIconClick,
  onFocus,
  onBlur = undefined,
}: Props) => {
  const [focused, setFocused] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    focused && inputRef.current?.focus()
  }, [value, focused])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    isFunction(onChange) && onChange(event.target.value, id)
  }

  const handleFocus = (e?: React.FocusEvent<HTMLInputElement>) => {
    if (!disabled) {
      setFocused(true)
      inputRef.current?.focus()
      isFunction(onFocus) && onFocus(e)
    }
  }
  const handleBlur = () => {
    if (!disabled) {
      setFocused(false)
      isFunction(onBlur) && onBlur(inputRef.current?.value || '', id)
    }
  }

  return (
    <InputWrapper
      active={active}
      customClasses={customClasses}
      data-test={`${dataTest}.wrapper`}
      disabled={disabled}
      help={help}
      invalid={invalid}
      onBlur={handleBlur}
      onFocus={handleFocus}
    >
      <div className={Style.inputContainer} data-test={`${dataTest}.container`}>
        <span
          className={cx([Style.label], { [Style.emptyValue]: !focused && !value, [Style.invalid]: invalid })}
          data-test={`${dataTest}.label`}
        >
          {label}
        </span>
        <input
          ref={inputRef}
          className={cx(Style.input, { [Style.invalid]: invalid })}
          data-test={`${dataTest}.input`}
          disabled={disabled}
          placeholder={focused ? placeholder : ''}
          readOnly={readonly}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      </div>
      {(buttonText || icon) && (
        <div className={Style.controlBlock}>
          {icon && (
            <button
              data-test={`${dataTest}.icon-wrapper`}
              className={cx([
                Style.iconWrapper,
                {
                  [Style.iconDisabled]: disabled,
                  [Style.beforeButton]: buttonText,
                },
              ])}
              onClick={() => !disabled && isFunction(onIconClick) && onIconClick()}
            >
              <Icon data-test={`${dataTest}.icon`} name={icon} />
            </button>
          )}
          {buttonText && (
            <div className={cx([Style.buttonContainer, { [Style.afterIcon]: icon }])}>
              <Button
                data-test={`${dataTest}.button`}
                disabled={disabled}
                fill={ButtonFill.Solid}
                icon={buttonIcon}
                size={ButtonSize.Small}
                onClick={onButtonClick}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      )}
      {suffix && value && (
        <div className={Style.suffix}>
          <span data-test={`${dataTest}.suffix`}>{suffix}</span>
        </div>
      )}
    </InputWrapper>
  )
}

export default Input

export { InputWrapper }
export type { Props, WrapperProps }
