import { assignIn, pick } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, BaseModelWarehouse } from './base-model'

export type RevueNotesSerialized = z.infer<typeof RevueNotes.schema>

export type RevueNotesWarehouse = BaseModelWarehouse & {
  source: string
  target: string
  source_notes: string
  target_notes: string
  other_feedback: string
}

export type RevueNotesStore = BaseModelStore & {
  source: string
  target: string
  source_notes: string
  target_notes: string
  other_feedback: string
}

export interface RevueNotesDetails {
  id: string
  source: string
  target: string
  source_notes: string
  target_notes: string
  other_feedback: string
}

export default class RevueNotes extends BaseModel implements RevueNotesDetails {
  source = ''
  target = ''
  source_notes = ''
  target_notes = ''
  other_feedback = ''

  constructor(id: string) {
    super(id)
  }

  static override readonly schema = BaseModel.schema.extend({
    source: z.string().max(10).default(''),
    target: z.string().max(10).default(''),
    source_notes: z.string().max(20000).default(''),
    target_notes: z.string().max(20000).default(''),
    other_feedback: z.string().max(20000).default(''),
  })

  static override fromSerialized(recordInput: RevueNotesSerialized): RevueNotes {
    const record = RevueNotes.schema.parse(recordInput)
    const model = new RevueNotes(record.id)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.source = record.source || ''
    model.target = record.target || ''
    model.source_notes = record.source_notes || ''
    model.target_notes = record.target_notes || ''
    model.other_feedback = record.other_feedback || ''
    return model
  }

  static override fromDataStore(record: RevueNotesSerialized): RevueNotes {
    const model = new RevueNotes(record.id)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.source = record.source || ''
    model.target = record.target || ''
    model.source_notes = record.source_notes || ''
    model.target_notes = record.target_notes || ''
    model.other_feedback = record.other_feedback || ''
    return model
  }

  override toSerialized(): RevueNotesSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
    } as unknown as RevueNotesSerialized
  }

  override toDataStore(): RevueNotesStore {
    return {
      ...super.toDataStore(),
      target: this.target,
      source: this.source,
      target_notes: this.target_notes,
      source_notes: this.source_notes,
      other_feedback: this.other_feedback,
    }
  }

  project(projection: string[]): Partial<RevueNotesSerialized> {
    const serialized = this.toSerialized()
    return projection?.length ? pick(serialized, projection) : serialized
  }
}
