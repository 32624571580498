import Engagement, { EngagementSerialized } from './engagement'

export enum EngagementGroupType {
  Active = 'active',
  Inactive = 'inactive',
  Draft = 'draft',
  All = 'all',
}

interface EngagementGroupsSerialized {
  active: EngagementSerialized[]
  inactive: EngagementSerialized[]
  draft: EngagementSerialized[]
  totalActive: number
  totalInactive: number
  totalDraft: number
}

export class EngagementGroups {
  active: Engagement[] = []
  inactive: Engagement[] = []
  draft: Engagement[] = []

  totalActive = 0
  totalInactive = 0
  totalDraft = 0

  addActive(engagements: Engagement[]): EngagementGroups {
    this.active.push(...engagements)
    return this
  }

  addInactive(engagements: Engagement[]): EngagementGroups {
    this.inactive.push(...engagements)
    return this
  }

  addDraft(engagements: Engagement[]): EngagementGroups {
    this.draft.push(...engagements)
    return this
  }

  toSerialized(): EngagementGroupsSerialized {
    return {
      active: this.active.map((m) => m.toSerialized()),
      inactive: this.inactive.map((m) => m.toSerialized()),
      draft: this.draft.map((m) => m.toSerialized()),
      totalActive: this.totalActive,
      totalInactive: this.totalInactive,
      totalDraft: this.totalDraft,
    }
  }

  static fromSerialized(serialized: EngagementGroupsSerialized): EngagementGroups {
    const result = new EngagementGroups()
    result
      .addActive(serialized.active.map(Engagement.fromSerialized))
      .addInactive(serialized.inactive.map(Engagement.fromSerialized))
      .addDraft(serialized.draft.map(Engagement.fromSerialized))
    result.totalActive = serialized.totalActive
    result.totalInactive = serialized.totalInactive
    result.totalDraft = serialized.totalDraft
    return result
  }
}
