import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import Loading from '@athena/component/atom/loading'
import { HttpStatus } from '@athena/core/http'

import { useIdentity } from '@apollo/client/hook/auth'
import links from '@future-view/frontend/page/layout/links'
import Path from '@future-view/frontend/router/path'
import { getEmployeeData } from '@future-view/frontend/util/cookieUtil'

interface AuthProps {
  children: React.ReactElement | null
}

const RequireAuth: React.FC<AuthProps> = ({ children }: AuthProps) => {
  const identity = useIdentity()
  const location = useLocation()

  if (identity.isLoading) {
    return <Loading data-test="app.loading" />
  }
  if (identity.isError) {
    toast.error(identity.error as string)
    return null
  }

  if (identity.data?.status !== HttpStatus.OK) {
    window.sessionStorage.setItem('intendedDestination', location.pathname)
    window.location.href = Path.Root
    return null
  }

  const pathData = links.find((item) => {
    return item.to === location.pathname
  })

  const employeeData = getEmployeeData()
  if (employeeData) {
    if (pathData?.peopleManager) {
      if (!employeeData.directReports || employeeData.directReports <= 0) {
        window.location.href = Path.Root
        return null
      }
    } else if (pathData?.admin) {
      if (employeeData.isAdmin !== 'YES') {
        window.location.href = Path.Root
        return null
      }
    }
  }

  return children
}

export default RequireAuth
