import classNames from 'classnames'
import { map } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { IconName as IconNames } from '@athena/component/atom/button'
import Loading from '@athena/component/atom/loading'
import Modal from '@athena/component/molecule/modal'
import { HttpStatus } from '@athena/core/http'
import { Feedback, Quantum } from '@athena/core/model'

import { useGetRoleByIds, useFeedbackByTargetYear } from '@apollo/client/hook/engagement'
import { useEmployeeByIds } from '@artemis/client/hook'
import PeerToPeerReviews from '@future-view/frontend/component/peer-to-peer-reviews'
import { PeerToPeer } from '@future-view/frontend/component/peerspective-matrix/interface'
import { ReviewersBlockProps } from '@future-view/frontend/page/peer-metrics/interface'

import Style from './style.module.scss'

interface ReviewerInfo {
  id: string
  name: string
  role: string
  engagement: string
  count?: string
  email?: string
  firstName?: string
  lastName?: string
}

const ReviewersBlock: React.FC<ReviewersBlockProps> = ({
  idResource,
  resourceFirstName,
  resourceLastName,
  'data-test': dataTest,
  year,
}: ReviewersBlockProps): JSX.Element => {
  const [showPeerToPeer, setPeerToPeer] = useState<Quantum<PeerToPeer>>()

  const [reviewerIds, setReviewerIds] = useState<Record<string, number>>({})
  const [reviewsCounts, setReviewsCounts] = useState<Record<string, number>>({})
  const reviewsResponse = useFeedbackByTargetYear(idResource as string, year)
  const reviews = useMemo<Feedback[]>(() => {
    if (reviewsResponse.isError) {
      toast.error('Could not load reviews')
    }

    return reviewsResponse.data?.data || []
  }, [reviewsResponse.data?.data, reviewsResponse.isError])

  // Compile all unique reviewers found in Feedback.rel.source for all feedbacks with number of occurances
  useEffect(() => {
    if (reviews.length) {
      const reviewCnt: { [key: string]: number } = {}
      const sourceIds = reviews.reduce((acc, curr) => {
        if (!reviewCnt[`${curr.rel.source}|${curr.rel.engagement}`]) {
          reviewCnt[`${curr.rel.source}|${curr.rel.engagement}`] = 0
        }
        reviewCnt[`${curr.rel.source}|${curr.rel.engagement}`] += 1

        if (acc[curr.rel.source]) {
          return {
            ...acc,
            [curr.rel.source]: acc[curr.rel.source] + 1,
          }
        }
        return { ...acc, [curr.rel.source]: 1 }
      }, {} as Record<string, number>)

      setReviewerIds(sourceIds)
      setReviewsCounts(reviewCnt)
    } else {
      setReviewerIds({})
      setReviewsCounts({})
    }
  }, [reviews])

  // Request Resource info for each unique reviewer found in Feedback.rel.source
  const reviewersResponse = useEmployeeByIds(Object.keys(reviewerIds))
  const reviewersRoleResponse = useGetRoleByIds(Object.keys(reviewerIds))

  // Extract needed info from the reviewers' Resource data
  const reviewers = useMemo<ReviewerInfo[]>(() => {
    if (reviewersResponse.isError) {
      toast.error('Could not load reviewers')
    }

    if (!reviewersResponse.data || reviewersResponse.data.status !== HttpStatus.OK || !reviewersResponse.data.data) {
      return []
    }
    const roleData = reviewersRoleResponse.data?.data
    const reviewerData = reviewersResponse.data.data
    const _reviewers = Object.keys(reviewsCounts).map<ReviewerInfo>((id) => {
      const reviewer = reviewerData.find((item) => {
        return item.employeeId === id.split('|')[0]
      })
      return {
        id: reviewer?.employeeId || '',
        count: reviewsCounts[id].toString(),
        name: reviewer?.name || '',
        firstName: reviewer?.firstName,
        lastName: reviewer?.lastName,
        engagement: id.split('|')[1],
        email: '',
        role:
          roleData?.find((item) => {
            return item.rel.resource === reviewer?.employeeId && item.rel.engagement === id.split('|')[1]
          })?.role || '',
      }
    })

    return _reviewers.reduce((acc, item) => {
      const existingItem = acc.find((accItem) => accItem.id === item.id && accItem.role === item.role)
      if (existingItem) {
        existingItem.count = String(parseInt(existingItem?.count ?? '0', 10) + parseInt(item?.count ?? '0', 10))
      } else {
        acc.push(item)
      }
      return acc
    }, [] as ReviewerInfo[])
  }, [reviewersResponse, reviewersRoleResponse?.data?.data, reviewsCounts])

  const handleClick = (data: PeerToPeer) => {
    return (event: React.MouseEvent) => {
      setPeerToPeer(data)
      event.preventDefault()
    }
  }

  const handleClose = () => {
    setPeerToPeer(undefined)
  }

  return (
    <>
      <div className={Style.wrapper} data-test={`${dataTest}.container`}>
        <div className={Style.title} data-test={`${dataTest}.title`}>
          {`Reviewers` + (reviews ? ` (${Object.keys(reviewerIds).length})` : '')}
        </div>
        <div className={Style.table}>
          <header className={Style.row}>
            <div className={Style.col}>Name</div>
            <div className={Style.col}>Role</div>
            <div className={classNames(Style.col, Style.cellRight)}>Reviews</div>
          </header>
          {(reviewsResponse.isLoading || reviewersResponse.isLoading) && (
            <div className={Style.loadingContainer}>
              <Loading show data-test={`${dataTest}.loading`} />
            </div>
          )}
          {!(reviewsResponse.isLoading || reviewersResponse.isLoading) &&
            map(
              reviewers,
              (reviewer) =>
                reviewer.role && (
                  <div key={`${reviewer.name}-${reviewer.role}`} className={Style.row} data-test={`${dataTest}.row`}>
                    <div
                      className={classNames(Style.col, Style.clickable)}
                      onClick={handleClick({
                        source: {
                          id: reviewer.id,
                          firstName: reviewer.firstName || '',
                          lastName: reviewer.lastName || '',
                          name: reviewer.name,
                          email: '',
                          role: reviewer.role,
                        },
                        target: {
                          id: idResource,
                          firstName: resourceFirstName,
                          lastName: resourceLastName,
                          name: `${resourceFirstName} ${resourceLastName}`,
                          email: '',
                          role: '',
                        },
                      })}
                    >
                      {reviewer.name}
                    </div>
                    <div className={Style.col}>{reviewer.role}</div>
                    <div className={classNames(Style.col, Style.cellRight)}>{reviewer.count}</div>
                  </div>
                )
            )}
          {!reviewsResponse.isLoading && !reviewersResponse.isLoading && (!reviews || reviews.length === 0) && (
            <div className={Style.noContent} data-test={`${dataTest}.no-items`}>{`No reviewers`}</div>
          )}
        </div>
      </div>
      <Modal
        closeIcon={IconNames.XCircle}
        data-test={dataTest}
        headerText={''}
        isShown={!!showPeerToPeer}
        wrapperCustomClasses={Style.peerToPeerModal}
        onHide={handleClose}
      >
        <PeerToPeerReviews
          data-test={'PeerToPeerReviews'}
          source={showPeerToPeer?.source}
          target={showPeerToPeer?.target}
          wrapperCustomClasses={''}
        ></PeerToPeerReviews>
      </Modal>
    </>
  )
}

export default ReviewersBlock
