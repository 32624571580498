import { assignIn, chain, map, round, toNumber } from 'lodash'
import { DateTime } from 'luxon'
import { z } from 'zod'

import ActivityAssignment, { ActivityAssignmentProjection } from './activity-assignment'
import BaseModel, { BaseModelWarehouse, DateFormat, Quantum, Reference, Schema } from './base-model'
import RequiredCapability, { RequiredCapabilityProjection } from './required-capability'

export type ResourcedActivitySerialized = z.infer<typeof ResourcedActivity.schema>

export type ResourcedActivityWarehouse = BaseModelWarehouse & {
  KimbleOne__BusinessUnit__c: string
  BusinessUnit__Name: string
  Owner__Resource: string
  User__Name: string
  KimbleOne__FullName__c: string
  KimbleOne__ActivatedTime__c: string
  KimbleOne__EarliestAssignmentStartDate__c: string
  KimbleOne__LatestAssignmentEndDate__c: string
  KimbleOne__ActualCost__c: number
  KimbleOne__ActualUsage__c: string
  KimbleOne__BaselineUsage__c: string
  KimbleOne__ForecastUsage__c: string
  KimbleOne__ForecastP3Usage__c: number
  KimbleOne__ForecastP3Cost__c: number
  KimbleOne__DefaultRevenue__c: string
  KimbleOne__AllowFrameworkAssignment__c: string
  KimbleOne__AllowNewExpenseItems__c: string
  KimbleOne__AllowNewRevenueCostItems__c: string
  KimbleOne__AllowNewTasks__c: string
  KimbleOne__AllowNewTimeAdjustments__c: string
  KimbleOne__AllowNewTimeEntries__c: string
}

export default class ResourcedActivity extends BaseModel {
  rel = {
    businessUnit: '',
    owner: '',
  }
  capabilities: RequiredCapability[] = []
  assignments: ActivityAssignment[] = []
  businessUnit = ''
  owner = ''
  fullName = ''
  activated: Quantum<Date>
  earliestAssignmentStart: Quantum<Date>
  latestAssignmentEnd: Quantum<Date>
  actualCost = 0
  actualUsage = 0
  baselineUsage = 0
  forecastUsage = 0
  forecastP3Cost = 0
  forecastP3Usage = 0
  defaultRevenue = 0
  allowFrameworkAssignment = false
  allowNewExpense = false
  allowNewRevenueCost = false
  allowNewTask = false
  allowNewTimeAdjustment = false
  allowNewTimeEntry = false

  constructor(id: string, name: string, created: Quantum<Date>, updated: Quantum<Date>, businessUnit: string) {
    super(id, name, created, updated)
    this.businessUnit = businessUnit || ''
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      businessUnit: Schema.Id,
      owner: Schema.Id,
    }),
    capabilities: z.array(RequiredCapability.schema).default([]),
    assignments: z.array(ActivityAssignment.schema).default([]),
    businessUnit: Schema.Name,
    owner: Schema.Name,
    fullName: z.string().max(255).default(''),
    activated: Schema.Date,
    earliestAssignmentStart: Schema.Date,
    latestAssignmentEnd: Schema.Date,
    actualCost: z.number().min(0).max(100_000_000).default(0),
    actualUsage: z.number().min(0).max(100_000).default(0),
    forecastUsage: z.number().min(0).max(1_000_000).default(0),
    forecastP3Cost: z.number().min(0).max(100_000_000).default(0),
    forecastP3Usage: z.number().min(0).max(1_000_000).default(0),
    defaultRevenue: z.number().min(0).max(100_000_000).default(0),
    allowFrameworkAssignment: z.boolean().default(false),
    allowNewExpense: z.boolean().default(false),
    allowNewRevenueCost: z.boolean().default(false),
    allowNewTask: z.boolean().default(false),
    allowNewTimeAdjustment: z.boolean().default(false),
    allowNewTimeEntry: z.boolean().default(false),
  })

  static override fromSerialized(recordInput: ResourcedActivitySerialized): ResourcedActivity {
    const record = ResourcedActivity.schema.parse(recordInput)
    const model = new ResourcedActivity(
      record.id,
      record.name,
      BaseModel.parseDate(record.created),
      BaseModel.parseDate(record.updated),
      record.businessUnit
    )
    model.rel = record.rel || {}
    model.capabilities = record.capabilities?.map(RequiredCapability.fromSerialized) || []
    model.assignments = record.assignments?.map(ActivityAssignment.fromSerialized) || []
    model.owner = record.owner
    model.fullName = record.fullName
    model.activated = BaseModel.parseDate(record.activated)
    model.earliestAssignmentStart = BaseModel.parseDate(record.earliestAssignmentStart)
    model.latestAssignmentEnd = BaseModel.parseDate(record.latestAssignmentEnd)
    model.actualCost = round(record.actualCost, 2)
    model.actualUsage = round(record.actualCost, 2)
    model.forecastUsage = round(record.forecastUsage, 2)
    model.forecastP3Cost = round(record.forecastP3Cost, 2)
    model.forecastP3Usage = round(record.forecastP3Usage, 2)
    model.defaultRevenue = round(record.defaultRevenue, 2)
    model.allowFrameworkAssignment = !!record.allowFrameworkAssignment
    model.allowNewExpense = !!record.allowNewExpense
    model.allowNewRevenueCost = !!record.allowNewRevenueCost
    model.allowNewTask = !!record.allowNewTask
    model.allowNewTimeAdjustment = !!record.allowNewTimeAdjustment
    model.allowNewTimeEntry = !!record.allowNewTimeEntry

    return model
  }

  static override fromWarehouse(record: ResourcedActivityWarehouse): ResourcedActivity {
    const model = new ResourcedActivity(
      record.Id,
      record.Name,
      BaseModel.parseDate(record.CreatedDate),
      BaseModel.parseDate(record.LastModifiedDate),
      record.BusinessUnit__Name
    )
    model.rel = {
      businessUnit: record.KimbleOne__BusinessUnit__c,
      owner: record.Owner__Resource,
    }
    model.owner = record.User__Name
    model.fullName = record.KimbleOne__FullName__c
    model.activated = BaseModel.parseDate(record.KimbleOne__ActivatedTime__c)
    model.earliestAssignmentStart = BaseModel.parseDate(record.KimbleOne__EarliestAssignmentStartDate__c)
    model.latestAssignmentEnd = BaseModel.parseDate(record.KimbleOne__LatestAssignmentEndDate__c)
    model.actualCost = round(record.KimbleOne__ActualCost__c, 2)
    model.actualUsage = round(toNumber(record.KimbleOne__ActualUsage__c), 2)
    model.forecastUsage = round(toNumber(record.KimbleOne__ForecastUsage__c), 2)
    model.forecastP3Cost = round(record.KimbleOne__ForecastP3Cost__c, 2)
    model.forecastP3Usage = round(record.KimbleOne__ForecastP3Usage__c, 2)
    model.defaultRevenue = round(toNumber(record.KimbleOne__DefaultRevenue__c), 2)
    model.allowFrameworkAssignment = BaseModel.parseBoolean(record.KimbleOne__AllowFrameworkAssignment__c)
    model.allowNewExpense = BaseModel.parseBoolean(record.KimbleOne__AllowNewExpenseItems__c)
    model.allowNewRevenueCost = BaseModel.parseBoolean(record.KimbleOne__AllowNewRevenueCostItems__c)
    model.allowNewTask = BaseModel.parseBoolean(record.KimbleOne__AllowNewTasks__c)
    model.allowNewTimeAdjustment = BaseModel.parseBoolean(record.KimbleOne__AllowNewTimeAdjustments__c)
    model.allowNewTimeEntry = BaseModel.parseBoolean(record.KimbleOne__AllowNewTimeEntries__c)

    return model
  }

  static override referenceFromWarehouse(record: ResourcedActivityWarehouse): Reference {
    return {
      id: record.Id,
      name: record.KimbleOne__FullName__c,
    }
  }

  override toSerialized(): ResourcedActivitySerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      capabilities: map(this.capabilities, (c) => c.project(RequiredCapabilityProjection.Embed)),
      assignments: map(this.assignments, (a) => a.project(ActivityAssignmentProjection.Embed)),
      activated: BaseModel.formatDate(this.activated, DateFormat.Date),
      earliestAssignmentStart: BaseModel.formatDate(this.earliestAssignmentStart, DateFormat.Date),
      latestAssignmentEnd: BaseModel.formatDate(this.latestAssignmentEnd, DateFormat.Date),
    } as unknown as ResourcedActivitySerialized
  }

  get isUpcoming(): boolean {
    if (!this.earliestAssignmentStart) {
      return false
    }

    return this.earliestAssignmentStart.getTime() > new Date().getTime()
  }

  get isActive(): boolean {
    if (!this.earliestAssignmentStart || !this.latestAssignmentEnd) {
      return false
    }

    const startTime = this.earliestAssignmentStart.getTime()
    const endTime = this.latestAssignmentEnd.getTime()
    const now = new Date().getTime()
    return now > startTime && now < endTime
  }

  get locationList(): string[] {
    return chain(this.assignments)
      .map((a) => a.resourceLocation)
      .uniq()
      .value()
  }

  get skillList(): string[] {
    return chain(this.capabilities)
      .map((c) => c.capabilityType)
      .uniq()
      .value()
  }

  get capabilityGroupList(): string[] {
    return chain(this.capabilities)
      .map((c) => c.capabilityGroup)
      .uniq()
      .value()
  }

  formatDateRange() {
    const start = this.earliestAssignmentStart
      ? DateTime.fromJSDate(this.earliestAssignmentStart).toLocaleString(DateTime.DATE_MED)
      : ''
    const end = this.latestAssignmentEnd
      ? DateTime.fromJSDate(this.latestAssignmentEnd).toLocaleString(DateTime.DATE_MED)
      : ''
    return start && end ? `${start} | ${end}` : `${start}`
  }
}
