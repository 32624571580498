import { isFunction } from 'lodash'
import { useEffect, useState } from 'react'

import Combobox from '@athena/component/atom/combobox'
import Textarea from '@athena/component/atom/textarea'

import { JustificationSelectorProps } from './interface'
import { getOptions } from './survey-utils'

const JustificationSelector = ({
  'data-test': dataTest,
  list,
  maxAccepted,
  maxDisplayed,
  elementName,
  label,
  disabled = false,
  onChange,
}: JustificationSelectorProps) => {
  const [selectedItems, setSelectedItems] = useState<Record<string, { value: string; index: number }>>({})

  useEffect(() => {
    const lessThanMaxDisplayed = Object.keys(list).length <= maxDisplayed
    const newSelectedItems = Object.keys(list).reduce((acc, curr, index) => {
      let i = list[curr]?.index
      if (lessThanMaxDisplayed) {
        i = index
      }
      return {
        ...acc,
        [curr]: {
          value: list[curr]?.value || '',
          index: i,
        },
      }
    }, {})
    setSelectedItems(newSelectedItems)
  }, [list, maxAccepted, maxDisplayed])

  return (
    <div className="flex flex-col w-full gap-4" data-test={`${dataTest}.container`}>
      {Object.keys(list).length <= Math.min(maxAccepted, maxDisplayed)
        ? Object.keys(list).map((key, index) => {
            const el = Object.keys(selectedItems).find((k) => selectedItems[k].index === index)

            return el ? (
              <Textarea
                key={key}
                data-test={`${dataTest}.input.${key}`}
                disabled={disabled}
                label={key}
                valid={!!selectedItems[el]?.value?.length}
                value={selectedItems[el]?.value}
                help={
                  !selectedItems[el].value.length ? `Provide a supporting example exemplifying this ${elementName}` : ''
                }
                onChange={(val) => {
                  const newSelectedItems = { ...selectedItems, [el]: { ...selectedItems[el], value: val } }
                  setSelectedItems(newSelectedItems)
                  isFunction(onChange) && onChange(newSelectedItems)
                }}
              />
            ) : null
          })
        : null}
      {Object.keys(list).length > Math.min(maxAccepted, maxDisplayed)
        ? Object.keys(list)
            .slice(0, maxDisplayed)
            .map((key, index) => {
              const el = Object.keys(selectedItems).find((k) => selectedItems[k].index === index)
              return (
                <div key={el || index} className="flex flex-col gap-2 w-full">
                  <Combobox
                    data-test={`${dataTest}.select.${el || index}`}
                    disabled={disabled}
                    label={label}
                    value={getOptions([el || ''])[0]}
                    options={getOptions([
                      ...Object.keys(list).filter(
                        (i) =>
                          !Object.keys(selectedItems)
                            .filter((k) => selectedItems[k].index > -1)
                            .includes(i)
                      ),
                    ])}
                    onChange={(val) => {
                      if (!val) {
                        return
                      }
                      const _val = (val as { value: string }).value
                      const newSelectedItems = {
                        ...selectedItems,
                        [_val]: { value: '', index },
                        ...(el && { [el]: { value: '', index: -1 } }),
                      }
                      setSelectedItems(newSelectedItems)
                      isFunction(onChange) && onChange(newSelectedItems)
                    }}
                  />
                  {el ? (
                    <Textarea
                      key={el}
                      data-test={`${dataTest}.input.${el}`}
                      disabled={disabled}
                      label={el}
                      valid={!!selectedItems[el].value.length}
                      value={selectedItems[el].value}
                      help={
                        !selectedItems[el].value.length
                          ? `Provide a supporting example exemplifying this ${elementName}`
                          : ''
                      }
                      onChange={(val) => {
                        const newSelectedItems = { ...selectedItems, [el]: { ...selectedItems[el], value: val } }
                        setSelectedItems(newSelectedItems)
                        isFunction(onChange) && onChange(newSelectedItems)
                      }}
                    />
                  ) : null}
                </div>
              )
            })
        : null}
    </div>
  )
}

export default JustificationSelector
