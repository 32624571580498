import classNames from 'classnames'
import { isFunction } from 'lodash'
import React from 'react'

import Avatar from '@future-view/frontend/component/avatar'

import Props from './interface'
import Style from './style.module.scss'

const ResourceCard: React.FC<Props> = ({
  'data-test': dataTest = 'resource-card',
  id,
  name = '',
  role = '',
  fname = '',
  lname = '',
  wrapperCustomClasses,
  onClick,
}) => {
  return (
    <div
      className={classNames(wrapperCustomClasses, Style.wrapper, { 'cursor-pointer': !!onClick })}
      data-test={`${dataTest}.container`}
      onClick={() => isFunction(onClick) && id && onClick(id)}
    >
      {id && <Avatar cid={id} data-test={`${dataTest}.avatar`} wrapperCustomClasses={Style.img}></Avatar>}
      <div className={Style.textWrapper}>
        {name && (
          <label className={Style.name} data-test={`${dataTest}.name`}>
            {name}
          </label>
        )}
        {role && (
          <label className={Style.role} data-test={`${dataTest}.role`}>
            {role}
          </label>
        )}
        {fname && (
          <label className={Style.name} data-test={`${dataTest}.fname`}>
            {fname}
          </label>
        )}
        {fname && (
          <label className={Style.name} data-test={`${dataTest}.lname`}>
            {lname}
          </label>
        )}
      </div>
    </div>
  )
}

export default ResourceCard
export type { Props }
