export default interface Props {
  'data-test': string
  autoSaveInterval?: number
  customClasses?: string
  disabled?: boolean
  help?: string
  id?: string
  isAutogrow?: boolean
  isAutoSave?: boolean
  label: string
  maxSymbols?: number
  placeholder?: string
  retryCt?: number
  retryInterval?: number
  rows?: number
  valid?: boolean
  value?: string
  onChange?: (value: string, id?: string) => void
  onBlur?: (value: string, id?: string) => void
  onSave?: (value: string, id?: string) => Promise<void>
  onSavingStatusChange?: (value: SavingStatus, id?: string) => void
  onFocus?: (flag: boolean, id?: string) => void
}

export enum SavingStatus {
  NotSaved = '',
  Saved = 'Saved',
  Saving = 'Saving...',
  Failed = 'Unable to save',
}
