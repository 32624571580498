import { useMemo, useState } from 'react'

import { Option } from '@athena/component/atom/combobox'
import Modal from '@athena/component/molecule/modal'

import { useIdentity } from '@apollo/client/hook/auth'
import { useGetDistinctRevueObjectiveCreationYears } from '@apollo/client/hook/revue'
import { useEmployeeByEmail, useResourceDept } from '@artemis/client/hook'
import PerformanceObjectiveDetail from '@future-view/frontend/component/performance-objective-detail'
import PerformanceObjectiveList from '@future-view/frontend/component/performance-objective-list'
import RevueNotes from '@future-view/frontend/component/revue_notes'
import { generateYearOptionsFromArray } from '@future-view/frontend/util'

import RevueHeader from './header'
import Style from './style.module.scss'

const dataTest = 'revue.dashboard'

const RevueDashboard = () => {
  const [yearOptions, setYearOptions] = useState<Option[]>([])
  const [selectedYearOption, setSelectedYearOption] = useState<Option | null>(null)
  const [selectedObjective, setSelectedObjective] = useState<string>()
  const identity = useIdentity()
  const loggedInUserEmail = identity?.data?.data?.email
  const { data: resourceResponse } = useEmployeeByEmail(loggedInUserEmail?.toLowerCase() || '')
  const { data: headerResponse } = useResourceDept(resourceResponse?.data?.employeeId || '')
  const headerData = headerResponse?.data
  const revueObjectivesCreationYears = useGetDistinctRevueObjectiveCreationYears(identity?.data?.data?.cid || '')

  useMemo(() => {
    if (revueObjectivesCreationYears.data) {
      const years = revueObjectivesCreationYears.data.data as string[]
      const yearOps = generateYearOptionsFromArray(years)
      setYearOptions(yearOps)
      if (yearOps.length > 0) setSelectedYearOption(yearOps[0])
    }
  }, [revueObjectivesCreationYears.data])

  const onYearSelection = (year: Option) => {
    setSelectedYearOption(year)
  }

  const onHideObjectiveModal = () => {
    setSelectedObjective(undefined)
  }
  return (
    <div className={'flex flex-col min-h-full gap-[10px] p-[10px]'} data-test={`${dataTest}.wrapper`}>
      <RevueHeader
        data-test={`${dataTest}.header`}
        department={headerData?.department || ''}
        level={headerData?.division?.split('- ').pop() || ''}
        managerId={headerData?.rel?.manager || ''}
        managerName={`${headerData ? `${headerData?.firstName || ''} ${headerData?.lastName || ''}` : ''}`}
        selectedYear={selectedYearOption}
        yearOptions={yearOptions}
        onYearChange={onYearSelection}
      ></RevueHeader>
      <div className={'flex flex-nowrap flex-row'}>
        <div className={'flex flex-col flex-1 basis-1/2 gap-[10px] h-full'}>
          <PerformanceObjectiveList
            data-test={`${dataTest}.objective-list`}
            target={resourceResponse?.data?.employeeId || ''}
            year={selectedYearOption?.value}
            onClicked={setSelectedObjective}
          ></PerformanceObjectiveList>
        </div>
        <div
          className={'flex flex-col flex-1 basis-1/2 gap-[10px] h-[calc(100vh-157px)]'}
          data-test={`${dataTest}.general`}
        >
          <RevueNotes
            data-test={`${dataTest}.objective-list`}
            target={resourceResponse?.data?.employeeId || ''}
            year={selectedYearOption?.value}
            onClicked={setSelectedObjective}
          ></RevueNotes>
        </div>
      </div>
      <Modal
        hideCloseButton
        data-test={`${dataTest}.modal`}
        isShown={!!selectedObjective}
        wrapperCustomClasses={Style.modalHeight}
      >
        <PerformanceObjectiveDetail
          data-test={`interest-confirmation`}
          handleClose={onHideObjectiveModal}
          id={selectedObjective || ''}
        />
      </Modal>
    </div>
  )
}
export default RevueDashboard
