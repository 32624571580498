import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import Response from '@athena/core/client/response'
import { Engagement, Feedback, FeedbackRequest } from '@athena/core/model'
import { EngagementGroupType } from '@athena/core/model/engagement-grouped-list'

import {
  addEngagement,
  addFeedback,
  addFeedbackRequest,
  addFeedbackRequestsForEngagement,
  canBeDeleted,
  getEngagement,
  getEngagementByActivity,
  getEngagementList,
  getEngagements,
  getFeedback,
  getFeedbackByEngagement,
  getFeedbackByFeedbackRequest,
  getFeedbackBySource,
  getFeedbackByTargetArray,
  getFeedbackByTargetYear,
  getFeedbackBySourceAndTarget,
  getFeedbackList,
  getFeedbackRequest,
  getFeedbackRequestByEngagement,
  getFeedbackRequestBySource,
  getFeedbackRequestByTarget,
  getFeedbackRequestMaxCreatedDateForActiveSeats,
  removeEngagement,
  removeFeedback,
  removeFeedbackRequest,
  updateEngagement,
  updateFeedback,
  updateFeedbackRequest,
  getEngagementsByResource,
  getFeedbackRequests,
  getEngagementsDateRange,
  getRoleById,
  getFeedbackByTrait,
} from '@apollo/client/api/engagement'

export const QueryKey = {
  Engagement: 'engagement-item',
  EngagementByActivity: 'engagement-by-activity',
  EngagementList: 'engagement-list',
  EngagementCanBeDeleted: 'can-be-deleted',
  EngagementByResource: 'engagement-by-resource',
  EngagementDateRange: 'engagement-date-range',
  Feedback: 'feedback-item',
  FeedbackList: 'feedback-list',
  FeedbackByEngagement: 'feedback-by-engagement',
  FeedbackByFeedbackRequest: 'feedback-by-feedback-request',
  FeedbackBySource: 'feedback-by-source',
  FeedbackByTarget: 'feedback-by-target',
  FeedbackByTargetYear: 'feedback-by-target-year',
  FeedbackBySourceAndTarget: 'feedback-by-source-and-target',
  FeedbackRequest: 'feedback-request-item',
  FeedbackRequestByEngagement: 'feedback-request-by-engagement',
  FeedbackRequestBySource: 'feedback-request-by-source',
  FeedbackRequestByTarget: 'feedback-request-by-target',
  FeedbackRequestByTargetArray: 'feedback-request-by-target-array',
  RoleById: 'role-by-id',
  FeedbackListReport: 'feedback-report-list',
}

const useInvalidateEngagement = (client: QueryClient) => (resp?: Response<Engagement>) => {
  client.invalidateQueries({ queryKey: [QueryKey.Engagement, resp?.data?.id] })
  client.invalidateQueries({ queryKey: [QueryKey.EngagementList] })
  client.invalidateQueries({ queryKey: [QueryKey.EngagementByActivity, resp?.data?.rel.activity] })
}

const useInvalidateAllEngagement = (client: QueryClient) => () => {
  client.invalidateQueries({ queryKey: [QueryKey.Engagement] })
  client.invalidateQueries({ queryKey: [QueryKey.EngagementList] })
  client.invalidateQueries({ queryKey: [QueryKey.EngagementByActivity] })
}

const useInvalidateFeedbackRequest = (client: QueryClient) => (resp?: Response<FeedbackRequest>) => {
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackRequest, resp?.data?.id] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackRequestByEngagement, resp?.data?.rel.engagement] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackRequestBySource, resp?.data?.rel.source] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackRequestByTarget, resp?.data?.rel.target] })
}

const useInvalidateAllFeedbackRequest = (client: QueryClient) => () => {
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackRequest] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackRequestByEngagement] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackRequestBySource] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackRequestByTarget] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackRequestByTargetArray] })
}

const useInvalidateFeedback = (client: QueryClient) => (resp?: Response<Feedback>) => {
  client.invalidateQueries({ queryKey: [QueryKey.Feedback, resp?.data?.id] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackByEngagement, resp?.data?.rel.engagement] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackByFeedbackRequest, resp?.data?.rel.feedbackRequest] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackBySource, resp?.data?.rel.source] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackByTarget, resp?.data?.rel.target] })
}

const useInvalidateAllFeedback = (client: QueryClient) => () => {
  client.invalidateQueries({ queryKey: [QueryKey.Feedback] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackByEngagement] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackBySource] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackByTarget] })
  client.invalidateQueries({ queryKey: [QueryKey.FeedbackByFeedbackRequest] })
}

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //

export const useEngagement = (id: string) =>
  useQuery({
    queryKey: [QueryKey.Engagement, id],
    queryFn: () => getEngagement(id),
    enabled: !!id,
  })

export const useEngagementList = () =>
  useQuery({
    queryKey: [QueryKey.EngagementList],
    queryFn: getEngagementList,
  })

export const useEngagementByActivity = (id: string) =>
  useQuery({
    queryKey: [QueryKey.EngagementByActivity, id],
    queryFn: () => getEngagementByActivity(id),
    enabled: !!id,
  })

export const useGetEngagements = (state: EngagementGroupType, limit: number, offset: number) =>
  useQuery({
    queryKey: [QueryKey.EngagementList, state, limit, offset],
    queryFn: () => getEngagements(state, limit, offset, true),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    keepPreviousData: false,
  })

export const useAddEngagement = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: addEngagement,
    onSuccess: useInvalidateEngagement(client),
    onError: (err) => toast.error(`Error adding engagement: ${err}`),
  })
}

export const useUpdateEngagement = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: updateEngagement,
    onSuccess: useInvalidateEngagement(client),
    onError: (err) => toast.error(`Error updating engagement: ${err}`),
  })
}

export const useRemoveEngagement = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: removeEngagement,
    onSuccess: useInvalidateAllEngagement(client),
    onError: (err) => toast.error(`Error removing engagement: ${err}`),
  })
}

export const useGetEngagementsByResource = (idResource: string, year: string) =>
  useQuery({
    queryKey: [QueryKey.EngagementByResource, idResource, year],
    queryFn: () => getEngagementsByResource(idResource, year),
    enabled: !!idResource,
  })

export const useGetEngagementsDateRange = (idEmployee: string) =>
  useQuery({
    queryKey: [QueryKey.EngagementDateRange, idEmployee],
    queryFn: () => getEngagementsDateRange(idEmployee),
    enabled: !!idEmployee,
  })

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //

export const useFeedbackRequest = (id: string) =>
  useQuery({
    queryKey: [QueryKey.FeedbackRequest, id],
    queryFn: () => getFeedbackRequest(id),
    enabled: !!id,
  })

export const useFeedbackRequestByEngagement = (id: string) =>
  useQuery({
    queryKey: [QueryKey.FeedbackRequestByEngagement, id],
    queryFn: () => getFeedbackRequestByEngagement(id),
    enabled: !!id,
  })

//TODO review if this is needed because it is not used in the UI
export const useFeedbackRequestBySource = (id: string) =>
  useQuery({
    queryKey: [QueryKey.FeedbackRequestBySource, id],
    queryFn: () => getFeedbackRequestBySource(id),
    enabled: !!id,
  })

export const useFeedbackRequestByTarget = (id: string) =>
  useQuery({
    queryKey: [QueryKey.FeedbackRequestByTarget, id],
    queryFn: () => getFeedbackRequestByTarget(id),
    enabled: !!id,
  })

export const useFeedbackRequests = (ids: string[], states: string[], source: string, isForManager: boolean) =>
  useQuery({
    queryKey: [QueryKey.FeedbackRequestByTargetArray, ids, states, source, isForManager],
    queryFn: () => getFeedbackRequests({ ids, states, source, isForManager }),
    enabled: !!ids && !!source,
  })

//TODO review if this is needed because it is not used in the UI
export const useAddFeedbackRequest = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: addFeedbackRequest,
    onSuccess: useInvalidateFeedbackRequest(client),
    onError: (err) => toast.error(`Error adding feedback request ${err}`),
  })
}

export const useAddEngagementRequests = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: addFeedbackRequestsForEngagement,
    onSuccess: useInvalidateAllFeedbackRequest(client),
    onError: (err) => toast.error(`Error creating feedback requests for the engagement ${err}`),
  })
}

export const useUpdateFeedbackRequest = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: updateFeedbackRequest,
    onSuccess: useInvalidateAllFeedbackRequest(client),
    onError: (err) => toast.error(`Error updating feedback request ${err}`),
  })
}

export const useRemoveFeedbackRequest = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: removeFeedbackRequest,
    onSuccess: useInvalidateAllFeedbackRequest(client),
    onError: (err) => toast.error(`Error removing feedback: request ${err}`),
  })
}

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //
//TODO review if this is needed because it is not used in the UI
export const useFeedback = (id: string) =>
  useQuery({
    queryKey: [QueryKey.Feedback, id],
    queryFn: () => getFeedback(id),
    enabled: !!id,
  })
//TODO review if this is needed because it is not used in the UI
export const useFeedbackByEngagement = (id: string) =>
  useQuery({
    queryKey: [QueryKey.FeedbackByEngagement, id],
    queryFn: () => getFeedbackByEngagement(id),
    enabled: !!id,
  })

export const useFeedbackByFeedbackRequest = (id: string) =>
  useQuery({
    queryKey: [QueryKey.FeedbackByFeedbackRequest, id],
    queryFn: () => getFeedbackByFeedbackRequest(id),
    enabled: !!id,
  })
//TODO review if this is needed because it is not used in the UI
export const useFeedbackBySource = (id: string) =>
  useQuery({
    queryKey: [QueryKey.FeedbackBySource, id],
    queryFn: () => getFeedbackBySource(id),
    enabled: !!id,
  })

export const useFeedbackByTargetArray = (ids: string[]) =>
  useQuery({
    queryKey: [QueryKey.FeedbackByTarget, ids],
    queryFn: () => getFeedbackByTargetArray(ids),
    enabled: !!ids,
  })

export const useFeedbackBySourceAndTargetCall = (data: Feedback) =>
  useQuery({
    queryKey: [QueryKey.FeedbackBySourceAndTarget, data],
    queryFn: () => getFeedbackBySourceAndTarget(data),
    enabled: !!data,
  })

export const useFeedbackByTargetYear = (id: string, year: string | null) =>
  useQuery({
    queryKey: [QueryKey.FeedbackByTargetYear, id, year],
    queryFn: () => getFeedbackByTargetYear(id, year as string),
    enabled: !!id,
  })

export const useFeedbackBySourceAndTarget = () => {
  return useMutation({
    mutationFn: getFeedbackBySourceAndTarget,
    onError: (err) => toast.error(`Error getting feedbacks ${err}`),
  })
}

export const useFeedbackByTrait = (trait: string, target: string) =>
  useQuery({
    queryKey: [QueryKey.FeedbackBySourceAndTarget, trait, target],
    queryFn: () => getFeedbackByTrait(trait, target),
    enabled: !!trait && !!target,
  })

export const useAddFeedback = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: addFeedback,
    onSuccess: useInvalidateAllFeedback(client),
    onError: (err) => toast.error(`Error adding feedback ${err}`),
  })
}

export const useUpdateFeedback = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: updateFeedback,
    onSuccess: useInvalidateFeedback(client),
    onError: (err) => toast.error(`Error updating feedback ${err}`),
  })
}
//TODO review if this is needed because it is not used in the UI
export const useRemoveFeedback = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: removeFeedback,
    onSuccess: useInvalidateAllFeedback(client),
    onError: (err) => toast.error(`Error removing feedback: ${err}`),
  })
}

export const useFeedbackRequestHorizon = (id: string) => {
  return useQuery({
    queryKey: ['feedback_date', id],
    queryFn: () => getFeedbackRequestMaxCreatedDateForActiveSeats(id),
    enabled: !!id,
  })
}

// get all feedbacks, possibly by user id like getAllFeedbacksByUserId(id)
export const useGetAllFeedbacks = () =>
  useQuery({
    queryKey: [QueryKey.FeedbackList],
    queryFn: getFeedbackList,
  })

export const useCanBeDeleted = (id: string) => {
  return useQuery({
    queryKey: [QueryKey.EngagementCanBeDeleted, id],
    queryFn: () => canBeDeleted(id),
    enabled: !!id,
  })
}

export const useGetRoleByIds = (ids: string[]) =>
  useQuery({
    queryKey: [QueryKey.RoleById, ids],
    queryFn: () => getRoleById(ids),
    enabled: !!ids,
  })

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //
