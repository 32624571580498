import { assignIn, isDate, includes, toLower, trim } from 'lodash'
import { DateTime } from 'luxon'
import { z } from 'zod'

/**
 * Baseline type for serialized models.
 * This represents model data that will be rendered into a portable format
 * for transport between system modules. More often times that not this is used for pre-processing
 * before/after serializing or parsing to JSON.
 */
export type BaseModelSerialized = {
  id: string
  name: string
  created: string | null
  updated: string | null
}

/**
 * Baseline type for warehouse models.
 * This represents the baseline properties that are always expected from any data that is retrieved
 * from the data warehouse.
 */
export type BaseModelWarehouse = {
  Id: string
  Name: string
  CreatedDate: string
  LastModifiedDate?: string
}

/**
 * Baseline type for operational datastore models.
 * This represents the baseline properties that are always expected from any model that is retrieved
 * from the operational datastore.
 */
export type BaseModelStore = {
  id: string
  created: string | null
  updated: string | null
}

/**
 * Baseline type for warehouse references.
 */
export type ReferenceWarehouse = {
  Id: string
  Name: string
}

/**
 * An abbreviated representation of the model that only exposes the unique identifier and a human-readable
 * string representation of said model
 */
export type Reference = {
  id: string
  name: string
}

/**
 * Utility type that facilitates the declaration of optional data types.
 * This is a shortcut to a type that can potentially return the declared type or undefined
 */
export type Quantum<T> = T | undefined | null

/**
 * Utility union type that declares the possible data types that can be used to declare date values.
 */
export type DateLike = Date | string | number | undefined | null

/** List of predefined formats for date value serialization */
export enum DateFormat {
  Full,
  Date,
  Time,
}

/** List of predefined reusable schema definition for common value types in models */
export const Schema = {
  Id: z.string().max(24),
  Name: z.string().max(256),
  Date: z
    .string()
    .regex(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)
    .nullable(),
  Time: z
    .string()
    .regex(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/)
    .nullable(),
  DateTime: z
    .string()
    .regex(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.?[0-9]{0,4}Z$/)
    .nullable(),
}

/**
 * Baseline model. All system models should be sub classes of this class.
 */
export default class BaseModel {
  /** System-wide, unique identifier */
  id: string

  /** Primary name that identifies the model. Typically human-readable. */
  name: string

  /** When was this model originally created? */
  created: Quantum<Date>

  /** When was the last time this model updated? */
  updated: Quantum<Date>

  /** Creates a new instance of the base model */
  constructor(id?: string, name?: string, created?: Quantum<Date>, updated?: Quantum<Date>) {
    this.id = id || ''
    this.name = name || ''
    this.created = created
    this.updated = updated
  }

  /**
   * Data validation schema. Used to assert payloads with serialized data
   * before converting them to domain models.
   */
  static readonly schema = z.object({
    id: Schema.Id,
    name: Schema.Name,
    created: Schema.DateTime,
    updated: Schema.DateTime.nullable(),
  })

  /**
   * Utility function that helps format a date into a standardizes ISO format
   * that will be used as a standard across the system.
   * @param date    Date to format
   * @param format  What type of format should be used to produce the output.
   *                If no value is passed, `Full` is assumed
   * @returns       Formatted equivalent
   */
  static formatDate(date?: Date | Quantum<Date>, format?: DateFormat): string | null {
    if (!date) {
      return null
    }

    switch (format) {
      case DateFormat.Date:
        return DateTime.fromJSDate(date).toUTC().toISODate()

      case DateFormat.Time:
        return DateTime.fromJSDate(date).toUTC().toISOTime()

      default:
        return DateTime.fromJSDate(date).toUTC().toISO({ suppressMilliseconds: true })
    }
  }

  /**
   * Utility function that attempts to parse a date from one of mulitple possibe rendering options.
   * - If the value is a date object or undefined, there is no conversion necesary. Just returned as is.
   * - If the value is a number, we assuem thsi is a unix timestamp value.
   * - If the value is a string, we assuem it's a standar Date ISO string.
   */
  static parseDate(date?: DateLike): Quantum<Date> {
    if (isDate(date)) {
      return date
    }

    switch (typeof date) {
      case 'number':
        return DateTime.fromSeconds(date).toJSDate()

      case 'string':
        return DateTime.fromISO(date).toJSDate()

      default:
        return undefined
    }
  }

  /**
   * Utility function that tries to match a string value to a corresponding boolean
   * Assumes that the string value 'true' is a [true] value, all other values are considered [false]
   * @param val  Value to evaluate
   * @returns    Boolean equivalent
   */
  static parseBoolean(val: string): boolean {
    return includes(['true', 'yes'], toLower(trim(val)))
  }

  /**
   * Creates a new model derived from a serialized record
   * @param recordInput  serialized record
   * @returns            Model equivalent
   */
  static fromSerialized(recordInput: BaseModelSerialized) {
    if (!recordInput) {
      return undefined
    }

    const record = BaseModel.schema.parse(recordInput)
    return new BaseModel(
      record.id,
      record.name,
      BaseModel.parseDate(record.created),
      BaseModel.parseDate(record.updated)
    )
  }

  /**
   * Creates a new model derived from a record retrieved from the data warehouse
   * @param record  serialized recored
   * @returns       model equivalent
   */
  static fromWarehouse(record: BaseModelWarehouse) {
    return new BaseModel(
      record.Id,
      record.Name,
      BaseModel.parseDate(record.CreatedDate),
      BaseModel.parseDate(record.LastModifiedDate)
    )
  }

  static referenceFromWarehouse(record: ReferenceWarehouse): Reference {
    return {
      id: record.Id,
      name: record.Name,
    }
  }

  static fromDataStore(record: BaseModelStore) {
    return new BaseModel(record.id, '', BaseModel.parseDate(record.created), BaseModel.parseDate(record.updated))
  }

  /**
   * Creates a serialized equivalent of the current model. This is useful to transport the data between
   * modules or third-party services.
   * @returns  Serialized equivalent of the model
   */
  toSerialized(): BaseModelSerialized {
    return {
      ...assignIn({}, this),
      created: BaseModel.formatDate(this.created) as string,
      updated: BaseModel.formatDate(this.updated),
    }
  }

  toDataStore(): BaseModelStore {
    return {
      id: this.id,
      created: BaseModel.formatDate(this.created),
      updated: BaseModel.formatDate(this.updated),
    }
  }

  /**
   * Creates a sanitized reference of the model.
   * Used to create references to the model. Useful to create lists that provide the user interface or other external
   * entities a quick way to choose between collection of models of the same type.
   * Another good use case This is to print the model in console for debugging, serialized
   * representations for logging and other areas where we need a "clean" version of the model. This should avoid including
   * data that is considered a security or business risk to leak. It should also be a short, and simple to read and consume.
   */
  toReference(): Reference {
    return {
      id: this.id,
      name: this.name,
    }
  }

  toString() {
    return JSON.stringify(this.toReference(), null, '  ')
  }
}
