import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { groupBy, isEmpty, isFunction, range } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import Combobox, { Option } from '@athena/component/atom/combobox'
import RevueObjectiveGoal from '@athena/core/model/revue-objective-goal'

import { useGetRevueReport } from '@apollo/client/hook/reports'
import useEmployeeMap from '@future-view/frontend/hook/use-employee-map'

import { columnDef } from './column-def'
import Props, { Status } from './interface'
import Style from './style.module.scss'

const RevueReport: React.FC<Props> = ({ 'data-test': dataTest }) => {
  const resourceList = useEmployeeMap()
  const reportResponse = useGetRevueReport()
  const [gridApi, setGridApi] = useState<GridApi>()
  const [years, setYears] = useState<Option[]>([])
  const currentYear = new Date().getFullYear().toString()
  const [selectedYear, setSelectedYear] = useState<Option>({ label: currentYear, value: currentYear })

  const gridStyle = {
    height: '89%',
  }
  const gridRef = useRef<AgGridReact>(null)
  const gridOptions: GridOptions = {
    columnDefs: columnDef,
    suppressHorizontalScroll: false,
    defaultColDef: {
      resizable: true,
      flex: 1,
      filter: 'agSetColumnFilter',
    },
  }

  const onFilterTextBoxChanged = (event: any) => {
    const value = event.target.value
    gridApi && gridApi.setQuickFilter(value)
  }

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
    event.api.sizeColumnsToFit()
  }

  const excludeList = [
    'C-98475',
    'C-99374',
    'C-98461',
    'C-80803',
    'C-98396',
    'C-71076',
    'C-98999',
    'C-71453',
    'C-80302',
  ]

  useEffect(() => {
    const yearsOption = range(2023, Number(currentYear) + 1, 1).map((item) => {
      return item.toString()
    })
    setYears(
      yearsOption.map((item) => {
        return { label: item, value: item }
      }) as Option[]
    )
    years?.length && setSelectedYear(years[0])
  }, [])

  useEffect(() => {
    if (resourceList && selectedYear) {
      reportResponse.mutateAsync(selectedYear.value).then((data: any) => {
        const tdata = data?.data
        const filteredData = tdata?.filter(
          (item: any) => resourceList[item.target]?.isActiveEmployee === 'YES' && !excludeList.includes(item.target)
        )
        const groupedData = groupBy(filteredData, 'target')
        const selfStatusData: any = {}
        const managerStatusData: any = {}
        Object.keys(groupedData).forEach((key) => {
          const selfStatuses: Status[] = groupedData[key].map((item) => {
            return { title: item.title, status: getRevueStatus(item.title, item.self_rating, item.self_assessment) }
          })
          selfStatusData[groupedData[key][selfStatuses.length - 1].id] = getRevueOverallStatus(selfStatuses)

          const managerStatuses = groupedData[key].map((item) => {
            return {
              title: item.title,
              status: getRevueStatus(item.title, item.manager_rating, item.manager_assessment),
            }
          })
          managerStatusData[groupedData[key][managerStatuses.length - 1].id] = getRevueOverallStatus(managerStatuses)
        })
        const rdata =
          resourceList &&
          Object.keys(resourceList).length &&
          filteredData?.map((item: any) => {
            return {
              sFname: resourceList[item.source]?.firstName,
              sLname: resourceList[item.source]?.lastName,
              source: item.source,
              semail: resourceList[item.source]?.email,
              tFname: resourceList[item.target]?.firstName,
              tLname: resourceList[item.target]?.lastName,
              target: item.target,
              temail: resourceList[item.target]?.email,
              title: item.title,
              kpi: item.kpi,
              current: item.current,
              self_rating: item.self_rating,
              self_assessment: item.self_assessment,
              manager_rating: item.manager_rating,
              manager_assessment: item.manager_assessment,
              businessTeam: resourceList[item.target]?.businessTeam,
              practice: resourceList[item.target]?.practice,
              officeCity: resourceList[item.target]?.officeCity,
              leader: resourceList[item.target]?.mgrDirectReports ? 'Yes' : 'No',
              selfStatus: getRevueStatus(item.title, item.self_rating, item.self_assessment),
              selfOverall: selfStatusData[item.id],
              managerStatus: getRevueStatus(item.title, item.manager_rating, item.manager_assessment),
              managerOverall: managerStatusData[item.id],
              promotion: item.promotion && item.promotion?.rel && item.promotion.rel.sponsor1 ? 'Yes' : 'No',
              sponsor1: getSponsorName(item.promotion?.rel?.sponsor1),
              sponsor2: getSponsorName(item.promotion?.rel?.sponsor2),
              craft: item.promotion?.craft,
              method: item.promotion?.method,
              leadership: item.promotion?.leadership,
              goal1: getGoals(item.goals, 1),
              goal2: getGoals(item.goals, 2),
              goal3: getGoals(item.goals, 3),
            }
          })
        if (rdata) {
          gridApi && gridApi.setRowData(rdata)
        }
      })
    }
  }, [resourceList, selectedYear])

  const getSponsorName = (sponsor: string) => {
    const name = resourceList[sponsor]
    if (!name) {
      return ''
    }
    return `${name.firstName} ${name.lastName}`
  }

  const getGoals = (goals: RevueObjectiveGoal[], index: number) => {
    if (!goals) {
      return ''
    }
    if (goals.length === 0) {
      return ''
    }
    if (index > 2) {
      goals = goals.splice(2)
    } else {
      goals = goals.slice(index - 1, index)
    }
    return goals.reduce((acc: string, goal: RevueObjectiveGoal) => {
      acc = `${acc}
      Title: ${goal.title}
      Progress: ${goal.progress}
      Description:${goal.description}
      ManagerNote: ${goal.manager_note || ''}\n\n`
      return acc
    }, '')
  }

  const getRevueStatus = (title: string, selfRating: string, selfAssessment: string) => {
    if (isEmpty(selfRating) && isEmpty(selfAssessment)) {
      return 'Not Started'
    } else if (!isEmpty(selfRating) && !isEmpty(selfAssessment)) {
      return 'Submitted'
    } else if (!isEmpty(selfRating) || !isEmpty(selfAssessment)) {
      return 'In Progress'
    }
    return 'Not Started'
  }

  const getRevueOverallStatus = (statusArr: Status[]) => {
    if (!statusArr) {
      return null
    }
    const statArr = statusArr
      .filter((stat) => {
        return stat.title?.toLowerCase() !== 'sales participation' && stat.title !== 'Summary of Accomplishments'
      })
      .map((stat) => {
        return stat.status
      })

    if (statArr.every((item) => item === 'Submitted')) {
      return 'Submitted'
    } else if (statArr.every((item) => item === 'Not Started')) {
      return 'Not Started'
    } else if (statArr.includes('In Progress') || (statArr.includes('Submitted') && statArr.includes('Not Started'))) {
      return 'In Progress'
    }
    return null
  }

  const onBtExport = () => {
    const params = {
      fileName: 'ReVue Report.csv',
    }
    gridApi && gridApi.exportDataAsCsv(params)
  }

  const onYearReportChange = (val: Option) => {
    setSelectedYear(val)
  }

  return (
    <>
      <div className={`${Style.wrapper}`} data-test={`${dataTest}.container`}>
        <div className={Style.header}>
          <div className={Style.filterInput}>
            <input
              className={`${Style.input} mb-[10px]`}
              id="filter-text-box"
              placeholder="Filter"
              type="text"
              onKeyUp={onFilterTextBoxChanged}
            />
            <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.0001 3C14.7548 3 17.4552 3.23205 20.0831 3.67767C20.6159 3.76803 21 4.23355 21 4.77402V5.81802C21 6.41476 20.7629 6.98705 20.341 7.40901L14.909 12.841C14.4871 13.2629 14.25 13.8352 14.25 14.432V17.3594C14.25 18.2117 13.7685 18.9908 13.0062 19.3719L9.75 21V14.432C9.75 13.8352 9.51295 13.2629 9.09099 12.841L3.65901 7.40901C3.23705 6.98705 3 6.41476 3 5.81802V4.77404C3 4.23357 3.38408 3.76805 3.91694 3.67769C6.54479 3.23206 9.24533 3 12.0001 3Z"
                stroke="#232832"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
          </div>
          <div className={`${Style.alignCenter} flex`}>
            <Combobox
              customClasses={`${Style.yearDD} min-w-[200px]`}
              data-test={`${dataTest}.revue-report`}
              label="Year"
              options={years}
              value={selectedYear}
              onChange={(value) => isFunction(onYearReportChange) && onYearReportChange(value as Option)}
            />
            <button className={Style.exportButton} onClick={onBtExport}>
              <svg fill="none" height="20" viewBox="0 0 18 20" width="18" xmlns="http://www.w3.org/2000/svg">
                <path
                  clipRule="evenodd"
                  d="M9 0.5C9.41421 0.5 9.75 0.835787 9.75 1.25L9.75 13.8401L11.7004 11.7397C11.9823 11.4361 12.4568 11.4186 12.7603 11.7004C13.0639 11.9823 13.0814 12.4568 12.7996 12.7603L9.54959 16.2603C9.40768 16.4132 9.20855 16.5 9 16.5C8.79145 16.5 8.59231 16.4132 8.4504 16.2603L5.2004 12.7603C4.91855 12.4568 4.93613 11.9823 5.23966 11.7004C5.54319 11.4186 6.01774 11.4361 6.29959 11.7397L8.25 13.8401L8.25 1.25C8.25 0.835787 8.58578 0.5 9 0.5Z"
                  fill="#232832"
                  fillRule="evenodd"
                />
                <path d="M1 5.5V19.5H17V5.5" stroke="#232832" strokeLinecap="round" />
              </svg>
              &nbsp;&nbsp;Export to CSV
            </button>
          </div>
        </div>
        <AgGridReact
          ref={gridRef}
          className={'ag-theme-alpine'}
          containerStyle={gridStyle}
          enableRangeSelection={false}
          gridOptions={gridOptions}
          headerHeight={40}
          rowHeight={48}
          onGridReady={onGridReady}
        />
      </div>
    </>
  )
}

export default RevueReport
export type { Props }
