import classNames from 'classnames'
import React, { useState } from 'react'

import { IconName as IconNames } from '@athena/component/atom/button'
import Modal from '@athena/component/molecule/modal'
import { Quantum } from '@athena/core/model'

import PeerToPeerReviews from '@future-view/frontend/component/peer-to-peer-reviews'
import { PeerToPeer } from '@future-view/frontend/component/peerspective-matrix/interface'
import { formatDate } from '@future-view/frontend/component/peerspective-matrix/util'

import Props from './interface'
import Style from './style.module.scss'

const FeedbackCard: React.FC<Props> = ({
  'data-test': dataTest = 'feedback-card',
  id,
  name = '',
  role = '',
  engagement = '',
  date = null,
  feedback = '',
  source = null,
  target = null,
}) => {
  const [showPeerToPeer, setPeerToPeer] = useState<Quantum<PeerToPeer>>()
  const handleClick = () => {
    source && target && setPeerToPeer({ source, target })
  }
  const handleClose = () => {
    setPeerToPeer(undefined)
  }

  return (
    <>
      <div key={`${id}-${date}-${name}`} className={Style.content} data-test={`${dataTest}.container`}>
        <div>
          <label
            className={classNames(Style.tags, Style.name, source && target && Style.hover)}
            data-test={`${dataTest}.name`}
            onClick={() => handleClick()}
          >
            {name}
          </label>
          <label className={Style.tags} data-test={`${dataTest}.role`}>
            {role}
          </label>
          <label className={Style.tags} data-test={`${dataTest}.engagement`}>
            {engagement}
          </label>
          <label className={Style.date} data-test={`${dataTest}.date`}>
            {formatDate(date)}
          </label>
        </div>
        <p className={'mt-[10px] ml-[15px]'} data-test={`${dataTest}.feedback`}>
          {feedback}
        </p>
      </div>
      <Modal
        closeIcon={IconNames.XCircle}
        data-test={dataTest}
        headerText={''}
        isShown={!!showPeerToPeer}
        wrapperCustomClasses={Style.peerToPeerModal}
        onHide={handleClose}
      >
        <PeerToPeerReviews
          data-test={'PeerToPeerReviews'}
          source={showPeerToPeer?.source}
          target={showPeerToPeer?.target}
          wrapperCustomClasses={''}
        ></PeerToPeerReviews>
      </Modal>
    </>
  )
}

export default FeedbackCard
export type { Props }
