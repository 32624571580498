import classnames from 'classnames'
import { isFunction } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import { WrapperProps } from './interface'
import Style from './style.module.scss'

const cx = classnames.bind(Style)

const InputWrapper: React.FC<WrapperProps> = ({
  'data-test': dataTest = 'input',
  active = false,
  children,
  customClasses,
  disabled = false,
  fixedHeight = true,
  help = '',
  invalid,
  onBlur,
  onFocus,
}) => {
  const [focused, setFocused] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (e: MouseEvent) => {
    if (e.target instanceof HTMLElement && !wrapperRef.current?.contains(e.target)) {
      setFocused(false)
      isFunction(onBlur) && onBlur()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  const handleFocus = () => {
    if (!disabled) {
      setFocused(true)
      isFunction(onFocus) && onFocus()
    }
  }

  return (
    <div ref={wrapperRef} className={cx([Style.inputWrapper, customClasses])} data-test={`${dataTest}.container`}>
      <div
        data-test={`${dataTest}.main-block`}
        className={cx([
          Style.mainBlock,
          {
            [Style.invalid]: invalid,
            [Style.active]: focused || active,
            [Style.disabled]: disabled,
            [Style.fixedHeight]: fixedHeight,
          },
        ])}
        onClick={handleFocus}
      >
        {children}
      </div>
      {help && (
        <span className={cx([Style.helperText, { [Style.invalid]: invalid }])} data-test={`${dataTest}.help`}>
          {help}
        </span>
      )}
    </div>
  )
}
InputWrapper.displayName = 'input-wrapper'

export default InputWrapper
