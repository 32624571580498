import classNames from 'classnames'
import { isFunction } from 'lodash'
import React, { MouseEvent, useState } from 'react'

import Button, { ButtonFill, ButtonSize, IconName } from '@athena/component/atom/button'

import Props, { TagSize, TagVariant } from './interface'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

export const Tag: React.FC<Props> = ({
  'data-test': dataTest = 'tag',
  'data-test-arg': dataTestArg,
  children,
  customClasses,
  hasClose = false,
  disabled = false,
  interactive = false,
  size = TagSize.Small,
  variant = TagVariant.Light,
  onClose,
}): JSX.Element => {
  const [selected, setSelected] = useState(false)

  const handleClick = () => interactive && setSelected(!selected)

  const handleClose = (e?: MouseEvent) => {
    e?.stopPropagation()
    isFunction(onClose) && onClose(children)
  }

  const containerStyle = cx([
    Style.tag,
    Style[variant],
    Style[`size-${size}`],
    {
      [Style.interactive]: interactive,
      [Style.selected]: selected,
    },
    customClasses,
  ])
  return (
    <div className={containerStyle} data-test={`${dataTest}.element`} data-test-arg={dataTestArg} onClick={handleClick}>
      <span data-test={`${dataTest}.label`}>{children}</span>
      {interactive && hasClose && (
        <Button
          customClasses={Style.close}
          data-test={`${dataTest}.close`}
          disabled={disabled}
          fill={ButtonFill.Flat}
          icon={IconName.XMark}
          size={ButtonSize.Small}
          onClick={handleClose}
        />
      )}
    </div>
  )
}

export default Tag
export type { Props }
export { TagSize, TagVariant }
