import { isEmpty, isFunction, isNil } from 'lodash'
import { useState, useEffect, useRef } from 'react'

import RevueObjectiveGoal from '@athena/core/model/revue-objective-goal'

import PerformanceObjectiveGoal from './goals-card'
import PerformanceObjectiveGoalDetail from './goals-details'
import Props from './interface'
import Style from './style.module.scss'

const PerformanceObjectiveGoalList: React.FC<Props> = ({
  'data-test': dataTest = 'performance-objective-goal',
  isGoalsInvalid,
  isManager = true,
  goals = [],
  onGoalDetailsChange,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const goalsLengthRef = useRef(goals.length)

  useEffect(() => {
    const prevGoalsLength = goalsLengthRef.current
    const currentGoalsLength = goals.length
    if (currentGoalsLength > prevGoalsLength) {
      setSelectedIndex(0)
    } else if (currentGoalsLength < prevGoalsLength) {
      setSelectedIndex((prev) => {
        if (currentGoalsLength === 0) return 0
        return prev - 1 > 0 ? prev - 1 : 0
      })
    }
    goalsLengthRef.current = currentGoalsLength
  }, [goals.length])

  const handleGoalClick = (index: number) => {
    if (!isGoalsInvalid) {
      setSelectedIndex(index)
    }
  }

  return (
    <div className={`flex ${Style.wrapper}`} data-test={`${dataTest}.wrapper`}>
      <div className={Style.leftSection}>
        {goals.map((goal, index) => (
          <PerformanceObjectiveGoal
            key={goal.id}
            data-test={`${dataTest}.card`}
            goal={goal}
            index={index}
            isDisabled={isGoalsInvalid}
            isSelected={index === selectedIndex}
            onClicked={handleGoalClick}
          />
        ))}
      </div>
      <div className={Style.rightSection}>
        {!isNil(selectedIndex) && !isEmpty(goals) && (
          <PerformanceObjectiveGoalDetail
            goal={goals?.[selectedIndex]}
            isManager={isManager}
            onChange={(goal: RevueObjectiveGoal | null) => {
              isFunction(onGoalDetailsChange) && onGoalDetailsChange(goal, selectedIndex)
            }}
          />
        )}
      </div>
    </div>
  )
}

export default PerformanceObjectiveGoalList
export type { Props }
