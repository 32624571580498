import axios from 'axios'

import Response from '@athena/core/client/response'
import Employee from '@athena/core/model/employee'

import Environment from '@artemis/client/environment'

const client = axios.create({
  baseURL: `${Environment.api.host}/employee`,
  timeout: Environment.api.timeout,
  validateStatus: (s) => s > 199 && s < 500,
  withCredentials: true,
})

export const getEmployee = async (idInput: string): Promise<Response<Employee>> => {
  const id = Employee.schema.shape.employeeId.parse(idInput)
  const resp = await client.get(`/${id}`)
  return Response.single(resp)
}

export const getEmployeeByIds = async (idsInput: string[]): Promise<Response<Employee[]>> => {
  const formData = { ids: idsInput }
  const resp = await client.post(``, formData)
  return Response.list(resp)
}

export const getEmployeeByEmail = async (email: string): Promise<Response<Employee>> => {
  const resp = await client.get(`/email/${email}`)
  return Response.single(resp)
}

export const getEmployeeList = async (): Promise<Response<Employee[]>> => {
  const resp = await client.get('')
  return Response.list(resp)
}
