import { isFunction } from 'lodash'
import { useState, useEffect } from 'react'

import Loading from '@athena/component/atom/loading'
import RevueObjective from '@athena/core/model/revue-objective'

import { useRevueObjectiveBySourceTarget } from '@apollo/client/hook/revue'
import { getDescription, getTitle } from '@future-view/frontend/page/revue-dashboard/util'
import { formatDateTime } from '@future-view/frontend/util'

import Props from './interface'
import PerformanceObjective from './performance-objective'
import Style from './style.module.scss'

const PerformanceObjectiveList: React.FC<Props> = ({
  'data-test': dataTest = 'performance-objective-list',
  year,
  source,
  target,
  onClicked,
  ratings,
  isManager = false,
}: Props) => {
  const getObjectives = useRevueObjectiveBySourceTarget()
  const [objectivesList, setObjectivesList] = useState<RevueObjective[]>([])

  useEffect(() => {
    const fetchObjectives = async () => {
      if (!year || !target) {
        setObjectivesList([])
        return
      }
      const objectivesResponse = await getObjectives.mutateAsync({
        source: source || '',
        target: target || '',
        year: year || '',
      })
      setObjectivesList(objectivesResponse?.data?.filter((item: RevueObjective) => item.id) || [])
    }
    fetchObjectives()
  }, [source, target, year])

  useEffect(() => {
    isFunction(ratings) && ratings(objectivesList)
  }, [objectivesList])

  return (
    <div className={'flex flex-col flex-1 gap-[10px] mr-[10px]'} data-test={`${dataTest}.objectives`}>
      {getObjectives.isLoading && (
        <div className={Style.blockingOpaqueBlock}>
          <div className={Style.loadingContainer}>
            <Loading data-test={`revue-objectives.loading`} />
          </div>
        </div>
      )}
      {!getObjectives.isLoading &&
        objectivesList &&
        objectivesList.map((objective: RevueObjective) => {
          return (
            <PerformanceObjective
              key={objective.id}
              data-test={''}
              date={formatDateTime(objective.created)}
              description={getDescription(objective, isManager)}
              id={objective.id}
              title={getTitle(objective, isManager)}
              onClicked={() => onClicked(objective.id)}
            ></PerformanceObjective>
          )
        })}
      {!getObjectives.isLoading && objectivesList?.length === 0 && (
        <p
          className={'flex justify-center items-center rounded-[8px] bg-white p-[25px] mb-[10px] mr-[10px] h-[160px]'}
          data-test={`${dataTest}.wrapper`}
        >
          No objectives
        </p>
      )}
    </div>
  )
}

export default PerformanceObjectiveList
export type { Props }
