import classNames from 'classnames/bind'
import { isFunction } from 'lodash'
import React, { useEffect, useState } from 'react'

import Props from './interface'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

const Switch: React.FC<Props> = ({
  'data-test': dataTest = 'checkbox',
  label = '',
  customClasses = '',
  description = '',
  disabled = false,
  value = false,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(value)

  useEffect(() => {
    setIsChecked(value)
  }, [onChange, value])

  const handleClick = () => {
    if (disabled) {
      return
    }
    setIsChecked(!isChecked)
    isFunction(onChange) && onChange(label, !isChecked)
  }

  return (
    <label className={cx(Style.switch, 'flex items-center', customClasses)} data-test={`${dataTest}.container`}>
      <div className={Style['switch-wrapper']}>
        <input
          checked={isChecked}
          data-test={`${dataTest}.input`}
          disabled={disabled}
          type="checkbox"
          onChange={handleClick}
        />
        <span className={cx([Style['switch-slider'], { [Style.disabled]: disabled }])} />
      </div>
      {(label || description) && (
        <label className="text-[14px] ml-[8px]" data-test={`${dataTest}.label`}>
          {label && label}
          {description && <div className={Style.description}>{description}</div>}
        </label>
      )}
    </label>
  )
}

export default Switch

export type { Props }
