import { useQuery } from '@tanstack/react-query'

import { getSAPSearch } from '@apollo/client/api/sap-search'

const QueryKey = {
  SAPSearch: 'sap-search',
}

export const useSAPSearch = (
  email: string,
  firstName: string,
  lastName: string,
  managerId: string,
  sapId: string,
  directId: string
) =>
  useQuery({
    queryKey: [QueryKey.SAPSearch, email, firstName, lastName, managerId, sapId, directId],
    queryFn: () =>
      getSAPSearch({
        email,
        firstName,
        lastName,
        managerId,
        sapId,
        directId,
      }),
    enabled: !!email || !!firstName || !!lastName || !!managerId || !!sapId || !!directId,
  })
