import React, { PropsWithChildren, useState } from 'react'

import { useIdentity } from '@apollo/client/hook/auth'
import { useEmployeeByEmail } from '@artemis/client/hook'
import Sidebar from '@future-view/frontend/component/sidebar'
import Environment from '@future-view/frontend/environment'
import links from '@future-view/frontend/page/layout/links'
import { setEmployeeData } from '@future-view/frontend/util/cookieUtil'

import { refreshContext } from './ReferenceDataContext'

const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const [refreshKey, setRefreshKey] = useState(1)
  const identity = useIdentity()
  const identityData = identity.data?.data
  const employee = useEmployeeByEmail(identityData ? identityData?.email : '')
  if (identityData && employee.data && employee.data.data) {
    identityData.cid = employee.data.data.employeeId
    identityData.directReports = employee.data.data.mgrDirectReports
    identityData.isAdmin = employee.data.data.isAdmin
    setEmployeeData(identityData)
  }

  return (
    <div className="flex">
      {!employee.isLoading && (
        <>
          <Sidebar data-test="dashboard.sidebar" identityData={identityData} links={links} refreshCounts={refreshKey} />
          <refreshContext.Provider value={{ refreshKey, setRefreshKey }}>
            <div className="w-full bg-dashboardwhite h-screen overflow-y-scroll">
              {Environment.isDevStage && (
                <div className="text-center bg-yellow-500 text-lg font-bold py-2">Caution: Not a Production Server</div>
              )}
              {children}
            </div>
          </refreshContext.Provider>
        </>
      )}
    </div>
  )
}

export default Layout
