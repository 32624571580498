import { isString } from 'lodash'
import { z } from 'zod'

import Pager from '@athena/core/api/pager'
import { BaseModel } from '@athena/core/model'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Mapper<M extends BaseModel> = (json: any) => M

const schema = z.object({
  offset: Pager.schema.shape.offset,
  pageSize: Pager.schema.shape.pageSize,
  collectionSize: z.number().min(0).max(100000000),
  data: z.array(z.any()),
})

const tryParse = (input: string) => {
  try {
    return JSON.parse(input)
  } catch {
    return undefined
  }
}

export default class Slice<M extends BaseModel> {
  pager: Pager
  collectionSize = 0
  data: M[] = []
  status = 0

  constructor(pager: Pager, collectionSize: number, data: M[]) {
    this.pager = pager
    this.collectionSize = collectionSize || 0
    this.data = data || []
  }

  static fromSerialized<M extends BaseModel>(status: number, respInput: string, mapper: Mapper<M>): Slice<M> {
    const respData = isString(respInput) ? tryParse(respInput) : respInput
    const job = respData ? schema.parse(respData) : { offset: 0, pageSize: 0, collectionSize: 0, data: [] }
    const models = job.data?.map(mapper) || []
    const pager = new Pager(job.offset, job.pageSize)
    const payload = new Slice<M>(pager, job.collectionSize, models)
    payload.status = status || 0
    return payload
  }

  toSerialized() {
    return {
      ...this.pager,
      collectionSize: this.collectionSize,
      data: this.data?.map((m) => m.toSerialized()) || [],
    }
  }

  get previous() {
    return Math.max(this.pager.offset - this.pager.pageSize, 0)
  }

  get next() {
    return Math.min(this.pager.offset + this.pager.pageSize, this.collectionSize)
  }

  get pages() {
    return Math.ceil(this.collectionSize / (this.pager.pageSize || 1))
  }

  get currentPage() {
    return Math.floor(this.pager.offset / (this.pager.pageSize || 1))
  }
}
