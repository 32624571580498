import pino from 'pino'

import Environment from '@future-view/frontend/environment'

const log = pino({
  level: Environment.logLevel || 'warn',
  prettyPrint: {
    levelFirst: true,
    supperFlushSyncWarning: true,
    colorize: true,
    ignore: 'time,hostname',
  },
})
export default log
