import { assignIn, map, pick } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, BaseModelWarehouse, Quantum, Reference } from './base-model'
import RevueObjectiveGoal from './revue-objective-goal'

export type RevueObjectiveSerialized = z.infer<typeof RevueObjective.schema>

export type RevueObjectiveWarehouse = BaseModelWarehouse & {
  title: string
  description: string
  source: string
  target: string
  kpi: string
  current: string
  self_assessment: string
  manager_assessment: string
  self_rating: string
  manager_rating: string
  old_manager_rating: string
  show_details_popup: boolean
  bulk_updated: string
  comment: string
}

export type RevueObjectiveStore = BaseModelStore & {
  title: string
  description: string
  source: string
  target: string
  kpi: string
  current: string
  self_assessment: string
  manager_assessment: string
  self_rating: string
  manager_rating: string
  old_manager_rating: string
  show_details_popup: boolean
  bulk_updated: string
  comment: string
  self_assessment_enabled?: boolean
  manager_assessment_enabled?: boolean
  manager_assessment_visible?: boolean
  manager_rating_disabled?: boolean
}

export enum RevueObjectiveTitle {
  PROJECT_DELIVERY = 'Project Delivery',
  SALES_PARTICIPATION = 'Sales participation',
  PERSONAL_CHARGEABILITY = 'Personal Chargeability',
  CONTINUOUS_LEARNING = 'Continuous Learning',
  PRACTICE_DEVELOPMENT = 'Practice Development / R&D',
  SUMMARY_OF_ACCOMPLISHMENTS = 'Summary of Accomplishments',
  PERFORMANCE = 'Performance',
  BUSINESS_DEVELOPMENT = 'Business Development',
  CONTINUOUS_LEARNING_AND_INITIATIVES = 'Continuous Learning and Initiatives',
}
export interface RevueObjectiveDetails {
  [key: string]:
    | string
    | boolean
    | RevueObjectiveGoal[]
    | Quantum<Date>
    | (() => Reference)
    | (() => string)
    | (() => boolean)
    | (() => RevueObjectiveStore)
    | ((projection: string[]) => Partial<RevueObjectiveSerialized>)
  id: string
  title: string
  description: string
  source: string
  target: string
  kpi: string
  current: string
  self_assessment: string
  manager_assessment: string
  self_rating: string
  manager_rating: string
  old_manager_rating: string
  show_details_popup: boolean
  bulk_updated: string
  comment: string
  goals: RevueObjectiveGoal[]
}

export enum Rating {
  NA = 'N/A',
  Poor = 'Poor',
  Below = 'Below',
  Meets = 'Meets',
  Exceeds = 'Exceeds',
  Stellar = 'Stellar',
}

export default class RevueObjective extends BaseModel implements RevueObjectiveDetails {
  title = ''
  description = ''
  source = ''
  target = ''
  kpi = ''
  current = ''
  self_assessment = ''
  manager_assessment = ''
  self_rating = ''
  manager_rating = ''
  old_manager_rating = ''
  show_details_popup = false
  bulk_updated = ''
  comment = ''
  goals: RevueObjectiveGoal[] = []
  promotion: any
  self_assessment_enabled?: boolean = false
  manager_assessment_enabled?: boolean = false
  manager_assessment_visible?: boolean = false
  manager_rating_disabled?: boolean = false

  constructor(id: string) {
    super(id)
  }
  [key: string]:
    | string
    | boolean
    | RevueObjectiveGoal[]
    | Quantum<Date>
    | (() => Reference)
    | (() => string)
    | (() => boolean)
    | (() => RevueObjectiveStore)
    | ((projection: string[]) => Partial<RevueObjectiveSerialized>)

  static override readonly schema = BaseModel.schema.extend({
    title: z.string().min(1).max(200).default(''),
    description: z.string().min(1).max(1000).default(''),
    source: z.string().max(10).default(''),
    target: z.string().max(10).default(''),
    kpi: z.string().max(10).default(''),
    current: z.string().max(20).default(''),
    self_assessment: z.string().min(0).max(5000).default(''),
    manager_assessment: z.string().min(0).max(5000).default(''),
    self_rating: z.string().min(0).max(10).default(''),
    manager_rating: z.string().min(0).max(10).default(''),
    old_manager_rating: z.string().min(0).max(10).default(''),
    show_details_popup: z.boolean().default(false),
    bulk_updated: z.string().max(50).default(''),
    comment: z.string().max(1000).default(''),
    goals: z.array(RevueObjectiveGoal.schema),
    self_assessment_enabled: z.boolean().optional(),
    manager_assessment_enabled: z.boolean().optional(),
    manager_assessment_visible: z.boolean().optional(),
    manager_rating_disabled: z.boolean().optional(),
  })

  static override fromSerialized(recordInput: RevueObjectiveSerialized): RevueObjective {
    const record = RevueObjective.schema.parse(recordInput)
    const model = new RevueObjective(record.id)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.title = record.title || ''
    model.description = record.description || ''
    model.source = record.source || ''
    model.target = record.target || ''
    model.kpi = record.kpi || ''
    model.current = record.current || ''
    model.self_assessment = record.self_assessment || ''
    model.manager_assessment = record.manager_assessment || ''
    model.self_rating = record.self_rating || ''
    model.manager_rating = record.manager_rating || ''
    model.old_manager_rating = record.old_manager_rating || ''
    model.show_details_popup = !!record.show_details_popup
    model.bulk_updated = record.bulk_updated || ''
    model.comment = record.comment || ''
    model.goals = map(record.goals, RevueObjectiveGoal.fromSerialized)
    model.manager_assessment_enabled = !!record.manager_assessment_enabled
    model.self_assessment_enabled = !!record.self_assessment_enabled
    model.manager_assessment_visible = !!record.manager_assessment_visible
    model.manager_rating_disabled = !!record.manager_rating_disabled
    return model
  }

  static override fromDataStore(record: RevueObjectiveStore): RevueObjective {
    const model = new RevueObjective(record.id)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.title = record.title || ''
    model.description = record.description || ''
    model.source = record.source || ''
    model.target = record.target || ''
    model.kpi = record.kpi || ''
    model.current = record.current || ''
    model.self_assessment = record.self_assessment || ''
    model.manager_assessment = record.manager_assessment || ''
    model.self_rating = record.self_rating || ''
    model.manager_rating = record.manager_rating || ''
    model.old_manager_rating = record.old_manager_rating || ''
    model.show_details_popup = !!record.show_details_popup
    model.bulk_updated = record.bulk_updated || ''
    model.comment = record.comment || ''
    model.self_assessment_enabled = !!record.self_assessment_enabled
    model.manager_assessment_enabled = !!record.manager_assessment_enabled
    model.manager_assessment_visible = !!record.manager_assessment_visible
    model.manager_rating_disabled = !!record.manager_rating_disabled
    return model
  }

  override toSerialized(): RevueObjectiveSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      goals: map(this.goals, (g) => g.toSerialized()),
    } as unknown as RevueObjectiveSerialized
  }

  override toDataStore(): RevueObjectiveStore {
    return {
      ...super.toDataStore(),
      title: this.title,
      description: this.description,
      target: this.target,
      source: this.source,
      kpi: this.kpi,
      current: this.current,
      self_assessment: this.self_assessment,
      manager_assessment: this.manager_assessment,
      self_rating: this.self_rating,
      manager_rating: this.manager_rating,
      old_manager_rating: this.old_manager_rating,
      show_details_popup: !!this.show_details_popup,
      bulk_updated: this.bulk_updated,
      comment: this.comment,
    }
  }

  project(projection: string[]): Partial<RevueObjectiveSerialized> {
    const serialized = this.toSerialized()
    return projection?.length ? pick(serialized, projection) : serialized
  }
}
