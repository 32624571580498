import { useState, useEffect, useCallback } from 'react'
import { unstable_usePrompt, useBeforeUnload } from 'react-router-dom'

import Loading from '@athena/component/atom/loading'
import Textarea from '@athena/component/atom/textarea'
import { SavingStatus } from '@athena/component/atom/textarea/interface'
import RevueNotes from '@athena/core/model/revue-notes'

import { useRevueNotesBySourceTarget, useRevueUpdateNotes } from '@apollo/client/hook/revue'

import Props from './interface'
import Style from './style.module.scss'

const RevueNotesComp: React.FC<Props> = ({
  'data-test': dataTest = 'revue-notes',
  year,
  source,
  target,
  employeeName,
  onClicked,
}: Props) => {
  const getNotes = useRevueNotesBySourceTarget()
  const [selfNotes, setSelfNotes] = useState<string | undefined>(undefined)
  const [managerNotes, setManagerNotes] = useState<string | undefined>(undefined)
  const [otherFeedback, setOtherFeedback] = useState<string | undefined>(undefined)
  const [savingStatusMap, setSavingStatusMap] = useState<{ [key: string]: string }>({})
  const updateRevueNotes = useRevueUpdateNotes()
  const [focusedTextarea, setFocusedTextarea] = useState<string | undefined>('')

  useEffect(() => {
    const fetchNotes = async () => {
      if (!year || !target) {
        setSelfNotes(undefined)
        setManagerNotes(undefined)
        setOtherFeedback(undefined)
        return
      }
      const notesResponse = await getNotes.mutateAsync({
        source: source || '',
        target: target || '',
        year: year || '',
      })
      if (notesResponse?.data && notesResponse?.data.length) {
        const notesData = notesResponse.data[0]
        setManagerNotes(notesData.source_notes)
        setSelfNotes(notesData.target_notes)
        setOtherFeedback(notesData.other_feedback)
      }
    }
    fetchNotes()
  }, [year, source, target])

  const saveNotes = useCallback(
    async (source_notes: string, target_notes: string, other_feedback: string) => {
      const notes = new RevueNotes('0')
      notes.source = source || ''
      notes.target = target || ''
      notes.source_notes = source_notes
      notes.target_notes = target_notes
      notes.other_feedback = other_feedback
      notes.created = new Date(`${year}-01-01T00:00:00.000Z`)
      const resp = await updateRevueNotes.mutateAsync(notes)

      if (resp?.data) {
        // setSelfNotes(target_notes)
        // setManagerNotes(source_notes)
      } else {
        throw updateRevueNotes.error
      }
    },
    [updateRevueNotes]
  )

  const handleSave = useCallback(
    async (text: string, _id?: string) => {
      if (!_id) return
      if (!source) {
        await saveNotes(managerNotes || '', text, otherFeedback || '')
      } else {
        await saveNotes(text, selfNotes || '', otherFeedback || '')
      }
    },
    [saveNotes]
  )

  const handleFocus = useCallback(
    (flag: boolean, _id?: string) => {
      if (source) {
        flag ? setFocusedTextarea(_id) : setFocusedTextarea(undefined)
      }
    },
    [source]
  )

  const handleOtherFeedbackSave = useCallback(
    async (text: string, _id?: string) => {
      if (!_id) return
      await saveNotes(managerNotes || '', selfNotes || '', text)
    },
    [saveNotes]
  )

  const handleSavingStatusChange = useCallback(
    (status: SavingStatus, key?: string) => {
      if (!key) return
      setSavingStatusMap((prev) => ({ ...prev, [key]: status }))
    },
    [setSavingStatusMap]
  )

  const isEverythingSaved = useCallback(() => {
    const unsavedKeys = Object.keys(savingStatusMap).filter((key) => savingStatusMap[key] !== SavingStatus.Saved)
    return unsavedKeys.length === 0
  }, [savingStatusMap])

  unstable_usePrompt({ when: !isEverythingSaved(), message: 'Changes you made may not be saved.' })

  useBeforeUnload(
    useCallback(
      (event: BeforeUnloadEvent) => {
        if (!isEverythingSaved()) event.returnValue = ''
      },
      [isEverythingSaved]
    )
  )

  const getHeight = (id: string) => {
    if (!source) {
      return 'h-[50%]'
    }
    if (focusedTextarea === id) {
      return 'h-[100%]'
    }
    return 'h-[33%]'
  }

  return (
    <>
      <div
        className={`flex flex-col bg-white p-[16px] rounded-[8px] bg-white ${getHeight('self_notes')}`}
        data-test={`${dataTest}.self-wrapper`}
      >
        <p className={Style.blueText}>One To One</p>
        <p className={'fs-[20px]'}>Your Notes</p>
        {getNotes.isLoading && (
          <div className={Style.blockingOpaqueBlock}>
            <div className={Style.loadingContainer}>
              <Loading data-test={`${dataTest}.self-loader`} />
            </div>
          </div>
        )}
        {!getNotes.isLoading && (
          <Textarea
            customClasses={Style.textArea}
            data-test={`${dataTest}.self-notes`}
            id="self_notes"
            isAutoSave={true}
            isAutogrow={false}
            label={''}
            placeholder={'Type here'}
            value={!source ? selfNotes : managerNotes}
            onFocus={handleFocus}
            onSave={handleSave}
            onSavingStatusChange={handleSavingStatusChange}
          ></Textarea>
        )}
      </div>
      {source && (
        <div
          className={`flex flex-col bg-white p-[16px] rounded-[8px] bg-white ${getHeight('other_feedback')}`}
          data-test={`${dataTest}.other-feedback-wrapper`}
        >
          <p className={'fs-[20px]'}>Other Feedback</p>
          {getNotes.isLoading && (
            <div className={Style.blockingOpaqueBlock}>
              <div className={Style.loadingContainer}>
                <Loading data-test={`${dataTest}.other-feedback-loader`} />
              </div>
            </div>
          )}
          {!getNotes.isLoading && (
            <Textarea
              customClasses={Style.textArea}
              data-test={`${dataTest}.other-feedback`}
              id="other_feedback"
              isAutoSave={true}
              isAutogrow={false}
              label={''}
              placeholder={'Type here'}
              value={otherFeedback}
              onFocus={handleFocus}
              onSave={handleOtherFeedbackSave}
              onSavingStatusChange={handleSavingStatusChange}
            ></Textarea>
          )}
        </div>
      )}
      <div
        data-test={`${dataTest}.manager-wrapper`}
        className={`flex flex-col bg-white p-[16px] rounded-[8px] bg-white ${!source ? 'h-[50%]' : 'h-[33%]'} ${
          Style.managerNotes
        }`}
      >
        {source === undefined && <p data-test={`${dataTest}.manager-label`}>Manager&apos;s Notes</p>}
        {source !== undefined && (
          <p data-test={`${dataTest}.employee-label`}>
            {employeeName && employeeName.split(' ')[0].trim()}&apos;s Notes
          </p>
        )}
        {getNotes.isLoading && (
          <div className={Style.blockingOpaqueBlock}>
            <div className={Style.loadingContainer}>
              <Loading data-test={`${dataTest}.manager-loader`} />
            </div>
          </div>
        )}
        {!getNotes.isLoading && (
          <p className={`fs-[20px] px-[10px] overflow-y-auto ${Style.notes}`} data-test={`${dataTest}.manager-notes`}>
            {!source ? managerNotes : selfNotes}
          </p>
        )}
      </div>
    </>
  )
}

export default RevueNotesComp
export type { Props }
