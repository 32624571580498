import HeatmapItem from './heatmap-item'
import Style from './style.module.scss'

export type HeatmapEntryType = 'strength' | 'improvement'

export type HeatmapValue = {
  value: number
  label: string
  key: string
  type: HeatmapEntryType
}

export interface HeatmapProps {
  values: HeatmapValue[]
  openModal: (key: string, value: number) => void
  'data-test': string
  title?: string
}

const Heatmap = ({ 'data-test': dataTest, values, openModal, title = '' }: HeatmapProps) => {
  return (
    <div className={Style.container} data-test={`${dataTest}.container`}>
      {values.length > 0 && title && <span data-test={`${dataTest}.title`}>{title}</span>}
      {values.map(({ value, key, label, type }) => (
        <HeatmapItem
          key={key}
          data-test={`${dataTest}.item.${key}`}
          label={label}
          type={type}
          value={value}
          onClick={() => openModal(key, value)}
        />
      ))}
    </div>
  )
}

export default Heatmap
