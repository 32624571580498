import classnames from 'classnames'
import FocusTrap from 'focus-trap-react'
import { isFunction } from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useRef, useState } from 'react'
import { DayPicker } from 'react-day-picker'
import { usePopper } from 'react-popper'
import 'react-day-picker/dist/style.css'

import { IconName } from '@athena/component/atom/icon'
import Input from '@athena/component/molecule/input'

import Props, { DatePickerMode, InputSelection } from './interface'
import Style from './style.module.scss'
import './rdp.css'

const formatDate = (date?: Date) => (date ? DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT) : '')

const cx = classnames.bind(Style)

const DatePicker = ({
  'data-test': dataTest = 'date-picker',
  customClasses = '',
  disabled = false,
  disabledDate,
  end,
  endLabel = '',
  endPlaceholder = '',
  help = '',
  invalid = false,
  mode = DatePickerMode.Single,
  start,
  startLabel = '',
  startPlaceholder = '',
  onClearRange,
  onPickDay,
  onPickRange,
}: Props) => {
  const [selectedStart, setSelectedStart] = useState<Date | undefined>()
  const [selectedEnd, setSelectedEnd] = useState<Date | undefined>()
  const [selectedInput, setSelectedInput] = useState(InputSelection.None)

  const popperRef = useRef<HTMLDivElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const popper = usePopper(popperRef.current, popperElement, {
    placement: selectedInput === InputSelection.Start ? 'bottom-start' : 'bottom-end',
  })

  const handleIconClick = (selection: InputSelection) => () => setSelectedInput(selection)

  const handlePickDay = (date: Date) => {
    setSelectedStart(date)
    setSelectedInput(InputSelection.None)
    isFunction(onPickDay) && onPickDay(date)
  }

  const handlePickRange = (date: Date) => {
    let startDate = selectedStart
    let endDate = selectedEnd

    switch (selectedInput) {
      case InputSelection.Start:
        setSelectedStart(date)
        startDate = date

        if (selectedEnd && date > selectedEnd) {
          setSelectedEnd(undefined)
          endDate = undefined
          isFunction(onClearRange) && onClearRange()
        }

        setSelectedInput(InputSelection.End)
        isFunction(onPickDay) && onPickDay(date, selectedInput)
        break

      case InputSelection.End:
        setSelectedEnd(date)
        endDate = date

        if (selectedStart && date < selectedStart) {
          setSelectedStart(undefined)
          startDate = undefined
          isFunction(onClearRange) && onClearRange()
        }

        setSelectedInput(InputSelection.None)
        isFunction(onPickDay) && onPickDay(date, selectedInput)
        break
    }

    if (startDate && endDate && isFunction(onPickRange)) {
      onPickRange({ start: startDate, end: endDate })
    }
  }

  const startRef = useRef<Date | undefined>(start)
  const endRef = useRef<Date | undefined>(end)

  useEffect(() => {
    if (start !== startRef.current) {
      setSelectedStart(start)
      startRef.current = start
    }

    if (end !== endRef.current) {
      setSelectedEnd(end)
      endRef.current = end
    }
  }, [start, end])

  return (
    <div className={cx(Style.datepicker, customClasses)} data-test={`${dataTest}.container`}>
      <div ref={popperRef} className={Style.inputContainer}>
        <Input
          readonly
          active={selectedInput === InputSelection.Start}
          customClasses={Style.startDateInput}
          data-test={`${dataTest}.start`}
          disabled={disabled}
          icon={IconName.CalendarDays}
          invalid={invalid}
          label={startLabel}
          placeholder={startPlaceholder}
          value={formatDate(selectedStart)}
          onFocus={handleIconClick(InputSelection.Start)}
          onIconClick={handleIconClick(InputSelection.Start)}
        />
        {mode === DatePickerMode.Range && (
          <Input
            readonly
            active={selectedInput === InputSelection.End}
            customClasses={Style.endDateInput}
            data-test={`${dataTest}.end`}
            disabled={disabled}
            icon={IconName.CalendarDays}
            invalid={invalid}
            label={endLabel}
            placeholder={endPlaceholder}
            value={formatDate(selectedEnd)}
            onFocus={handleIconClick(InputSelection.End)}
            onIconClick={handleIconClick(InputSelection.End)}
          />
        )}
      </div>
      {selectedInput !== InputSelection.None && (
        <FocusTrap
          active
          focusTrapOptions={{
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            fallbackFocus: `.${Style.datepicker} [role="dialog"]`,
            initialFocus: false,
            returnFocusOnDeactivate: false,
          }}
        >
          <div
            style={popper.styles.popper}
            tabIndex={-1}
            {...popper.attributes.popper}
            ref={setPopperElement}
            className={Style.popupContainer}
            role="dialog"
          >
            {mode === DatePickerMode.Single && (
              <DayPicker
                disabled={disabledDate}
                initialFocus={selectedInput === InputSelection.Start}
                mode={DatePickerMode.Single}
                selected={selectedStart}
                onDayClick={handlePickDay}
              />
            )}
            {mode === DatePickerMode.Range && (
              <DayPicker
                disabled={disabledDate}
                initialFocus={selectedInput === InputSelection.End}
                mode={DatePickerMode.Range}
                selected={{
                  from: selectedStart,
                  to: selectedEnd,
                }}
                onDayClick={handlePickRange}
              />
            )}
          </div>
        </FocusTrap>
      )}
      {help && (
        <div>
          <span className={cx([Style.helperText, { [Style.invalid]: invalid }])} data-test={`${dataTest}.help`}>
            {help}
          </span>
        </div>
      )}
    </div>
  )
}

export default DatePicker

export type { Props }
export { DatePickerMode }
