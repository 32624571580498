import classNames from 'classnames'
import { map } from 'lodash'
import React from 'react'

import Loading from '@athena/component/atom/loading'
import { FeedbackRequestState } from '@athena/core/model'

import RequestListItem from '@future-view/frontend/component/request-list/request-list-item'

import Props from './interface'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

const TITLE_MAP: Partial<Record<FeedbackRequestState, string>> = {
  [FeedbackRequestState.Pending]: 'Pending',
  [FeedbackRequestState.Completed]: 'Completed',
  [FeedbackRequestState.AwaitingApproval]: 'Pending Approval',
  [FeedbackRequestState.SkipRequested]: 'Pending Approval',
  [FeedbackRequestState.Skipped]: 'Completed',
}

const FeedbackRequestGroup: React.FC<Props> = ({
  'data-test': dataTest,
  feedbackData = [],
  filterIds = [],
  handleClick,
  isForManager = false,
  resourceList,
  loading,

  types,
}) => {
  feedbackData = feedbackData.filter((request) => !filterIds.includes(request.id)) ?? []
  return (
    <div
      className={cx(Style.listWrapper, 'mt-[45px] mx-[12px] p-[24px] pb-[56px] rounded-[16px] bg-white ')}
      data-test={`${dataTest}.container`}
    >
      <div className="mb-[36px] text-[18px] font-semibold capitalize" data-test={`${dataTest}.title`}>
        {TITLE_MAP[types?.[0]] + (feedbackData ? ` (${feedbackData.length})` : '')}
      </div>
      {!loading && (
        <>
          {map(feedbackData, (review, i) => (
            <RequestListItem
              key={review.id}
              data-test={`${dataTest}.list-item-${i}`}
              isForManager={isForManager}
              request={review}
              resourceList={resourceList}
              onClick={handleClick}
            />
          ))}
          {(!feedbackData || feedbackData.length === 0) && (
            <div data-test={`${dataTest}.no-items`}>{`No ${TITLE_MAP[types?.[0]]} reviews`}</div>
          )}
        </>
      )}
      {loading && (
        <div className={Style.blockingOpaqueBlock}>
          <div className={Style.loadingContainer}>
            <Loading show data-test={`peer-review-page.loading`} />
          </div>
        </div>
      )}
    </div>
  )
}

export default FeedbackRequestGroup
export type { Props }
