export enum LogoSize {
  Small = 'small',
  Normal = 'normal',
  Large = 'large',
}

export default interface LogoProps {
  'data-test': string
  customClasses?: string
  label?: boolean
  size?: LogoSize
}
