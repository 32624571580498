import { cloneDeep, includes, isFunction } from 'lodash'
import { useState, useEffect, useCallback, useRef } from 'react'
import { toast } from 'react-toastify'

import Button, { ButtonFill, IconName } from '@athena/component/atom/button'
import Icon from '@athena/component/atom/icon'
import Textarea from '@athena/component/atom/textarea'
import Tooltip from '@athena/component/atom/tooltip'
import Input from '@athena/component/molecule/input'
import Modal from '@athena/component/molecule/modal'
import RevueObjectiveGoal from '@athena/core/model/revue-objective-goal'

import { useRemoveRevueObjectiveGoal } from '@apollo/client/hook/revue'
import ConfirmationDialog from '@future-view/frontend/component/confirm-dialog'
import { validationRules } from '@future-view/frontend/component/performance-objective-detail/util'

import Props from './interface'
import Style from './style.module.scss'

const descriptionTooltipHTML = (
  <div>
    Define your objectives with clarity, ensuring they are Specific, Measurable, Achievable, Relevant, and Time-bound.
    Track your progress above throughout the year, monitoring milestones and adjustments to stay on course toward your
    SMART goals.
  </div>
)
const PerformanceObjectiveGoalDetail: React.FC<Props> = ({
  'data-test': dataTest = 'performance-objective-goal-detail',
  isManager = true,
  goal,
  onChange,
}: Props) => {
  const removeRevueObjectiveGoal = useRemoveRevueObjectiveGoal()
  const [isTitleInvalid, setIsTitleInvalid] = useState<boolean>(false)
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState<boolean>(false)
  const [titleErrorMsg, setTitleErrorMsg] = useState<string | null>(null)
  const [isProgressInvalid, setIsProgressInvalid] = useState<boolean>(false)
  const [progressErrorMsg, setProgressErrorMsg] = useState<string | null>(null)
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false)
  const [showDescriptionTooltip, setShowDescriptionTooltip] = useState<boolean>(false)
  const goalRef = useRef(goal)

  const handleChange = useCallback(
    (value: string, _id?: string) => {
      if (!_id) return
      let localValue: string | number = value as string
      const validationResult = validationRules[_id]?.validate(localValue)
      if (_id === 'title') {
        setIsTitleInvalid(validationResult.isInvalid)
        setTitleErrorMsg(validationResult.errorMsg)
      } else if (_id === 'progress') {
        setIsProgressInvalid(validationResult.isInvalid)
        setProgressErrorMsg(validationResult.errorMsg)
        if (!validationResult.isInvalid) {
          localValue = parseInt(value)
        }
      } else if (_id === 'description') {
        setIsDescriptionInvalid(validationResult.isInvalid)
      }
      const goalCopy = cloneDeep(goal)
      goalCopy[_id as keyof RevueObjectiveGoal] = localValue
      isFunction(onChange) && onChange(goalCopy)
    },
    [goal, onChange]
  )

  const handleDelete = () => {
    const success = () => {
      toast.success('Goal deleted successfully')
      setShowDeleteConfirmationDialog(false)
      isFunction(onChange) && onChange(null)
    }
    if (includes(goal.id, 'new')) {
      success()
    } else {
      const remove = async () => {
        const res = await removeRevueObjectiveGoal.mutateAsync(goal.id)
        if (res.data) {
          success()
        }
      }
      remove()
    }
  }

  const handleBlur = useCallback(
    (value: string, _id?: string) => {
      handleChange(value ?? '', _id)
    },
    [handleChange]
  )

  useEffect(() => {
    const prevGoalId = goalRef.current?.id
    const currentGoalId = goal?.id
    if (currentGoalId !== prevGoalId) {
      setIsTitleInvalid(false)
    }
    goalRef.current = goal
  }, [goal])

  return (
    <>
      <div className={`${Style.wrapper}`} data-test={`${dataTest}.wrapper`}>
        <section className={Style.header}>
          <Input
            customClasses={Style.progress}
            data-test={`${dataTest}.progress`}
            help={progressErrorMsg ?? ''}
            id="progress"
            invalid={isProgressInvalid}
            label="Progress"
            suffix="%"
            value={goal?.progress + ''}
            //onBlur={handleBlur}
            onChange={handleChange}
          />
          <div className={`${Style.icons} flex`}>
            {!isManager && (
              <Tooltip content={'Delete'} data-test={`${dataTest}.tooltip-delete`}>
                <Button
                  basicOutline
                  data-test={`${dataTest}.delete-button`}
                  fill={ButtonFill.Flat}
                  icon={IconName.Trash}
                  onClick={() => {
                    setShowDeleteConfirmationDialog(true)
                  }}
                ></Button>
              </Tooltip>
            )}
          </div>
        </section>
        <hr className={'my-[20px] w-[100%]'}></hr>
        <Input
          data-test={`${dataTest}.title`}
          help={titleErrorMsg ?? ''}
          id="title"
          invalid={isTitleInvalid}
          label="Goal Name"
          value={goal?.title}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <hr className={'my-[20px] w-[100%]'}></hr>
        <div className={Style.smartFields}>
          <section className={Style.fields}>
            <h4 className={'mt-[20px] mb-[10px]'}>Manager&apos;s Goal Notes</h4>
            <Textarea
              data-test={`${dataTest}.manager`}
              disabled={!isManager}
              id="manager_note"
              label=""
              maxSymbols={1000}
              rows={2}
              value={goal?.manager_note || ''}
              onChange={handleChange}
            />
          </section>
          <section className={Style.fields}>
            <h4 className={'flex justify-start gap-[8px] mt-[20px] mb-[20px]'}>
              SMART Goal description
              {!showDescriptionTooltip && (
                <span onClick={() => setShowDescriptionTooltip(true)}>
                  <Icon data-test={`tooltip.close-icon`} name={IconName.InformationCircle} />
                </span>
              )}
              {showDescriptionTooltip && (
                <span onClick={() => setShowDescriptionTooltip(false)}>
                  <Icon data-test={`tooltip.close-icon`} name={IconName.XMark} />
                </span>
              )}
            </h4>
            {showDescriptionTooltip && descriptionTooltipHTML}
            <Textarea
              data-test={`${dataTest}.description`}
              disabled={isManager}
              help={isDescriptionInvalid ? 'Description is required' : ''}
              id="description"
              label=""
              maxSymbols={5000}
              rows={12}
              valid={!isDescriptionInvalid}
              value={goal?.description || ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </section>
        </div>
      </div>
      <Modal
        hideCloseButton
        data-test={`${dataTest}.delete-confirmation-modal`}
        isShown={showDeleteConfirmationDialog}
        onHide={() => setShowDeleteConfirmationDialog(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTest}.delete-confirmation-dialog`}
          description={'Goal will be deleted'}
          returnButtonText=""
          onConfirm={(confirmed: boolean) => {
            if (confirmed) {
              handleDelete()
            } else {
              setShowDeleteConfirmationDialog(false)
            }
          }}
        />
      </Modal>
    </>
  )
}

export default PerformanceObjectiveGoalDetail
export type { Props }
