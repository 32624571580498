import classNames from 'classnames'
import { isFunction } from 'lodash'
import { useEffect, useState } from 'react'

import Button, { ButtonFill } from '@athena/component/atom/button'
import Icon, { IconName } from '@athena/component/atom/icon'
import { EngagementSeatRole } from '@athena/core/model'

import Avatar from '@future-view/frontend/component/avatar'

import { roles, SeatCardProps } from './interface'
import { Label } from './label'
import Style from './style.module.scss'

enum CardState {
  Disabled,
  Normal,
  Hover,
  DeleteWarning,
}

const cx = classNames.bind(Style)

const SeatCard = ({ disabled, seat, onEditClick, onDeleteClick }: SeatCardProps): JSX.Element => {
  const dataTestContainer = `engagement.seat-card.${seat.id}`

  const [state, setState] = useState(CardState.Normal)

  useEffect(() => {
    setState(disabled ? CardState.Disabled : CardState.Normal)
  }, [disabled])
  return (
    <div
      key={seat.id}
      className={cx([Style.seat])}
      onMouseLeave={() => state === CardState.Hover && setState(CardState.Normal)}
      onMouseEnter={() => {
        state === CardState.Normal && setState(CardState.Hover)
      }}
    >
      {state === CardState.Normal && (
        <div className={cx([Style.normalState])}>
          <div>
            <Label selected roleClass={seat.role || ''} value={roles.get(seat.role as EngagementSeatRole) || ''} />
          </div>
          <div className={Style.userContainer}>
            <Avatar cid={seat.resourceId} data-test={`seat-avatar`} wrapperCustomClasses={''}></Avatar>
            {seat.userFullName}
          </div>
        </div>
      )}
      {state === CardState.Disabled && (
        <div className={cx([Style.disabledState])}>
          <div>
            <Label selected roleClass={seat.role || ''} value={roles.get(seat.role as EngagementSeatRole) || ''} />
          </div>
          <div className={Style.userContainer}>
            <Avatar cid={seat.resourceId} data-test={`seat-avatar`} wrapperCustomClasses={''}></Avatar>
            {seat.userFullName}
          </div>
        </div>
      )}
      {state === CardState.Hover && (
        <div className={cx([Style.hoverState], { [Style[seat.role || '']]: seat.role })}>
          <span onClick={() => setState(CardState.DeleteWarning)}>
            <Icon customClasses={Style.icon} data-test={`${dataTestContainer}.delete-seat`} name={IconName.Trash} />
          </span>
          <div className={Style.separator}></div>
          <span
            onClick={() => {
              onEditClick && isFunction(onEditClick) && onEditClick(seat)
            }}
          >
            <Icon
              customClasses={Style.icon}
              data-test={`${dataTestContainer}.edit-seat`}
              name={IconName.PencilSquare}
            />
          </span>
        </div>
      )}
      {state === CardState.DeleteWarning && (
        <div className={cx([Style.deleteWarningState])}>
          <span className={Style.warningText}>Are you sure?</span>
          <div className={Style.buttonContainer}>
            <Button
              customClasses={Style.no}
              data-test={`${dataTestContainer}.delete-confirmation-no`}
              fill={ButtonFill.Outline}
              onClick={() => {
                setState(CardState.Hover)
              }}
            >
              No
            </Button>
            <Button
              customClasses={Style.yes}
              data-test={`${dataTestContainer}.delete-confirmation-yes`}
              fill={ButtonFill.Outline}
              onClick={() => {
                onDeleteClick && isFunction(onDeleteClick) && onDeleteClick(seat)
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SeatCard
