import { assignIn, pick } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelWarehouse } from './base-model'

export type EmployeeSerialized = z.infer<typeof Employee.schema>

export type EmployeeWarehouse = BaseModelWarehouse & {
  employeeId: string
  mgrDirectReports: number
  isAdmin: string
  preferredFirstName: string
  preferredLastName: string
  groupEmailAddress: string
  directMgrEmployeeId: string
  directMgrEmail: string
  isActiveEmployee: string
  businessTeam: string
  practice: string
  officeCity: string
}

export type EmployeeDetails = {
  isAdmin: string
  employeeId: string
  employeeFirstName: string
  employeeLastName: string
  groupEmailAddress: string
  managerGroupEmailAddress: string
  managerFirstName: string
  managerLastName: string
  businessTeam: string
  practice: string
  officeCity: string
}

export default class Employee extends BaseModel {
  employeeId = ''
  mgrDirectReports = 0
  isAdmin = 'NO'
  firstName = ''
  lastName = ''
  email = ''
  directMgrEmployeeId = ''
  groupEmailAddress = ''
  preferredFirstName = ''
  preferredLastName = ''
  directMgrEmail = ''
  isActiveEmployee = ''
  businessTeam = ''
  practice = ''
  officeCity = ''

  constructor(id: string, firstName: string, lastName: string) {
    super(id, firstName, null, null)
    this.employeeId = id || ''
    this.firstName = firstName || ''
    this.lastName = lastName || ''
    this.name = `${this.firstName} ${this.lastName}`
    this.id = id
  }

  static override readonly schema = BaseModel.schema.extend({
    employeeId: z.string().max(16).default(''),
    mgrDirectReports: z.number().max(20).default(0),
    directMgrEmployeeId: z.string().max(20).default(''),
    isAdmin: z.string().max(5).default(''),
    firstName: z.string().max(50).default(''),
    lastName: z.string().max(50).default(''),
    email: z.string().email().max(100).default(''),
    groupEmailAddress: z.string().max(100).default(''),
    preferredFirstName: z.string().max(50).default(''),
    preferredLastName: z.string().max(50).default(''),
    directMgrEmail: z.string().max(100).default(''),
    isActiveEmployee: z.string().max(3).default(''),
    businessTeam: z.string().max(50).default(''),
    practice: z.string().max(50).default(''),
    officeCity: z.string().max(100).default(''),
  })

  static override fromSerialized(recordInput: EmployeeSerialized): Employee {
    const record = Employee.schema.parse(recordInput)
    const model = new Employee(record.id, record.firstName, record.lastName)
    model.mgrDirectReports = record.mgrDirectReports || 0
    model.isAdmin = record.isAdmin || 'NO'
    model.isActiveEmployee = record.isActiveEmployee || 'NO'
    model.email = record.email || ''
    model.directMgrEmployeeId = record.directMgrEmployeeId || ''
    model.groupEmailAddress = record.groupEmailAddress || ''
    model.preferredFirstName = record.preferredFirstName || ''
    model.preferredLastName = record.preferredLastName || ''
    model.directMgrEmail = record.directMgrEmail || ''
    model.businessTeam = record.businessTeam || ''
    model.practice = record.practice || ''
    model.officeCity = record.officeCity || ''

    return model
  }

  static override fromWarehouse(record: EmployeeWarehouse): Employee {
    const model = new Employee(record.employeeId, record.preferredFirstName, record.preferredLastName)
    model.mgrDirectReports = record.mgrDirectReports || 0
    model.isAdmin = record.isAdmin || 'NO'
    model.isActiveEmployee = record.isActiveEmployee || 'NO'
    model.email = record.groupEmailAddress || ''
    model.directMgrEmployeeId = record.directMgrEmployeeId || ''
    model.groupEmailAddress = record.groupEmailAddress || ''
    model.preferredFirstName = record.preferredFirstName || ''
    model.preferredLastName = record.preferredLastName || ''
    model.directMgrEmail = record.directMgrEmail || ''
    model.businessTeam = record.businessTeam || ''
    model.practice = record.practice || ''
    model.officeCity = record.officeCity || ''

    return model
  }

  override toSerialized(): EmployeeSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
    } as unknown as EmployeeSerialized
  }

  project(projection: string[]): Partial<EmployeeSerialized> {
    const serialized = this.toSerialized()
    return projection?.length ? pick(serialized, projection) : serialized
  }
}
