import classNames from 'classnames'
import React from 'react'

import Loading from '@athena/component/atom/loading'
import Traits from '@athena/core/model/traits'

import { useFeedbackByTrait } from '@apollo/client/hook/engagement'
import FeedbackCard from '@future-view/frontend/component/feedback-card'
import { HeatmapEntryType } from '@future-view/frontend/component/heatmap'
import { buildStyles } from '@future-view/frontend/component/heatmap/util'
import ResourceCard from '@future-view/frontend/component/resource-card'
import useEmployeeMap from '@future-view/frontend/hook/use-employee-map'

import Props from './interface'
import Style from './style.module.scss'

const StrengthImprovementTraits: React.FC<Props> = ({
  'data-test': dataTest = 'strength-improvement-trait',
  data,
  wrapperCustomClasses,
}) => {
  const key = data?.key?.split('-')[1]
  const entryType: HeatmapEntryType = data?.key?.split('-')[0] as HeatmapEntryType
  const resourceList = useEmployeeMap()
  const feedbacksData = useFeedbackByTrait(key || '', data.idResource)
  const feedbacks = feedbacksData.data?.data || []
  const strenghts: Traits[] = feedbacks.filter((item) => {
      return item.key === 'Strength'
    }),
    improvements: Traits[] = feedbacks.filter((item) => {
      return item.key !== 'Strength'
    })

  return (
    <>
      {feedbacksData.isLoading && resourceList.isLoading && (
        <div className={Style.blockingOpaqueBlock}>
          <div className={Style.loadingContainer}>
            <Loading data-test={`trait-modal.loading`} />
          </div>
        </div>
      )}
      {!feedbacksData.isLoading && !resourceList.isLoading && (
        <div className={classNames(wrapperCustomClasses, Style.wrapper)} data-test={`${dataTest}.container`}>
          <div className={Style.textWrapper}>
            <div className={Style.header}>
              <ResourceCard
                data-test={`${dataTest}.resource-card`}
                fname={data?.resourceFirstName}
                id={data.idResource}
                lname={data?.resourceLastName}
                name={''}
                role={''}
                wrapperCustomClasses={Style.peerCard}
              ></ResourceCard>
              <div
                className={Style.trait}
                data-test={`${dataTest}.trait`}
                style={buildStyles(entryType, data?.value || 0)}
              >
                {key}
              </div>
            </div>
            <h3 className={'my-[20px] text-center'} data-test={`${dataTest}.strength-label`}>
              Strengths
            </h3>
            <div data-test={`${dataTest}.strengths-wrapper`}>
              {strenghts.length > 0 &&
                strenghts.map((i: Traits) => {
                  return (
                    <FeedbackCard
                      key={`${i.id}-${i.key}-${i.created}`}
                      data-test={`${dataTest}.strength-card`}
                      date={i.created}
                      engagement={i.title}
                      feedback={i.justification}
                      id={i.id}
                      name={resourceList[i.id_source]?.name}
                      role={resourceList[data.idResource]?.directMgrEmployeeId === i.id_source ? 'Lead' : 'Peer'}
                    ></FeedbackCard>
                  )
                })}
              {strenghts.length === 0 && <div className={'text-center'}>No strength data</div>}
            </div>
            <h3 className={'my-[20px] text-center'} data-test={`${dataTest}.improvement-label`}>
              Improvements
            </h3>
            <div data-test={`${dataTest}.improvements-wrapper`}>
              {improvements.length > 0 &&
                improvements.map((i: Traits) => {
                  return (
                    <FeedbackCard
                      key={`${i.id}-${i.key}-${i.created}`}
                      data-test={`${dataTest}.improvement-card`}
                      date={i.created}
                      engagement={i.title}
                      feedback={i.justification}
                      id={i.id}
                      name={resourceList[i.id_source]?.name}
                      role={resourceList[data.idResource]?.directMgrEmployeeId === i.id_source ? 'Lead' : 'Peer'}
                    ></FeedbackCard>
                  )
                })}
              {improvements.length === 0 && <div className={'text-center'}>No improvement data</div>}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default StrengthImprovementTraits
export type { Props }
