import classNames from 'classnames'
import { isFunction } from 'lodash'
import React, { useMemo } from 'react'

import Icon, { IconName } from '@athena/component/atom/icon'
import { FeedbackRequest, FeedbackRequestState } from '@athena/core/model'

import { useEngagement } from '@apollo/client/hook/engagement'
import Avatar from '@future-view/frontend/component/avatar'
import { getTimeDifference } from '@future-view/frontend/component/request-list/utils'
import ResourceCard from '@future-view/frontend/component/resource-card'
import useEmployeeMap from '@future-view/frontend/hook/use-employee-map'

import Props from './interface'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

const RequestListItem: React.FC<Props> = ({
  'data-test': dataTest = 'request-list-item',
  isForManager = false,
  onClick,
  request,
  resourceList,
}) => {
  const handleClick = (reviewItem: FeedbackRequest) => isFunction(onClick) && onClick(reviewItem)
  const { data: engagementData } = useEngagement(request.rel.engagement)

  const targetRole = useMemo(
    () => engagementData?.data?.seats.filter((seat) => seat.rel.resource === request.rel.target)[0]?.role,
    [engagementData, request.rel.target]
  )
  const sourceRole = useMemo(
    () => engagementData?.data?.seats.filter((seat) => seat.rel.resource === request.rel.source)[0]?.role,
    [engagementData, request.rel.source]
  )
  // const resourceList = useEmployeeMap()

  const targetName = useMemo(() => resourceList[request.rel.target]?.name, [resourceList, request.rel.target])
  const targetId = useMemo(() => resourceList[request.rel.target]?.id, [resourceList, request.rel.target])
  const sourceName = useMemo(() => resourceList[request.rel.source]?.name, [resourceList, request.rel.source])
  const sourceId = useMemo(() => resourceList[request.rel.source]?.id, [resourceList, request.rel.source])

  return (
    <div
      key={request.id}
      className={cx(Style.review, 'bg-grey-50 pl-[64px] pr-[16px] py-[14px] rounded-[16px] flex')}
      data-test={`${dataTest}.item`}
      onClick={() => handleClick(request)}
    >
      <div className="py-[26px] flex items-center">
        {isForManager && (
          <>
            <ResourceCard
              data-test={`${dataTest}.source-name`}
              id={sourceId}
              name={sourceName}
              role={sourceRole || ''}
              wrapperCustomClasses={'w-[250px] items-center'}
            ></ResourceCard>
            <Icon
              customClasses="mx-[30px]"
              data-test={`${dataTest}.right-arrow-icon`}
              name={IconName.ArrowRightCircle}
            />
          </>
        )}
        <div className={`mr-[12px] ${Style.imgWrapper}`}>
          <Avatar cid={targetId} data-test={`${dataTest}.target-avatar`} wrapperCustomClasses={Style.img}></Avatar>
        </div>
        <div>
          {targetName && (
            <div className="text-[18px] font-semibold" data-test={`${dataTest}.target-name`}>
              {targetName}
            </div>
          )}
          {!isForManager && (
            <div className="text-[14px] uppercase text-grey-700" data-test={`${dataTest}.description`}>
              {request.description}
            </div>
          )}
          {isForManager && targetRole && (
            <div className="text-[14px] uppercase text-grey-700" data-test={`${dataTest}.role`}>
              {targetRole}
            </div>
          )}
        </div>
      </div>
      <div className="ml-auto">
        {request.state === FeedbackRequestState.Pending && (
          <div className={cx(Style.remainingTime, 'flex items-center')} data-test={`${dataTest}.remaining-time`}>
            <Icon data-test={`${dataTest}.time-icon`} name={IconName.Clock} />
            {request.due && getTimeDifference(request.due, false) && (
              <span className="ml-[8px] text-[20px]">{getTimeDifference(request.due, false)}</span>
            )}
          </div>
        )}
        {(request.state === FeedbackRequestState.AwaitingApproval ||
          request.state === FeedbackRequestState.SkipRequested) && (
          <div className="h-full flex flex-col justify-between">
            <div className={cx(Style.remainingTime, 'flex items-center')} data-test={`${dataTest}.time-since-update`}>
              <Icon data-test={`${dataTest}.calendar-icon`} name={IconName.CalendarDays} />
              {request.due && getTimeDifference(request.updated as Date, true) && (
                <span className="ml-[8px] text-[20px]">{`${getTimeDifference(request.updated as Date, true)} ${
                  getTimeDifference(request.updated as Date, true) === 'Now' ? '' : 'ago'
                }`}</span>
              )}
            </div>
            {isForManager && (
              <div className="text-[14px] uppercase text-grey-700" data-test={`${dataTest}.description`}>
                {engagementData?.data?.title}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default RequestListItem
export type { Props }
