import classNames from 'classnames'
import { isFunction } from 'lodash'
import React, { useMemo, useState } from 'react'

import Button, { ButtonFill } from '@athena/component/atom/button'
import Combobox from '@athena/component/atom/combobox'

import UserPlaceholder from '@future-view/frontend/asset/user-placeholder.svg'

import { roles, Option, Seat, SeatModalProps } from './interface'
import { Label } from './label'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

export const SeatModalContent: React.FC<SeatModalProps> = ({
  dataTestContainer,
  editedSeat,
  users,
  onHide,
  onSave,
  isResourcePresent,
}) => {
  const [seat, setSeat] = useState<Seat>(editedSeat)

  const resourceOptions = useMemo(
    () => Array.from(users, ([userId, userName]) => ({ label: userName, value: userId })),
    [users]
  )

  return (
    <div className={Style.createSeatContainer}>
      <div className={Style.tagList}>
        {Array.from(roles, ([role, roleTitle]) => (
          <Label
            key={role}
            roleClass={role}
            selected={role === seat.role}
            value={roleTitle}
            onClick={() => {
              setSeat({ ...seat, role })
            }}
          />
        ))}
      </div>
      <div className={Style.inputBlock}>
        <img alt="" className={Style.userIcon} src={UserPlaceholder} />
        <Combobox
          customClasses={Style.userName}
          data-test={`${dataTestContainer}.username-combobox`}
          options={resourceOptions}
          value={resourceOptions.find((val) => val.value === seat.resourceId) || null}
          onChange={(value) => {
            const resourceOption = value as Option
            setSeat({ ...seat, resourceId: resourceOption.value || '', userFullName: resourceOption.label || '' })
          }}
        />
        <div className={Style.buttonsContainer}>
          <Button
            data-test={`${dataTestContainer}.cancel-create-seat`}
            fill={ButtonFill.Outline}
            onClick={() => {
              isFunction(onHide) && onHide()
            }}
          >
            Cancel
          </Button>
          <Button
            data-test={`${dataTestContainer}.clear-seat`}
            fill={ButtonFill.Outline}
            onClick={() => {
              setSeat({ ...seat, resourceId: '', userFullName: '' })
            }}
          >
            Clear Seat
          </Button>
          <Button
            customClasses={cx(Style.createSeat, { [Style.disabled]: !seat.role })}
            data-test={`${dataTestContainer}.dialog-create-seat`}
            disabled={!seat.role}
            fill={ButtonFill.Solid}
            onClick={() => {
              if (isFunction(isResourcePresent) && isResourcePresent(seat)) {
                isFunction(onSave) && onSave(seat)
                isFunction(onHide) && onHide()
              }
            }}
          >
            Save Seat
          </Button>
        </div>
      </div>
    </div>
  )
}
