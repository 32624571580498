import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { getSidebarCount } from '@apollo/client/api/sidebar'

export const QueryKey = {
  SidebarCount: 'sidebar-count',
}

export const useSidebarCountMutate = () => {
  return useMutation({
    mutationFn: getSidebarCount,
    onError: (err) => toast.error(`Error fetching Sidebar Count ${err}`),
  })
}
