import { assignIn } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, DateFormat, Quantum, Schema } from '@athena/core/model/base-model'

export type FeedbackRequestSerialized = z.infer<typeof FeedbackRequest.schema>

export enum FeedbackRequestState {
  Pending = 'pending',
  AwaitingApproval = 'awaiting-approval',
  Completed = 'completed',
  Expired = 'expired',
  RevisionRequested = 'revision-requested',
  SkipRequested = 'skip-requested',
  Skipped = 'skipped',
}

export type FeedbackRequestStore = BaseModelStore & {
  id_engagement: string
  id_source: string
  id_target: string
  due: string | null
  state: FeedbackRequestState
  description: string
  skip_reason: string
  revision_msg: string
}

export default class FeedbackRequest extends BaseModel {
  rel = {
    engagement: '',
    source: '',
    target: '',
  }
  due: Quantum<Date>
  state = FeedbackRequestState.Pending
  description = ''
  skipReason = ''
  revisionMsg = ''

  constructor(id = '', engagement = '', source = '', target = '') {
    super(id)
    this.rel = { engagement, source, target }
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      engagement: Schema.Id,
      source: Schema.Id,
      target: Schema.Id,
    }),
    due: Schema.Date,
    state: z.nativeEnum(FeedbackRequestState),
    description: z.string().max(255).default(''),
    skipReason: z.string().max(1000).default(''),
    revisionMsg: z.string().max(1000).default(''),
  })

  static override fromSerialized(recordInput: FeedbackRequestSerialized): FeedbackRequest {
    const record = FeedbackRequest.schema.parse(recordInput)
    const model = new FeedbackRequest(record.id, record.rel.engagement, record.rel.source, record.rel.target)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.due = BaseModel.parseDate(record.due)
    model.state = record.state as FeedbackRequestState
    model.description = record.description || ''
    model.name = record.name || ''
    model.skipReason = record.skipReason || ''
    model.revisionMsg = record.revisionMsg || ''
    return model
  }

  static override fromDataStore(record: FeedbackRequestStore): FeedbackRequest {
    const model = new FeedbackRequest(record.id, record.id_engagement, record.id_source, record.id_target)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.due = BaseModel.parseDate(record.due)
    model.state = record.state as FeedbackRequestState
    model.description = record.description || ''
    model.skipReason = record.skip_reason || ''
    model.revisionMsg = record.revision_msg || ''
    return model
  }

  override toSerialized(): FeedbackRequestSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      due: BaseModel.formatDate(this.due, DateFormat.Date),
    } as FeedbackRequestSerialized
  }

  override toDataStore(): FeedbackRequestStore {
    return {
      ...super.toDataStore(),
      id_engagement: this.rel.engagement,
      id_source: this.rel.source,
      id_target: this.rel.target,
      due: BaseModel.formatDate(this.due),
      state: this.state,
      description: this.description,
      skip_reason: this.skipReason,
      revision_msg: this.revisionMsg,
    }
  }
}
