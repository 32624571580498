import axios from 'axios'

import Pager, { PagerData } from '@athena/core/api/pager'
import Slice from '@athena/core/api/slice'
import Response from '@athena/core/client/response'
import { Reference, ResourcedActivity } from '@athena/core/model'

import Environment from '@artemis/client/environment'

const client = axios.create({
  baseURL: `${Environment.api.host}/resourced-activity`,
  timeout: Environment.api.timeout,
  validateStatus: (s) => s > 199 && s < 500,
  withCredentials: true,
})

const resolveParams = (pagerData?: PagerData) => (pagerData ? Pager.fromData(pagerData).toSerialized() : {})

export const getActivity = async (idInput: string): Promise<Response<ResourcedActivity>> => {
  const id = ResourcedActivity.schema.shape.id.parse(idInput)
  const resp = await client.get(`/${id}`)
  return Response.single(resp, ResourcedActivity.fromSerialized)
}

export const getActivityReference = async (): Promise<Response<Reference[]>> => {
  const resp = await client.get('/reference')
  return Response.list(resp)
}

export const getActivitySlice = async (pagerData?: PagerData): Promise<Slice<ResourcedActivity>> => {
  const resp = await client.get('/', { params: resolveParams(pagerData) })
  return Slice.fromSerialized(resp.status, resp.data, ResourcedActivity.fromSerialized)
}

export const getUpcomingActivity = async (pagerData?: PagerData): Promise<Slice<ResourcedActivity>> => {
  const resp = await client.get('/upcoming', { params: resolveParams(pagerData) })
  return Slice.fromSerialized(resp.status, resp.data, ResourcedActivity.fromSerialized)
}
