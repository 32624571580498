import classNames from 'classnames'
import { isFunction } from 'lodash'
import React, { useEffect, useState } from 'react'

import Icon, { IconName, IconSize } from '@athena/component/atom/icon'

import { Props } from './interface'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

const Checkbox: React.FC<Props> = ({
  'data-test': dataTest = 'checkbox',
  isDisabled = false,
  label,
  value,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState(!!value)

  useEffect(() => {
    setIsChecked(value)
  }, [onChange, value])

  const handleChange = () => {
    if (!isDisabled) {
      setIsChecked(!isChecked)
      isFunction(onChange) && onChange(!isChecked)
    }
  }

  return (
    <div
      className={cx(Style.checkbox, { [Style.checked]: isChecked })}
      data-test={`${dataTest}.container`}
      onClick={handleChange}
    >
      <input readOnly checked={isChecked} data-test={`${dataTest}.input`} disabled={isDisabled} type="checkbox" />
      {isChecked && (
        <Icon customClasses="icon" data-test={`${dataTest}.icon`} name={IconName.Check} size={IconSize.Small} />
      )}
      {label && <span data-test={`${dataTest}.label`}>{label}</span>}
    </div>
  )
}

export default Checkbox
export type { Props }
