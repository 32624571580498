import { Buffer } from 'buffer'

import LocalIdentity from '@athena/core/client/local-identity'

export const getEmployeeData = () => {
  const data = localStorage.getItem('employee')
  if (data) {
    return JSON.parse(Buffer.from(data, 'base64').toString('ascii'))
  }
  return null
}

export const setEmployeeData = (identityData: LocalIdentity) => {
  localStorage.setItem('employee', Buffer.from(JSON.stringify(identityData)).toString('base64'))
}
