import Button, { ButtonColor, ButtonFill, ButtonSize } from '@athena/component/atom/button'
import Tag, { TagSize, TagVariant } from '@athena/component/atom/tag'

import { TagSelectorProps } from './interface'

const TagSelector = ({
  'data-test': dataTest,
  disabled = false,
  onChange,
  onDialogRequest,
  title,
  list,
  subtitle,
}: TagSelectorProps) => {
  return (
    <div className={`flex flex-col ${list.length > 0 ? 'mb-4' : ''}`}>
      <div className="flex flex-row p-4 rounded-lg justify-between border border-grey-200 border-solid mb-1">
        <div className={`flex flex-col gap-2 ${list.length > 0 ? 'justify-start' : 'justify-center'}`}>
          <span className={`${list.length > 0 ? 'text-xs text-grey-600 mt-[-4px]' : 'text-base text-grey-500'}`}>
            {title}
          </span>
          {list.length > 0 ? (
            <div className="flex flex-row gap-2 flex-wrap">
              {list.map((item) => (
                <Tag
                  key={item}
                  interactive
                  data-test={`${dataTest}.tag.${item}`}
                  data-test-arg={item}
                  disabled={disabled}
                  hasClose={!disabled}
                  size={TagSize.Large}
                  variant={TagVariant.Dark}
                  onClose={() => onChange(list.filter((i) => i !== item))}
                >
                  {item}
                </Tag>
              ))}
            </div>
          ) : null}
        </div>
        {!disabled && (
          <Button
            color={ButtonColor.Accent}
            customClasses="self-end bg-accent"
            data-test={`${dataTest}.button`}
            disabled={disabled}
            fill={ButtonFill.Solid}
            size={ButtonSize.Small}
            onClick={onDialogRequest}
          >
            Open List
          </Button>
        )}
      </div>
      <span className="text-grey-500 text-xs">{subtitle}</span>
    </div>
  )
}

export default TagSelector
