import Response from '@athena/core/client/response'
import FeedbackReport from '@athena/core/model/feedback-report'
import RevueObjective from '@athena/core/model/revue-objective'

import Environment from '@apollo/client/environment'
import { getAxiosInstance } from '@apollo/client/util/interceptor'

const client = getAxiosInstance({
  baseURL: `${Environment.apollo.host}/reports`,
  timeout: Environment.apollo.timeout,
  validateStatus: (s) => s > 199 && s < 300,
  withCredentials: true,
})

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //
export const getReviewCompletionReport = async (): Promise<Response<FeedbackReport[]>> => {
  const resp = await client.get('/completion-report')
  return Response.list(resp)
}

export const getRevueReport = async (year: string): Promise<Response<RevueObjective[]>> => {
  const resp = await client.get(`/revue-report/${year}`)
  return Response.list(resp)
}
// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //
