import React from 'react'

import { ObjectiveProps } from '@future-view/frontend/page/revue-dashboard/interface'

import Style from './style.module.scss'

const PerformanceObjective: React.FC<ObjectiveProps> = ({
  'data-test': dataTest = 'performance-objective',
  id,
  title,
  description,
  date,
  onClicked,
}) => {
  return (
    <>
      <div
        key={id}
        className={`${Style.sectionWrapper} rounded-[8px] bg-white p-[25px]`}
        data-test={`${dataTest}.wrapper`}
        onClick={() => onClicked(id)}
      >
        <h3 className={Style.title} data-test={`${dataTest}.title`}>
          {title}
        </h3>
        <p className={Style.date} data-test={`${dataTest}.date`}>
          {date}
        </p>
        <p className={Style.description} data-test={`${dataTest}.description`}>
          {description}
        </p>
      </div>
    </>
  )
}

export default PerformanceObjective
export type { ObjectiveProps }
