import axios from 'axios'

import Response from '@athena/core/client/response'
import { Resource } from '@athena/core/model'
import RevueNotes from '@athena/core/model/revue-notes'
import RevueObjective from '@athena/core/model/revue-objective'

import Environment from '@apollo/client/environment'

const client = axios.create({
  baseURL: `${Environment.apollo.host}/revue`,
  timeout: Environment.apollo.timeout,
  validateStatus: (s) => s > 199 && s < 500,
  withCredentials: true,
})

export const getRevueObjectiveBySourceTarget = async (data: {
  source: string
  target: string
  year: string
}): Promise<Response<RevueObjective[]>> => {
  const source = RevueObjective.schema.shape.source.parse(data.source)
  const target = RevueObjective.schema.shape.source.parse(data.target)
  const resp = await client.get('/objectives', {
    params: { source, target, year: data.year },
  })
  return Response.list(resp)
}

export const addRevueObjective = async (revueObjective: RevueObjective): Promise<Response<RevueObjective>> => {
  const serialized = RevueObjective.schema.parse(revueObjective.toSerialized())
  const resp = await client.post('/objectives', serialized)
  return Response.single(resp, RevueObjective.fromSerialized)
}

export const updateRevueObjective = async (revueObjective: RevueObjective): Promise<Response<RevueObjective>> => {
  const serialized = RevueObjective.schema.parse(revueObjective.toSerialized())
  const resp = await client.put(`/objectives/${serialized.id}`, serialized)
  return Response.single(resp, RevueObjective.fromSerialized)
}

export const getRevueObjectiveById = async (id: string): Promise<Response<RevueObjective>> => {
  const resp = await client.get(`/objectives/${id}`)
  return Response.single(resp, RevueObjective.fromSerialized)
}

export const removeRevueObjective = async (id: string): Promise<Response<boolean>> => {
  const resp = await client.delete(`/objectives/${id}`)
  return Response.deletion(resp)
}

export const getRevueNotesBySourceTarget = async (data: {
  source: string
  target: string
  year: string
}): Promise<Response<RevueNotes[]>> => {
  const source = RevueNotes.schema.shape.source.parse(data.source)
  const target = RevueNotes.schema.shape.source.parse(data.target)
  const resp = await client.get('/notes', {
    params: { source, target, year: data.year },
  })
  return Response.list(resp)
}

export const removeRevueObjectiveGoal = async (id: string): Promise<Response<boolean>> => {
  const resp = await client.delete(`/objectives/goal/${id}`)
  return Response.deletion(resp)
}

export const updateRevueNotes = async (notes: RevueNotes): Promise<Response<boolean>> => {
  const serialized = RevueNotes.schema.parse(notes.toSerialized())
  const resp = await client.put(`/notes`, serialized)
  return Response.deletion(resp)
}

export const getDistinctRevueObjectiveCreationYears = async (target: string): Promise<Response<string[]>> => {
  const id = Resource.schema.shape.id.parse(target)
  const resp = await client.get(`/creationYears/${id}`)
  return resp
}

export const updRevueObjectiveShowRatings = async (target: string): Promise<Response<RevueObjective>> => {
  const resp = await client.put(`/objectives/showRatings/${target}`)
  return Response.single(resp, RevueObjective.fromSerialized)
}

export const createBulkObjectives = async (year: string): Promise<Response<string>> => {
  const resp = await client.get(`/createBulk/${year}`)
  return Response.single(resp)
}

export const checkIfObjectivesCreated = async (year: string): Promise<Response<string>> => {
  const resp = await client.get(`/objective-created/${year}`)
  return Response.single(resp)
}

export const updateBulkObjectives = async (data: { year: string; records: any[] }): Promise<Response<string>> => {
  const resp = await client.put(`/bulkUpdate`, data)
  return Response.single(resp)
}
