import axios from 'axios'

import Pager, { PagerData } from '@athena/core/api/pager'
import Slice from '@athena/core/api/slice'
import Response from '@athena/core/client/response'
import { Reference, Resource, User } from '@athena/core/model'

import Environment from '@artemis/client/environment'

const client = axios.create({
  baseURL: `${Environment.api.host}/resource`,
  timeout: Environment.api.timeout,
  validateStatus: (s) => s > 199 && s < 500,
  withCredentials: true,
})

const resolveParams = (pagerData?: PagerData) => (pagerData ? Pager.fromData(pagerData).toSerialized() : {})

export const getResourceSlice = async (pagerData?: PagerData): Promise<Slice<Resource>> => {
  const resp = await client.get('/', { params: resolveParams(pagerData) })
  return Slice.fromSerialized(resp.status, resp.data, Resource.fromSerialized)
}

export const getResourceReference = async (): Promise<Response<Reference[]>> => {
  const resp = await client.get('/reference')
  return Response.list(resp)
}

export const getResource = async (idInput: string): Promise<Response<Resource>> => {
  const id = Resource.schema.shape.id.parse(idInput)
  const resp = await client.get(`/${id}`)
  return Response.single(resp, Resource.fromSerialized)
}

export const getResourceList = async (idsInput: string[]): Promise<Response<Resource[]>> => {
  const ids = idsInput.map((id) => Resource.schema.shape.id.parse(id))
  const resp = await client.get(`/list/${ids.join(',')}`)
  return Response.list(resp)
}

export const getResourceByEmail = async (email: string): Promise<Response<Resource>> => {
  const resp = await client.get(`/email/${email}`)

  return Response.single(resp, Resource.fromSerialized)
}

export const getPeersByEmail = async (
  email: string,
  subordinatesOfSubordinates?: boolean
): Promise<Response<Resource[]>> => {
  const resp = await client.get(`/peers/${email}&${subordinatesOfSubordinates}`)

  return Response.list(resp)
}

export const getResourceDept = async (connectionId: string): Promise<Response<User>> => {
  const resp = await client.get(`/dept/${connectionId}`)

  return Response.single(resp)
}

export const getResourceAvatar = async (connectionId: string): Promise<Response<string>> => {
  const resp = await client.get(`/avatar/${connectionId}`)

  return Response.single(resp)
}
