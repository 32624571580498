import { createBrowserRouter } from 'react-router-dom'

import Logout from '@future-view/frontend/page/auth/logout'
import Splash from '@future-view/frontend/page/auth/splash'
import EngagementDashboard from '@future-view/frontend/page/engagement-dashboard'
import CreateEngagement from '@future-view/frontend/page/engagement/create-engagement'
import EngagementDetail from '@future-view/frontend/page/engagement/edit-engagement'
import Feedback from '@future-view/frontend/page/feedback'
import Layout from '@future-view/frontend/page/layout/layout'
import Page404 from '@future-view/frontend/page/page-404'
import PeerDetails from '@future-view/frontend/page/peer-details'
import PeerReviewPage from '@future-view/frontend/page/peer-review'
import PeersPectivePage from '@future-view/frontend/page/peerspective'
import FeedbackReviewPage from '@future-view/frontend/page/peerspective/feedback-review'
import RevueDashboard from '@future-view/frontend/page/revue-dashboard'
import Path from '@future-view/frontend/router/path'

import RequireAuth from './require-auth'

const routes = [
  {
    path: Path.Root,
    element: <Splash />,
  },
  {
    path: Path.Logout,
    element: (
      <RequireAuth>
        <Logout />
      </RequireAuth>
    ),
  },
  {
    path: Path.Engagements,
    element: (
      <RequireAuth>
        <Layout>
          <EngagementDashboard />
        </Layout>
      </RequireAuth>
    ),
  },
  {
    path: Path.NewEngagement,
    element: (
      <RequireAuth>
        <Layout>
          <CreateEngagement />
        </Layout>
      </RequireAuth>
    ),
  },
  {
    path: Path.ExistingEngagement,
    element: (
      <RequireAuth>
        <Layout>
          <EngagementDetail />
        </Layout>
      </RequireAuth>
    ),
  },
  {
    path: Path.PeerReview,
    element: (
      <RequireAuth>
        <Layout>
          <PeerReviewPage />
        </Layout>
      </RequireAuth>
    ),
  },
  {
    path: Path.PeersPective,
    element: (
      <RequireAuth>
        <Layout>
          <PeersPectivePage />
        </Layout>
      </RequireAuth>
    ),
  },
  {
    path: Path.PeerDetails,
    element: (
      <RequireAuth>
        <Layout>
          <PeerDetails />
        </Layout>
      </RequireAuth>
    ),
  },
  {
    path: Path.RevueDashboard,
    element: (
      <RequireAuth>
        <Layout>
          <RevueDashboard />
        </Layout>
      </RequireAuth>
    ),
  },
  {
    path: Path.Suggestions,
    element: (
      <RequireAuth>
        <Layout>
          <Feedback />
        </Layout>
      </RequireAuth>
    ),
  },
  {
    path: Path.Logout,
    element: (
      <RequireAuth>
        <Logout />
      </RequireAuth>
    ),
  },
  {
    path: Path.Page404,
    element: (
      <RequireAuth>
        <Layout>
          <Page404 />
        </Layout>
      </RequireAuth>
    ),
  },
  {
    path: '*',
    element: (
      <RequireAuth>
        <Layout>
          <Page404 />
        </Layout>
      </RequireAuth>
    ),
  },
]

export default createBrowserRouter(routes)
