import React from 'react'

export enum TooltipDirection {
  Top = 'top',
  Bottom = 'bottom',
  Right = 'right',
  Left = 'left',
}

export default interface Props {
  'data-test': string
  content?: string
  direction?: TooltipDirection
  delay?: number
  children?: React.ReactNode
}
