import { assignIn } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, Quantum, Reference, Schema } from './base-model'

export type PromotionSerialized = z.infer<typeof Promotion.schema>

export type PromotionStore = BaseModelStore & {
  id_objective: string
  id_source: string
  id_target: string
  id_sponsor1: string
  id_sponsor2: string
  craft: string
  method: string
  leadership: string
}

export default class Promotion extends BaseModel {
  rel: {
    [key: string]: string
    objective: string
    source: string
    target: string
    sponsor1: string
    sponsor2: string
  } = {
    objective: '',
    source: '',
    target: '',
    sponsor1: '',
    sponsor2: '',
  }
  craft = ''
  method = ''
  leadership = ''

  constructor(id: string, objective = '', source = '', target = '', sponsor1 = '', sponsor2 = '') {
    super(id)
    this.rel = { objective, source, target, sponsor1, sponsor2 }
  }

  [key: string]:
    | string
    | {
        [key: string]: string
        objective: string
        source: string
        target: string
        sponsor1: string
        sponsor2: string
      }
    | (() => Promotion)
    | Quantum<Date>
    | (() => Reference)
    | (() => string)
    | (() => PromotionStore)

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      objective: Schema.Id,
      source: Schema.Id.min(1),
      target: Schema.Id.min(1),
      sponsor1: Schema.Id.min(1),
      sponsor2: Schema.Id.min(1),
    }),
    craft: z.string().min(1).default(''),
    method: z.string().min(1).default(''),
    leadership: z.string().min(1).default(''),
  })

  static override fromSerialized(recordInput: PromotionSerialized): Promotion {
    const record = Promotion.schema.parse(recordInput)
    const model = new Promotion(
      record.id,
      record.rel.objective,
      record.rel.source,
      record.rel.target,
      record.rel.sponsor1,
      record.rel.sponsor2
    )
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.craft = record.craft || ''
    model.method = record.method || ''
    model.leadership = record.leadership || ''
    return model
  }

  static override fromDataStore(record: PromotionStore): Promotion {
    const model = new Promotion(
      record.id,
      record.id_objective,
      record.id_source,
      record.id_target,
      record.id_sponsor1,
      record.id_sponsor2
    )
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.craft = record.craft || ''
    model.method = record.method || ''
    model.leadership = record.leadership || ''
    return model
  }

  override toSerialized(): PromotionSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
    } as unknown as PromotionSerialized
  }

  override toDataStore(): PromotionStore {
    return {
      ...super.toDataStore(),
      id_objective: this.rel.objective,
      id_source: this.rel.source,
      id_target: this.rel.target,
      id_sponsor1: this.rel.sponsor1,
      id_sponsor2: this.rel.sponsor2,
      craft: this.craft,
      method: this.method,
      leadership: this.leadership,
    }
  }
}
