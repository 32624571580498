import { useQuery } from '@tanstack/react-query'

import { getEmployeeByEmail, getEmployeeList, getEmployee, getEmployeeByIds } from '@artemis/client/api/employee'

const QueryKey = {
  Employee: 'employee-item',
  EmployeeByEmail: 'employee-by-email',
  EmployeeList: 'employee-list',
}

export const useEmployee = (id: string) =>
  useQuery({
    queryKey: [QueryKey.Employee, id],
    queryFn: () => getEmployee(id),
  })

export const useEmployeeByIds = (ids: string[]) =>
  useQuery({
    queryKey: [QueryKey.Employee, ids],
    queryFn: () => getEmployeeByIds(ids),
  })

export const useEmployeeByEmail = (email: string) =>
  useQuery({
    queryKey: [QueryKey.EmployeeByEmail, email],
    queryFn: () => getEmployeeByEmail(email),
  })

export const useEmployeeReference = () =>
  useQuery({
    queryKey: [QueryKey.EmployeeList],
    queryFn: getEmployeeList,
  })
