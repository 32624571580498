export enum IconType {
  Solid = 'solid',
  Outline = 'outline',
}

export enum IconSize {
  Small = 'small',
  Normal = 'normal',
  Large = 'large',
}

export default interface Props {
  'data-test': string
  name: IconName
  size?: IconSize
  type?: IconType
  customClasses?: string
}

export enum IconName {
  AcademicCap = 'AcademicCapIcon',
  AdjustmentsHorizontal = 'AdjustmentsHorizontalIcon',
  AdjustmentsVertical = 'AdjustmentsVerticalIcon',
  ArchiveBoxArrowDown = 'ArchiveBoxArrowDownIcon',
  ArchiveBoxXMark = 'ArchiveBoxXMarkIcon',
  ArchiveBox = 'ArchiveBoxIcon',
  ArrowDownCircle = 'ArrowDownCircleIcon',
  ArrowDownLeft = 'ArrowDownLeftIcon',
  ArrowDownOnSquareStack = 'ArrowDownOnSquareStackIcon',
  ArrowDownOnSquare = 'ArrowDownOnSquareIcon',
  ArrowDownRight = 'ArrowDownRightIcon',
  ArrowDownTray = 'ArrowDownTrayIcon',
  ArrowDown = 'ArrowDownIcon',
  ArrowLeftCircle = 'ArrowLeftCircleIcon',
  ArrowLeftOnRectangle = 'ArrowLeftOnRectangleIcon',
  ArrowLeft = 'ArrowLeftIcon',
  ArrowLongDown = 'ArrowLongDownIcon',
  ArrowLongLeft = 'ArrowLongLeftIcon',
  ArrowLongRight = 'ArrowLongRightIcon',
  ArrowLongUp = 'ArrowLongUpIcon',
  ArrowPathRoundedSquare = 'ArrowPathRoundedSquareIcon',
  ArrowPath = 'ArrowPathIcon',
  ArrowRightCircle = 'ArrowRightCircleIcon',
  ArrowRightOnRectangle = 'ArrowRightOnRectangleIcon',
  ArrowRight = 'ArrowRightIcon',
  ArrowSmallDown = 'ArrowSmallDownIcon',
  ArrowSmallLeft = 'ArrowSmallLeftIcon',
  ArrowSmallRight = 'ArrowSmallRightIcon',
  ArrowSmallUp = 'ArrowSmallUpIcon',
  ArrowTopRightOnSquare = 'ArrowTopRightOnSquareIcon',
  ArrowTrendingDown = 'ArrowTrendingDownIcon',
  ArrowTrendingUp = 'ArrowTrendingUpIcon',
  ArrowUpCircle = 'ArrowUpCircleIcon',
  ArrowUpLeft = 'ArrowUpLeftIcon',
  ArrowUpOnSquareStack = 'ArrowUpOnSquareStackIcon',
  ArrowUpOnSquare = 'ArrowUpOnSquareIcon',
  ArrowUpRight = 'ArrowUpRightIcon',
  ArrowUpTray = 'ArrowUpTrayIcon',
  ArrowUp = 'ArrowUpIcon',
  ArrowUturnDown = 'ArrowUturnDownIcon',
  ArrowUturnLeft = 'ArrowUturnLeftIcon',
  ArrowUturnRight = 'ArrowUturnRightIcon',
  ArrowUturnUp = 'ArrowUturnUpIcon',
  ArrowsPointingIn = 'ArrowsPointingInIcon',
  ArrowsPointingOut = 'ArrowsPointingOutIcon',
  ArrowsRightLeft = 'ArrowsRightLeftIcon',
  ArrowsUpDown = 'ArrowsUpDownIcon',
  AtSymbol = 'AtSymbolIcon',
  Backspace = 'BackspaceIcon',
  Backward = 'BackwardIcon',
  Banknotes = 'BanknotesIcon',
  Bars2 = 'Bars2Icon',
  Bars3BottomLeft = 'Bars3BottomLeftIcon',
  Bars3BottomRight = 'Bars3BottomRightIcon',
  Bars3CenterLeft = 'Bars3CenterLeftIcon',
  Bars3 = 'Bars3Icon',
  Bars4 = 'Bars4Icon',
  BarsArrowDown = 'BarsArrowDownIcon',
  BarsArrowUp = 'BarsArrowUpIcon',
  Battery0 = 'Battery0Icon',
  Battery100 = 'Battery100Icon',
  Battery50 = 'Battery50Icon',
  Beaker = 'BeakerIcon',
  BellAlert = 'BellAlertIcon',
  BellSlash = 'BellSlashIcon',
  BellSnooze = 'BellSnoozeIcon',
  Bell = 'BellIcon',
  BoltSlash = 'BoltSlashIcon',
  Bolt = 'BoltIcon',
  BookOpen = 'BookOpenIcon',
  BookmarkSlash = 'BookmarkSlashIcon',
  BookmarkSquare = 'BookmarkSquareIcon',
  Bookmark = 'BookmarkIcon',
  Briefcase = 'BriefcaseIcon',
  BugAnt = 'BugAntIcon',
  BuildingLibrary = 'BuildingLibraryIcon',
  BuildingOffice2 = 'BuildingOffice2Icon',
  BuildingOffice = 'BuildingOfficeIcon',
  BuildingStorefront = 'BuildingStorefrontIcon',
  Cake = 'CakeIcon',
  Calculator = 'CalculatorIcon',
  CalendarDays = 'CalendarDaysIcon',
  Calendar = 'CalendarIcon',
  Camera = 'CameraIcon',
  ChartBarSquare = 'ChartBarSquareIcon',
  ChartBar = 'ChartBarIcon',
  ChartPie = 'ChartPieIcon',
  ChatBubbleBottomCenterText = 'ChatBubbleBottomCenterTextIcon',
  ChatBubbleBottomCenter = 'ChatBubbleBottomCenterIcon',
  ChatBubbleLeftEllipsis = 'ChatBubbleLeftEllipsisIcon',
  ChatBubbleLeftRight = 'ChatBubbleLeftRightIcon',
  ChatBubbleLeft = 'ChatBubbleLeftIcon',
  ChatBubbleOvalLeftEllipsis = 'ChatBubbleOvalLeftEllipsisIcon',
  ChatBubbleOvalLeft = 'ChatBubbleOvalLeftIcon',
  CheckBadge = 'CheckBadgeIcon',
  CheckCircle = 'CheckCircleIcon',
  Check = 'CheckIcon',
  ChevronDoubleDown = 'ChevronDoubleDownIcon',
  ChevronDoubleLeft = 'ChevronDoubleLeftIcon',
  ChevronDoubleRight = 'ChevronDoubleRightIcon',
  ChevronDoubleUp = 'ChevronDoubleUpIcon',
  ChevronDown = 'ChevronDownIcon',
  ChevronLeft = 'ChevronLeftIcon',
  ChevronRight = 'ChevronRightIcon',
  ChevronUpDown = 'ChevronUpDownIcon',
  ChevronUp = 'ChevronUpIcon',
  CircleStack = 'CircleStackIcon',
  ClipboardDocumentCheck = 'ClipboardDocumentCheckIcon',
  ClipboardDocumentList = 'ClipboardDocumentListIcon',
  ClipboardDocument = 'ClipboardDocumentIcon',
  Clipboard = 'ClipboardIcon',
  Clock = 'ClockIcon',
  CloudArrowDown = 'CloudArrowDownIcon',
  CloudArrowUp = 'CloudArrowUpIcon',
  Cloud = 'CloudIcon',
  CodeBracketSquare = 'CodeBracketSquareIcon',
  CodeBracket = 'CodeBracketIcon',
  Cog6Tooth = 'Cog6ToothIcon',
  Cog8Tooth = 'Cog8ToothIcon',
  Cog = 'CogIcon',
  CommandLine = 'CommandLineIcon',
  ComputerDesktop = 'ComputerDesktopIcon',
  CpuChip = 'CpuChipIcon',
  CreditCard = 'CreditCardIcon',
  CubeTransparent = 'CubeTransparentIcon',
  Cube = 'CubeIcon',
  CurrencyBangladeshi = 'CurrencyBangladeshiIcon',
  CurrencyDollar = 'CurrencyDollarIcon',
  CurrencyEuro = 'CurrencyEuroIcon',
  CurrencyPound = 'CurrencyPoundIcon',
  CurrencyRupee = 'CurrencyRupeeIcon',
  CurrencyYen = 'CurrencyYenIcon',
  CursorArrowRays = 'CursorArrowRaysIcon',
  CursorArrowRipple = 'CursorArrowRippleIcon',
  DevicePhoneMobile = 'DevicePhoneMobileIcon',
  DeviceTablet = 'DeviceTabletIcon',
  DocumentArrowDown = 'DocumentArrowDownIcon',
  DocumentArrowUp = 'DocumentArrowUpIcon',
  DocumentChartBar = 'DocumentChartBarIcon',
  DocumentCheck = 'DocumentCheckIcon',
  DocumentDuplicate = 'DocumentDuplicateIcon',
  DocumentMagnifyingGlass = 'DocumentMagnifyingGlassIcon',
  DocumentMinus = 'DocumentMinusIcon',
  DocumentPlus = 'DocumentPlusIcon',
  DocumentText = 'DocumentTextIcon',
  Document = 'DocumentIcon',
  EllipsisHorizontalCircle = 'EllipsisHorizontalCircleIcon',
  EllipsisHorizontal = 'EllipsisHorizontalIcon',
  EllipsisVertical = 'EllipsisVerticalIcon',
  EnvelopeOpen = 'EnvelopeOpenIcon',
  Envelope = 'EnvelopeIcon',
  ExclamationCircle = 'ExclamationCircleIcon',
  ExclamationTriangle = 'ExclamationTriangleIcon',
  EyeDropper = 'EyeDropperIcon',
  EyeSlash = 'EyeSlashIcon',
  Eye = 'EyeIcon',
  FaceFrown = 'FaceFrownIcon',
  FaceSmile = 'FaceSmileIcon',
  Film = 'FilmIcon',
  FingerPrint = 'FingerPrintIcon',
  Fire = 'FireIcon',
  Flag = 'FlagIcon',
  FolderArrowDown = 'FolderArrowDownIcon',
  FolderMinus = 'FolderMinusIcon',
  FolderOpen = 'FolderOpenIcon',
  FolderPlus = 'FolderPlusIcon',
  Folder = 'FolderIcon',
  Forward = 'ForwardIcon',
  Funnel = 'FunnelIcon',
  Gif = 'GifIcon',
  GiftTop = 'GiftTopIcon',
  Gift = 'GiftIcon',
  GlobeAlt = 'GlobeAltIcon',
  GlobeAmericas = 'GlobeAmericasIcon',
  GlobeAsiaAustralia = 'GlobeAsiaAustraliaIcon',
  GlobeEuropeAfrica = 'GlobeEuropeAfricaIcon',
  HandRaised = 'HandRaisedIcon',
  HandThumbDown = 'HandThumbDownIcon',
  HandThumbUp = 'HandThumbUpIcon',
  Hashtag = 'HashtagIcon',
  Heart = 'HeartIcon',
  HomeModern = 'HomeModernIcon',
  Home = 'HomeIcon',
  Identification = 'IdentificationIcon',
  InboxArrowDown = 'InboxArrowDownIcon',
  InboxStack = 'InboxStackIcon',
  Inbox = 'InboxIcon',
  InformationCircle = 'InformationCircleIcon',
  Key = 'KeyIcon',
  Language = 'LanguageIcon',
  Lifebuoy = 'LifebuoyIcon',
  LightBulb = 'LightBulbIcon',
  Link = 'LinkIcon',
  ListBullet = 'ListBulletIcon',
  LockClosed = 'LockClosedIcon',
  LockOpen = 'LockOpenIcon',
  MagnifyingGlassCircle = 'MagnifyingGlassCircleIcon',
  MagnifyingGlassMinus = 'MagnifyingGlassMinusIcon',
  MagnifyingGlassPlus = 'MagnifyingGlassPlusIcon',
  MagnifyingGlass = 'MagnifyingGlassIcon',
  MapPin = 'MapPinIcon',
  Map = 'MapIcon',
  Megaphone = 'MegaphoneIcon',
  Microphone = 'MicrophoneIcon',
  MinusCircle = 'MinusCircleIcon',
  MinusSmall = 'MinusSmallIcon',
  Minus = 'MinusIcon',
  Moon = 'MoonIcon',
  MusicalNote = 'MusicalNoteIcon',
  Newspaper = 'NewspaperIcon',
  NoSymbol = 'NoSymbolIcon',
  PaintBrush = 'PaintBrushIcon',
  PaperAirplane = 'PaperAirplaneIcon',
  PaperClip = 'PaperClipIcon',
  PauseCircle = 'PauseCircleIcon',
  Pause = 'PauseIcon',
  PencilSquare = 'PencilSquareIcon',
  Pencil = 'PencilIcon',
  PhoneArrowDownLeft = 'PhoneArrowDownLeftIcon',
  PhoneArrowUpRight = 'PhoneArrowUpRightIcon',
  PhoneXMark = 'PhoneXMarkIcon',
  Phone = 'PhoneIcon',
  Photo = 'PhotoIcon',
  PlayCircle = 'PlayCircleIcon',
  PlayPause = 'PlayPauseIcon',
  Play = 'PlayIcon',
  PlusCircle = 'PlusCircleIcon',
  PlusSmall = 'PlusSmallIcon',
  Plus = 'PlusIcon',
  Power = 'PowerIcon',
  PresentationChartBar = 'PresentationChartBarIcon',
  PresentationChartLine = 'PresentationChartLineIcon',
  Printer = 'PrinterIcon',
  PuzzlePiece = 'PuzzlePieceIcon',
  QrCode = 'QrCodeIcon',
  QuestionMarkCircle = 'QuestionMarkCircleIcon',
  QueueList = 'QueueListIcon',
  Radio = 'RadioIcon',
  ReceiptPercent = 'ReceiptPercentIcon',
  ReceiptRefund = 'ReceiptRefundIcon',
  RectangleGroup = 'RectangleGroupIcon',
  RectangleStack = 'RectangleStackIcon',
  RocketLaunch = 'RocketLaunchIcon',
  Rss = 'RssIcon',
  Scale = 'ScaleIcon',
  Scissors = 'ScissorsIcon',
  ServerStack = 'ServerStackIcon',
  Server = 'ServerIcon',
  Share = 'ShareIcon',
  ShieldCheck = 'ShieldCheckIcon',
  ShieldExclamation = 'ShieldExclamationIcon',
  ShoppingBag = 'ShoppingBagIcon',
  ShoppingCart = 'ShoppingCartIcon',
  SignalSlash = 'SignalSlashIcon',
  Signal = 'SignalIcon',
  Sparkles = 'SparklesIcon',
  SpeakerWave = 'SpeakerWaveIcon',
  SpeakerXMark = 'SpeakerXMarkIcon',
  Square2Stack = 'Square2StackIcon',
  Square3Stack3D = 'Square3Stack3DIcon',
  Squares2X2 = 'Squares2X2Icon',
  SquaresPlus = 'SquaresPlusIcon',
  Star = 'StarIcon',
  StopCircle = 'StopCircleIcon',
  Stop = 'StopIcon',
  Sun = 'SunIcon',
  Swatch = 'SwatchIcon',
  TableCells = 'TableCellsIcon',
  Tag = 'TagIcon',
  Ticket = 'TicketIcon',
  Trash = 'TrashIcon',
  Trophy = 'TrophyIcon',
  Truck = 'TruckIcon',
  Tv = 'TvIcon',
  UserCircle = 'UserCircleIcon',
  UserGroup = 'UserGroupIcon',
  UserMinus = 'UserMinusIcon',
  UserPlus = 'UserPlusIcon',
  User = 'UserIcon',
  Users = 'UsersIcon',
  Variable = 'VariableIcon',
  VideoCameraSlash = 'VideoCameraSlashIcon',
  VideoCamera = 'VideoCameraIcon',
  ViewColumns = 'ViewColumnsIcon',
  ViewfinderCircle = 'ViewfinderCircleIcon',
  Wallet = 'WalletIcon',
  Wifi = 'WifiIcon',
  Window = 'WindowIcon',
  WrenchScrewdriver = 'WrenchScrewdriverIcon',
  Wrench = 'WrenchIcon',
  XCircle = 'XCircleIcon',
  XMark = 'XMarkIcon',
}
