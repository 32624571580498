import { DateTime } from 'luxon'

import { Engagement, EngagementSeat, EngagementSeatRole, Quantum, Location } from '@athena/core/model'

import { EngagementDates, PrimaryDetails, Seat } from '@future-view/frontend/page/engagement/interface'
interface EngagementLuxonDate {
  lastFeedbackRequestGenerationDate: Quantum<DateTime>
  startDate: DateTime
  endDate: DateTime
  today: DateTime
}

const toLuxonDates = (engagementDates: EngagementDates): EngagementLuxonDate => {
  return {
    lastFeedbackRequestGenerationDate: engagementDates.lastFeedbackRequestGenerationDate
      ? DateTime.fromJSDate(engagementDates.lastFeedbackRequestGenerationDate)
      : null,
    startDate: DateTime.fromJSDate(engagementDates.startDate),
    endDate: DateTime.fromJSDate(engagementDates.endDate),
    today: DateTime.fromJSDate(engagementDates.today),
  }
}

const calculateNextDate = (engagementDates: EngagementLuxonDate) => {
  if (engagementDates.today > engagementDates.endDate) {
    return null
  }

  let nextPeerReview = (
    engagementDates.lastFeedbackRequestGenerationDate
      ? engagementDates.lastFeedbackRequestGenerationDate
      : engagementDates.startDate
  ).plus({ days: 90 })

  if (nextPeerReview < engagementDates.today || nextPeerReview > engagementDates.endDate) {
    nextPeerReview = engagementDates.today
  }

  if (
    engagementDates.lastFeedbackRequestGenerationDate &&
    engagementDates.endDate.diff(engagementDates.lastFeedbackRequestGenerationDate, 'days').days < 90
  ) {
    return null
  }

  const diffDays = engagementDates.endDate.diff(nextPeerReview, 'days').days
  if (diffDays >= 0 && diffDays <= 60) {
    nextPeerReview = engagementDates.endDate.minus({ days: 14 })
  }
  if (engagementDates.endDate.diff(engagementDates.startDate, 'days').days <= 60) {
    nextPeerReview = engagementDates.endDate
  }

  return nextPeerReview < engagementDates.endDate ? nextPeerReview : engagementDates.endDate
}

const calculateFinalDate = (engagementDates: EngagementLuxonDate) => {
  const nextDate = calculateNextDate(engagementDates)
  if (!nextDate) {
    return null
  }
  if (nextDate && engagementDates.endDate.diff(nextDate, 'days').days === 14) {
    return nextDate
  }

  if (engagementDates.today > engagementDates.endDate) {
    return engagementDates.lastFeedbackRequestGenerationDate as DateTime
  }

  if (
    !engagementDates.lastFeedbackRequestGenerationDate &&
    engagementDates.endDate.diff(engagementDates.startDate, 'days').days < 90
  ) {
    return engagementDates.endDate
  }

  if (
    engagementDates.lastFeedbackRequestGenerationDate &&
    engagementDates.endDate.diff(engagementDates.lastFeedbackRequestGenerationDate, 'days').days < 14
  ) {
    return engagementDates.lastFeedbackRequestGenerationDate
  }

  const nextOrPrevGenerationDate = engagementDates.lastFeedbackRequestGenerationDate
    ? engagementDates.lastFeedbackRequestGenerationDate
    : engagementDates.startDate.plus({ days: 90 })

  const diff = engagementDates.endDate.diff(nextOrPrevGenerationDate, 'days').days
  const remainder = diff % 90

  return remainder < 15
    ? nextOrPrevGenerationDate.plus({ days: diff - remainder }) // last day in cycle
    : engagementDates.endDate
}

const utils = (engagementDates: EngagementDates) => {
  const engagementDateTimes = toLuxonDates(engagementDates)
  return {
    nextDate: calculateNextDate(engagementDateTimes)?.toJSDate(),
    finalDate: calculateFinalDate(engagementDateTimes)?.toJSDate(),
  }
}

export const buildEngagement = (primaryDetails: PrimaryDetails) => {
  const savedEngagement = new Engagement()
  savedEngagement.id = primaryDetails?.id || ''
  savedEngagement.title = primaryDetails.title
  savedEngagement.location = primaryDetails.locations
  savedEngagement.startDate = primaryDetails.startDate
  savedEngagement.endDate = primaryDetails.endDate
  savedEngagement.description = primaryDetails.description
  savedEngagement.rel.activity = primaryDetails.activityId as string
  savedEngagement.seats = primaryDetails.seats.map((seat: Seat) => {
    const userEngagementRole = new EngagementSeat(seat.id || '')
    userEngagementRole.rel.engagement = savedEngagement.id
    userEngagementRole.rel.resource = seat.resourceId
    userEngagementRole.role = seat.role as EngagementSeatRole
    return userEngagementRole
  })

  return savedEngagement
}

export const isInactive = (engagement: Engagement) => {
  const today = DateTime.now().set({ hour: 0, minute: 0, second: 0 }).toJSDate()
  return !!engagement?.endDate && engagement.endDate < today
}

export const locationOptions = [
  {
    label: 'Budapest',
    value: Location.Budapest,
  },
  {
    label: 'Noida',
    value: Location.Noida,
  },
  {
    label: 'Sydney',
    value: Location.Sydney,
  },
  {
    label: 'Washington D.C.',
    value: Location.WashingtonDC,
  },
  {
    label: 'New York',
    value: Location.NewYork,
  },
  {
    label: 'London',
    value: Location.London,
  },
]

export default utils
