import { assignIn, pick } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, BaseModelWarehouse, Quantum, Reference, Schema } from './base-model'

export type RevueObjectiveGoalSerialized = z.infer<typeof RevueObjectiveGoal.schema>

export type RevueObjectiveGoalWarehouse = BaseModelWarehouse & {
  title: string
  manager_note: string
  description: string
  progress: number
}

export type RevueObjectiveGoalStore = BaseModelStore & {
  id_objective: string
  title: string
  manager_note: string | null
  description: string | null
  progress: number
}

export default class RevueObjectiveGoal extends BaseModel {
  rel = {
    objective: '',
  }
  title = ''
  manager_note: string | null = null
  description: string | null = null
  progress = 0

  constructor(id: string, objective = '') {
    super(id)
    this.rel = { objective }
  }

  [key: string]:
    | string
    | number
    | { objective: string }
    | Quantum<Date>
    | (() => Reference)
    | (() => string)
    | (() => RevueObjectiveGoalStore)
    | ((projection: string[]) => Partial<RevueObjectiveGoalSerialized>)

  static override readonly schema = BaseModel.schema.extend({
    title: z.string().min(1).max(200),
    manager_note: z.string().max(2000).nullable(),
    description: z.string().min(1).max(5000),
    progress: z
      .number()
      .refine((value) => value >= 0 && value <= 100, { message: 'Progress must be between 0 and 100' }),
    rel: z.object({
      objective: Schema.Id,
    }),
  })

  static override fromSerialized(recordInput: RevueObjectiveGoalSerialized): RevueObjectiveGoal {
    const record = RevueObjectiveGoal.schema.parse(recordInput)
    const model = new RevueObjectiveGoal(record.id, record.rel.objective)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.created)
    model.title = record.title || ''
    model.manager_note = record.manager_note
    model.description = record.description
    model.progress = record.progress
    return model
  }

  static override fromDataStore(record: RevueObjectiveGoalStore): RevueObjectiveGoal {
    const model = new RevueObjectiveGoal(record.id, record.id_objective)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.title = record.title || ''
    model.manager_note = record.manager_note
    model.description = record.description
    model.progress = record.progress
    return model
  }

  override toSerialized(): RevueObjectiveGoalSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      rel: {
        objective: this.rel.objective,
      },
    } as unknown as RevueObjectiveGoalSerialized
  }

  override toDataStore(): RevueObjectiveGoalStore {
    return {
      ...super.toDataStore(),
      id_objective: this.rel.objective,
      title: this.title,
      manager_note: this.manager_note,
      description: this.description,
      progress: this.progress,
    }
  }

  project(projection: string[]): Partial<RevueObjectiveGoalSerialized> {
    const serialized = this.toSerialized()
    return projection?.length ? pick(serialized, projection) : serialized
  }
}
