import classNames from 'classnames'
import { groupBy, map } from 'lodash'
import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { IconName } from '@athena/component/atom/icon'
import Logo, { LogoSize } from '@athena/component/atom/logo'
import Modal from '@athena/component/molecule/modal'

import { useSidebarCountMutate } from '@apollo/client/hook/sidebar'
import Avatar from '@future-view/frontend/component/avatar'
import Environment from '@future-view/frontend/environment'
import Path from '@future-view/frontend/router/path'

import Props, { SidebarLink } from './interface'
import SidebarLinkComponent from './sidebar-link'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

const Sidebar: React.FC<Props> = ({ 'data-test': dataTest = 'sidebar', links = [], refreshCounts, identityData }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [activeLink, setActiveLink] = useState(location.pathname)
  const [sidebarValues, setSidebarValues] = useState<any>([])

  const sidebarCountHook = useSidebarCountMutate()

  const directReports = identityData?.directReports ? identityData?.directReports : 0
  const isAdmin = identityData?.isAdmin === 'YES'
  const filteredLinks: SidebarLink[] = []

  if (!Environment.isDevStage) {
    links = links.filter((item) => !item.isDev)
  }

  links.map((item) => {
    if (item.peopleManager) {
      if (directReports > 0) {
        filteredLinks.push(item)
      }
    } else if (item.admin) {
      if (isAdmin) {
        filteredLinks.push(item)
      }
    } else {
      filteredLinks.push(item)
    }
    return item
  })
  links = [...filteredLinks]
  const groupedLinks = groupBy(links, (obj) => (obj.admin ? 'adminLinks' : 'nonAdminLinks'))

  const handleLogout = () => navigate(Path.Logout)

  useEffect(() => {
    setActiveLink(location.pathname)
  }, [location])

  useEffect(() => {
    const getCount = async () => {
      const res = await sidebarCountHook.mutateAsync(identityData ? identityData?.cid : '')
      if (res.data) {
        setSidebarValues(res.data)
      }
    }
    if (identityData && identityData.cid) {
      getCount()
    }
  }, [refreshCounts])

  const getCount = (label: string) => {
    if (sidebarValues && sidebarValues.length) {
      return sidebarValues[0][label]
    }
    return null
  }
  return (
    <>
      <div
        className={cx(Style.sidebarWrapper, 'flex flex-col bg-darkGrey1 z-50 pt-[10px]')}
        data-test={`${dataTest}.container`}
      >
        <Link data-test={`${dataTest}.home-link`} to={Path.PeerReview}>
          <Logo label customClasses="text-white pl-[22px]" data-test={`${dataTest}.logo`} size={LogoSize.Large} />
        </Link>
        <div className={cx(Style.content, 'flex grow h-max flex-col mt-[24px]')}>
          {map(groupedLinks.nonAdminLinks, ({ icon, label, to }) => (
            <SidebarLinkComponent
              key={label}
              activeLink={activeLink}
              data-test={`${dataTest}.link`}
              link={{ icon, label, to, count: getCount(label) }}
              setActiveLink={setActiveLink}
            />
          ))}
          <div className="grow" />
          <div className="mb-[10px]">
            <SidebarLinkComponent
              key={'Suggestions'}
              activeLink={activeLink}
              data-test={`${dataTest}.link`}
              link={{ icon: IconName.ChatBubbleBottomCenterText, label: 'Suggestions', to: Path.Suggestions }}
              setActiveLink={setActiveLink}
            />
          </div>
          <div>
            {groupedLinks.adminLinks && groupedLinks.adminLinks.length > 0 ? (
              <>
                <div className={Style.separator} />
                <div className="mt-[16px] mb-[40px]">
                  {map(groupedLinks.adminLinks, ({ icon, label, to }) => (
                    <SidebarLinkComponent
                      key={label}
                      activeLink={activeLink}
                      data-test={`${dataTest}.link`}
                      link={{ icon, label, to, count: getCount(label) }}
                      setActiveLink={setActiveLink}
                    />
                  ))}
                </div>
                <div className={Style.separator} />
              </>
            ) : null}
          </div>

          <div className="cursor-pointer mb-[36px] pl-[22px] mt-[26px]" onClick={() => setShowModal(true)}>
            <div
              className={cx(Style.userName, 'flex items-center text-[12px] text-white uppercase')}
              data-test={`${dataTest}.avatar`}
            >
              <Avatar
                cid={identityData?.cid || ''}
                data-test={`${dataTest}.manager-avatar`}
                wrapperCustomClasses={'inline-block bg-grey w-[35px] h-[35px] mr-[12px] rounded-[18px]'}
              ></Avatar>
              <span data-test={`${dataTest}.user-name`}>{identityData?.displayName || ''}</span>
            </div>
          </div>
        </div>
      </div>
      <Modal
        data-test={`${dataTest}.logout-modal`}
        isShown={showModal}
        modalCustomClasses={Style.logoutModal}
        wrapperCustomClasses={Style.logoutModalWrapper}
        onHide={() => setShowModal(false)}
      >
        <div className={cx(Style.userName, 'flex items-center text-[12px] text-white uppercase')}>
          <Avatar
            cid={identityData?.cid || ''}
            data-test={`${dataTest}.manager-avatar`}
            wrapperCustomClasses={'inline-block bg-grey w-[35px] h-[35px] mr-[12px] rounded-[18px]'}
          ></Avatar>
          {identityData?.displayName || ''}
        </div>
        <div
          className={cx(
            Style.logout,
            'cursor-pointer hover:text-redhighlight inline-block mt-[20px] text-[16px] text-white'
          )}
          onClick={handleLogout}
        >
          Log out
        </div>
      </Modal>
    </>
  )
}

export default Sidebar
export type { Props, SidebarLink }
