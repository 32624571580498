/* eslint-disable import/named */
import { AxiosResponse } from 'axios'
import { head, identity, isArray, map } from 'lodash'

import { HttpStatus } from '@athena/core/http'

class Response<T> {
  status: number
  data: T | null

  constructor(status: number, data: T | null = null) {
    this.status = status
    this.data = data
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static single<T>(resp: AxiosResponse, mapper: (x: any) => T = identity): Response<T> {
    let data: T | null = null
    try {
      data = isArray(resp.data) ? mapper(head(resp.data)) : mapper(resp.data)
    } catch {
      null
    }

    return new Response<T>(resp.status, data)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static list<T>(resp: AxiosResponse, mapper: (x: any) => T = identity): Response<T[]> {
    let data: T[] = []
    try {
      data = isArray(resp.data) ? map(resp.data, mapper) : [mapper(resp.data)]
    } catch {
      null
    }

    return new Response<T[]>(resp.status, data)
  }

  static deletion(resp: AxiosResponse): Response<boolean> {
    return new Response<boolean>(resp.status, resp.status === HttpStatus.NoContent)
  }
}

export default Response
