import LocalIdentity from '@athena/core/client/local-identity'
import Response from '@athena/core/client/response'

import Environment from '@apollo/client/environment'
import { getAxiosInstance } from '@apollo/client/util/interceptor'

const client = getAxiosInstance({
  baseURL: `${Environment.apollo.host}/auth`,
  timeout: Environment.apollo.timeout,
  validateStatus: (s) => s > 199 && s < 500,
  withCredentials: true,
})

export const login = async (password: string): Promise<Response<LocalIdentity>> => {
  const body = { username: 'apollo-client', password }
  const resp = await client.post('/login', body)
  return Response.single(resp)
}

export const logout = async () => {
  const resp = await client.post('/logout')
  return Response.single(resp)
}

export const getIdentity = async (): Promise<Response<LocalIdentity>> => {
  const resp = await client.get('/identity')
  return Response.single(resp)
}
