import { Feedback, Quantum } from '@athena/core/model'

import { maxPoint, totalLikerts } from '@future-view/frontend/component/survey-form/survey-utils'
export type TypeStyle = { readonly [key: string]: string }
export const getColor = (data: Feedback, style: TypeStyle) => {
  if (!data) {
    return chooseColor(0, style)
  }
  const total =
    (data.delivery ? parseInt(data.delivery) : 0) +
    (data.positiveImpact ? parseInt(data.positiveImpact) : 0) +
    (data.teamCollaboration ? parseInt(data.teamCollaboration) : 0) +
    (data.clientCollaboration ? parseInt(data.clientCollaboration) : 0)
  const avg = Math.ceil((10 * total) / (maxPoint * totalLikerts))
  return chooseColor(avg, style)
}

const chooseColor = (i: number, style: TypeStyle) => {
  switch (i) {
    case 0:
      return style.color0
    case 1:
      return style.color1
    case 2:
      return style.color2
    case 3:
      return style.color3
    case 4:
      return style.color4
    case 5:
      return style.color5
    case 6:
      return style.color6
    case 7:
      return style.color7
    case 8:
      return style.color8
    case 9:
      return style.color9
    case 10:
      return style.color10
    default:
      return ''
  }
}

export const formatDate = (date: Quantum<Date>) => {
  if (!date) {
    return 'N/A'
  }

  const monthOptions: Intl.DateTimeFormatOptions = { month: 'long' }
  const dayOptions: Intl.DateTimeFormatOptions = { day: 'numeric' }

  const month = new Intl.DateTimeFormat('en-US', monthOptions).format(new Date(date))
  const day = new Intl.DateTimeFormat('en-US', dayOptions).format(new Date(date))

  let daySuffix = 'th'
  if (day.endsWith('1') && !day.endsWith('11')) {
    daySuffix = 'st'
  } else if (day.endsWith('2') && !day.endsWith('12')) {
    daySuffix = 'nd'
  } else if (day.endsWith('3') && !day.endsWith('13')) {
    daySuffix = 'rd'
  }

  return `${month} ${day}${daySuffix}, ${new Date(date).getFullYear()}`
}
