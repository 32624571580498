import { useQuery } from '@tanstack/react-query'

import { PagerData } from '@athena/core/api/pager'

import {
  getPeersByEmail,
  getResource,
  getResourceAvatar,
  getResourceByEmail,
  getResourceDept,
  getResourceList,
  getResourceReference,
  getResourceSlice,
} from '@artemis/client/api/resource'

const QueryKey = {
  Resource: 'resource-item',
  ResourceList: 'resource-list',
  ResourceReference: 'resource-reference',
  ResourceSlice: 'resource-slice',
  ResourceByEmail: 'resource-email',
  PeersByEmail: 'peers-email',
  Avatar: 'avatar',
}

export const useResource = (id: string) =>
  useQuery({
    queryKey: [QueryKey.Resource, id],
    queryFn: () => getResource(id),
  })

export const useResourceList = (ids: string[]) =>
  useQuery({
    queryKey: [QueryKey.ResourceList, ids],
    queryFn: () => getResourceList(ids),
  })

export const useResourceReference = () =>
  useQuery({
    queryKey: [QueryKey.ResourceReference],
    queryFn: getResourceReference,
  })

export const useResourceSlice = (pager?: PagerData) =>
  useQuery({
    queryKey: [QueryKey.ResourceSlice, pager],
    queryFn: () => getResourceSlice(pager),
  })

export const useResourceByEmail = (email: string) =>
  useQuery({
    queryKey: [QueryKey.ResourceByEmail, email],
    queryFn: () => getResourceByEmail(email),
  })

export const usePeersByEmail = (email: string, subordinatesOfSubordinates?: boolean) =>
  useQuery({
    queryKey: [QueryKey.PeersByEmail, email, subordinatesOfSubordinates],
    queryFn: () => getPeersByEmail(email, subordinatesOfSubordinates),
  })

export const useResourceDept = (connectionId: string) =>
  useQuery({
    queryKey: [QueryKey.PeersByEmail, connectionId],
    queryFn: () => getResourceDept(connectionId),
    enabled: !!connectionId,
  })

export const useAvatar = (id: string) =>
  useQuery({
    queryKey: [QueryKey.Avatar, id],
    queryFn: () => getResourceAvatar(id),
    enabled: !!id,
  })
