import { assignIn } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, Schema } from '@athena/core/model/base-model'

import { Skill } from './skill'

export type StrengthImprovSerialized = z.infer<typeof StrengthImprov.schema>

export type StrengthImprovStore = BaseModelStore & {
  id_feedback: string
  skill: Skill
  justification?: string
}

export default class StrengthImprov extends BaseModel {
  rel = {
    feedback: '',
  }
  skill = Skill.Accountibility
  justification: string | undefined

  constructor(id = '', feedback = '') {
    super(id, '', undefined, undefined)
    this.rel = { feedback }
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      feedback: Schema.Id,
    }),
    skill: z.nativeEnum(Skill),
    justification: z.string().max(1000).optional(),
  })

  static override fromSerialized(recordInput: StrengthImprovSerialized): StrengthImprov {
    const record = StrengthImprov.schema.parse(recordInput)
    const model = new StrengthImprov(record.id, record.rel.feedback)
    model.created = BaseModel.parseDate(record.created)
    model.skill = record.skill
    model.justification = record.justification
    return model
  }

  static override fromDataStore(record: StrengthImprovStore): StrengthImprov {
    const model = new StrengthImprov(record.id, record.id_feedback)
    model.created = BaseModel.parseDate(record.created)
    model.skill = record.skill
    model.justification = record.justification
    return model
  }

  override toSerialized(): StrengthImprovSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      rel: {
        feedback: this.rel.feedback,
      },
    } as StrengthImprovSerialized
  }

  override toDataStore(): StrengthImprovStore {
    return {
      ...super.toDataStore(),
      id_feedback: this.rel.feedback,
      skill: this.skill,
      justification: this.justification,
    }
  }
}
