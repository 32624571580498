import { MutateOptions } from '@tanstack/react-query'
import classNames from 'classnames'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Button, { ButtonFill, IconName } from '@athena/component/atom/button'
import Loading from '@athena/component/atom/loading'
import Typography, { TypographySize } from '@athena/component/atom/typography'
import Modal from '@athena/component/molecule/modal'
import { Engagement, EngagementState } from '@athena/core/model'

import { useAddEngagement } from '@apollo/client/hook/engagement'
import ConfirmationDialog from '@future-view/frontend/component/confirm-dialog'
import Path from '@future-view/frontend/router/path'

import { Details } from './details'
import { PrimaryDetails } from './interface'
import Seats from './seats'
import Style from './style.module.scss'
import { buildEngagement } from './utils'

const dataTestContainer = 'engagement.container'
const cx = classNames.bind(Style)
const emptyPrimaryDetails = {
  id: '',
  title: '',
  activityId: '',
  locations: [],
  startDate: null,
  endDate: null,
  description: '',
  seats: [],
}

const CreateEngagement = () => {
  const navigate = useNavigate()
  const addEngagement = useAddEngagement()
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showDraftDialog, setShowDraftDialog] = useState(false)
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const [showPublishConfirmationDialog, setShowPublishConfirmationDialog] = useState(false)
  const [primaryDetails, setPrimaryDetails] = useState<PrimaryDetails>({ ...emptyPrimaryDetails })
  const handlePublish = () => setShowPublishConfirmationDialog(true)
  const handleCancel = () => {
    if (isChanged) {
      setShowCancelDialog(true)
    } else {
      navigate(Path.Engagements)
    }
  }
  const handleDraft = () => {
    setShowDraftDialog(true)
  }
  const saveAsDraft = () =>
    saveEngagement(EngagementState.Draft, () => {
      toast.success('Engagement Saved as Draft')
      navigate(Path.Engagements)
    })

  const saveEngagement = (
    state: EngagementState,
    onSuccess?: MutateOptions<unknown, unknown, Engagement>['onSuccess']
  ) => {
    const savedEngagement = buildEngagement(primaryDetails)
    savedEngagement.state = state
    addEngagement.mutate(savedEngagement, { onSuccess })
  }
  /** Rendering */
  const saveButtonDisabled = useMemo(() => {
    return !primaryDetails.title.trim() || !primaryDetails.activityId
  }, [primaryDetails.title, primaryDetails.activityId])

  const publishButtonDisabled = useMemo(() => {
    return (
      !primaryDetails.title.trim() ||
      !primaryDetails.activityId ||
      !primaryDetails.startDate ||
      !primaryDetails.endDate ||
      !primaryDetails.locations.length ||
      !primaryDetails.description.trim() ||
      primaryDetails.seats.length < 2
    )
  }, [primaryDetails])

  useEffect(() => {
    if (isEqual(emptyPrimaryDetails, primaryDetails)) {
      setIsChanged(false)
    } else {
      setIsChanged(true)
    }
  }, [primaryDetails])

  return (
    <div className={Style.container} data-test={dataTestContainer}>
      {/* HEADER */}
      <div className={Style.header}>
        <Typography data-test={`${dataTestContainer}.header`} size={TypographySize.Header3}>
          Create New Engagement
        </Typography>
        <Button
          customClasses={Style.cancelButton}
          data-test={`${dataTestContainer}.cancel`}
          fill={ButtonFill.Outline}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          data-test={`${dataTestContainer}.save-as-draft`}
          disabled={saveButtonDisabled}
          fill={ButtonFill.Outline}
          icon={IconName.DocumentText}
          onClick={handleDraft}
        >
          Save as draft
        </Button>
        <Button
          customClasses={cx(Style.publishButton, { [Style.disabled]: publishButtonDisabled })}
          data-test={`${dataTestContainer}.publish-button`}
          disabled={publishButtonDisabled}
          fill={ButtonFill.Solid}
          icon={IconName.ArrowUpOnSquare}
          onClick={handlePublish}
        >
          Publish
        </Button>
      </div>
      {/* DETAILS */}
      <Details
        dataTestContainer={dataTestContainer}
        engagementState={undefined}
        isEditable={true}
        primaryDetails={primaryDetails}
        setPrimaryDetails={setPrimaryDetails}
      ></Details>
      {/* SEATS */}
      <Seats
        dataTestContainer={dataTestContainer}
        primaryDetails={primaryDetails}
        setPrimaryDetails={setPrimaryDetails}
      ></Seats>
      {/* MODALS */}
      <Modal
        hideCloseButton
        data-test={`${dataTestContainer}.cancel-modal`}
        isShown={showCancelDialog}
        onHide={() => setShowCancelDialog(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTestContainer}.cancel-dialog`}
          description="Changes will not be saved"
          onConfirm={(confirmed: boolean) => {
            setShowCancelDialog(false)
            confirmed && navigate(Path.Engagements)
          }}
        />
      </Modal>
      <Modal
        hideCloseButton
        data-test={`${dataTestContainer}.draft-modal`}
        isShown={showDraftDialog}
        onHide={() => setShowDraftDialog(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTestContainer}.draft-dialog`}
          description="Engagement will be moved to draft"
          onConfirm={(confirmed: boolean) => {
            setShowDraftDialog(false)
            if (confirmed) {
              saveAsDraft()
            }
          }}
        />
      </Modal>
      <Modal
        hideCloseButton
        data-test={`${dataTestContainer}.publish-confirmation-modal`}
        isShown={showPublishConfirmationDialog}
        onHide={() => setShowPublishConfirmationDialog(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTestContainer}.publish-confirmation-dialog`}
          description={'Engagement will be saved and published'}
          returnButtonText=""
          onConfirm={(confirmed: boolean) => {
            if (confirmed) {
              saveEngagement(EngagementState.Published, () => {
                toast.success('Engagement Published')
                setShowPublishConfirmationDialog(false)
                navigate(Path.Engagements)
              })
            } else {
              setShowPublishConfirmationDialog(false)
            }
          }}
        />
      </Modal>
      {addEngagement.isLoading && (
        <div className={Style.blockingOpaqueBlock}>
          <div className={Style.loadingContainer}>
            <Loading show data-test={`${dataTestContainer}.loading`} />
          </div>
        </div>
      )}
    </div>
  )
}

export default CreateEngagement
