import axios from 'axios'

import Response from '@athena/core/client/response'

import Environment from '@apollo/client/environment'

const client = axios.create({
  baseURL: `${Environment.apollo.host}/sidebar`,
  timeout: Environment.apollo.timeout,
  validateStatus: (s) => s > 199 && s < 500,
  withCredentials: true,
})

export const getSidebarCount = async (cid: string): Promise<Response<any[]>> => {
  const resp = await client.get(`/count/${cid}`)
  return Response.list(resp)
}
