import classNames from 'classnames'
import { isFunction } from 'lodash'
import React, { useEffect, useState } from 'react'

import { Props } from './interface'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

const RadioButton: React.FC<Props> = ({
  'data-test': dataTest = 'radio-button',
  disabled = false,
  label,
  desc,
  value,
  onChange,
}) => {
  const id = `radio-button-${label}`
  const [isChecked, setIsChecked] = useState(!!value)

  useEffect(() => {
    setIsChecked(value)
  }, [onChange, value])

  const handleChange = () => {
    if (disabled) {
      return
    }
    setIsChecked(!isChecked)
    isFunction(onChange) && onChange(!isChecked)
  }

  return (
    <div className={Style.radioButton} data-test={`${dataTest}.element`}>
      <input
        checked={isChecked}
        className={cx({ [Style.disabled]: disabled })}
        data-test={`${dataTest}.input`}
        disabled={disabled}
        id={id}
        type="radio"
        onChange={handleChange}
      />
      {(label || desc) && (
        <label className={Style.textContainer} data-test={`${dataTest}.label`} htmlFor={id}>
          {label && label}
          {desc && <p data-test={`${dataTest}.desc`}>{desc}</p>}
        </label>
      )}
    </div>
  )
}

export default RadioButton
export type { Props }
