/**
 * Sub-section of HTTP status codes that will be used specifically by the server-side application layer
 * and the clienta pplications of the platform.
 *
 * Different projects have different interpretations and recommendations for usage of HTTP Status codes.
 * Each code will have documetnation the standard practice, as documented by Mozilla Development Network.
 * It will also include additional implementation notes indicating how the tashi platform team intends to
 * use the code, if it deviates from the MDN docs.
 */
export const HttpStatus = {
  /**
   * The request succeeded. The result meaning of "success" depends on the HTTP method:
   * - GET: The resource has been fetched and transmitted in the message body.
   * - HEAD: The representation headers are included in the response without any message body.
   * - PUT or POST: The resource describing the result of the action is transmitted in the message body.
   */
  OK: 200,

  /**
   * The request succeeded, and a new resource was created as a result. This is typically the response sent after
   * POST requests, or some PUT requests.
   * --
   * Implementation Notes: The created status should be used in transactions that result in the creation of a new
   * resource. If something new was created as a result of the transaction it should return this status.
   */
  Created: 201,

  /**
   * The request has been received but not yet acted upon. It is noncommittal, since there is no way in HTTP to
   * later send an asynchronous response indicating the outcome of the request. It is intended for cases where
   * another process or server handles the request, or for batch processing.
   * --
   * Implementation Notes: For use with asynchronous transactions. When we have the situation that we immediately
   * respond to the client, but then perform additonal asynchronous processing in the background, we can reply with
   * this status. This indicates that we have begun processing, but have not necessarily immediately finished
   * at the moment the response is received by the client application.
   */
  Accepted: 202,

  /**
   * There is no content to send for this request, but the headers may be useful. The user agent may update its
   * cached headers for this resource with the new ones.
   * --
   * Implementation Notes: This status should be returned in one of two scenarios:
   * - A DELETE transaction is exected successfuly and the requested resources have been deleted.
   * - A GET transaction is executed successfully and the resulting data set is empy (no records are found).
   *
   * It is *WRONG* to respond with OK (200), BadRequest (400) or NotFound (404) for these scenarios.
   * - An OK resonse should always be accompanied by data.
   * - A BadRequest response is sent when the request payload in incorrect. It should be triggered at the
   *   start of transaction processing, and possibly no datastore transactions are even executed.
   * - A NotFound response is sent when the URL that is invoked is not registered to any handler. This is
   *   different from having a valid URL, that yields no data.
   */
  NoContent: 204,

  /* ----------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------- */

  /**
   * The URL of the requested resource has been changed permanently. The new URL is given in the response.
   */
  MovedPermanently: 301,

  /**
   * This response code means that the URI of requested resource has been changed temporarily. Further changes
   * in the URI might be made in the future. Therefore, this same URI should be used by the client in
   * future requests.
   */
  Found: 302,

  /**
   * This is used for caching purposes. It tells the client that the response has not been modified,
   * so the client can continue to use the same cached version of the response.
   */
  NotModified: 304,

  /* ----------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------- */

  /**
   * The server cannot or will not process the request due to something that is perceived to be a client error
   * (e.g., malformed request syntax, invalid request message framing, or deceptive request routing).
   */
  BadRequest: 400,

  /**
   * Although the HTTP standard specifies "unauthorized", semantically this response means "unauthenticated".
   * That is, the client must authenticate itself to get the requested response.
   */
  Unauthorized: 401,

  /**
   * The client does not have access rights to the content; that is, it is unauthorized, so the server is refusing
   * to give the requested resource. Unlike 401 Unauthorized, the client's identity is known to the server.
   */
  Forbidden: 403,

  /**
   * The server can not find the requested resource. In the browser, this means the URL is not recognized.
   * In an API, this can also mean that the endpoint is valid but the resource itself does not exist.
   * Servers may also send this response instead of 403 Forbidden to hide the existence of a resource from an
   * unauthorized client. This response code is probably the most well known due to its frequent occurrence on
   * the web.
   */
  NotFound: 404,

  /**
   * The request method is known by the server but is not supported by the target resource. For example, an API
   * may not allow calling DELETE to remove a resource.
   */
  MethodNotAllowed: 405,

  /**
   * This response is sent when a request conflicts with the current state of the server.
   * --
   * Implementation Notes: Used when there is a conflict that prevents the transaction from completing. Common
   * reasons for a conflict are integrity contraint violations or missing data store references.
   */
  Conflict: 409,

  /**
   * The client has indicated preconditions in its headers which the server does not meet.
   * --
   * Implementation Notes: Used when there is a logical inconsistency with the transaction and it should not be
   * allowed to proceed. This is dinstinguished from the =Conflict= status (409). =Conflict= is raised when there
   * is a physical constraint that prevents the transaction from completing (like a foreing key constraint error).
   * =PreconditionFailed= should be raised in situations whre logical inconsistencies would arise, checked by the
   * application layer, that would escape the physicial checks of the underlying data store. Things like having a
   * user's profile in the wrong state to perform a tranasction, etc.
   */
  PreconditionFailed: 412,

  /* ----------------------------------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------------------------- */

  /**
   * The server has encountered a situation it does not know how to handle.
   */
  InternalServerError: 500,

  /**
   * The request method is not supported by the server and cannot be handled. The only methods that servers
   * are required to support (and therefore that must not return this code) are GET and HEAD.
   */
  NotImplemented: 501,

  /**
   * The server is not ready to handle the request. Common causes are a server that is down for maintenance
   * or that is overloaded. Note that together with this response, a user-friendly page explaining the problem
   * should be sent. This response should be used for temporary conditions and the Retry-After HTTP header should,
   * if possible, contain the estimated time before the recovery of the service. The webmaster must also take care
   * about the caching-related headers that are sent along with this response, as these temporary condition
   * responses should usually not be cached.
   * */
  ServiceUnavaiable: 503,
}

export const HttpMethod = {
  Get: 'GET',
  Post: 'POST',
  Put: 'PUT',
  Delete: 'DELETE',
  Head: 'HEAD',
  Patch: 'PATCH',
  Options: 'OPTIONS',
}
