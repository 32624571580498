import { assignIn, pick, round, toNumber } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelWarehouse, Quantum, Schema } from './base-model'

export type RequiredCapabilitySerialized = z.infer<typeof RequiredCapability.schema>

export type RequiredCapabilityWarehouse = BaseModelWarehouse & {
  KimbleOne__ActivityAssignment__c: string
  KimbleOne__CapabilityGroup__c: string
  KimbleOne__CapabilityType__c: string
  Kimble__KimbleOne__Resource__c: string
  ActivityAssignment__Name: string
  CapabilityGroup__Name: string
  CapabilityType__Name: string
  Resource__Name: string
  KimbleOne__IsMandatory__c: string
  KimbleOne__MinimumNumericRating__c: string
}

export const RequiredCapabilityProjection = {
  Embed: [
    'id',
    'name',
    'created',
    'updated',
    'rel.activityAssignment',
    'rel.capabilityType',
    'activityAssignment',
    'capabilityGroup',
    'capabilityType',
    'resource',
    'isMandatory',
    'minimumRating',
  ],
}

export default class RequiredCapability extends BaseModel {
  rel = {
    activityAssignment: '',
    capabilityType: '',
  }
  activityAssignment = ''
  capabilityGroup = ''
  capabilityType = ''
  resource = ''
  isMandatory = false
  minimumRating = 0

  constructor(
    id: string,
    name: string,
    created: Quantum<Date>,
    updated: Quantum<Date>,
    activityAssignment: string,
    capabilityGroup: string,
    capabilityType: string,
    resource: string
  ) {
    super(id, name, created, updated)
    this.activityAssignment = activityAssignment || ''
    this.capabilityGroup = capabilityGroup || ''
    this.capabilityType = capabilityType || ''
    this.resource = resource || ''
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      activityAssignment: Schema.Id,
      capabilityType: Schema.Id,
    }),
    activityAssignment: Schema.Name,
    capabilityGroup: Schema.Name,
    capabilityType: Schema.Name,
    resource: Schema.Name,
    isMandatory: z.boolean().default(false),
    minimumRating: z.number().min(0).max(10).default(0),
  })

  static override fromSerialized(recordInput: RequiredCapabilitySerialized): RequiredCapability {
    const record = RequiredCapability.schema.parse(recordInput)
    const model = new RequiredCapability(
      record.id,
      record.name,
      BaseModel.parseDate(record.created),
      BaseModel.parseDate(record.updated),
      record.activityAssignment,
      record.capabilityGroup,
      record.capabilityType,
      record.resource
    )
    model.rel = record.rel || {}
    model.isMandatory = record.isMandatory || false
    model.minimumRating = record.minimumRating || 0
    return model
  }

  static override fromWarehouse(record: RequiredCapabilityWarehouse): RequiredCapability {
    const model = new RequiredCapability(
      record.Id,
      record.Name,
      BaseModel.parseDate(record.CreatedDate),
      BaseModel.parseDate(record.LastModifiedDate),
      record.ActivityAssignment__Name,
      record.CapabilityGroup__Name,
      record.CapabilityType__Name,
      record.Resource__Name
    )
    model.rel = {
      activityAssignment: record.KimbleOne__ActivityAssignment__c,
      capabilityType: record.KimbleOne__CapabilityType__c,
    }
    model.isMandatory = BaseModel.parseBoolean(record.KimbleOne__IsMandatory__c)
    model.minimumRating = round(toNumber(record.KimbleOne__MinimumNumericRating__c), 1)
    return model
  }

  override toSerialized(): RequiredCapabilitySerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
    } as unknown as RequiredCapabilitySerialized
  }

  project(projection: string[]): Partial<RequiredCapabilitySerialized> {
    const serialized = this.toSerialized()
    return projection?.length ? pick(serialized, projection) : serialized
  }
}
