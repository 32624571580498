import classnames from 'classnames'
import { isFunction } from 'lodash'

import { getColor } from '@future-view/frontend/page/revue-dashboard/util'

import Props from './interface'
import Style from './style.module.scss'
const cx = classnames.bind(Style)

const PerformanceObjectiveGoal: React.FC<Props> = ({
  'data-test': dataTest = 'performance-objective-goal-card',
  onClicked,
  index,
  goal,
  isSelected = false,
  isDisabled,
}: Props) => {
  const handleClick = () => {
    isFunction(onClicked) && onClicked(index)
  }
  const progress = parseInt(goal?.progress + '') || 0
  let limitedProgress = progress
  if (limitedProgress < 0) {
    limitedProgress = 0
  } else if (limitedProgress > 100) {
    limitedProgress = 100
  }
  const progressStyle = {
    width: `${limitedProgress}%`,
  }
  return (
    <div
      className={cx(Style.cardWrapper, { [Style.active]: isSelected }, { [Style.disabled]: isDisabled })}
      data-test={`${dataTest}.wrapper`}
      onClick={handleClick}
    >
      <div className={Style.card}>
        <p className={Style.tag} data-test={`${dataTest}.tag`}>
          {goal.progress === 100 ? 'Completed' : ''}
        </p>
        <p className={Style.title} data-test={`${dataTest}.title`}>
          {goal?.title}
        </p>
        <div className={`${Style.barWrap}`}>
          <span className={`${Style.bar} ${getColor(goal?.progress ?? 0)}`} style={progressStyle}></span>
        </div>
        <p className={Style.percentage} data-test={`${dataTest}.progress`}>
          {goal?.progress || 0}
        </p>
      </div>
      <div className={`${Style.rightWhiteBorder}`}></div>
      <div className={`${Style.rightBorder}`}></div>
      <div className={Style.polygon}></div>
    </div>
  )
}

export default PerformanceObjectiveGoal
export type { Props }
