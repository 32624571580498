import classnames from 'classnames'
import { noop } from 'lodash'
import React, { useContext, useMemo, useState } from 'react'

import Button, { ButtonColor, ButtonFill, IconName } from '@athena/component/atom/button'
import Icon from '@athena/component/atom/icon'
import Textarea from '@athena/component/atom/textarea'
import Modal from '@athena/component/molecule/modal'
import { FeedbackRequestState } from '@athena/core/model'

import { useEngagement, useFeedbackRequest, useUpdateFeedbackRequest } from '@apollo/client/hook/engagement'
import ConfirmationDialog from '@future-view/frontend/component/confirm-dialog'
import ResourceCard from '@future-view/frontend/component/resource-card'
import SurveyForm from '@future-view/frontend/component/survey-form'
import useEmployeeMap from '@future-view/frontend/hook/use-employee-map'
import { refreshContext } from '@future-view/frontend/page/layout/ReferenceDataContext'

import Props from './interface'
import Style from './style.module.scss'

const cx = classnames.bind(Style)
const FeedbackReviewPage: React.FC<Props> = ({
  'data-test': dataTest = 'feedback-review',
  feedbackRequestId,
  hideModal,
}) => {
  const { data: request } = useFeedbackRequest(feedbackRequestId || '')
  const { data: engagementData } = useEngagement(request?.data?.rel?.engagement || '')
  const updateFeedbackRequest = useUpdateFeedbackRequest()
  const targetRole = ''
  const sourceRole = ''
  // const targetRole = useMemo(
  //   () => engagementData?.data?.seats.filter((seat) => seat.rel.resource === request?.data?.rel.target)[0].role || '',
  //   [engagementData, request?.data?.rel.target]
  // )
  // const sourceRole = useMemo(
  //   () => engagementData?.data?.seats.filter((seat) => seat.rel.resource === request?.data?.rel.source)[0].role || '',
  //   [engagementData, request?.data?.rel.source]
  // )
  const resourceList = useEmployeeMap()
  const targetName = useMemo(
    () => (request?.data && resourceList[request?.data?.rel?.target]?.name) || '',
    [resourceList, request?.data]
  )
  const targetId = useMemo(
    () => (request?.data && resourceList[request?.data?.rel?.target]?.employeeId) || '',
    [resourceList, request?.data]
  )
  const sourceName = useMemo(
    () => (request?.data && resourceList[request?.data?.rel?.source]?.name) || '',
    [resourceList, request?.data]
  )
  const sourceId = useMemo(
    () => (request?.data && resourceList[request?.data?.rel?.source]?.employeeId) || '',
    [resourceList, request?.data]
  )

  const [showRevisionModal, setShowRevisionModal] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const { refreshKey, setRefreshKey } = useContext(refreshContext)
  const [, setPausedModal] = useState(false)
  const [isValid, setValid] = useState(false)
  const [message, setMessage] = useState('')

  const approveFeedback = async () => {
    if (request?.data) {
      if (request.data.state === FeedbackRequestState.SkipRequested) {
        request.data.state = FeedbackRequestState.Skipped
      } else if (request.data.state === FeedbackRequestState.AwaitingApproval) {
        request.data.state = FeedbackRequestState.Completed
      }

      await updateFeedbackRequest.mutateAsync(request?.data)
      setRefreshKey(Math.ceil(Math.random() * 1000))
    }
  }

  const requestRevision = async () => {
    if (request?.data) {
      request.data.revisionMsg = message
      request.data.state = FeedbackRequestState.RevisionRequested
      await updateFeedbackRequest.mutateAsync(request?.data)
      setRefreshKey(Math.ceil(Math.random() * 1000))
    }
  }

  const handleMessageChange = (value: string) => {
    value.length ? setValid(true) : setValid(false)
    setMessage(value)
  }

  const handleConfirmationCancel = () => {
    setShowConfirmModal(false)
  }
  const handleConfirm = () => {
    approveFeedback()
    setShowConfirmModal(false)
    hideModal(feedbackRequestId)
  }

  const handleSend = () => {
    requestRevision()
    setShowRevisionModal(false)
    hideModal(feedbackRequestId)
  }

  return (
    <div
      className={cx(Style.container, 'mt-[45px] mx-[12px] p-[28px] pb-[56px] rounded-[16px] bg-white')}
      data-test={`${dataTest}.container`}
    >
      <header className="flex justify-end">
        <Button
          basicOutline
          data-test={`${dataTest}.cancel-button`}
          fill={ButtonFill.Outline}
          onClick={() => hideModal(undefined)}
        >
          Cancel
        </Button>
        {request?.data?.state === FeedbackRequestState.AwaitingApproval && (
          <Button
            customClasses="ml-[10px]"
            data-test={`${dataTest}.request-revision`}
            fill={ButtonFill.Outline}
            icon={IconName.ArrowPath}
            onClick={() => setShowRevisionModal(true)}
          >
            Request Revision
          </Button>
        )}
        {request?.data?.state === FeedbackRequestState.SkipRequested && (
          <Button
            customClasses="ml-[10px]"
            data-test={`${dataTest}.reject-skip`}
            fill={ButtonFill.Outline}
            icon={IconName.ArrowPath}
            onClick={() => setShowRevisionModal(true)}
          >
            Reject Skip
          </Button>
        )}
        <Button
          color={ButtonColor.Accent}
          customClasses={cx(Style.publishButton, 'ml-[10px]')}
          data-test={`${dataTest}.approveButton`}
          icon={IconName.CheckCircle}
          onClick={() => setShowConfirmModal(true)}
        >
          Approve
        </Button>
      </header>
      <section className="flex justify-center items-center gap-[32px] mt-[36px] mb-[32px]">
        <ResourceCard
          data-test={`${dataTest}.sourceCard`}
          id={sourceId}
          name={sourceName}
          role={sourceRole}
          wrapperCustomClasses={'bg-dashboardwhite'}
        ></ResourceCard>
        <Icon data-test={`${dataTest}.arrowRightIcon`} name={IconName.ArrowRightCircle}></Icon>
        <ResourceCard
          data-test={`${dataTest}.targetCard`}
          id={targetId}
          name={targetName}
          role={targetRole}
          wrapperCustomClasses={'bg-dashboardwhite'}
        ></ResourceCard>
      </section>
      {request?.data && <section className="text-center uppercase">{engagementData?.data?.title}</section>}
      {request?.data && request.data.state === FeedbackRequestState.AwaitingApproval && (
        <SurveyForm
          data-test={`${dataTest}.survey`}
          hideModal={noop}
          isForManager={true}
          pauseModal={setPausedModal}
          request={request?.data}
        />
      )}
      {(request?.data?.state === FeedbackRequestState.SkipRequested ||
        request?.data?.state === FeedbackRequestState.Skipped) && (
        <section
          className="flex flex-col max-w-[400px] m-auto break-normal mt-[20px]"
          data-test={`${dataTest}.skip-reason`}
        >
          <h4 className="mt-[20px] mb-[10px]">Reason for Exception Request</h4>
          <p>{request.data.skipReason ?? ''}</p>
        </section>
      )}
      <Modal
        hideCloseButton
        data-test={`${dataTest}.revision`}
        isShown={showRevisionModal}
        modalCustomClasses={Style.modal}
        wrapperCustomClasses={Style.surveyModalWrapper}
        onHide={() => setShowRevisionModal(false)}
      >
        <div className="px-[40px] pt-[24px] pb-[40px] flex flex-col">
          <div className="text-[20px] mb-[40px] text-grey-700 text-center">Request Revision</div>
          <Textarea
            customClasses={Style.input}
            data-test={`${dataTest}.message`}
            label={'Message'}
            maxSymbols={1000}
            rows={5}
            value={message}
            onChange={handleMessageChange}
          />
          <div className="flex pt-[32px]">
            <Button
              basicOutline
              customClasses="mr-[16px] w-[65px]"
              data-test={`${dataTest}.cancel`}
              fill={ButtonFill.Outline}
              onClick={() => setShowRevisionModal(false)}
            >
              Cancel
            </Button>
            <Button
              basicOutline
              color={ButtonColor.Accent}
              customClasses="mr-[16px] w-[83x]"
              data-test={`${dataTest}.send`}
              disabled={!isValid}
              fill={ButtonFill.Outline}
              icon={IconName.ArrowUpOnSquare}
              onClick={handleSend}
            >
              Send
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        hideCloseButton
        data-test={`${dataTest}.confirmation`}
        isShown={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTest}.cancel-dialog`}
          description="All submissions are final."
          onConfirm={(confirmed: boolean) => {
            confirmed ? handleConfirm() : handleConfirmationCancel()
          }}
        />
      </Modal>
    </div>
  )
}
export default FeedbackReviewPage

export type { Props }
