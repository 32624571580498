import { ReactNode } from 'react'

export enum TypographySize {
  Header1 = 'h1',
  Header2 = 'h2',
  Header3 = 'h3',
  Header4 = 'h4',
  Large = 'b-l',
  Medium = 'b-m',
  Small = 'b-s',
  ExtraSmall = 'b-xs',
}

export default interface Props {
  'data-test': string
  size: TypographySize
  children: ReactNode
  bold?: boolean
  uppercase?: boolean
  customClass?: string
}
