import React from 'react'

import { IconName } from '@athena/component/atom/icon'

export enum ButtonExpand {
  Block = 'block',
  Full = 'full',
}

export enum ButtonFill {
  Solid = 'solid',
  Outline = 'outline',
  Flat = 'flat',
}

export enum ButtonSize {
  Default = 'default',
  Large = 'large',
  Small = 'small',
}

export enum ButtonColor {
  Dark = 'dark',
  Accent = 'accent',
}

export default interface Props {
  'data-test': string
  basicOutline?: boolean
  children?: React.ReactNode
  color?: ButtonColor
  customClasses?: string
  disabled?: boolean
  expand?: ButtonExpand
  fill?: ButtonFill
  icon?: IconName
  size?: ButtonSize
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  onMouseDown?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  onMouseUp?: (e?: React.MouseEvent<HTMLButtonElement>) => void
  onMouseLeave?: (e?: React.MouseEvent<HTMLButtonElement>) => void
}
