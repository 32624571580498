import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TooltipItem,
  ChartTypeRegistry,
  LineControllerChartOptions,
} from 'chart.js'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'

import Icon, { IconName } from '@athena/component/atom/icon'
import Feedback, { FeedbackRank } from '@athena/core/model/feedback'

import { useFeedbackByTargetYear } from '@apollo/client/hook/engagement'

import Style from './../style.module.scss'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export interface rankProps {
  attrition: string[]
  potential: string[]
  motivation: string[]
}

const rankMetric: rankProps = {
  attrition: ['N/A', 'Critical', 'High', 'Medium', 'Low'],
  potential: ['N/A', 'Limited', 'Core', 'High', 'Top'],
  motivation: ['N/A', 'Very Low', 'Low', 'Medium', 'High'],
}

const tooltipLabel = (tooltipItems: TooltipItem<'line'>) => {
  const label = tooltipItems.dataset.label ? (tooltipItems.dataset.label as string) : ''
  return label + ': ' + rankMetric[label?.toLowerCase() as keyof rankProps][parseInt(tooltipItems.formattedValue, 10)]
}

const ChartComponent = ({ chartData }: { chartData: any }) => {
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: tooltipLabel,
        },
      },
    },
    scales: {
      x: {
        datalabels: {
          color: 'white' as const,
          font: {
            weight: 'bold' as const,
          },
        },
        border: {
          color: '#ffffff',
        },
        stacked: false,
        ticks: {
          color: '#E6E6E6',
        },
        grid: {
          display: true,
          color: '#333333',
          drawOnChartArea: true,
          tickColor: '#E6E6E6',
        },
      },
      y: {
        scaleLabel: {
          display: false,
        },
        datalabels: {
          display: false,
          color: 'white',
          font: {
            weight: 'bold',
          },
        },
        suggestedMin: 0,
        suggestedMax: 4,
        border: {
          color: '#333333',
        },
        stacked: false,
        ticks: {
          color: '#dddddd',
          min: 0,
          max: 4,
          stepSize: 1,
          suggestedMin: 0,
          suggestedMax: 4,
        },
        grid: {
          display: true,
          color: '#333333',
          drawOnChartArea: true,
          tickColor: '#4d4d4d',
        },
      },
    },
  }
  return <Line data={chartData} height={'80%'} options={chartOptions} />
}

ChartComponent.propTypes = {
  chartData: PropTypes.node.isRequired,
}

export interface MapProps {
  'data-test': string
  empId: string
  year: string | null
}

// eslint-disable-next-line react/function-component-definition
export default function MapLine({ 'data-test': dataTest = 'map-line', empId, year }: MapProps) {
  const getFilteredFeedbacks = useFeedbackByTargetYear(empId, year)
  const feedbackData = getFilteredFeedbacks?.data?.data as Feedback[]
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const monthsArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const labels: number[] = []

  const [data, setData] = useState<any>({
    labels,
    datasets: [
      {
        label: 'Motivation',
        data: [0],
        borderColor: 'rgb(119, 169, 249)',
        backgroundColor: 'rgb(119, 169, 249, )',
      },
      {
        label: 'Attrition',
        data: [0],
        borderColor: 'rgb(244, 170,202)',
        backgroundColor: 'rgb(244, 170,202)',
      },
      {
        label: 'Potential',
        data: [0],
        borderColor: 'rgb(222, 155,251)',
        backgroundColor: 'rgb(222, 155,251)',
      },
    ],
  })

  const HighlightLine = (line: number) => {
    const chartData = JSON.parse(JSON.stringify(data))
    if (line < chartData.datasets.length) {
      chartData.datasets.map((dataset: any) => {
        dataset.borderDash = [6, 6]
        dataset.borderWidth = 0
        return dataset
      })
    } else {
      chartData.datasets.map((dataset: any) => {
        dataset.borderDash = [0, 0]
        dataset.borderWidth = 3
        return dataset
      })
    }
    if (line < chartData.datasets.length) {
      chartData.datasets[line].borderDash = [0, 0]
      chartData.datasets[line].borderWidth = 3
    }
    setData(chartData)
  }

  useEffect(() => {
    const motivation: FeedbackRank[] = []
    const attrition: FeedbackRank[] = []
    const potential: FeedbackRank[] = []

    if (feedbackData) {
      const feedbacks = feedbackData
      feedbacks.forEach((feedback: Feedback) => {
        if (feedback.sourceIsLead && feedback.created) {
          const monthString = ('0' + (new Date(feedback.created).getMonth() + 1)).slice(-2)
          labels.push(parseInt(monthString, 10))
          motivation.push(feedback.motivation as FeedbackRank)
          attrition.push(feedback.attrition as FeedbackRank)
          potential.push(feedback.potential as FeedbackRank)
        }
      })
      setData({
        labels: labels.map((label) => monthsArray[label - 1]),
        datasets: [
          {
            label: 'Motivation',
            data: motivation,
            borderColor: 'rgb(119, 169, 249)',
            backgroundColor: 'rgb(119, 169, 249)',
          },
          {
            label: 'Attrition',
            data: attrition,
            borderColor: 'rgb(244, 170,202)',
            backgroundColor: 'rgb(244, 170,202)',
          },
          {
            label: 'Potential',
            data: potential,
            borderColor: 'rgb(222, 155,251)',
            backgroundColor: 'rgb(222, 155,251)',
          },
        ],
      })
    }
  }, [feedbackData])

  return (
    <>
      <div className={Style.metricBlock} data-test={`${dataTest}.container`}>
        <div className={Style.buttonBlock}>
          <span className={[Style.colorbox, Style.motivation].join(' ')}></span>
          <button data-test={`${dataTest}.motivation-button`} onClick={() => HighlightLine(0)}>
            Motivation
          </button>
          <span className={[Style.colorbox, Style.attrition].join(' ')}></span>
          <button data-test={`${dataTest}.attrition-button`} onClick={() => HighlightLine(1)}>
            Attrition
          </button>
          <span className={[Style.colorbox, Style.potential].join(' ')}></span>
          <button data-test={`${dataTest}.potential-button`} onClick={() => HighlightLine(2)}>
            Potential
          </button>
          <>&nbsp;</>
          <button data-test={`${dataTest}.x-button`} onClick={() => HighlightLine(5)}>
            [X]
          </button>
          <span className={Style.spacer}></span>
          {!showTooltip && (
            <span onClick={() => setShowTooltip(true)}>
              <Icon
                customClasses={Style.lineGraphIcon}
                data-test={`${dataTest}.info-icon`}
                name={IconName.InformationCircle}
              />
            </span>
          )}
          {showTooltip && (
            <span onClick={() => setShowTooltip(false)}>
              <Icon customClasses={Style.lineGraphIcon} data-test={`${dataTest}.info-icon`} name={IconName.XMark} />
            </span>
          )}
        </div>
        <ChartComponent chartData={data} />
        {showTooltip && (
          <div className={Style.tooltipWrapper} data-test={`${dataTest}.tooltip`}>
            <h4>Motivation</h4>
            <p>High - this individual is excited about the opportunities at Lab and to progress here</p>
            <p>Medium - this individual will take opportunities when presented</p>
            <p>Low - this individual is well placed to deliver on their engagement</p>
            <p>Very low - this individual is disengaged on their engagement</p>
            <h4 className={'mt-[20px]'}>Attrition</h4>
            <p>Low - this individual will likely be at Lab49 after 12 months</p>
            <p>Medium - this individual will likely be at Lab for at least the next 6 - 12 months</p>
            <p>High - this individual is likely to leave Lab in the next six months</p>
            <p>Critical - this individual is likely to leave Lab within the next three months </p>
            <h4 className={'mt-[20px]'}>Potential</h4>
            <p>Top talent - this individual is ready to take the next career step</p>
            <p>High potential - Given training and time, this individual is ready to take the next career step</p>
            <p>Core talent - this individual is well placed to deliver against their current responsibilities</p>
            <p>Limited - this individual would be more successful at another engagement</p>
          </div>
        )}
      </div>
    </>
  )
}
