import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Router from '@future-view/frontend/router'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      cacheTime: 0,
    },
  },
})

const App = () => (
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={Router} />
    {/* <ReactQueryDevtools initialIsOpen={false} position="top-right" /> */}
    <ToastContainer />
  </QueryClientProvider>
)

export default App
