import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { HttpStatus } from '@athena/core/http'

import { getIdentity, login, logout } from '@apollo/client/api/auth'

export const QueryKey = {
  Identity: 'identity',
  Login: 'login',
}

export const useIdentity = () =>
  useQuery({
    queryKey: [QueryKey.Identity],
    queryFn: getIdentity,
    retry: false,
  })

export const useLogin = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: login,
    onSuccess: (resp) => {
      switch (resp?.status) {
        case HttpStatus.Unauthorized:
          toast.error('Unrecognized password. Cannot log in.')
          client.invalidateQueries({ queryKey: [QueryKey.Identity] })
          return

        case HttpStatus.OK:
          client.setQueryData([QueryKey.Identity], resp)
          return

        default:
          toast.error(`Unexpected error trying to log in: ${resp.data}`)
          return
      }
    },
    onError: (err) => toast.error(`System error attempting to log in ${err}`),
  })
}

export const useLogout = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: logout,
    onSettled: () => client.invalidateQueries(),
    onError: (err) => toast.error(`System error attempting to log out: ${err}`),
  })
}
