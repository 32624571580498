import { toNumber, toLower, trim } from 'lodash'

const Environment = {
  isDev: toLower(trim(process.env.NODE_ENV)) !== 'production',
  isDevStage: window.location.hostname.includes('localhost') || window.location.hostname.includes('dev'),
  logLevel: toLower(trim(process.env.LOG_LEVEL)) || 'warn',
  adLogoutUrl: trim(process.env.FV_AD_LOGOUT_URL),
  artemis: {
    host: trim(process.env.ART_API_HOST),
    timeout: toNumber(process.env.ART_API_TIMEOUT),
  },
  apollo: {
    host: trim(process.env.APL_API_HOST),
    timeout: toNumber(process.env.APL_API_TIMEOUT),
  },
}

export default Environment
