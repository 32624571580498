import { Rating } from '@athena/core/model/revue-objective'
import RevueObjectiveGoal from '@athena/core/model/revue-objective-goal'

export const validationRules: {
  [key: string]: {
    validate: (
      value: string | RevueObjectiveGoal[],
      isPromoted?: boolean
    ) => { isInvalid: boolean; errorMsg: string | null }
  }
} = {
  current: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = !/^(?:0|[1-9]\d?|1\d{2}|200)?$/.test(value as string)
      return { isInvalid, errorMsg: isInvalid ? 'Value must be between 0 and 200 or empty' : null }
    },
  },
  self_rating: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = (value as string).trim() === ''
      return { isInvalid, errorMsg: isInvalid ? 'Self Rating is required' : null }
    },
  },
  self_assessment: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = (value as string).trim() === ''
      return { isInvalid, errorMsg: isInvalid ? 'Self Assessment is required' : null }
    },
  },
  manager_rating: {
    validate: (value: string | RevueObjectiveGoal[], isPromoted?: boolean) => {
      const isInvalid = (value as string).trim() === '' || (!!isPromoted && value === Rating.NA)
      return { isInvalid, errorMsg: isInvalid ? 'Manager Rating is required' : null }
    },
  },
  manager_assessment: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = (value as string).trim() === ''
      return { isInvalid, errorMsg: isInvalid ? 'Manager Assessment is required' : null }
    },
  },
  goals: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const val = value as RevueObjectiveGoal[]
      let isInvalid
      const invalidGoal = val.find(
        (goal: RevueObjectiveGoal) =>
          validationRules['title'].validate(goal?.title).isInvalid ||
          validationRules['progress'].validate(goal?.progress + '').isInvalid ||
          validationRules['description'].validate(goal?.description ?? '').isInvalid
      )
      let errorMsg = null
      if (value.length > 10) {
        errorMsg = 'You cannot add more than 10 goals'
        isInvalid = true
      } else if (value.length === 10) {
        errorMsg = 'You cannot add more than 10 goals'
        isInvalid = !!invalidGoal
      } else if (invalidGoal) {
        errorMsg = null
        isInvalid = true
      } else {
        errorMsg = null
        isInvalid = false
      }
      return { isInvalid, errorMsg }
    },
  },
  title: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = !(value && (value as string).length > 0 && (value as string).trim().length < 200)
      return {
        isInvalid,
        errorMsg: isInvalid ? '1-200 characters required' : null,
      }
    },
  },
  progress: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = !/^(100|[0-9]|[1-9][0-9])$/.test(value as string)
      return { isInvalid, errorMsg: isInvalid ? 'Value must be between 0 and 100' : null }
    },
  },
  sponsor1: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = !value
      return { isInvalid, errorMsg: isInvalid ? 'Sponsor 1 is required' : null }
    },
  },
  sponsor2: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = !value
      return { isInvalid, errorMsg: isInvalid ? 'Sponsor 2 is required' : null }
    },
  },
  craft: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = !value
      return { isInvalid, errorMsg: isInvalid ? 'Craft is required' : null }
    },
  },
  method: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = !value
      return { isInvalid, errorMsg: isInvalid ? 'Method is required' : null }
    },
  },
  leadership: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = !value
      return { isInvalid, errorMsg: isInvalid ? 'Leadership is required' : null }
    },
  },
  description: {
    validate: (value: string | RevueObjectiveGoal[]) => {
      const isInvalid = (value as string).trim() === ''
      return { isInvalid, errorMsg: isInvalid ? 'Goal Description is required' : null }
    },
  },
}
