import classNames from 'classnames'
import { cloneDeep, isEmpty, map, omit } from 'lodash'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import Button, { ButtonFill } from '@athena/component/atom/button'
import Icon, { IconName } from '@athena/component/atom/icon'
import Typography, { TypographySize } from '@athena/component/atom/typography'
import Modal from '@athena/component/molecule/modal'

import { useEmployeeReference } from '@artemis/client/hook'
import SeatImage from '@future-view/frontend/asset/seat.svg'

import { Seat, SeatsProps } from './interface'
import SeatCard from './seat-card'
import { SeatModalContent } from './seat-modal-content'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

const Seats = ({
  dataTestContainer,
  isEditable = true,
  primaryDetails,
  setPrimaryDetails,
}: SeatsProps): JSX.Element => {
  const [selectedSeat, setSelectedSeat] = useState<Seat | null>()
  const [newSeatId, setNewSeatId] = useState(0)
  const resourceReference = useEmployeeReference()
  const [userFullNameByResourceId, setResourceIdToUserFullNameMap] = useState<Map<string, string>>()

  const showSaveSeatDialog = (seat?: Seat) => {
    const seatToModify = seat || {
      id: '',
      resourceId: '',
      userFullName: '',
    }
    setSelectedSeat(seatToModify)
  }

  const isResourcePresent = (seat: Seat) => {
    const ifResourcePresent = primaryDetails.seats.find(
      (item) => item.resourceId === seat.resourceId && item.id !== seat.id
    )
    if (ifResourcePresent) {
      toast.error('Resource already present')
      return false
    }
    return true
  }

  const saveSeat = (seat: Seat) => {
    if (seat.id) {
      const seatIndex = primaryDetails.seats.findIndex((s) => s.id === seat.id)
      if (seatIndex >= 0) {
        const newSeat: Seat = cloneDeep(seat)
        newSeat.userFullName = userFullNameByResourceId?.get(seat.resourceId) || ''
        setPrimaryDetails((prev) => {
          const newSeats = [...prev.seats]
          newSeats[seatIndex] = newSeat
          return { ...prev, seats: newSeats }
        })
      }
    } else {
      const newSeat: Seat = omit(seat, ['id'])
      newSeat.id = '__' + newSeatId
      newSeat.userFullName = userFullNameByResourceId?.get(seat.resourceId) || ''
      setNewSeatId((prev) => prev + 1)
      setPrimaryDetails((prev) => ({ ...prev, seats: [...prev.seats, newSeat] }))
    }
  }

  useEffect(() => {
    if (resourceReference.data) {
      const data = resourceReference.data.data || []
      const userFullNameById: Map<string, string> = data
        .filter((item) => item.isActiveEmployee === 'YES')
        .reduce((acc, resource) => {
          acc.set(resource.id, resource.name)
          return acc
        }, new Map<string, string>())

      setResourceIdToUserFullNameMap(userFullNameById)
    }
  }, [resourceReference.data])

  return (
    <div className={Style.seatsContainer}>
      <Typography
        customClass={Style.seatsHeader}
        data-test={`${dataTestContainer}.form-seats-header`}
        size={TypographySize.Large}
      >
        Seats
      </Typography>
      {isEmpty(primaryDetails.seats) && (
        <div className={Style.emptySeats}>
          <img src={SeatImage} />
          <Typography
            customClass={Style.noSeats}
            data-test={`${dataTestContainer}.no-seats-header`}
            size={TypographySize.Medium}
          >
            No seats have been created yet
          </Typography>
          {isEditable && (
            <Button
              data-test={`${dataTestContainer}.add-first-seat`}
              disabled={!isEditable}
              fill={ButtonFill.Outline}
              icon={IconName.Plus}
              onClick={() => showSaveSeatDialog()}
            >
              Create seat
            </Button>
          )}
        </div>
      )}
      {!isEmpty(primaryDetails.seats) && (
        <div className={cx(Style.seats, 'gap-4')}>
          {map(primaryDetails.seats, (seat: Seat) => (
            <SeatCard
              key={seat.id}
              disabled={!isEditable}
              seat={seat}
              onEditClick={showSaveSeatDialog}
              onDeleteClick={(deletedSeat: Seat) => {
                setPrimaryDetails((prev) => ({
                  ...prev,
                  seats: primaryDetails.seats.filter((s) => s.id !== deletedSeat.id),
                }))
              }}
            />
          ))}
          {isEditable && (
            <div className={Style.addSeatContainer}>
              <button className={Style.addSeat} onClick={() => showSaveSeatDialog()}>
                <Icon data-test={`${dataTestContainer}.add-seat`} name={IconName.Plus} />
              </button>
            </div>
          )}
        </div>
      )}
      <Modal hideCloseButton data-test={`${dataTestContainer}.create-seat-dialog`} isShown={!!selectedSeat}>
        <SeatModalContent
          dataTestContainer={dataTestContainer}
          editedSeat={selectedSeat as Seat}
          isResourcePresent={isResourcePresent}
          users={userFullNameByResourceId || new Map()}
          onHide={() => setSelectedSeat(null)}
          onSave={saveSeat}
        />
      </Modal>
    </div>
  )
}

export default Seats
