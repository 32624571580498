import { assignIn, pick, round, toNumber } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelWarehouse, Quantum, Schema } from './base-model'

export type ResourceCapabilitySerialized = z.infer<typeof ResourceCapability.schema>

export type ResourceCapabilityWarehouse = BaseModelWarehouse & {
  KimbleOne__CapabilityType__c: string
  KimbleOne__Resource__c: string
  CapabilityType__Name: string
  Resource__Name: string
  KimbleOne__ExpiryDate__c: string
  KimbleOne__NumericRating__c: string
}

export const ResourceCapabilityProjection = {
  Embed: ['id', 'capabilityType', 'rating'],
}

export default class ResourceCapability extends BaseModel {
  rel = {
    capabilityType: '',
    resource: '',
  }
  capabilityType = ''
  resource = ''
  expiry: Quantum<Date>
  rating = 0

  constructor(
    id: string,
    name: string,
    created: Quantum<Date>,
    updated: Quantum<Date>,
    capabilityType: string,
    resource: string
  ) {
    super(id, name, created, updated)
    this.capabilityType = capabilityType || ''
    this.resource = resource || ''
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      capabilityType: Schema.Id,
      resource: Schema.Id,
    }),
    capabilityType: Schema.Name,
    resource: Schema.Name,
    expiry: Schema.DateTime,
    rating: z.number().min(0).max(100).default(0),
  })

  static override fromSerialized(recordInput: ResourceCapabilitySerialized): ResourceCapability {
    const record = ResourceCapability.schema.parse(recordInput)
    const model = new ResourceCapability(
      record.id,
      record.name,
      BaseModel.parseDate(record.created),
      BaseModel.parseDate(record.updated),
      record.capabilityType,
      record.resource
    )
    model.rel = record.rel || {}
    model.expiry = BaseModel.parseDate(record.expiry)
    model.rating = record.rating
    return model
  }

  static override fromWarehouse(record: ResourceCapabilityWarehouse): ResourceCapability {
    const model = new ResourceCapability(
      record.Id,
      record.Name,
      BaseModel.parseDate(record.CreatedDate),
      BaseModel.parseDate(record.LastModifiedDate),
      record.CapabilityType__Name,
      record.Resource__Name
    )
    model.rel = {
      capabilityType: record.KimbleOne__CapabilityType__c,
      resource: record.KimbleOne__Resource__c,
    }
    model.expiry = BaseModel.parseDate(record.KimbleOne__ExpiryDate__c)
    model.rating = round(toNumber(record.KimbleOne__NumericRating__c), 1)
    return model
  }

  override toSerialized(): ResourceCapabilitySerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      expiry: BaseModel.formatDate(this.expiry),
    } as unknown as ResourceCapabilitySerialized
  }

  project(projection: string[]): Partial<ResourceCapabilitySerialized> {
    const serialized = this.toSerialized()
    return projection?.length ? pick(serialized, projection) : serialized
  }
}
