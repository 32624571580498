import axios from 'axios'

import Response from '@athena/core/client/response'
import Employee from '@athena/core/model/employee'

import Environment from '@apollo/client/environment'

const client = axios.create({
  baseURL: `${Environment.apollo.host}/sap-search`,
  timeout: Environment.apollo.timeout,
  validateStatus: (s) => s > 199 && s < 300,
  withCredentials: true,
})

export const getSAPSearch = async (data: {
  email: string
  firstName: string
  lastName: string
  managerId: string
  sapId: string
  directId: string
}): Promise<Response<Employee[]>> => {
  const resp = await client.get(
    `/email=${data.email}&firstName=${data.firstName}&lastName=${data.lastName}&managerId=${data.managerId}&sapId=${data.sapId}&directId=${data.directId}`
  )
  return Response.list(resp)
}
