import { map } from 'lodash'
import { Dispatch, SetStateAction } from 'react'

import { EngagementSeatRole, Quantum, Reference, Location, EngagementState } from '@athena/core/model'

export const roles = new Map([
  [EngagementSeatRole.Engineer, 'Engineer'],
  [EngagementSeatRole.Designer, 'Designer'],
  [EngagementSeatRole.DeliveryManager, 'Delivery Manager'],
  [EngagementSeatRole.ProductManager, 'Product Manager'],
])

export interface Seat {
  /** For existing seat this comes from the backend. Empty for seats created on engagement page */
  id?: string
  userFullName: string
  resourceId: string
  role?: EngagementSeatRole
}

export interface Option {
  value: string
  label: string
}

export interface LabelProps {
  value: string
  roleClass: string
  selected?: boolean
  onClick?: (value: string) => void
}

export interface SeatCardProps {
  disabled?: boolean
  seat: Seat
  onEditClick: (seat: Seat) => void
  onDeleteClick: (seat: Seat) => void
}

export interface SeatModalProps {
  editedSeat: Seat
  capabilities?: Map<string, string>
  dataTestContainer: string
  users: Map<string, string>
  onDelete?: (deletedSeatId: string) => void
  onHide?: () => void
  onSave?: (savedSeat: Seat) => void
  isResourcePresent?: (savedSeat: Seat) => boolean
}

export interface PeerReviewPanelProps {
  lastDate?: Date
  nextDate?: Date
  finalDate?: Date
  startPeerReviewDisabled: boolean
  onStartPeerReviewClick: () => void
}

export interface EngagementDates {
  lastFeedbackRequestGenerationDate: Quantum<Date>
  startDate: Date
  endDate: Date
  // to not depend on the current date in tests
  today: Date
}

export interface PrimaryDetails {
  id: string
  title: string
  activityId: string
  startDate: Date | null
  endDate: Date | null
  locations: Location[]
  description: string
  seats: Seat[]
}

export interface DetailsProps {
  dataTestContainer: string
  engagementState: EngagementState | null | undefined
  isEditable: boolean
  primaryDetails: PrimaryDetails
  setPrimaryDetails: Dispatch<SetStateAction<PrimaryDetails>>
}

export interface SeatsProps {
  dataTestContainer: string
  isEditable?: boolean
  primaryDetails: PrimaryDetails
  setPrimaryDetails: Dispatch<SetStateAction<PrimaryDetails>>
}

export const transformToOptions = (references: Reference[] = []) =>
  map(references, (r) => ({ value: r.id, label: r.name }))
