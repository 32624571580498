import React from 'react'

import LoadingSpinner from '@athena/component/asset/loading-spinner.gif'

import Props from './interface'
import Style from './style.module.scss'

const Loading: React.FC<Props> = ({ 'data-test': dataTest, show = true }) => {
  if (!show) {
    return null
  }

  return <img alt="loading" className={Style.loading} data-test={`${dataTest}.loading`} src={LoadingSpinner} />
}
export default Loading
export type { Props }
