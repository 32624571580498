import { includes } from 'lodash'

import { Option } from '@athena/component/atom/combobox'
import { Quantum } from '@athena/core/model'

export const formatDate = (date: Quantum<Date>) =>
  date?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  }) || ''

export const formatDateTime = (date: Quantum<Date>) => {
  if (date) {
    date = new Date(date)
    return `${date.getDate()} ${date.toLocaleDateString('en-US', {
      month: 'long',
    })} ${date.getFullYear()} - ${date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    })}`
  }
  return ''
}

export const getEnumByValue = <T extends object>(enumObj: T, value: string): T[keyof T] | null => {
  const enumKeys = Object.keys(enumObj) as Array<keyof T>
  for (const key of enumKeys) {
    if (enumObj[key] === value) {
      return enumObj[key]
    }
  }
  return null
}

type EnumWithKeyAndValue<K extends string, V extends string> = {
  [key in K]: V
}

export const splitCamelCase = (input: string): string => {
  return input.replace(/([a-z])([A-Z])/g, '$1 $2')
}
/**
 *  It takes an object representing an enum and returns an array of options.
 *  Each option in the array has a label and a value, where the label is a modified version of the enum key and the value is the corresponding enum value.
 * @param enumObj with camel case keys
 * @returns Option[]
 */
export const mapEnumToOptions = (enumObj: EnumWithKeyAndValue<string, string>) => {
  const options: Option[] = []
  for (const [key, value] of Object.entries(enumObj)) {
    const splittedKey = splitCamelCase(key)
    options.push({
      label: splittedKey,
      value,
    })
  }

  return options
}

export const generateRevueDD = (enumObj: EnumWithKeyAndValue<string, string>) => {
  const options: Option[] = []
  let index = 0
  for (const [key, value] of Object.entries(enumObj)) {
    const splittedKey = splitCamelCase(key)
    options.push({
      label: index === 0 ? splittedKey : `${index} - ${splittedKey}`,
      value,
    })
    index++
  }

  return options
}

export const getCurrentYear = () => new Date().getFullYear().toString()

export const getCurrentYearOption = () => {
  const currentYear = getCurrentYear()
  return { label: currentYear, value: currentYear }
}

export const optionAll = { label: 'All', value: 'all' }

export const generateYearOptions = (startYear: number, endYear: number, isOptionAll = false): Option[] => {
  if (!startYear || !endYear) return []
  const years: number[] = []
  for (let year = endYear; year >= startYear; year--) {
    years.push(year)
  }
  const ops = years.map((value) => ({ label: value.toString(), value: value.toString() }))
  if (isOptionAll) {
    ops.unshift(optionAll)
  }
  return ops as Option[]
}

export const generateYearOptionsFromArray = (years: string[]): Option[] => {
  const yearOps = !years?.length
    ? []
    : years?.map((value) => ({
        label: value,
        value,
      }))

  const isCurrentYearInOptions = includes(years, getCurrentYear())
  if (!isCurrentYearInOptions) {
    yearOps.unshift(getCurrentYearOption())
  }
  return yearOps
}

export const generateOptionsFromArray = (arr: string[]): Option[] => {
  return arr.map((value) => ({
    label: value,
    value,
  }))
}
