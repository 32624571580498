import classNames from 'classnames'
import { isNil } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import Icon, { IconSize } from '@athena/component/atom/icon'

import { SidebarLinkProps } from './interface'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

const SidebarLink: React.FC<SidebarLinkProps> = ({
  'data-test': dataTest = 'sidebar-link',
  link,
  activeLink,
  setActiveLink,
}) => {
  const { icon, label, to, count } = link

  return (
    <Link
      key={label}
      data-test={`${dataTest}.link`}
      data-test-arg={label}
      to={to}
      className={cx(
        Style.link,
        { [Style.activeLink]: activeLink === to },
        'flex h-[42px]  pl-[22px] items-center text-[14px] text-white uppercase'
      )}
      onClick={() => setActiveLink(to)}
    >
      <Icon customClasses="mr-[20px]" data-test={`${dataTest}.icon`} name={icon} size={IconSize.Large} />
      {label}
      {!isNil(count) && count !== 0 && <div className={Style.count}>{count}</div>}
    </Link>
  )
}

export default SidebarLink
