import axios from 'axios'

import Response from '@athena/core/client/response'
import Promotion from '@athena/core/model/promotion'

import Environment from '@apollo/client/environment'

const client = axios.create({
  baseURL: `${Environment.apollo.host}/promotion`,
  timeout: Environment.apollo.timeout,
  validateStatus: (s) => s > 199 && s < 500,
  withCredentials: true,
})

export const addPromotion = async (promotion: Promotion): Promise<Response<Promotion>> => {
  const serialized = Promotion.schema.parse(promotion.toSerialized())
  const resp = await client.post('/', serialized)
  return Response.single(resp, Promotion.fromSerialized)
}

export const updatePromotion = async (promotion: Promotion): Promise<Response<Promotion>> => {
  const serialized = Promotion.schema.parse(promotion.toSerialized())
  const resp = await client.put('/', serialized)
  return Response.single(resp, Promotion.fromSerialized)
}

export const removePromotion = async (id: string): Promise<Response<boolean>> => {
  const resp = await client.delete(`/${id}`)
  return Response.deletion(resp)
}

export const getPromotionByObjectiveId = async (objectiveId: string): Promise<Response<Promotion>> => {
  const resp = await client.get(`/objective/${objectiveId}`)
  return Response.single(resp, Promotion.fromSerialized)
}
