import classNames from 'classnames'
import React from 'react'

import Props, { TypographySize } from './interface'
import Style from './style.module.scss'

export const Typography: React.FC<Props> = ({
  'data-test': dataTest = 'typography',
  size,
  children,
  bold = false,
  uppercase = false,
  customClass,
}: Props) => (
  <div
    className={classNames(Style.typography, Style[size], { bold, uppercase }, customClass)}
    data-test={`${dataTest}.element`}
  >
    {children}
  </div>
)

export default Typography
export type { Props }
export { TypographySize }
