import { MutateOptions } from '@tanstack/react-query'
import classNames from 'classnames'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import Button, { ButtonFill, IconName } from '@athena/component/atom/button'
import Loading from '@athena/component/atom/loading'
import Typography, { TypographySize } from '@athena/component/atom/typography'
import Modal from '@athena/component/molecule/modal'
import { HttpStatus } from '@athena/core/http'
import { BaseModel, Engagement, EngagementState, Quantum } from '@athena/core/model'

import {
  useAddEngagement,
  useAddEngagementRequests,
  useEngagement,
  useFeedbackRequestHorizon,
  useCanBeDeleted,
  useRemoveEngagement,
  useUpdateEngagement,
} from '@apollo/client/hook/engagement'
import { useEmployeeReference } from '@artemis/client/hook/employee'
import ConfirmationDialog from '@future-view/frontend/component/confirm-dialog'
import { PeerReviewPanel } from '@future-view/frontend/page/engagement/peer-review-panel'
import utils, { buildEngagement, isInactive } from '@future-view/frontend/page/engagement/utils'
import Path from '@future-view/frontend/router/path'
import Logger from '@future-view/frontend/util/log'

import { Details } from './details'
import { EngagementDates, PrimaryDetails } from './interface'
import Seats from './seats'
import Style from './style.module.scss'

const dataTestContainer = 'engagement.container'

interface FeedbackRequestGenerationDates {
  lastDate?: Date
  nextDate?: Date
  finalDate?: Date
}

interface ModifyEngagementError {
  code: string
  response: {
    data: string
    status: number
  }
}

const log = Logger.child({ module: 'page/engagement/edit-engagement' })

const cx = classNames.bind(Style)

const EngagementDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const [engagement, setEngagement] = useState<Engagement>()
  const [userFullNameByResourceId, setResourceIdToUserFullNameMap] = useState<Map<string, string>>()
  const [showReviewDialog, setShowReviewDialog] = useState(false)
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false)
  const [showUnpublishConfirmationDialog, setShowUnpublishConfirmationDialog] = useState(false)
  const [showPublishConfirmationDialog, setShowPublishConfirmationDialog] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const [primaryDetails, setPrimaryDetails] = useState<PrimaryDetails>({
    id: '',
    title: '',
    locations: [],
    startDate: null,
    endDate: null,
    description: '',
    activityId: '',
    seats: [],
  })
  const [beforeEditPrimaryDetails, setBeforeEditPrimaryDetails] = useState(primaryDetails)
  const [feedbackRequestGenerationDates, setFeedbackRequestGenerationDates] = useState<FeedbackRequestGenerationDates>({
    lastDate: undefined,
    nextDate: undefined,
    finalDate: undefined,
  })
  const [lastPeerReviewDate, setLastPeerReviewDate] = useState<Quantum<Date>>()
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const resourceReference = useEmployeeReference()
  const getEngagement = useEngagement(id || '')
  const canBeDeleted = useCanBeDeleted(id || '')
  const addEngagement = useAddEngagement()
  const updateEngagement = useUpdateEngagement()
  const removeEngagement = useRemoveEngagement()
  const horizonResp = useFeedbackRequestHorizon(id || '')
  const addFeedbackRequests = useAddEngagementRequests()

  /** =============== Initial load =============== */
  useEffect(() => {
    if (resourceReference.data && getEngagement.data) {
      if (getEngagement.data.status === 204) navigate(Path.NewEngagement)
      setIsLoading(false)
    }
  }, [getEngagement.data, navigate, resourceReference.data])

  useEffect(() => {
    if (resourceReference.data) {
      const data = resourceReference.data.data || []
      const userFullNameById: Map<string, string> = data.reduce((acc, resource) => {
        acc.set(resource.id, resource.name)
        return acc
      }, new Map<string, string>())

      setResourceIdToUserFullNameMap(userFullNameById)
    }

    if (getEngagement.data) {
      setEngagement(getEngagement.data.data as Engagement)
    }
  }, [resourceReference.data, getEngagement.data])

  useEffect(() => {
    if (engagement && userFullNameByResourceId) {
      const newPrimaryDetails = {
        id: engagement?.id || '',
        title: engagement.title,
        locations: engagement.location,
        startDate: BaseModel.parseDate(engagement.startDate) || null,
        endDate: BaseModel.parseDate(engagement.endDate) || null,
        description: engagement.description,
        activityId: engagement.rel.activity,
        seats: engagement.seats.map((seat) => {
          return {
            id: seat.id as string,
            role: seat.role,
            resourceId: seat.rel.resource,
            userFullName: userFullNameByResourceId.get(seat.rel.resource as string) || '', // TODO firing multiple requests for full names is not good
          }
        }),
      }
      setPrimaryDetails(newPrimaryDetails)
      setBeforeEditPrimaryDetails({ ...newPrimaryDetails })
    }
  }, [engagement, userFullNameByResourceId])

  useEffect(() => {
    if (horizonResp.data) {
      setLastPeerReviewDate(horizonResp.data.data)
    }
  }, [horizonResp.data])

  useEffect(() => {
    if (addFeedbackRequests.data) {
      toast.success('Feedback requests generated successfully')
      setLastPeerReviewDate(new Date())
    }

    if (updateEngagement.error) {
      toast.error('Failed to generate feedback requests')
    }
  }, [addFeedbackRequests.data, updateEngagement.error])

  useEffect(() => {
    const engagementDates: EngagementDates = {
      startDate: primaryDetails.startDate || new Date(),
      endDate: primaryDetails.endDate || new Date(),
      lastFeedbackRequestGenerationDate: lastPeerReviewDate,
      today: new Date(),
    }

    const { nextDate, finalDate } = utils(engagementDates)
    setFeedbackRequestGenerationDates({
      lastDate: lastPeerReviewDate || undefined,
      nextDate,
      finalDate,
    })
  }, [lastPeerReviewDate, primaryDetails.startDate, primaryDetails.endDate])

  const handleEdit = () => {
    setBeforeEditPrimaryDetails({ ...primaryDetails })
    setIsEditable(true)
  }

  const handlePublish = () => {
    if (EngagementState.Published === engagement?.state) {
      setShowUnpublishConfirmationDialog(true)
    } else {
      setShowPublishConfirmationDialog(true)
    }
  }

  const handleSaveChanges = () => {
    if (engagement) {
      saveEngagement(engagement.state, () => {
        toast.success('Engagement Saved')
        setBeforeEditPrimaryDetails({ ...primaryDetails })
        setIsEditable(false)
      })
    }
  }

  const handleCancel = () => {
    if (isChanged) {
      setShowCancelDialog(true)
    } else {
      setIsEditable(false)
    }
  }

  const handleClose = () => {
    navigate(Path.Engagements)
  }

  const saveEngagement = (
    state: EngagementState,
    onSuccess?: MutateOptions<unknown, unknown, Engagement>['onSuccess']
  ) => {
    const savedEngagement = buildEngagement(primaryDetails)
    savedEngagement.state = state
    if (savedEngagement.id) {
      updateEngagement.mutate(savedEngagement, { onSuccess })
    } else {
      addEngagement.mutate(savedEngagement, { onSuccess })
    }
  }

  useEffect(() => {
    if (addEngagement.error) {
      showErrorOnSaveEngagement(addEngagement.error as ModifyEngagementError)
    } else if (updateEngagement.error) {
      showErrorOnSaveEngagement(updateEngagement.error as ModifyEngagementError)
    }
  }, [addEngagement.error, updateEngagement.error])

  const showErrorOnSaveEngagement = (err: ModifyEngagementError) => {
    toast.error(err.response?.status === HttpStatus.BadRequest ? err.response.data : 'Failed to save engagement')
    log.child({ err }).error('Error attempting to save engagement')
  }

  /** Rendering */
  const isPublishButtonDisabled = useMemo(() => {
    return (
      !primaryDetails.activityId ||
      !primaryDetails.locations.length ||
      !primaryDetails.startDate ||
      !primaryDetails.endDate ||
      !primaryDetails.description.trim() ||
      primaryDetails.seats.length < 2
    )
  }, [primaryDetails])

  const isSaveButtonDisabled = useMemo(() => {
    return !primaryDetails.activityId || !primaryDetails.title || !isChanged
  }, [primaryDetails, isChanged])

  useEffect(() => {
    if (isEditable && !isEqual(beforeEditPrimaryDetails, primaryDetails)) {
      setIsChanged(true)
    } else {
      setIsChanged(false)
    }
  }, [primaryDetails, isEditable, beforeEditPrimaryDetails])

  if (isLoading)
    return (
      <div className={Style.blockingOpaqueBlock}>
        <div className={Style.loadingContainer}>
          <Loading show data-test={`${dataTestContainer}.loading`} />
        </div>
      </div>
    )
  return (
    <div className={Style.container} data-test={dataTestContainer}>
      {/* HEADER */}
      <div className={Style.header}>
        <Typography data-test={`${dataTestContainer}.header`} size={TypographySize.Header3}>
          Manage Engagement
        </Typography>
        {!isEditable && (
          <Button
            customClasses={Style.closeButton}
            data-test={`${dataTestContainer}.close-button`}
            fill={ButtonFill.Flat}
            icon={IconName.XMark}
            onClick={handleClose}
          ></Button>
        )}
      </div>
      {engagement && (
        <PeerReviewPanel
          finalDate={feedbackRequestGenerationDates.finalDate}
          lastDate={feedbackRequestGenerationDates.lastDate}
          nextDate={feedbackRequestGenerationDates.nextDate}
          startPeerReviewDisabled={
            isEditable || engagement.state !== EngagementState.Published || isInactive(engagement)
          }
          onStartPeerReviewClick={() => setShowReviewDialog(true)}
        />
      )}
      <section className={Style.buttonRow}>
        {!isEditable && (
          <Button
            customClasses={Style.editButton}
            data-test={`${dataTestContainer}.edit`}
            fill={ButtonFill.Outline}
            icon={IconName.PencilSquare}
            onClick={handleEdit}
          >
            Edit
          </Button>
        )}
        {isEditable && (
          <Button
            customClasses={Style.cancelButton}
            data-test={`${dataTestContainer}.cancel`}
            fill={ButtonFill.Outline}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        )}
        {canBeDeleted.data?.data && isEditable && (
          <Button
            data-test={`${dataTestContainer}.trash`}
            fill={ButtonFill.Outline}
            icon={IconName.Trash}
            onClick={() => {
              setShowDeleteConfirmationDialog(true)
            }}
          >
            Trash
          </Button>
        )}
        {isEditable && (
          <Button
            customClasses={cx(Style.publishButton, { [Style.disabled]: isPublishButtonDisabled })}
            data-test={`${dataTestContainer}.publish-button`}
            disabled={isPublishButtonDisabled}
            fill={ButtonFill.Outline}
            icon={IconName.ArrowUpOnSquare}
            onClick={handlePublish}
          >
            {EngagementState.Published === engagement?.state ? 'Unpublish' : 'Publish'}
          </Button>
        )}
        {isEditable && (
          <Button
            customClasses={Style.saveChanges}
            data-test={`${dataTestContainer}.save-changes`}
            disabled={isSaveButtonDisabled}
            fill={ButtonFill.Solid}
            icon={IconName.DocumentText}
            onClick={handleSaveChanges}
          >
            Save changes
          </Button>
        )}
      </section>
      {/* DETAILS */}
      <Details
        dataTestContainer={dataTestContainer}
        engagementState={engagement?.state}
        isEditable={isEditable}
        primaryDetails={primaryDetails}
        setPrimaryDetails={setPrimaryDetails}
      ></Details>
      {/* SEATS */}
      <Seats
        dataTestContainer={dataTestContainer}
        isEditable={isEditable}
        primaryDetails={primaryDetails}
        setPrimaryDetails={setPrimaryDetails}
      ></Seats>
      {id && (
        <Modal
          hideCloseButton
          data-test={`${dataTestContainer}.create-peer-review-confirmation-modal`}
          isShown={showReviewDialog}
          onHide={() => setShowReviewDialog(false)}
        >
          <ConfirmationDialog
            confirmButtonText="Yes"
            data-test={`${dataTestContainer}.create-peer-review-confirmation-dialog`}
            description="This will override system generated reviews"
            returnButtonText=""
            onConfirm={(confirmed: boolean) => {
              if (confirmed) {
                addFeedbackRequests.mutate(id, {
                  onSuccess: () => {
                    setShowReviewDialog(false)
                  },
                })
              } else {
                setShowReviewDialog(false)
              }
            }}
          />
        </Modal>
      )}
      {id && (
        <Modal
          hideCloseButton
          data-test={`${dataTestContainer}.delete-confirmation-modal`}
          isShown={showDeleteConfirmationDialog}
          onHide={() => setShowDeleteConfirmationDialog(false)}
        >
          <ConfirmationDialog
            confirmButtonText="Yes"
            data-test={`${dataTestContainer}.delete-confirmation-dialog`}
            description={'Engagement will be deleted'}
            returnButtonText=""
            onConfirm={(confirmed: boolean) => {
              if (confirmed) {
                removeEngagement.mutate(id, {
                  onSuccess: () => {
                    toast.success('Engagement Deleted')
                    setShowDeleteConfirmationDialog(false)
                    navigate(Path.Engagements)
                  },
                })
              } else {
                setShowDeleteConfirmationDialog(false)
              }
            }}
          />
        </Modal>
      )}
      <Modal
        hideCloseButton
        data-test={`${dataTestContainer}.unpublish-confirmation-modal`}
        isShown={showUnpublishConfirmationDialog}
        onHide={() => setShowUnpublishConfirmationDialog(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTestContainer}.unpublish-confirmation-dialog`}
          description={isChanged ? 'Engagement will be saved and moved to draft' : 'Engagement will be moved to draft'}
          returnButtonText=""
          onConfirm={(confirmed: boolean) => {
            if (confirmed) {
              saveEngagement(EngagementState.Draft, () => {
                const msg = isChanged ? 'Engagement Saved and Unpublished' : 'Engagement Unpublished'
                toast.success(msg)
                setShowUnpublishConfirmationDialog(false)
                navigate(Path.Engagements)
              })
            } else {
              setShowUnpublishConfirmationDialog(false)
            }
          }}
        />
      </Modal>
      <Modal
        hideCloseButton
        data-test={`${dataTestContainer}.publish-confirmation-modal`}
        isShown={showPublishConfirmationDialog}
        onHide={() => setShowPublishConfirmationDialog(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTestContainer}.publish-confirmation-dialog`}
          description={isChanged ? 'Engagement will be saved and published' : 'Engagement will be published'}
          returnButtonText=""
          onConfirm={(confirmed: boolean) => {
            if (confirmed) {
              saveEngagement(EngagementState.Published, () => {
                const msg = isChanged ? 'Engagement Saved and Published' : 'Engagement Published'
                toast.success(msg)
                setShowPublishConfirmationDialog(false)
                setIsEditable(false)
              })
            } else {
              setShowPublishConfirmationDialog(false)
            }
          }}
        />
      </Modal>
      <Modal
        hideCloseButton
        data-test={`${dataTestContainer}.cancel-modal`}
        isShown={showCancelDialog}
        onHide={() => setShowCancelDialog(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTestContainer}.cancel-dialog`}
          description="Changes will not be saved"
          onConfirm={(confirmed: boolean) => {
            setShowCancelDialog(false)
            if (confirmed) {
              setIsEditable(false)
              setPrimaryDetails({ ...beforeEditPrimaryDetails })
            }
          }}
        />
      </Modal>
      {(getEngagement.isLoading ||
        addEngagement.isLoading ||
        updateEngagement.isLoading ||
        addFeedbackRequests.isLoading) && (
        <div className={Style.blockingOpaqueBlock}>
          <div className={Style.loadingContainer}>
            <Loading show data-test={`${dataTestContainer}.loading`} />
          </div>
        </div>
      )}
    </div>
  )
}

export default EngagementDetail
