import { isFunction } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button, { ButtonFill, IconName } from '@athena/component/atom/button'
import Combobox, { Option } from '@athena/component/atom/combobox'

import Avatar from '@future-view/frontend/component/avatar'
import { HeaderProps } from '@future-view/frontend/page/peer-metrics/interface'
import Path from '@future-view/frontend/router/path'

import Style from './style.module.scss'

const Header: React.FC<HeaderProps> = ({
  'data-test': dataTest = 'header',
  yearOptions,
  directReportOptions,
  selectedDirectReportOption,
  employeeId,
  employeeLevel,
  defaultYear,
  onYearChange = undefined,
  onDirectReportChange = undefined,
}) => {
  const navigate = useNavigate()

  return (
    <div
      className={'flex self-stretch items-start items-center gap-[10px] p-[24px] rounded-[8px] bg-white'}
      data-test={`${dataTest}.wrapper`}
    >
      <div className={'flex flex-1 items-center pr-[0px]'} data-test={`${dataTest}.profile-wrapper`}>
        <section className={`flex items-center gap-[16px] ${Style.section}`} data-test={`${dataTest}.employee`}>
          <Avatar cid={employeeId} data-test={`${dataTest}.employee-avatar`} wrapperCustomClasses={Style.img}></Avatar>
          <Combobox
            customClasses="min-w-[200px]"
            data-test={`${dataTest}.employee`}
            label="Direct Report"
            options={directReportOptions}
            value={selectedDirectReportOption}
            onChange={(value) => isFunction(onDirectReportChange) && onDirectReportChange(value as Option)}
          />
        </section>
        <section
          className={`flex flex-col justify-center items-start pr-[0px] ${Style.section}`}
          data-test={`${dataTest}.level`}
        >
          <p className={Style.heading}>Level</p>
          <p className={'text-[24px]'}>{employeeLevel}</p>
        </section>
        <Combobox
          customClasses={Style.year}
          data-test={`${dataTest}.year`}
          disabled={!yearOptions || yearOptions?.length < 2}
          label="Year"
          options={yearOptions}
          value={defaultYear}
          onChange={(value) => isFunction(onYearChange) && onYearChange(value as Option)}
        />
      </div>
      <div className={'flex items-center gap-[16px]'} data-test={`${dataTest}.year-wrapper`}>
        <div className={Style.back}>
          <Button
            customClasses={Style.back}
            data-test={`${dataTest}.back`}
            disabled={false}
            fill={ButtonFill.Outline}
            icon={IconName.ArrowLeftCircle}
            onClick={() => navigate(Path.PeersPective)}
          >
            Dashboard
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Header
