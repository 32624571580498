import { assignIn } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, Quantum, Schema } from './base-model'

export type AssessmentWindowSerialized = z.infer<typeof AssessmentWindow.schema>

export type AssessmentWindowStore = BaseModelStore & {
  enable_self_assessment_from: string | null
  enable_self_assessment_to: string | null
  enable_manager_assessment_from: string | null
  enable_manager_assessment_to: string | null
  show_manager_assessment_from: string | null
  disable_manager_rating: boolean
  target_year: number
}

export interface AssessmentWindowDetails {
  enableSelfAssessmentFrom: Quantum<Date>
  enableSelfAssessmentTo: Quantum<Date>
  enableManagerAssessmentFrom: Quantum<Date>
  enableManagerAssessmentTo: Quantum<Date>
  showManagerAssessmentFrom: Quantum<Date>
  disableManagerRating: boolean
  targetYear: number
}

export default class AssessmentWindow extends BaseModel implements AssessmentWindowDetails {
  enableSelfAssessmentFrom: Quantum<Date>
  enableSelfAssessmentTo: Quantum<Date>
  enableManagerAssessmentFrom: Quantum<Date>
  enableManagerAssessmentTo: Quantum<Date>
  showManagerAssessmentFrom: Quantum<Date>
  disableManagerRating = false
  targetYear = 1970

  constructor(id = '', targetYear: number) {
    super(id)
    this.targetYear = targetYear
  }

  static override readonly schema = BaseModel.schema.extend({
    enableSelfAssessmentFrom: Schema.DateTime,
    enableSelfAssessmentTo: Schema.DateTime,
    enableManagerAssessmentFrom: Schema.DateTime,
    enableManagerAssessmentTo: Schema.DateTime,
    showManagerAssessmentFrom: Schema.DateTime,
    disableManagerRating: z.boolean().default(false),
    targetYear: z.number(),
  })

  static override fromSerialized(recordInput: AssessmentWindowSerialized): AssessmentWindow {
    const record = AssessmentWindow.schema.parse(recordInput)
    const model = new AssessmentWindow(record.id, record.targetYear)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.enableSelfAssessmentFrom = BaseModel.parseDate(record.enableSelfAssessmentFrom)
    model.enableSelfAssessmentTo = BaseModel.parseDate(record.enableSelfAssessmentTo)
    model.enableManagerAssessmentFrom = BaseModel.parseDate(record.enableManagerAssessmentFrom)
    model.enableManagerAssessmentTo = BaseModel.parseDate(record.enableManagerAssessmentTo)
    model.showManagerAssessmentFrom = BaseModel.parseDate(record.showManagerAssessmentFrom)
    model.disableManagerRating = record.disableManagerRating
    return model
  }

  static override fromDataStore(record: AssessmentWindowStore): AssessmentWindow {
    const model = new AssessmentWindow(record.id, record.target_year)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.enableSelfAssessmentFrom = BaseModel.parseDate(record.enable_self_assessment_from)
    model.enableSelfAssessmentTo = BaseModel.parseDate(record.enable_self_assessment_to)
    model.enableManagerAssessmentFrom = BaseModel.parseDate(record.enable_manager_assessment_from)
    model.enableManagerAssessmentTo = BaseModel.parseDate(record.enable_manager_assessment_to)
    model.showManagerAssessmentFrom = BaseModel.parseDate(record.show_manager_assessment_from)
    model.disableManagerRating = record.disable_manager_rating
    return model
  }

  override toSerialized(): AssessmentWindowSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      enableSelfAssessmentFrom: BaseModel.formatDate(this.enableSelfAssessmentFrom),
      enableSelfAssessmentTo: BaseModel.formatDate(this.enableSelfAssessmentTo),
      enableManagerAssessmentFrom: BaseModel.formatDate(this.enableManagerAssessmentFrom),
      enableManagerAssessmentTo: BaseModel.formatDate(this.enableManagerAssessmentTo),
      showManagerAssessmentFrom: BaseModel.formatDate(this.showManagerAssessmentFrom),
      disableManagerRating: this.disableManagerRating,
      targetYear: this.targetYear,
    } as unknown as AssessmentWindowSerialized
  }

  override toDataStore(): AssessmentWindowStore {
    return {
      ...super.toDataStore(),
      enable_self_assessment_from: BaseModel.formatDate(this.enableSelfAssessmentFrom),
      enable_self_assessment_to: BaseModel.formatDate(this.enableSelfAssessmentTo),
      enable_manager_assessment_from: BaseModel.formatDate(this.enableManagerAssessmentFrom),
      enable_manager_assessment_to: BaseModel.formatDate(this.enableManagerAssessmentTo),
      show_manager_assessment_from: BaseModel.formatDate(this.showManagerAssessmentFrom),
      disable_manager_rating: this.disableManagerRating,
      target_year: this.targetYear,
    }
  }
}
