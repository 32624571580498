import { useQuery } from '@tanstack/react-query'

import { PagerData } from '@athena/core/api/pager'

import {
  getActivity,
  getActivityReference,
  getActivitySlice,
  getUpcomingActivity,
} from '@artemis/client/api/resourced-activity'

const QueryKey = {
  Activity: 'resourced-ativity-item',
  ActivityReference: 'resourced-activity-reference',
  ActivitySlice: 'resourced-activity-slice',
  UpcomingSlice: 'upcoming-slice',
}

export const useActivity = (id: string) =>
  useQuery({
    queryKey: [QueryKey.Activity, id],
    queryFn: () => getActivity(id),
  })

export const useActivityReference = () =>
  useQuery({
    queryKey: [QueryKey.ActivityReference],
    queryFn: getActivityReference,
  })

export const useActivitySlice = (pagerData?: PagerData) =>
  useQuery({
    queryKey: [QueryKey.ActivitySlice, pagerData],
    queryFn: () => getActivitySlice(pagerData),
  })

export const useUpcomingActivity = (pagerData?: PagerData) =>
  useQuery({
    queryKey: [QueryKey.UpcomingSlice, pagerData],
    queryFn: () => getUpcomingActivity(pagerData),
  })
