import { IconName } from '@athena/component/atom/icon'

import Path from '@future-view/frontend/router/path'

const links = [
  {
    label: 'Peerspective',
    to: Path.PeerReview,
    icon: IconName.UserGroup,
  },
  {
    label: 'Revue',
    to: Path.RevueDashboard,
    icon: IconName.Newspaper,
  },
  {
    label: 'Admin',
    to: Path.Engagements,
    icon: IconName.Identification,
    admin: true,
  },
  {
    label: 'Manager',
    to: Path.PeersPective,
    icon: IconName.Users,
    admin: true,
    peopleManager: true,
  },
]

export default links
