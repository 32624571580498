import { isFunction } from 'lodash'
import React from 'react'

import Combobox, { Option } from '@athena/component/atom/combobox'
import Icon, { IconName, IconSize } from '@athena/component/atom/icon'

import Avatar from '@future-view/frontend/component/avatar'
import { HeaderProps } from '@future-view/frontend/page/revue-dashboard/interface'
import Logger from '@future-view/frontend/util/log'

import Style from './style.module.scss'

const log = Logger.child({ module: 'page/revue-dashboard/header' })

const RevueHeader: React.FC<HeaderProps> = ({
  'data-test': dataTest = 'header',
  managerId,
  managerName,
  department,
  level,
  selectedYear,
  yearOptions,
  onYearChange,
}) => {
  return (
    <div
      className={'flex self-stretch items-start items-center gap-[10px] p-[24px] rounded-[8px] bg-white'}
      data-test={`${dataTest}.wrapper`}
    >
      <div className={'flex flex-1 items-center pr-[0px]'} data-test={`${dataTest}.profile-wrapper`}>
        <section className={`flex items-center gap-[16px] ${Style.section}`} data-test={`${dataTest}.manager`}>
          <Avatar cid={managerId} data-test={`${dataTest}.manager-avatar`} wrapperCustomClasses={Style.img}></Avatar>
          <div>
            <p className={Style.heading}>Your Manager</p>
            <p className={'text-[24px]'}>{managerName}</p>
          </div>
        </section>
        <section
          className={`flex flex-col justify-center items-start pr-[0px] ${Style.section}`}
          data-test={`${dataTest}.department`}
        >
          <p className={Style.heading}>Department</p>
          <p className={'text-[24px]'}>{department}</p>
        </section>
        <section
          className={`flex flex-col justify-center items-start pr-[0px] ${Style.section}`}
          data-test={`${dataTest}.level`}
        >
          <p className={Style.heading}>Your Level</p>
          <p className={'text-[24px]'}>{level}</p>
        </section>
      </div>
      <div className={'flex items-start gap-[16px]'} data-test={`${dataTest}.year-wrapper`}>
        <Combobox
          customClasses="w-[124px]"
          data-test={`${dataTest}.year`}
          disabled={!yearOptions || yearOptions?.length < 2}
          label="Year"
          options={yearOptions}
          value={selectedYear}
          onChange={(value) => isFunction(onYearChange) && onYearChange(value as Option)}
        />
        <Icon data-test={`${dataTest}.info-icon`} name={IconName.InformationCircle} size={IconSize.Small} />
      </div>
    </div>
  )
}

export default RevueHeader
