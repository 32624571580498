import { useLayoutEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import Loading from '@athena/component/atom/loading'

import { useLogout } from '@apollo/client/hook/auth'
import Path from '@future-view/frontend/router/path'

const Logout = () => {
  const logout = useLogout()
  const [inProgress, setProgress] = useState(false)

  useLayoutEffect(() => {
    if (!inProgress) {
      window.sessionStorage.removeItem('intendedDestination')
      logout.mutate()
      setProgress(true)
    }
  }, [inProgress, logout])

  if (logout.isLoading) {
    return (
      <div data-test="logout.container">
        <Loading data-test="logout.loading" />
      </div>
    )
  }

  return <Navigate replace to={Path.Root} />
}
export default Logout
