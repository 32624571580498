import { assignIn } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, Schema } from '@athena/core/model/base-model'

export type EngagementSeatSerialized = z.infer<typeof EngagementSeat.schema>

export enum EngagementSeatRole {
  Engineer = 'engineer',
  Designer = 'designer',
  DeliveryManager = 'delivery-manager',
  ProductManager = 'product-manager',
}

export type EngagementSeatStore = BaseModelStore & {
  id_engagement: string
  id_resource: string
  role: EngagementSeatRole
}

export default class EngagementSeat extends BaseModel {
  rel = {
    engagement: '',
    resource: '',
  }
  role = EngagementSeatRole.Engineer

  constructor(id = '', engagement = '', resource = '') {
    super(id, '', undefined, undefined)
    this.rel = { engagement, resource }
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      engagement: Schema.Id,
      resource: Schema.Id,
    }),
    role: z.nativeEnum(EngagementSeatRole),
  })

  static override fromSerialized(recordInput: EngagementSeatSerialized): EngagementSeat {
    const record = EngagementSeat.schema.parse(recordInput)
    const model = new EngagementSeat(record.id, record.rel.engagement, record.rel.resource)
    model.created = BaseModel.parseDate(record.created)
    model.role = record.role
    return model
  }

  static override fromDataStore(record: EngagementSeatStore): EngagementSeat {
    const model = new EngagementSeat(record.id, record.id_engagement, record.id_resource)
    model.created = BaseModel.parseDate(record.created)
    model.role = record.role
    return model
  }

  override toSerialized(): EngagementSeatSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      rel: {
        resource: this.rel.resource,
        engagement: this.rel.engagement,
      },
    } as EngagementSeatSerialized
  }

  override toDataStore(): EngagementSeatStore {
    return {
      ...super.toDataStore(),
      id_engagement: this.rel.engagement,
      id_resource: this.rel.resource,
      role: this.role,
    }
  }
}
