import classNames from 'classnames'
import { isFunction } from 'lodash'
import React from 'react'

import { Icon, IconName, IconSize } from '@athena/component/atom/icon'

import Avatar from '@future-view/frontend/component/avatar'

import Props from './interface'
import Style from './style.module.scss'

const ResourceDetailCard: React.FC<Props> = ({
  'data-test': dataTest = 'resource-card',
  id,
  name = '',
  email = '',
  wrapperCustomClasses,
  onClick,
}) => {
  return (
    <div
      className={classNames(wrapperCustomClasses, Style.wrapper, { 'cursor-pointer': !!onClick })}
      data-test={`${dataTest}.container`}
      onClick={() => isFunction(onClick) && id && onClick(id)}
    >
      <div className={Style.textWrapper}>
        {id && <Avatar cid={id || ''} data-test={`${dataTest}.avatar`} wrapperCustomClasses={Style.img}></Avatar>}
        {name && (
          <label className={Style.name} data-test={`${dataTest}.name`}>
            {name}
          </label>
        )}
      </div>
      <div className={Style.textWrapper}>
        <Icon customClasses="mr-[5px]" data-test={`icon`} name={IconName.Envelope} size={IconSize.Small} />
        {email && (
          <a className={Style.email} data-test={`${dataTest}.email`} href={'mailto:' + email}>
            {email}
          </a>
        )}
      </div>
    </div>
  )
}

export default ResourceDetailCard
export type { Props }
