import classNames from 'classnames'
import { isFunction } from 'lodash'

import { LabelProps } from './interface'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

export const Label = ({ value, roleClass, selected, onClick }: LabelProps): JSX.Element => {
  return (
    <div
      className={cx([
        {
          [Style[roleClass]]: selected,
          [Style.selected]: selected,
        },
        Style.roleLabel,
      ])}
      onClick={() => {
        isFunction(onClick) && onClick(value)
      }}
    >
      {value}
    </div>
  )
}
