import { orderBy, sortBy } from 'lodash'
import { useCallback, useState } from 'react'

import Icon, { IconName, IconSize } from '@athena/component/atom/icon'
import { Feedback, StrengthImprov } from '@athena/core/model'

import { HeatmapEntryType, HeatmapValue } from '@future-view/frontend/component/heatmap'

import Style from './style.module.scss'
import { buildStyles } from './util'

export interface HeatmapItemProps {
  value: number
  label: string
  onClick: () => void
  'data-test': string
  type: HeatmapEntryType
}

export const calculateHeatmapPct = (value: number, total: number) => {
  const pct = (100 * value) / total
  let opacity = 0
  if (pct >= 80) {
    opacity = 100
  } else if (pct >= 60) {
    opacity = 80
  } else if (pct >= 40) {
    opacity = 60
  } else if (pct >= 20) {
    opacity = 40
  } else {
    opacity = 20
  }
  return opacity
}

export const sourceType = {
  lead: 'lead',
  peer: 'peer',
}

export const calculateHeatmap = (type: string, feedbacks: Feedback[], managerId: string) => {
  const strenghts: { [key: string]: number } = {},
    improvements: { [key: string]: number } = {}
  const retStrength: HeatmapValue[] = [],
    retImprov: HeatmapValue[] = []

  const feedback = feedbacks.filter((item: Feedback) => {
    let retItem
    if (type === sourceType.lead && item.rel.source === managerId) {
      retItem = item
    } else if (type === sourceType.peer && item.rel.source !== managerId) {
      retItem = item
    }
    return retItem
  })
  feedback.forEach((item: Feedback) => {
    item.strengths.forEach((strength: StrengthImprov) => {
      if (!strenghts[strength.skill]) {
        strenghts[strength.skill] = 0
      }
      strenghts[strength.skill] = strenghts[strength.skill] + 1
    })
    item.improvementAreas.forEach((improvement: StrengthImprov) => {
      if (!improvements[improvement.skill]) {
        improvements[improvement.skill] = 0
      }
      improvements[improvement.skill] = improvements[improvement.skill] + 1
    })
  })
  Object.keys(strenghts).forEach((key) => {
    retStrength.push({
      value: calculateHeatmapPct(strenghts[key], feedbacks.length),
      label: key,
      key: `strength-${key}`,
      type: 'strength',
    })
  })
  Object.keys(improvements).forEach((key) => {
    retImprov.push({
      value: calculateHeatmapPct(improvements[key], feedbacks.length),
      label: key,
      key: `improvement-${key}`,
      type: 'improvement',
    })
  })
  return [...orderBy(retStrength, 'value', 'desc'), ...sortBy(retImprov, 'value', 'asc')]
}

const HeatmapItem = ({ 'data-test': dataTest, value, label, type, onClick }: HeatmapItemProps) => {
  const [hovered, setHovered] = useState<boolean>(false)

  const buildStateHandler = useCallback(
    (state: boolean) => () => {
      setHovered(state)
    },
    []
  )

  if (hovered) {
    return (
      <div
        className={Style.hovered}
        data-test={`${dataTest}.hovered`}
        style={buildStyles(type, value)}
        onClick={onClick}
        onMouseLeave={buildStateHandler(false)}
      >
        <span className={Style.text}>
          <Icon
            customClasses={Style.icon}
            data-test={`${dataTest}.icon`}
            name={IconName.ArrowTopRightOnSquare}
            size={IconSize.Small}
          />
          View Details
        </span>
      </div>
    )
  }

  return (
    <div
      className={Style.item}
      data-test={`${dataTest}.normal`}
      style={buildStyles(type, value)}
      onClick={onClick}
      onMouseEnter={buildStateHandler(true)}
    >
      <span data-test={`${dataTest}.label`}>{label}</span>
    </div>
  )
}

export default HeatmapItem
