import classnames from 'classnames'
import { useState } from 'react'

import Loading from '@athena/component/atom/loading'
import { Quantum } from '@athena/core/model'

import Style from './style.module.scss'

const Feedback = () => {
  const [isLoaded, setIsLoaded] = useState<Quantum<boolean>>(false)
  const hideSpinner = () => {
    setTimeout(setIsLoaded, 2000, true)
  }
  return (
    <>
      {!isLoaded ? (
        <div className={classnames(Style.blockingOpaqueBlock, Style.loading)}>
          <div className={Style.loadingContainer}>
            <Loading data-test={`feedback.loading`} />
          </div>
        </div>
      ) : null}
      <iframe
        className={classnames(Style.wrapper, 'clickup-embed', 'clickup-dynamic-height', !isLoaded ? Style.hidden : '')}
        height="100%"
        src="https://forms.clickup.com/10633820/f/a4gjw-20191/Y2RCKZFHE0CWLF988C"
        width="100%"
        onLoad={hideSpinner}
      ></iframe>
      <script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
    </>
  )
}

export default Feedback
