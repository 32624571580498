import classNames from 'classnames'
import { Link, Navigate } from 'react-router-dom'

import Loading from '@athena/component/atom/loading'
import Logo from '@athena/component/atom/logo'
import { HttpStatus } from '@athena/core/http'

import { useIdentity } from '@apollo/client/hook/auth'
import background from '@future-view/frontend/asset/image/login-hero.png'
import Environment from '@future-view/frontend/environment'
import Path from '@future-view/frontend/router/path'

import Style from './style.module.scss'

const Splash = () => {
  localStorage.removeItem('employee')
  const resp = useIdentity()
  if (resp.isLoading) {
    return <Loading data-test="splash.loading" />
  }
  if (resp.isSuccess && resp.data?.status === HttpStatus.OK) {
    const intendedDestination = window.sessionStorage.getItem('intendedDestination')
    return <Navigate to={intendedDestination || Path.PeerReview} />
  }

  return (
    <div className={Style.splash} data-test="splash.container">
      <div className={classNames(Style.welcome, 'bg-white shadow flex w-[80%] xl:w-[65%]')}>
        <img className="h-full" data-test="splash.image" src={background} />
        <div className="w-full flex flex-col items-center justify-center">
          <Logo customClasses={classNames(Style.logo, 'mb-[40px]')} data-test="splash.logo" />
          <div className="text-[32px] xl:text-[42px] text-redhighlight">Welcome to Lab360</div>
          <Link to={`${Environment.apollo.host}/auth/return`}>
            <span className={Style.login}>Log In with SSO</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Splash
