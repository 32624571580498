import { assignIn, pick, round, toNumber } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelWarehouse, DateFormat, Quantum, Schema } from './base-model'

export type ActivityAssignmentSerialized = z.infer<typeof ActivityAssignment.schema>

export type ActivityAssignmentWarehouse = BaseModelWarehouse & {
  KimbleOne__ActivityRole__c: string
  KimbleOne__BusinessUnit__c: string
  KimbleOne__Resource__c: string
  KimbleOne__ResourcedActivity__c: string
  ActivityRole__Name: string
  BusinessUnit__Name: string
  Resource__Name: string
  Resource__Location: string
  ResourcedActivity__Name: string
  KC_Grade__c: string
  KC_Engagement_Owner__c: string
  KC_Resource_Status__c: string
  KimbleOne__BaselineCost__c: string
  KimbleOne__BaselineUsage__c: string
  KimbleOne__BaselineUtilisationPercentage__c: number
  KimbleOne__CandidateIsPreferred__c: string
  KimbleOne__CostRate__c: number
  KimbleOne__DefaultCostRate__c: number
  KimbleOne__DefaultCostRatePercentage__c: string
  KimbleOne__DefaultRevenueRate__c: number
  KimbleOne__DefaultRevenueRatePercentage__c: string
  KimbleOne__StartDate__c: string
  KimbleOne__EarliestTimeEntryDate__c: string
  KimbleOne__EndingSoon__c: string
  KimbleOne__EntryRemainingUsage__c: number
  KimbleOne__ForecastP1Revenue__c: string
  KimbleOne__ForecastP1Usage__c: number
  KimbleOne__ForecastP1EndDate__c: string
  KimbleOne__ForecastP2Revenue__c: string
  KimbleOne__ForecastP2Usage__c: number
  KimbleOne__ForecastP2EndDate__c: string
  KimbleOne__ForecastP3Revenue__c: string
  KimbleOne__ForecastP3Usage__c: number
  KimbleOne__ForecastP3EndDate__c: string
  KimbleOne__ForecastCostRateEffectiveDate__c: string
  KimbleOne__ForecastCostRate__c: number
  KimbleOne__ForecastCost__c: number
  KimbleOne__TimeEntryCount__c: number
  KimbleOne__TotalActualHours__c: number
  KimbleOne__TotalActualUsage__c: number
  KimbleOne__TotalActualCost__c: number
  KimbleOne__TotalActualRevenue__c: number
  KimbleOne__LongNotes__c: string
}

export const ActivityAssignmentProjection = {
  Embed: [
    'id',
    'name',
    'created',
    'updated',
    'rel.resource',
    'rel.activityRole',
    'rel.businessUnit',
    'rel.resourcedActivity',
    'activityRole',
    'businessUnit',
    'resourcedActivity',
    'resource',
    'resourceLocation',
    'grade',
    'engagementOwner',
    'resourceStatus',
    'baselineUtilizationPercentage',
    'start',
  ],
}

export default class ActivityAssignment extends BaseModel {
  rel = {
    activityRole: '',
    businessUnit: '',
    resource: '',
    resourcedActivity: '',
  }
  activityRole = ''
  businessUnit = ''
  resource = ''
  resourceLocation = ''
  resourcedActivity = ''
  grade = ''
  engagementOwner = ''
  resourceStatus = ''
  baselineCost = 0
  baselineUsage = 0
  baselineUtilizationPercentage = 0
  preferred = false
  costRate = 0
  defaultCostRate = 0
  defaultCostRatePercentage = 0
  defaultRevenueRate = 0
  defaultRevenueRatePercentage = 0
  start: Quantum<Date>
  earliestTimeEntry: Quantum<Date>
  endingSoon = false
  entryRemainingUsage = 0
  forecastP1Revenue = 0
  forecastP1Usage = 0
  forecastP1End: Quantum<Date>
  forecastP2Revenue = 0
  forecastP2Usage = 0
  forecastP2End: Quantum<Date>
  forecastP3Revenue = 0
  forecastP3Usage = 0
  forecastP3End: Quantum<Date>
  forecastCostRateEffective: Quantum<Date>
  forecastCostRate = 0
  forecastCost = 0
  timeEntryCount = 0
  totalActualHours = 0
  totalActualUsage = 0
  totalActualCost = 0
  totalActualRevenue = 0
  longNotes = ''

  constructor(
    id: string,
    name: string,
    created: Quantum<Date>,
    updated: Quantum<Date>,
    activityRole: string,
    businessUnit: string,
    resource: string,
    resourcedActivity: string
  ) {
    super(id, name, created, updated)
    this.activityRole = activityRole || ''
    this.businessUnit = businessUnit || ''
    this.resource = resource || ''
    this.resourcedActivity = resourcedActivity || ''
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      activityRole: Schema.Id,
      businessUnit: Schema.Id,
      resource: Schema.Id,
      resourcedActivity: Schema.Id,
    }),
    activityRole: Schema.Name,
    businessUnit: Schema.Name,
    resource: Schema.Name,
    resourceLocation: z.string().max(32).nullable().optional(),
    resourcedActivity: Schema.Name,
    grade: z.string().max(32).nullable(),
    engagementOwner: z.string().max(32).nullable(),
    resourceStatus: z.string().max(16).nullable(),
    baselineCost: z.number().min(0).max(10000000).optional().default(0),
    baselineUsage: z.number().min(0).max(100000).optional().default(0),
    baselineUtilizationPercentage: z.number().min(0).max(10000).optional().default(0),
    preferred: z.boolean().default(false),
    costRate: z.number().min(0).max(10000).optional().default(0),
    defaultCostRate: z.number().min(0).max(10000).optional().default(0),
    defaultCostRatePercentage: z.number().min(0).max(10000000).optional().default(0),
    defaultRevenueRate: z.number().min(0).max(100000).optional().default(0),
    defaultRevenueRatePercentage: z.number().min(0).max(10000000).optional().default(0),
    start: Schema.Date.optional().default(null),
    earliestTimeEntry: Schema.Date.optional().default(null),
    endingSoon: z.boolean().default(false),
    entryRemainingUsage: z.number().min(0).max(10000).optional().default(0),
    forecastP1Revenue: z.number().min(0).max(1000000).optional().default(0),
    forecastP1Usage: z.number().min(0).max(100000).optional().default(0),
    forecastP1End: Schema.Date.optional().default(null),
    forecastP2Revenue: z.number().min(0).max(1000000).optional().default(0),
    forecastP2Usage: z.number().min(0).max(100000).optional().default(0),
    forecastP2End: Schema.Date.optional().default(null),
    forecastP3Revenue: z.number().min(0).max(1000000).optional().default(0),
    forecastP3Usage: z.number().min(0).max(100000).optional().default(0),
    forecastP3End: Schema.Date.optional().default(null),
    forecastCostRateEffective: Schema.Date.optional().default(null),
    forecastCostRate: z.number().min(0).max(10000).optional().default(0),
    forecastCost: z.number().min(0).max(10000000).optional().default(0),
    timeEntryCount: z.number().min(0).max(10000).optional().default(0),
    totalActualHours: z.number().min(0).max(10000).optional().default(0),
    totalActualUsage: z.number().min(0).max(10000).optional().default(0),
    totalActualCost: z.number().min(0).max(10000000).optional().default(0),
    totalActualRevenue: z.number().min(0).max(10000000).optional().default(0),
    longNotes: z.string().max(2048).optional().nullable().default(''),
  })

  static override fromSerialized(recordInput: ActivityAssignmentSerialized): ActivityAssignment {
    const record = ActivityAssignment.schema.parse(recordInput)
    const model = new ActivityAssignment(
      record.id,
      record.name,
      BaseModel.parseDate(record.created),
      BaseModel.parseDate(record.updated),
      record.activityRole,
      record.businessUnit,
      record.resource,
      record.resourcedActivity
    )
    model.rel = record.rel || {}
    model.grade = record.grade || ''
    model.engagementOwner = record.engagementOwner || ''
    model.resourceLocation = record.resourceLocation || ''
    model.resourceStatus = record.resourceStatus || ''
    model.baselineCost = round(record.baselineCost, 2)
    model.baselineUsage = round(record.baselineUsage, 2)
    model.baselineUtilizationPercentage = round(record.baselineUtilizationPercentage, 2)
    model.preferred = !!record.preferred
    model.costRate = round(record.costRate, 2)
    model.defaultCostRate = round(record.defaultCostRate, 2)
    model.defaultCostRatePercentage = round(record.defaultCostRatePercentage, 2)
    model.defaultRevenueRate = round(record.defaultRevenueRate, 2)
    model.defaultRevenueRatePercentage = round(record.defaultCostRatePercentage, 2)
    model.start = BaseModel.parseDate(record.start)
    model.earliestTimeEntry = BaseModel.parseDate(record.earliestTimeEntry)
    model.endingSoon = !!record.endingSoon
    model.entryRemainingUsage = round(record.entryRemainingUsage, 2)
    model.forecastP1Revenue = round(record.forecastP1Revenue, 2)
    model.forecastP1Usage = round(record.forecastP1Usage || 0, 2)
    model.forecastP1End = BaseModel.parseDate(record.forecastP1End)
    model.forecastP2Revenue = round(record.forecastP2Revenue || 0, 2)
    model.forecastP2Usage = round(record.forecastP2Usage || 0, 2)
    model.forecastP2End = BaseModel.parseDate(record.forecastP2End)
    model.forecastP3Revenue = round(record.forecastP3Revenue || 0, 2)
    model.forecastP3Usage = round(record.forecastP3Usage || 0, 2)
    model.forecastP3End = BaseModel.parseDate(record.forecastP3End)
    model.forecastCostRateEffective = BaseModel.parseDate(record.forecastCostRateEffective)
    model.forecastCostRate = round(record.forecastCostRate, 2)
    model.forecastCost = round(record.forecastCost, 2)
    model.timeEntryCount = round(record.timeEntryCount, 2)
    model.totalActualHours = round(record.totalActualHours, 2)
    model.totalActualUsage = round(record.totalActualUsage, 2)
    model.totalActualCost = round(record.totalActualCost, 2)
    model.totalActualRevenue = round(record.totalActualRevenue, 2)
    model.longNotes = record.longNotes || ''

    return model
  }

  static override fromWarehouse(record: ActivityAssignmentWarehouse): ActivityAssignment {
    const model = new ActivityAssignment(
      record.Id,
      record.Name,
      BaseModel.parseDate(record.CreatedDate),
      BaseModel.parseDate(record.LastModifiedDate),
      record.ActivityRole__Name,
      record.BusinessUnit__Name,
      record.Resource__Name,
      record.ResourcedActivity__Name
    )
    model.rel = {
      activityRole: record.KimbleOne__ActivityRole__c,
      businessUnit: record.KimbleOne__BusinessUnit__c,
      resource: record.KimbleOne__Resource__c,
      resourcedActivity: record.KimbleOne__ResourcedActivity__c,
    }
    model.grade = record.KC_Grade__c
    model.engagementOwner = record.KC_Engagement_Owner__c
    model.resourceLocation = record.Resource__Location
    model.resourceStatus = record.KC_Resource_Status__c
    model.baselineCost = round(toNumber(record.KimbleOne__BaselineCost__c), 2)
    model.baselineUsage = round(toNumber(record.KimbleOne__BaselineUsage__c), 2)
    model.baselineUtilizationPercentage = round(record.KimbleOne__BaselineUtilisationPercentage__c, 2)
    model.preferred = BaseModel.parseBoolean(record.KimbleOne__CandidateIsPreferred__c)
    model.costRate = round(record.KimbleOne__CostRate__c, 2)
    model.defaultCostRate = round(record.KimbleOne__DefaultCostRate__c, 2)
    model.defaultCostRatePercentage = round(toNumber(record.KimbleOne__DefaultCostRatePercentage__c), 2)
    model.defaultRevenueRate = round(record.KimbleOne__DefaultRevenueRate__c, 2)
    model.defaultRevenueRatePercentage = round(toNumber(record.KimbleOne__DefaultRevenueRatePercentage__c), 2)
    model.start = BaseModel.parseDate(record.KimbleOne__StartDate__c)
    model.earliestTimeEntry = BaseModel.parseDate(record.KimbleOne__EarliestTimeEntryDate__c)
    model.endingSoon = BaseModel.parseBoolean(record.KimbleOne__EndingSoon__c)
    model.entryRemainingUsage = round(record.KimbleOne__EntryRemainingUsage__c, 2)
    model.forecastP1Revenue = round(toNumber(record.KimbleOne__ForecastP1Revenue__c), 2)
    model.forecastP1Usage = round(record.KimbleOne__ForecastP1Usage__c, 2)
    model.forecastP1End = BaseModel.parseDate(record.KimbleOne__ForecastP1EndDate__c)
    model.forecastP2Revenue = round(toNumber(record.KimbleOne__ForecastP2Revenue__c), 2)
    model.forecastP2Usage = round(record.KimbleOne__ForecastP2Usage__c, 2)
    model.forecastP2End = BaseModel.parseDate(record.KimbleOne__ForecastP2EndDate__c)
    model.forecastP3Revenue = round(toNumber(record.KimbleOne__ForecastP3Revenue__c), 2)
    model.forecastP3Usage = round(record.KimbleOne__ForecastP3Usage__c, 2)
    model.forecastP3End = BaseModel.parseDate(record.KimbleOne__ForecastP3EndDate__c)
    model.forecastCostRateEffective = BaseModel.parseDate(record.KimbleOne__ForecastCostRateEffectiveDate__c)
    model.forecastCostRate = round(record.KimbleOne__ForecastCostRate__c, 2)
    model.forecastCost = round(record.KimbleOne__ForecastCost__c, 2)
    model.timeEntryCount = round(record.KimbleOne__TimeEntryCount__c, 2)
    model.totalActualHours = round(record.KimbleOne__TotalActualHours__c, 2)
    model.totalActualUsage = round(record.KimbleOne__TotalActualUsage__c, 2)
    model.totalActualCost = round(record.KimbleOne__TotalActualCost__c, 2)
    model.totalActualRevenue = round(record.KimbleOne__TotalActualRevenue__c, 2)
    model.longNotes = record.KimbleOne__LongNotes__c

    return model
  }

  override toSerialized(): ActivityAssignmentSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      start: BaseModel.formatDate(this.start, DateFormat.Date),
      earliestTimeEntry: BaseModel.formatDate(this.earliestTimeEntry, DateFormat.Date),
      forecastP1End: BaseModel.formatDate(this.forecastP1End, DateFormat.Date),
      forecastP2End: BaseModel.formatDate(this.forecastP2End, DateFormat.Date),
      forecastP3End: BaseModel.formatDate(this.forecastP3End, DateFormat.Date),
      forecastCostRateEffective: BaseModel.formatDate(this.forecastCostRateEffective, DateFormat.Date),
    } as unknown as ActivityAssignmentSerialized
  }

  project(projection: string[]): Partial<ActivityAssignmentSerialized> {
    const serialized = this.toSerialized()
    return projection?.length ? pick(serialized, projection) : serialized
  }
}
