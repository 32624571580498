import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { toast } from 'react-toastify'

import { Option } from '@athena/component/atom/combobox'
import { DateRange } from '@athena/core/model'
import Employee from '@athena/core/model/employee'

import { useIdentity } from '@apollo/client/hook/auth'
import { useGetEngagementsDateRange } from '@apollo/client/hook/engagement'
import { useGetDistinctRevueObjectiveCreationYears } from '@apollo/client/hook/revue'
import { useSAPSearch } from '@apollo/client/hook/sap-search'
import { useEmployee, useEmployeeByEmail, useResourceDept } from '@artemis/client/hook'
import PeerMetrics from '@future-view/frontend/page/peer-metrics'
import ManagerRevue from '@future-view/frontend/page/revue'
import { generateYearOptions, generateYearOptionsFromArray, optionAll } from '@future-view/frontend/util'

import Header from './header'
import Style from './style.module.scss'

const dataTestContainer = 'peer-details'
const PeerDetails = () => {
  const [selectedYearOption, setSelectedYearOption] = useState<Option | null>(null)
  const [yearOptions, setYearOptions] = useState<Option[]>([])
  const [directReportOptions, setDirectReportOptions] = useState<Option[]>([])
  const [selectedDirectReportOption, setSelectedDirectReportOption] = useState<Option | null>(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const navigate = useNavigate()
  const { id } = useParams()
  const employee = useEmployee(id || '')?.data?.data
  const engagementsDateRange = useGetEngagementsDateRange(employee?.employeeId || '')
  const revueObjectivesCreationYears = useGetDistinctRevueObjectiveCreationYears(employee?.employeeId || '')
  const identity = useIdentity()
  const loggedInUserEmail = identity?.data?.data?.email
  const { data: loggedInEmployee } = useEmployeeByEmail(loggedInUserEmail?.toLowerCase() || '')
  const { data: levelResponse } = useResourceDept(id || '')
  const levelData = levelResponse?.data
  const directReportsData = useSAPSearch('', '', '', loggedInEmployee?.data?.employeeId || '', '', '')

  useEffect(() => {
    const directReports = directReportsData?.data?.data || []
    if (engagementsDateRange.data) {
      const drOptions = directReports
        .filter((dr: Employee) => dr.directMgrEmployeeId === loggedInEmployee?.data?.employeeId)
        .map((dr: Employee) => ({ label: `${dr.preferredFirstName} ${dr.preferredLastName}`, value: dr.employeeId }))

      setDirectReportOptions(drOptions as Option[])
      const defaultDirectReport = drOptions.find((o) => o.value === id) || null
      setSelectedDirectReportOption(defaultDirectReport)
    }

    if (engagementsDateRange.error) {
      toast.error('Error loading engagements')
    }
    if (directReportsData.error) {
      toast.error('Error loading Direct Reports data')
    }
  }, [
    engagementsDateRange.data,
    engagementsDateRange.error,
    id,
    directReportsData.data,
    directReportsData.error,
    loggedInEmployee?.data?.employeeId,
  ])

  const handleTabSelect = (index: number): boolean | void => {
    setSelectedTabIndex(index)
    setYearSelector(index)
  }

  const handleDirectReportChange = (val: Option) => {
    navigate(`/peerspective/peerdetails/${val?.value}`)
  }

  const handleYearSelect = (val: Option) => {
    setSelectedYearOption(val)
  }

  const setYearSelector = useCallback(
    (tabIndex: number) => {
      if (tabIndex === 0 && engagementsDateRange.data) {
        const dateRange = engagementsDateRange.data.data as DateRange
        if (!dateRange) {
          setYearOptions([optionAll])
          setSelectedYearOption(optionAll)
          return
        }
        const startYear = DateTime.fromISO(dateRange.startDate).year
        const endYear = DateTime.fromISO(dateRange.endDate).year
        const yearOps = generateYearOptions(startYear, endYear, true)
        setYearOptions(yearOps)
        if (!selectedYearOption) {
          const defaultYear = yearOps.find((o) => o.value === new Date().getFullYear().toString()) || optionAll
          setSelectedYearOption(defaultYear)
        } else if (!yearOps.find((o) => o.value === selectedYearOption?.value)) {
          setSelectedYearOption(optionAll)
        }
      } else if (tabIndex === 1 && revueObjectivesCreationYears.data) {
        const years = revueObjectivesCreationYears.data.data as string[]
        const yearOps = generateYearOptionsFromArray(years)
        setYearOptions(yearOps)
        const firstYearOption = yearOps.length > 0 ? yearOps[0] : null
        if (!selectedYearOption) {
          setSelectedYearOption(firstYearOption)
        } else if (!yearOps.find((o) => o.value === selectedYearOption?.value)) {
          setSelectedYearOption(firstYearOption)
        }
      }
    },
    [engagementsDateRange.data, revueObjectivesCreationYears.data, selectedYearOption]
  )

  useEffect(() => {
    setYearSelector(selectedTabIndex)
  }, [selectedTabIndex, engagementsDateRange.data, revueObjectivesCreationYears.data, setYearSelector])

  return (
    <div>
      <Header
        data-test={`${dataTestContainer}.header`}
        defaultYear={selectedYearOption}
        directReportOptions={directReportOptions}
        employeeId={employee?.employeeId || ''}
        employeeLevel={levelData?.division?.split('- ').pop() || ''}
        selectedDirectReportOption={selectedDirectReportOption}
        yearOptions={yearOptions}
        onDirectReportChange={handleDirectReportChange}
        onYearChange={handleYearSelect}
      />
      <Tabs defaultIndex={0} onSelect={handleTabSelect}>
        <TabList>
          <Tab>Peerspective</Tab>
          <Tab>Revue</Tab>
        </TabList>
        <hr className={Style.tabBorder}></hr>
        <TabPanel>
          <PeerMetrics
            data-test={`${dataTestContainer}.peer-metrics`}
            year={selectedYearOption?.value ?? ''}
          ></PeerMetrics>
        </TabPanel>
        <TabPanel>
          <ManagerRevue
            data-test={`${dataTestContainer}.revue`}
            employeeName={employee?.name || ''}
            showNewObjective={false}
            source={loggedInEmployee?.data?.employeeId || ''}
            target={id || ''}
            year={selectedYearOption?.value ?? ''}
          ></ManagerRevue>
        </TabPanel>
      </Tabs>
    </div>
  )
}
export default PeerDetails
