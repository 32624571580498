import classnames from 'classnames'
import { debounce, isFunction } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import Button, { ButtonFill } from '@athena/component/atom/button'
import { IconName } from '@athena/component/atom/icon'

import Props, { SavingStatus } from './interface'
import Style from './style.module.scss'

const cx = classnames.bind(Style)

const Textarea = ({
  'data-test': dataTest = 'textarea',
  customClasses = '',
  disabled = false,
  id = '',
  help = '',
  label = '',
  maxSymbols = 0,
  placeholder = '',
  rows = 2,
  valid = true,
  value = '',
  isAutogrow = true,
  isAutoSave = false,
  retryInterval = 3000,
  autoSaveInterval = 2000,
  retryCt = 3,
  onChange = undefined,
  onBlur = undefined,
  onSave = undefined,
  onSavingStatusChange = undefined,
  onFocus = undefined,
}: Props) => {
  const [focused, setFocused] = useState(false)
  const [currentValue, setValue] = useState(value)
  const [symbolCount, setSymbolCount] = useState(value?.length || 0)
  const [savingStatus, setSavingStatus] = useState(SavingStatus.Saved)
  const [hasChanged, setHasChanged] = useState(false)
  const onSaveRef = useRef(onSave)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const retryCtRef = useRef(retryCt)
  const retryTimeoutRef = useRef<NodeJS.Timeout>()
  const isUnmountedRef = useRef(false)

  // to show the correct symbol count on initial load
  useEffect(() => {
    setValue(value)
  }, [value])

  useEffect(() => {
    onSaveRef.current = onSave
  }, [onSave])

  useEffect(() => {
    isFunction(onSavingStatusChange) && onSavingStatusChange(savingStatus, id)
  }, [savingStatus, id])

  useEffect(() => {
    setValue(currentValue)
    setSymbolCount(currentValue.length)
    isFunction(onFocus) && onFocus(focused, id)
    focused && inputRef.current?.focus()
  }, [focused, currentValue])

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let currentText = event.target.value
    if (isAutogrow) {
      event.target.style.height = '100%'
      const scrollHeight = event.target.scrollHeight
      event.target.style.height = scrollHeight + 'px'
    }
    if (maxSymbols > 0 && currentText.length > maxSymbols) {
      currentText = currentText.substring(0, maxSymbols)
    }
    setValue(currentText)
    setHasChanged(true)
    if (isAutoSave) {
      setSavingStatus(SavingStatus.NotSaved)
      debouncedAutoSave(currentText)
    }
    isFunction(onChange) && onChange(currentText, id)
  }

  const handleFocus = () => !disabled && setFocused(true)
  const handleBlur = () => {
    setFocused(false)
    isFunction(onBlur) && onBlur(currentValue, id)
  }

  const autoSave = useCallback(
    async (text: string) => {
      try {
        setSavingStatus(SavingStatus.Saving)
        if (isFunction(onSaveRef.current)) {
          if (!isUnmountedRef.current) {
            await onSaveRef.current(text, id)
            setSavingStatus(SavingStatus.Saved)
            retryCtRef.current = retryCt
          }
        }
      } catch (error) {
        setSavingStatus(SavingStatus.Failed)
        if (retryCtRef.current > 0 && !isUnmountedRef.current) {
          retryTimeoutRef.current = setTimeout(async () => {
            retryCtRef.current -= 1
            await autoSave(text)
          }, retryInterval)
        }
      }
    },
    [id, retryCt, retryInterval]
  )
  const debouncedAutoSave = useRef(debounce(autoSave, autoSaveInterval)).current

  //unmount gracefully
  useEffect(() => {
    isUnmountedRef.current = false
    return () => {
      debouncedAutoSave.cancel()
      isUnmountedRef.current = true
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current)
      }
    }
  }, [])

  const savingStatusClass = classnames({
    [Style.saved]: savingStatus === SavingStatus.Saved,
    [Style.saving]: savingStatus === SavingStatus.Saving,
    [Style.notSaved]: savingStatus === SavingStatus.Failed,
  })
  return (
    <div className={cx([Style.textarea, customClasses])} data-test={`${dataTest}.container`}>
      <div
        data-test={`${dataTest}.main-block`}
        className={cx([Style.mainBlock, customClasses], {
          [Style.active]: focused,
          [Style.invalid]: !valid,
          [Style.disabled]: disabled,
        })}
      >
        <span
          className={cx([Style.label], { [Style.emptyValue]: !focused && !currentValue, [Style.invalid]: !valid })}
          data-test={`${dataTest}.label`}
        >
          {label}
        </span>
        <textarea
          className={cx({ [Style.invalid]: !valid, 'overflow-hidden': isAutogrow })}
          data-test={`${dataTest}.input`}
          disabled={disabled}
          placeholder={placeholder}
          rows={rows}
          value={currentValue}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
        ></textarea>
        <div className={Style.infobar}>
          {isAutoSave && hasChanged && (
            <>
              {savingStatus === SavingStatus.Failed && retryCtRef.current < 1 && (
                <Button
                  customClasses={Style.retryButton}
                  data-test={`${dataTest}.retry`}
                  fill={ButtonFill.Flat}
                  icon={IconName.ArrowPathRoundedSquare}
                  onClick={() => autoSave(currentValue)}
                ></Button>
              )}
              <span className={savingStatusClass} data-test={`${dataTest}.saving-status`}>
                {savingStatus}
              </span>
            </>
          )}
          {maxSymbols > 0 && (
            <span className={Style.symbolCount} data-test={`${dataTest}.symbol-count`}>
              {symbolCount}/{maxSymbols}
            </span>
          )}
        </div>
      </div>
      {help && (
        <div>
          <span className={cx([Style.helperText, { [Style.invalid]: !valid }])} data-test={`${dataTest}.help`}>
            {help}
          </span>
        </div>
      )}
    </div>
  )
}
export default Textarea

export type { Props }
