import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { getReviewCompletionReport, getRevueReport } from '@apollo/client/api/reports'

export const QueryKey = {
  ReviewCompletionReport: 'review-completion-report',
  RevueReport: 'revue-report',
}

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //

export const useGetReviewCompletionReport = () =>
  useQuery({
    queryKey: [QueryKey.ReviewCompletionReport],
    queryFn: getReviewCompletionReport,
  })

export const useGetRevueReport = () => {
  return useMutation({
    mutationFn: getRevueReport,
    onError: (err) => toast.error(`Error getting report: ${err}`),
  })
}
// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //
