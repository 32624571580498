import { toLower, toNumber, trim } from 'lodash'

const Environment = {
  isDev: toLower(trim(process.env['NODE_ENV'])) !== 'production',
  futureView: {
    host: trim(process.env['FV_HOST']),
    activeDirectoryLogoutUrl: trim(process.env['FV_AD_LOGOUT_URL']),
  },
  apollo: {
    host: trim(process.env['APL_API_HOST']),
    timeout: toNumber(process.env['APL_API_TIMEOUT'] || '30000'),
  },
}

export default Environment
