import { isFunction } from 'lodash'

import Button, { ButtonFill, IconName } from '@athena/component/atom/button'
import Typography, { TypographySize } from '@athena/component/atom/typography'
import { Quantum } from '@athena/core/model'

import { PeerReviewPanelProps } from './interface'
import Style from './style.module.scss'

const dataTestContainer = 'engagement-peer-reviews.container'

const formatDate = (date: Quantum<Date>) => {
  if (!date) {
    return 'N/A'
  }

  const dayOfWeekOptions: Intl.DateTimeFormatOptions = { weekday: 'long' }
  const monthOptions: Intl.DateTimeFormatOptions = { month: 'long' }
  const dayOptions: Intl.DateTimeFormatOptions = { day: 'numeric' }

  const dayOfWeek = new Intl.DateTimeFormat('en-US', dayOfWeekOptions).format(date)
  const month = new Intl.DateTimeFormat('en-US', monthOptions).format(date)
  const day = new Intl.DateTimeFormat('en-US', dayOptions).format(date)

  let daySuffix = 'th'
  if (day.endsWith('1') && !day.endsWith('11')) {
    daySuffix = 'st'
  } else if (day.endsWith('2') && !day.endsWith('12')) {
    daySuffix = 'nd'
  } else if (day.endsWith('3') && !day.endsWith('13')) {
    daySuffix = 'rd'
  }

  return `${dayOfWeek} - ${month} ${day}${daySuffix}, ${date.getFullYear()}`
}

export const PeerReviewPanel = ({
  lastDate,
  nextDate,
  finalDate,
  startPeerReviewDisabled,
  onStartPeerReviewClick,
}: PeerReviewPanelProps): JSX.Element => {
  return (
    <div className={Style.peerReviewsContainer}>
      <Typography customClass={Style.header} data-test={`${dataTestContainer}.form-header`} size={TypographySize.Large}>
        Peer Reviews
      </Typography>
      <div>
        <div className={Style.peerReview}>
          <Typography
            customClass={Style.label}
            data-test={`${dataTestContainer}.peer-review-title`}
            size={TypographySize.ExtraSmall}
          >
            Last Peer Review
          </Typography>
          <span className={Style.date}>{formatDate(lastDate)}</span>
        </div>
        <div className={Style.peerReview}>
          <Typography
            customClass={Style.label}
            data-test={`${dataTestContainer}.peer-review-title`}
            size={TypographySize.ExtraSmall}
          >
            Next Peer Review
          </Typography>
          <span className={Style.date}>{formatDate(nextDate)}</span>
        </div>
        <div className={Style.peerReview}>
          <Typography
            customClass={Style.label}
            data-test={`${dataTestContainer}.peer-review-title`}
            size={TypographySize.ExtraSmall}
          >
            Final Peer Review
          </Typography>
          <span className={Style.date}>{formatDate(finalDate)}</span>
        </div>
        <Button
          customClasses={Style.startManually}
          data-test={`${dataTestContainer}.peer-review-button`}
          disabled={startPeerReviewDisabled}
          fill={ButtonFill.Outline}
          icon={IconName.DocumentText}
          onClick={() => isFunction(onStartPeerReviewClick) && onStartPeerReviewClick()}
        >
          Start Peer Review Manually
        </Button>
      </div>
    </div>
  )
}
