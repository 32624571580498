import { groupBy } from 'lodash'
import { useCallback, useContext, useEffect, useState } from 'react'

import Modal from '@athena/component/molecule/modal'
import { FeedbackRequest, FeedbackRequestState } from '@athena/core/model'

import { useIdentity } from '@apollo/client/hook/auth'
import { useFeedbackRequests } from '@apollo/client/hook/engagement'
import { useSAPSearch } from '@apollo/client/hook/sap-search'
import { useEmployeeByEmail } from '@artemis/client/hook'
import RequestList from '@future-view/frontend/component/request-list'
import SurveyForm from '@future-view/frontend/component/survey-form'
import useEmployeeMap from '@future-view/frontend/hook/use-employee-map'
import { refreshContext } from '@future-view/frontend/page/layout/ReferenceDataContext'

import Style from './style.module.scss'

type FeedbackRecord = {
  [fieldName: string]: FeedbackRequest[]
}

const PeerReviewPage = () => {
  const resourceList = useEmployeeMap()

  const { data: identityData } = useIdentity()
  const { data: resourceResponse } = useEmployeeByEmail(identityData?.data?.email?.toLowerCase() || '')
  // get the feedbacks given for the direct reports of the logged-in user
  const types = [FeedbackRequestState.Pending, FeedbackRequestState.AwaitingApproval, FeedbackRequestState.Completed]
  const { data: sapSearch } = useSAPSearch('', '', '', resourceResponse?.data?.employeeId || '', '', '')
  const feedbackRequestResponse = useFeedbackRequests(
    sapSearch?.data
      ?.filter((item) => item.directMgrEmployeeId === resourceResponse?.data?.employeeId)
      .map((item) => {
        return item.employeeId
      }) || [],
    types,
    resourceResponse?.data?.employeeId || '',
    false
  )
  const { data: feedbackRequestResponseData } = feedbackRequestResponse

  const [requestList, setRequestList] = useState<FeedbackRequest[]>()
  const [requestListGrouped, setRequestListGrouped] = useState<FeedbackRecord>({})
  useEffect(() => {
    if (!feedbackRequestResponseData?.data) {
      return
    }
    feedbackRequestResponseData?.data.filter((request) => types.includes(request.state)) ?? []
    setRequestList(feedbackRequestResponseData?.data)
  }, [feedbackRequestResponseData?.data])

  const { refreshKey, setRefreshKey } = useContext(refreshContext)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [pausedModal, setPausedModal] = useState(false)
  const [currentRequest, setCurrentRequest] = useState<FeedbackRequest | null>(null)

  const handleClick = useCallback((request: FeedbackRequest) => {
    setShowFeedbackModal(true)
    setCurrentRequest(request)
  }, [])

  useEffect(() => {
    if (!requestList) {
      return
    }
    setRequestListGrouped(groupBy(requestList, 'state'))
  }, [requestList])

  const handleSurveyModalClose = useCallback(
    (state?: FeedbackRequestState) => {
      if (state) {
        setRefreshKey(Math.ceil(Math.random() * 1000))
        if (requestList) {
          setRequestList(
            requestList.map((feedbackRequest: FeedbackRequest) => {
              if (currentRequest?.id === feedbackRequest.id) {
                feedbackRequest.state = state
              }
              return feedbackRequest
            })
          )
        }
      }
      setShowFeedbackModal(false)
      setCurrentRequest(null)
    },
    [currentRequest, requestList]
  )

  return (
    <div data-test="peer-review-page.container">
      {
        <RequestList
          data-test="pending-feedback-request-list"
          feedbackData={requestListGrouped[FeedbackRequestState.Pending] || []}
          handleClick={handleClick}
          isForManager={false}
          loading={feedbackRequestResponse.isLoading}
          resourceList={resourceList}
          types={[FeedbackRequestState.Pending]}
        />
      }
      {
        <RequestList
          data-test="awaiting-feedback-request-list"
          feedbackData={requestListGrouped[FeedbackRequestState.AwaitingApproval] || []}
          handleClick={handleClick}
          isForManager={false}
          loading={feedbackRequestResponse.isLoading}
          resourceList={resourceList}
          types={[FeedbackRequestState.AwaitingApproval]}
        />
      }
      {
        <RequestList
          data-test="completed-feedback-request-list"
          feedbackData={requestListGrouped[FeedbackRequestState.Completed] || []}
          handleClick={handleClick}
          isForManager={false}
          loading={feedbackRequestResponse.isLoading}
          resourceList={resourceList}
          types={[FeedbackRequestState.Completed]}
        />
      }
      {currentRequest && (
        <Modal
          hideCloseButton
          data-test="peer-review-page.modal"
          isShown={showFeedbackModal}
          modalCustomClasses={Style.surveyModalContentWrapper}
          wrapperCustomClasses={pausedModal ? Style.surveyModalWrapperPaused : Style.surveyModalWrapper}
        >
          <SurveyForm
            data-test="feedback-survey-form"
            hideModal={handleSurveyModalClose}
            isForManager={false}
            pauseModal={setPausedModal}
            request={currentRequest}
          />
        </Modal>
      )}
    </div>
  )
}

export default PeerReviewPage
