import { assignIn } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, Quantum, Schema } from '@athena/core/model/base-model'

export type InterestSerialized = z.infer<typeof Interest.schema>

export type InterestStore = BaseModelStore & {
  id_resource: string
  id_resourced_activity: string
  revoked: string | null
  note: string
}

export default class Interest extends BaseModel {
  rel = {
    resourcedActivity: '',
    resource: '',
  }
  revoked: Quantum<Date>
  note = ''

  constructor(id = '', resource = '', resourcedActivity = '') {
    super(id, '', undefined, undefined)
    this.rel = { resource, resourcedActivity }
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      resource: Schema.Id,
      resourcedActivity: Schema.Id,
    }),
    revoked: Schema.DateTime,
    note: z.string().max(256).nullable().default(''),
  })

  static override fromSerialized(recordInput: InterestSerialized): Interest {
    const record = Interest.schema.parse(recordInput)
    const model = new Interest(record.id, record.rel.resource, record.rel.resourcedActivity)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.revoked = BaseModel.parseDate(record.revoked)
    model.note = record.note || ''
    return model
  }

  static override fromDataStore(record: InterestStore): Interest {
    const model = new Interest(record.id, record.id_resource, record.id_resourced_activity)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.revoked = BaseModel.parseDate(record.revoked)
    model.note = record.note || ''
    return model
  }

  override toSerialized(): InterestSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      revoked: BaseModel.formatDate(this.revoked),
    } as unknown as InterestSerialized
  }

  override toDataStore(): InterestStore {
    return {
      ...super.toDataStore(),
      id_resource: this.rel.resource,
      id_resourced_activity: this.rel.resourcedActivity,
      revoked: BaseModel.formatDate(this.revoked),
      note: this.note,
    }
  }
}
