import classNames from 'classnames'
import { isFunction } from 'lodash'
import React, { useCallback, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'

import Button, { ButtonFill, IconName } from '@athena/component/atom/button'

import Props from './interface'
import Style from './style.module.scss'

const Modal: React.FC<Props> = ({
  'data-test': dataTest = 'modal',
  children,
  headerIcon,
  headerText,
  headerToolbar,
  isShown,
  modalCustomClasses = '',
  hideCloseButton = false,
  wrapperCustomClasses = '',
  closeIcon = IconName.XMark,
  onHide,
}) => {
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.key === 'Escape' && isShown && isFunction(onHide)) {
        onHide()
      }
      event.stopPropagation()
    },
    [onHide, isShown]
  )

  useLayoutEffect(() => {
    document.body.style.overflow = isShown ? 'hidden' : 'auto'
  }, [isShown])

  const modal = (
    <div className={Style.modal} data-test={`${dataTest}.container`} onKeyDown={handleKeyDown}>
      <div aria-hidden className={Style.backdrop} data-test={`${dataTest}.backdrop`} onClick={onHide} />
      <FocusLock>
        <div
          aria-modal
          aria-labelledby={headerText}
          className={classNames(Style.wrapper, wrapperCustomClasses)}
          role="dialog"
          tabIndex={-1}
        >
          <div className={classNames(Style.styledModal, modalCustomClasses)}>
            {(!hideCloseButton || headerText || headerToolbar) && (
              <div className={Style.header}>
                {headerIcon}
                <div className={Style.headerText} data-test={`${dataTest}.header`}>
                  {headerText}
                </div>
                <div className={Style.headerToolbar} data-test={`${dataTest}.toolbar`}>
                  {headerToolbar}
                </div>
                {!hideCloseButton && (
                  <Button
                    basicOutline
                    data-test={`${dataTest}.hide`}
                    fill={ButtonFill.Flat}
                    icon={closeIcon}
                    onClick={onHide}
                  />
                )}
              </div>
            )}
            <div className={Style.content} data-test={`${dataTest}.content`}>
              {children}
            </div>
          </div>
        </div>
      </FocusLock>
    </div>
  )

  return ReactDOM.createPortal(<>{isShown ? modal : null}</>, document.body)
}

export default Modal
export type { Props }
