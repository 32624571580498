import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'

import Props, { TooltipDirection } from './interface'
import Style from './style.module.scss'

const Tooltip: React.FC<Props> = ({
  'data-test': dataTest = 'tooltip',
  children,
  content,
  delay = 0,
  direction = TooltipDirection.Top,
}) => {
  let timeout: ReturnType<typeof setTimeout>
  const [active, setActive] = useState(false)

  const showTip = () => {
    timeout = setTimeout(() => setActive(true), delay)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: direction,
    modifiers: [{ name: 'offset', options: { offset: [0, 4] } }],
  })

  return (
    <>
      <div ref={setReferenceElement} data-test={`${dataTest}.container`} onMouseEnter={showTip} onMouseLeave={hideTip}>
        {children}
      </div>
      {active &&
        ReactDOM.createPortal(
          <div
            ref={setPopperElement}
            className={Style.tip}
            data-test={`${dataTest}.portal`}
            style={styles['popper']}
            {...attributes['popper']}
          >
            {content}
          </div>,
          document.body
        )}
    </>
  )
}

export default Tooltip
export type { Props }
