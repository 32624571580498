import { assignIn, map, round, toNumber } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelWarehouse, DateFormat, Quantum, Schema } from './base-model'
import ResourceCapability, { ResourceCapabilityProjection } from './resource-capability'
import User, { UserProjection } from './user'

export type ResourceSerialized = z.infer<typeof Resource.schema>

export type ResourceWarehouse = BaseModelWarehouse & {
  KimbleOne__ActivityRole__c: string
  KimbleOne__BusinessUnit__c: string
  KimbleOne__Grade__c: string
  KimbleOne__User__c: string
  ActivityRole__Name: string
  BusinessUnit__Name: string
  Grade__Name: string
  ConnectION_ID__c: string
  KC_Hours_Per_Day__c: number
  KC_Resource_Department__c: string
  KimbleOne__AvailabilityScore__c: string
  KimbleOne__EffectiveDate__c: string
  KimbleOne__ExpenseReimbursementCurrencyIsoCode__c: string
  KimbleOne__FirstName__c: string
  KimbleOne__LastName__c: string
  KimbleOne__StandardCost__c: number
  KimbleOne__StandardRevenueCurrencyISOCode__c: string
  KimbleOne__StandardRevenue__c: string
  Reporting_Region__c: string
  Resource_Country__c: string
  Resource_state__c: string
  Lab49_Pricing_Location__c: string
  Lab49_Region_Capability__c: string
  Lab49_Primary_Role_Pricing_Location__c: string
  Lab49_Headcount__c: number
  Lab49_Primary_Capability__c: string
}

export default class Resource extends BaseModel {
  rel = {
    activityRole: '',
    businessUnit: '',
    grade: '',
    user: '',
  }
  user: Quantum<User>
  capabilities: ResourceCapability[] = []
  activityRole = ''
  businessUnit = ''
  grade = ''
  connectionId = ''
  hoursPerDay = 0
  department = ''
  availabilityScore = ''
  effective: Quantum<Date>
  expenseReimbursementCurrency = ''
  firstName = ''
  lastName = ''
  standardCost = 0
  standardRevenueCurrency = ''
  standardRevenue = 0
  region = ''
  country = ''
  state = ''
  pricingLocation = ''
  regionCapability = ''
  primaryRolePricingLocation = ''
  headCount = 0
  primaryCapability = ''

  constructor(
    id: string,
    name: string,
    created: Quantum<Date>,
    updated: Quantum<Date>,
    activityRole: string,
    businessUnit: string,
    grade: string,
    connectionId: string
  ) {
    super(id, name, created, updated)
    this.activityRole = activityRole || ''
    this.businessUnit = businessUnit || ''
    this.grade = grade || ''
    this.connectionId = connectionId || ''
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      activityRole: Schema.Id,
      businessUnit: Schema.Id,
      grade: Schema.Id,
      user: Schema.Id,
    }),
    activityRole: Schema.Name,
    businessUnit: Schema.Name,
    grade: Schema.Name,
    connectionId: z.string().max(32).default(''),
    hoursPerDay: z.string().max(10).default('0'),
    department: z.string().max(64).nullable(),
    availabilityScore: z.string().max(32).nullable().optional(),
    effective: z.string().max(16).default(''),
    expenseReimbursementCurrency: z.string().max(3).nullable(),
    firstName: z.string().max(32).default(''),
    lastName: z.string().max(32).default(''),
    standardCost: z.number().min(0).max(1_000_000).default(0),
    standardRevenueCurrency: z.string().max(3).nullable(),
    standardRevenue: z.number().min(0).max(1_000_000).default(0),
    region: z.string().max(6).nullable(),
    country: z.string().max(32).nullable(),
    state: z.string().max(32).nullable(),
    pricingLocation: z.string().max(32).default(''),
    regionCapability: z.string().max(32).default(''),
    primaryRolePricingLocation: z.string().max(64).default(''),
    headCount: z.string().max(10).default('0'),
    primaryCapability: z.string().max(64).nullable(),
  })

  static override fromSerialized(recordInput: ResourceSerialized): Resource {
    const record = Resource.schema.parse(recordInput)
    const model = new Resource(
      record.id,
      record.name,
      BaseModel.parseDate(record.created),
      BaseModel.parseDate(record.updated),
      record.activityRole,
      record.businessUnit,
      record.grade,
      record.connectionId
    )
    model.rel = record.rel || {}
    model.hoursPerDay = round(toNumber(record.hoursPerDay), 2)
    model.department = record.department || ''
    model.availabilityScore = record.availabilityScore || ''
    model.effective = BaseModel.parseDate(record.effective)
    model.expenseReimbursementCurrency = record.expenseReimbursementCurrency || ''
    model.firstName = record.firstName
    model.lastName = record.lastName
    model.standardCost = round(record.standardCost, 2)
    model.standardRevenueCurrency = record.standardRevenueCurrency || ''
    model.standardRevenue = round(record.standardRevenue, 2)
    model.region = record.region || ''
    model.country = record.country || ''
    model.state = record.state || ''
    model.pricingLocation = record.pricingLocation
    model.regionCapability = record.regionCapability
    model.primaryRolePricingLocation = record.primaryRolePricingLocation
    model.headCount = round(toNumber(record.headCount), 2)
    model.primaryCapability || ''

    return model
  }

  static override fromWarehouse(record: ResourceWarehouse): Resource {
    const model = new Resource(
      record.Id,
      record.Name,
      BaseModel.parseDate(record.CreatedDate),
      BaseModel.parseDate(record.LastModifiedDate),
      record.ActivityRole__Name,
      record.BusinessUnit__Name,
      record.Grade__Name,
      record.ConnectION_ID__c
    )
    model.rel = {
      activityRole: record.KimbleOne__ActivityRole__c,
      businessUnit: record.KimbleOne__BusinessUnit__c,
      grade: record.KimbleOne__Grade__c,
      user: record.KimbleOne__User__c,
    }
    model.hoursPerDay = record.KC_Hours_Per_Day__c
    model.department = record.KC_Resource_Department__c
    model.availabilityScore = record.KimbleOne__AvailabilityScore__c?.toString()
    model.effective = BaseModel.parseDate(record.KimbleOne__EffectiveDate__c)
    model.expenseReimbursementCurrency = record.KimbleOne__ExpenseReimbursementCurrencyIsoCode__c
    model.firstName = record.KimbleOne__FirstName__c
    model.lastName = record.KimbleOne__LastName__c
    model.standardCost = round(record.KimbleOne__StandardCost__c, 2)
    model.standardRevenueCurrency = record.KimbleOne__StandardRevenueCurrencyISOCode__c
    model.standardRevenue = round(toNumber(record.KimbleOne__StandardRevenue__c), 2)
    model.region = record.Reporting_Region__c
    model.country = record.Resource_Country__c
    model.state = record.Resource_state__c
    model.pricingLocation = record.Lab49_Pricing_Location__c
    model.regionCapability = record.Lab49_Region_Capability__c
    model.primaryRolePricingLocation = record.Lab49_Primary_Role_Pricing_Location__c
    model.headCount = record.Lab49_Headcount__c
    model.primaryCapability = record.Lab49_Primary_Capability__c

    return model
  }

  override toSerialized(): ResourceSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      user: this.user?.project(UserProjection.Embed),
      capabilities: map(this.capabilities, (c) => c.project(ResourceCapabilityProjection.Embed)),
      effective: BaseModel.formatDate(this.effective, DateFormat.Date),
    } as unknown as ResourceSerialized
  }
}
