import React from 'react'

export enum TagSize {
  Small = 'small',
  Large = 'large',
}

export enum TagVariant {
  Light = 'light',
  Dark = 'dark',
  Outline = 'outline',
}

export default interface Props {
  'data-test': string
  'data-test-arg': string
  disabled?: boolean
  children?: React.ReactNode
  customClasses?: string
  hasClose?: boolean
  icon?: boolean
  interactive?: boolean
  size?: TagSize
  variant?: TagVariant
  onClose?: () => void
}
