import RevueObjective, { RevueObjectiveTitle } from '@athena/core/model/revue-objective'

import Style from './style.module.scss'

export const getColor = (pct: number) => {
  if (pct >= 0 && pct <= 30) {
    return Style.red
  } else if (pct > 31 && pct <= 60) {
    return Style.orange
  } else if (pct > 60 && pct < 100) {
    return Style.green
  } else if (pct === 100) {
    return Style.green100
  }
  return Style.red
}

export const getDescription = (objectiveData: RevueObjective, isManager: boolean, html = false) => {
  if (objectiveData?.title === 'Summary of Accomplishments' && isManager) {
    if (html) {
      return `Outline primary achievements and any additional accomplishments.
      Recommend your direct report for promotion if they meet the criteria outlined in the <a
      className={Style.href}
      href="https://iontradingcom.sharepoint.com/teams/Lab49TalentProjects/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FLab49TalentProjects%2FShared%20Documents%2FShared%5FPeople%20Manager%20resources%2FPerformance%20management%2FCareer%20Framework%5FOctober2022%2Epdf&parent=%2Fteams%2FLab49TalentProjects%2FShared%20Documents%2FShared%5FPeople%20Manager%20resources%2FPerformance%20management&p=true&ga=1"
      rel="noreferrer"
      target="_blank"
    >Career Framework
    </a>. Refer to it for specific requirements and examples for each level.`
    }
    return `Outline primary achievements and any additional accomplishments.
      Recommend your direct report for promotion if they meet the criteria outlined in the Career Framework. Refer to it for specific requirements and examples for each level.`
  }
  return objectiveData?.description
}

export const getTitle = (objectiveData: RevueObjective, isManager: boolean) => {
  if (objectiveData?.title === RevueObjectiveTitle.SUMMARY_OF_ACCOMPLISHMENTS && isManager) {
    return 'Summary of Accomplishments and Promotion'
  }
  return objectiveData?.title
}

export const getLocal = (key: string) => {
  let localData: any = localStorage.getItem('revue')
  if (localData === null) {
    return null
  }
  localData = JSON.parse(localData)[key]
  return localData
}

export const setLocal = (data: any, key: string) => {
  let localData: any = localStorage.getItem('revue')
  if (localData === null) {
    localData = {}
  } else {
    localData = JSON.parse(localData)
  }
  localData[key] = data
  localStorage.setItem('revue', JSON.stringify(localData))
}

export const deleteLocal = (key: string) => {
  let localData: any = localStorage.getItem('revue')
  if (localData === null) {
    localData = {}
  } else {
    localData = JSON.parse(localData)
  }
  delete localData[key]
  localStorage.setItem('revue', JSON.stringify(localData))
}
