import classNames from 'classnames'
import { mergeWith, cloneDeep, isNil, isArray, omit } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Button, { ButtonColor, ButtonFill } from '@athena/component/atom/button'
import Combobox, { Option } from '@athena/component/atom/combobox'
import { IconName } from '@athena/component/atom/icon'
import Switch from '@athena/component/atom/switch'
import Textarea from '@athena/component/atom/textarea'
import Modal from '@athena/component/molecule/modal'
import RadioGroup from '@athena/component/molecule/radio-group'
import { Feedback, FeedbackRequestState, FeedbackRank, StrengthImprov } from '@athena/core/model'
import { Skill } from '@athena/core/model/skill'

import {
  useAddFeedback,
  useUpdateFeedbackRequest,
  useFeedbackByFeedbackRequest,
  useUpdateFeedback,
} from '@apollo/client/hook/engagement'
import { useEmployee } from '@artemis/client/hook'
import Avatar from '@future-view/frontend/component/avatar'
import ConfirmationDialog from '@future-view/frontend/component/confirm-dialog'
import { getEnumByValue } from '@future-view/frontend/util'

import { ClickMatrix } from './click-matrix'
import Props, { SkillState } from './interface'
import JustificationSelector from './justification-selector'
import Style from './style.module.scss'
import {
  potentialOptions,
  attritionOptions,
  motivationOptions,
  pointSurveyItems,
  ratingValues,
  strengthOptions,
  improvOptions,
  surveyDataSchema,
} from './survey-utils'
import TagSelector from './tag-selector'

const cx = classNames.bind(Style)

const encoreOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

const minRequired = 1
const maxDisplayed = 3

const convertToStrengthImprov = (selectedOptions: SkillState, feedbackId: string) => {
  return Object.keys(selectedOptions).map((k) => {
    const newStrength = new StrengthImprov('', feedbackId)
    const enumMember = getEnumByValue(Skill, k)
    if (enumMember === null) throw new Error('Invalid Skill enum key')
    newStrength.skill = enumMember
    newStrength.justification = selectedOptions[k]?.value
    return newStrength
  })
}

const isStrengthImprovValid = (tags: SkillState) => {
  const selectedForJustification = Object.keys(tags).filter((k) => tags[k]?.index > -1)
  const numberOfTags = Object.keys(tags).length
  if (
    numberOfTags < minRequired ||
    (numberOfTags > maxDisplayed && selectedForJustification.length < maxDisplayed) ||
    (numberOfTags <= maxDisplayed && selectedForJustification.length !== numberOfTags)
  ) {
    return false
  }
  return selectedForJustification.every((curr) => tags[curr]?.value?.length > 0)
}

const SurveyForm: React.FC<Props> = ({
  'data-test': dataTest = 'survey-form',
  hideModal,
  isForManager = false,
  request,
  pauseModal,
}) => {
  const isCompleted = useMemo(
    () =>
      request.state === FeedbackRequestState.Completed ||
      request.state === FeedbackRequestState.Skipped ||
      request.state === FeedbackRequestState.AwaitingApproval ||
      request.state === FeedbackRequestState.SkipRequested,
    [request.state]
  )
  const [strengthOptionsSelected, setStrengthOptionsSelected] = useState<SkillState>({})

  const [improvOptionsSelected, setImprovOptionsSelected] = useState<SkillState>({})

  const [isStrengthsValid, setIsStrengthsValid] = useState<boolean>(false)

  const [isImprovsValid, setIsImprovsValid] = useState<boolean>(false)

  const [feedback, setFeedback] = useState<Feedback>(() => {
    const defState = new Feedback()
    defState.encore = null
    return defState
  })
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showStrengths, setShowStrengths] = useState(false)
  const [showAreas, setShowAreas] = useState(false)
  const [confirmStatus, setConfirmStatus] = useState<'cancel' | 'publish' | ''>('')

  const { data: feedbackSurveyData } = useFeedbackByFeedbackRequest(request.id)
  const updateFeedbackRequest = useUpdateFeedbackRequest()
  const addFeedback = useAddFeedback()
  const updateFeedback = useUpdateFeedback()
  const resource = useEmployee(request.rel.target)
  const [isValidData, setIsValidData] = useState(false)
  const [showSkipModal, setShowSkipModal] = useState(false)

  useEffect(() => {
    setFeedback(
      (_feedback) =>
        ({
          ..._feedback,
          improvementAreas: Object.keys(improvOptionsSelected).map((s) => {
            const newImprov = new StrengthImprov('', feedback.id)
            const enumMember = getEnumByValue(Skill, s)
            if (enumMember === null) throw new Error('Invalid Skill enum key')
            newImprov.skill = enumMember
            newImprov.justification = improvOptionsSelected[s]?.value
            return newImprov
          }),
        } as Feedback)
    )
  }, [feedback.id, improvOptionsSelected])

  useEffect(() => {
    setFeedback(
      (_feedback) =>
        ({
          ..._feedback,
          strengths: convertToStrengthImprov(strengthOptionsSelected, feedback.id),
          improvementAreas: convertToStrengthImprov(improvOptionsSelected, feedback.id),
        } as Feedback)
    )

    setIsStrengthsValid(isStrengthImprovValid(strengthOptionsSelected))
    setIsImprovsValid(isStrengthImprovValid(improvOptionsSelected))
  }, [feedback.id, improvOptionsSelected, strengthOptionsSelected])

  useEffect(() => {
    if (!feedbackSurveyData?.data) {
      return
    }
    feedbackSurveyData.data = getLocalFeedback(feedbackSurveyData?.data)
    if (
      [
        FeedbackRequestState.Completed,
        FeedbackRequestState.Skipped,
        FeedbackRequestState.AwaitingApproval,
        FeedbackRequestState.SkipRequested,
        FeedbackRequestState.Pending,
      ].includes(request.state)
    ) {
      setFeedback(feedbackSurveyData?.data as Feedback)
      const f: Feedback = feedbackSurveyData?.data
      let index = -1
      const newStrengthOptions = f.strengths.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.skill]: { value: curr?.justification || '', index: curr?.justification ? ++index : -1 },
        }),
        {} as SkillState
      )
      index = -1
      const newImprovOptions = f.improvementAreas.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.skill]: { value: curr?.justification || '', index: curr?.justification ? ++index : -1 },
        }),
        {} as SkillState
      )
      setStrengthOptionsSelected(newStrengthOptions)
      setImprovOptionsSelected(newImprovOptions)

      setIsStrengthsValid(isStrengthImprovValid(newStrengthOptions))
      setIsImprovsValid(isStrengthImprovValid(newImprovOptions))
    }
  }, [feedbackSurveyData, feedbackSurveyData?.data, request.state])

  useEffect(() => {
    const isSchemaOk = surveyDataSchema(feedback.sourceIsLead).safeParse(feedback).success
    setIsValidData(isSchemaOk && isStrengthsValid && isImprovsValid)
    storeLocal(feedback)
  }, [feedback, isImprovsValid, isStrengthsValid])

  const getLocalFeedback = (fb: Feedback): Feedback => {
    if (request.state !== FeedbackRequestState.Pending) {
      return fb
    }
    const localFeedback = getLocal(fb.rel.feedbackRequest)
    if (
      localFeedback &&
      (!fb.updated || (fb.updated && localFeedback && new Date(localFeedback.updated) > new Date(fb.updated)))
    ) {
      fb = localFeedback
    }
    return fb
  }

  const generatePublishableFeedback = (): Feedback => {
    const publishableFeedback = new Feedback()
    publishableFeedback.sourceIsLead = feedback.sourceIsLead
    publishableFeedback.potential = feedback.potential
    publishableFeedback.attrition = feedback.attrition
    publishableFeedback.motivation = feedback.motivation
    publishableFeedback.delivery = feedback.delivery
    publishableFeedback.positiveImpact = feedback.positiveImpact
    publishableFeedback.teamCollaboration = feedback.teamCollaboration
    publishableFeedback.clientCollaboration = feedback.clientCollaboration
    publishableFeedback.strengths = feedback.strengths
    publishableFeedback.improvementAreas = feedback.improvementAreas
    publishableFeedback.comment = feedback.comment
    publishableFeedback.encore = feedback.encore
    // add references
    publishableFeedback.rel.engagement = request.rel.engagement
    publishableFeedback.rel.target = request.rel.target
    publishableFeedback.rel.source = request.rel.source
    publishableFeedback.rel.feedbackRequest = request.id
    return publishableFeedback
  }

  const generateFeedbackFromLocal = (feedbackObj: Feedback): Feedback => {
    const publishableFeedback = new Feedback(feedbackObj.id)
    publishableFeedback.sourceIsLead = feedbackObj.sourceIsLead
    publishableFeedback.potential = feedbackObj.potential
    publishableFeedback.attrition = feedbackObj.attrition
    publishableFeedback.motivation = feedbackObj.motivation
    publishableFeedback.delivery = feedbackObj.delivery
    publishableFeedback.positiveImpact = feedbackObj.positiveImpact
    publishableFeedback.teamCollaboration = feedbackObj.teamCollaboration
    publishableFeedback.clientCollaboration = feedbackObj.clientCollaboration
    publishableFeedback.strengths = feedbackObj.strengths.map((item: StrengthImprov) => {
      const strength = new StrengthImprov(item.id, item.rel.feedback)
      strength.skill = item.skill
      strength.justification = item.justification
      return strength
    })
    publishableFeedback.improvementAreas = feedbackObj.improvementAreas.map((item: StrengthImprov) => {
      const improvement = new StrengthImprov(item.id, item.rel.feedback)
      improvement.skill = item.skill
      improvement.justification = item.justification
      return improvement
    })
    publishableFeedback.comment = feedbackObj.comment
    publishableFeedback.encore = feedbackObj.encore
    publishableFeedback.created = feedbackObj.created
    // add references
    publishableFeedback.rel.engagement = feedbackObj.rel.engagement
    publishableFeedback.rel.target = feedbackObj.rel.target
    publishableFeedback.rel.source = feedbackObj.rel.source
    publishableFeedback.rel.feedbackRequest = feedbackObj.rel.feedbackRequest
    return publishableFeedback
  }

  const publishSurvey = async () => {
    if (!feedbackSurveyData?.data?.id) {
      const publishableFeedback = generatePublishableFeedback()
      storeLocal(publishableFeedback)
      await addFeedback.mutateAsync(publishableFeedback)
      deleteLocal(publishableFeedback)
    } else {
      let updatedFeedback = mergeWith(cloneDeep(feedbackSurveyData?.data), feedback, (a, b) => {
        if (isNil(b)) return a
        return isArray(b) ? b : undefined
      })

      updatedFeedback = generateFeedbackFromLocal(updatedFeedback)
      storeLocal(updatedFeedback)
      await updateFeedback.mutateAsync(updatedFeedback)
      deleteLocal(updatedFeedback)
    }
    request.state = FeedbackRequestState.AwaitingApproval
    await updateFeedbackRequest.mutateAsync(request)
    hideModal(FeedbackRequestState.AwaitingApproval)
  }

  const storeLocal = (feedbackData: Feedback) => {
    if (!feedbackData.rel.feedbackRequest) {
      return
    }
    let feedbacks: any = localStorage.getItem('feedbacks')
    feedbacks = feedbacks ? JSON.parse(feedbacks) : {}
    feedbacks[request.id] = { ...feedbackData, updated: new Date() }
    localStorage.setItem('feedbacks', JSON.stringify(feedbacks))
  }

  const deleteLocal = (feedbackData: Feedback) => {
    let feedbacks: any = localStorage.getItem('feedbacks')
    feedbacks = feedbacks ? JSON.parse(feedbacks) : {}
    delete feedbacks[request.id]
    localStorage.setItem('feedbacks', JSON.stringify(feedbacks))
  }

  const getLocal = (feedbackId: string) => {
    let feedbacks: any = localStorage.getItem('feedbacks')
    feedbacks = feedbacks ? JSON.parse(feedbacks) : {}
    return feedbacks[request.id]
  }

  const handleCancel = () => {
    if (
      request.state === FeedbackRequestState.Completed ||
      request.state === FeedbackRequestState.Skipped ||
      request.state === FeedbackRequestState.AwaitingApproval
    ) {
      hideModal(undefined)
      return
    }
    setConfirmStatus('cancel')
    setShowConfirmModal(true)
  }

  const handlePublish = () => {
    setConfirmStatus('publish')
    setShowConfirmModal(true)
  }

  const handleConfirm = () => {
    confirmStatus === 'publish' ? publishSurvey() : cancelSuccess()
  }

  const cancelSuccess = () => {
    deleteLocal(feedback)
    hideModal(undefined)
  }

  const handleFeedbackUpdate = useCallback(
    (payload: Partial<Feedback>) => {
      const newFeedback: Feedback = { ...feedback, ...payload } as Feedback
      storeLocal(newFeedback)
      setFeedback(newFeedback)
    },
    [feedback]
  )

  const handleConfirmationCancel = () => {
    setConfirmStatus('')
    setShowConfirmModal(false)
  }

  // but initial value should be undefined in this one case
  const getEncore = () => {
    if (feedback.encore === null) {
      return []
    }
    return feedback.encore ? encoreOptions[0] : encoreOptions[1]
  }

  const [message, setMessage] = useState('')
  const handleMessageChange = (value: string) => {
    value.length ? setValid(true) : setValid(false)
    setMessage(value)
  }

  const requestSkip = async () => {
    if (request) {
      request.skipReason = message
      request.state = FeedbackRequestState.SkipRequested
      const res = await updateFeedbackRequest.mutateAsync(request)
      return !!res.data
    }
    return false
  }

  const [isValid, setValid] = useState(false)
  const handleSend = async () => {
    if (await requestSkip()) {
      setShowSkipModal(false)
      hideModal(FeedbackRequestState.SkipRequested)
    }
  }

  return (
    <section className={cx(Style.surveyFormWrapper, 'py-[36px] px-[40px]')} data-test={`${dataTest}.container`}>
      {!isForManager && (
        <header className="flex items-center">
          <Avatar
            cid={resource.data?.data?.employeeId || ''}
            data-test={`${dataTest}.avatar`}
            wrapperCustomClasses={Style.avatar}
          ></Avatar>
          {resource.data?.data?.name && (
            <div className="ml-[12px] text-[24px] font-semibold">{resource.data.data.name}</div>
          )}
          <div className="flex ml-auto gap-[8px]">
            {request.state === FeedbackRequestState.Pending && (
              <Button
                basicOutline
                data-test={`${dataTest}.skip-button`}
                fill={ButtonFill.Outline}
                onClick={() => setShowSkipModal(true)}
              >
                Request an Exception
              </Button>
            )}
            <Button
              basicOutline
              data-test={`${dataTest}.cancel-button`}
              fill={ButtonFill.Outline}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            {request.state === FeedbackRequestState.Pending && (
              <Button
                color={ButtonColor.Accent}
                customClasses={cx(Style.publishButton)}
                data-test={`${dataTest}.publish-button`}
                disabled={!isValidData || isCompleted}
                icon={IconName.ArrowUpOnSquare}
                onClick={handlePublish}
              >
                Submit
              </Button>
            )}
          </div>
        </header>
      )}
      {!isForManager && (
        <section className="text-[20px] text-gray-600 text-center my-[36px]" data-test={`${dataTest}.disclaimer`}>
          Your review will be anonymized
        </section>
      )}
      <div className="inline-block">
        <Switch
          customClasses="mb-[16px]"
          data-test={`${dataTest}.lead-switch`}
          description="I am/was this individual's engagement lead"
          disabled={isCompleted}
          label="Lead"
          value={feedback?.sourceIsLead}
          onChange={() => handleFeedbackUpdate({ sourceIsLead: !feedback.sourceIsLead })}
        />
      </div>
      {feedback?.sourceIsLead && (
        <>
          <Combobox
            customClasses="mb-[16px] p-[0px]"
            data-test={`${dataTest}.potential`}
            disabled={isCompleted}
            label="Potential"
            options={potentialOptions}
            value={potentialOptions.find((option) => option.value === feedback.potential) || null}
            onChange={(val) => handleFeedbackUpdate({ potential: (val as Option).value as FeedbackRank })}
          />
          <Combobox
            customClasses="mb-[16px]"
            data-test={`${dataTest}.attrition`}
            disabled={isCompleted}
            label="Attrition"
            options={attritionOptions}
            value={attritionOptions.find((option) => option.value === feedback.attrition) || null}
            onChange={(val) => handleFeedbackUpdate({ attrition: (val as Option).value as FeedbackRank })}
          />
          <Combobox
            customClasses="mb-[16px]"
            data-test={`${dataTest}.motivation`}
            disabled={isCompleted}
            label="Motivation"
            options={motivationOptions}
            value={motivationOptions.find((option) => option.value === feedback.motivation) || null}
            onChange={(val) => handleFeedbackUpdate({ motivation: (val as Option).value as FeedbackRank })}
          />
        </>
      )}
      <div className="flex flex-col w-full gap-4">
        <div className="flex mb-[20px]">
          <div className="w-[220px]"></div>
          {ratingValues.map((label, i) => (
            <div key={i} className="w-[14.2%] flex justify-center">
              {label}
            </div>
          ))}
        </div>
        <RadioGroup
          data-test={`${dataTest}.delivery`}
          disabled={isCompleted}
          items={pointSurveyItems}
          label="Delivers what is required?"
          value={feedback.delivery?.toString()}
          onChange={(val) => handleFeedbackUpdate({ delivery: (val as Option).value as FeedbackRank })}
        />
        <RadioGroup
          data-test={`${dataTest}.positive-impact`}
          disabled={isCompleted}
          items={pointSurveyItems}
          label="Consistent positive impact?"
          value={feedback.positiveImpact?.toString()}
          onChange={(val) => handleFeedbackUpdate({ positiveImpact: (val as Option).value as FeedbackRank })}
        />
        <RadioGroup
          data-test={`${dataTest}.team-collaboration`}
          disabled={isCompleted}
          items={pointSurveyItems}
          label="Collaborates well with Lab49?"
          value={feedback.teamCollaboration?.toString()}
          onChange={(val) => handleFeedbackUpdate({ teamCollaboration: (val as Option).value as FeedbackRank })}
        />
        <RadioGroup
          data-test={`${dataTest}.client-collaboration`}
          disabled={isCompleted}
          items={pointSurveyItems}
          label="Collaborates well with client?"
          value={feedback.clientCollaboration?.toString()}
          onChange={(val) => handleFeedbackUpdate({ clientCollaboration: (val as Option).value as FeedbackRank })}
        />
      </div>
      <Combobox
        customClasses="mt-[32px] w-[500px]"
        data-test={`${dataTest}.encore`}
        disabled={isCompleted}
        label="I would like to work with this individual in the future"
        value={getEncore()}
        options={[
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ]}
        onChange={(val) => handleFeedbackUpdate({ encore: (val as Option).value === 'yes' })}
      />
      <div className="flex gap-[16px] mt-[32px]">
        <div className="w-[50%] relative">
          <TagSelector
            data-test={`${dataTest}.selector.strengths`}
            disabled={isCompleted}
            list={Object.keys(strengthOptionsSelected)}
            subtitle="Select as many applicable strengths"
            title="Strengths"
            onChange={(strengths) =>
              setStrengthOptionsSelected((prev) =>
                strengths.reduce((acc, curr) => ({ ...acc, [curr]: { ...prev[curr] } }), {})
              )
            }
            onDialogRequest={() => {
              pauseModal(true)
              setShowStrengths(true)
            }}
          />
        </div>
        <div className="w-[50%] relative">
          <TagSelector
            data-test={`${dataTest}.selector.areas`}
            disabled={isCompleted}
            list={Object.keys(improvOptionsSelected)}
            subtitle="Select as many applicable areas"
            title="Areas of Improvement"
            onChange={(improvs) =>
              setImprovOptionsSelected((prev) =>
                improvs.reduce((acc, curr) => ({ ...acc, [curr]: { ...prev[curr] } }), {})
              )
            }
            onDialogRequest={() => {
              pauseModal(true)
              setShowAreas(true)
            }}
          />
        </div>
      </div>
      <div className="w-full flex flex-row gap-4">
        <div className="w-1/2">
          <JustificationSelector
            data-test={`${dataTest}.justification.strength`}
            disabled={isCompleted}
            elementName="strength"
            label="Strength"
            list={strengthOptionsSelected}
            maxAccepted={3}
            maxDisplayed={maxDisplayed}
            onChange={(val) => {
              const newOptions = Object.keys(val).reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr]: { value: val[curr]?.value, index: val[curr]?.index },
                }),
                {}
              )
              setStrengthOptionsSelected(newOptions)
            }}
          />
        </div>
        <div className="w-1/2">
          <JustificationSelector
            data-test={`${dataTest}.justification.area`}
            disabled={isCompleted}
            elementName="area"
            label="Improvement Area"
            list={improvOptionsSelected}
            maxAccepted={3}
            maxDisplayed={3}
            onChange={(val) => {
              const newOptions = Object.keys(val).reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr]: { value: val[curr]?.value, index: val[curr]?.index },
                }),
                {}
              )
              setImprovOptionsSelected(newOptions)
            }}
          />
        </div>
      </div>
      <Textarea
        customClasses={cx([Style.additionalFeedback, 'mb-[36px] mt-[32px]'])}
        data-test={`${dataTest}.additional-feedback`}
        disabled={isCompleted}
        label="Additional Feedback"
        maxSymbols={Feedback.schema.shape.comment.maxLength || 1000}
        rows={5}
        value={feedback.comment}
        onChange={(val) => handleFeedbackUpdate({ comment: val })}
      />
      {!isForManager && (
        <div className="flex ml-auto">
          <div className="flex-grow" />
          <Button basicOutline data-test={`${dataTest}.cancel-button`} fill={ButtonFill.Outline} onClick={handleCancel}>
            Cancel
          </Button>
          {/* disabled when form is not finished */}
          {request.state === FeedbackRequestState.Pending && (
            <Button
              color={ButtonColor.Accent}
              customClasses={cx(Style.publishButton, 'ml-[10px]')}
              data-test={`${dataTest}.publish-button`}
              disabled={!isValidData || isCompleted}
              icon={IconName.ArrowUpOnSquare}
              onClick={handlePublish}
            >
              Submit
            </Button>
          )}
        </div>
      )}
      <Modal
        data-test={`${dataTest}.strengths-modal`}
        isShown={showStrengths}
        modalCustomClasses={Style.surveyModalContentWrapper}
        wrapperCustomClasses={Style.surveyModalWrapper}
        onHide={() => {
          pauseModal(false)
          setShowStrengths(false)
        }}
      >
        <ClickMatrix
          data-test={`${dataTest}.strengths-modal.matrix`}
          options={strengthOptions}
          selected={Object.keys(strengthOptionsSelected)}
          subtitle="Select all that apply (Min 1)"
          title={`What strengths does ${
            resource.data?.data?.firstName || resource.data?.data?.name?.split(' ')[0]
          } have?`}
          onConfirm={() => {
            pauseModal(false)
            setShowStrengths(false)
          }}
          onSelect={(value, selected) => {
            Object.keys(strengthOptionsSelected).includes(value) && !selected
              ? setStrengthOptionsSelected(omit(strengthOptionsSelected, value))
              : setStrengthOptionsSelected((prev) => ({
                  ...prev,
                  [value]: {
                    value: prev[value]?.value || '',
                    index: prev[value]?.index || -1,
                  },
                }))
          }}
        />
      </Modal>
      <Modal
        data-test={`${dataTest}.areas-modal`}
        isShown={showAreas}
        modalCustomClasses={Style.surveyModalContentWrapper}
        wrapperCustomClasses={Style.surveyModalWrapper}
        onHide={() => {
          pauseModal(false)
          setShowAreas(false)
        }}
      >
        <ClickMatrix
          data-test={`${dataTest}.areas-modal.matrix`}
          options={improvOptions}
          selected={Object.keys(improvOptionsSelected)}
          subtitle="Select all that apply (Min 1)"
          title={`What improvement areas does ${
            resource.data?.data?.firstName || resource.data?.data?.name?.split(' ')[0]
          } need to work on?`}
          onConfirm={() => {
            pauseModal(false)
            setShowAreas(false)
          }}
          onSelect={(value, selected) => {
            Object.keys(improvOptionsSelected).includes(value) && !selected
              ? setImprovOptionsSelected(omit(improvOptionsSelected, value))
              : setImprovOptionsSelected((prev) => ({
                  ...prev,
                  [value]: {
                    value: prev[value]?.value || '',
                    index: prev[value]?.index || -1,
                  },
                }))
          }}
        />
      </Modal>
      <Modal
        hideCloseButton
        data-test={`${dataTest}.confirmation`}
        isShown={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTest}.confirmation-dialog`}
          description={confirmStatus === 'publish' ? 'All submissions are final.' : 'Your survey will not be saved.'}
          returnButtonText=""
          onConfirm={(confirmed: boolean) => {
            if (confirmed) {
              handleConfirm()
            } else {
              handleConfirmationCancel()
            }
          }}
        />
      </Modal>
      <Modal
        hideCloseButton
        data-test={`${dataTest}.skip-modal`}
        isShown={showSkipModal}
        modalCustomClasses={Style.modal}
        wrapperCustomClasses={Style.surveyModalWrapper}
        onHide={() => setShowSkipModal(false)}
      >
        <div className="px-[40px] pt-[24px] pb-[40px] flex flex-col">
          <div className="text-[20px] mb-[40px] text-grey-700 text-center">
            Exception Request for Peer Review Removal
          </div>
          <Textarea
            customClasses={Style.input}
            data-test={`${dataTest}.message`}
            label={'Message'}
            maxSymbols={1000}
            rows={5}
            value={message}
            onChange={handleMessageChange}
          />
          <div className="flex pt-[32px]">
            <Button
              basicOutline
              customClasses="mr-[16px] w-[65px]"
              data-test={`${dataTest}.cancel`}
              fill={ButtonFill.Outline}
              onClick={() => setShowSkipModal(false)}
            >
              Cancel
            </Button>
            <Button
              basicOutline
              color={ButtonColor.Accent}
              customClasses="mr-[16px] w-[83x]"
              data-test={`${dataTest}.send`}
              disabled={!isValid}
              fill={ButtonFill.Outline}
              icon={IconName.ArrowUpOnSquare}
              onClick={handleSend}
            >
              Send
            </Button>
          </div>
        </div>
      </Modal>
    </section>
  )
}

export default SurveyForm

export type { Props }
