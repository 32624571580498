import Style from './style.module.scss'

const Page404 = () => {
  return (
    <div className={Style.wrapper}>
      <h2>Page Not Found</h2>
    </div>
  )
}

export default Page404
