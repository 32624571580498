import { noop } from 'lodash'
import React from 'react'

export const refreshContext = React.createContext<{
  refreshKey: number
  setRefreshKey: React.Dispatch<React.SetStateAction<number>>
}>({
  refreshKey: 1,
  setRefreshKey: noop,
})
