import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Option } from 'react-bootstrap-typeahead/types/types'
import { useNavigate } from 'react-router-dom'

import { IconName as IconNames } from '@athena/component/atom/button'
import { Icon, IconName, IconSize } from '@athena/component/atom/icon'
import Loading from '@athena/component/atom/loading'
import Modal from '@athena/component/molecule/modal'
import { Feedback } from '@athena/core/model'
import { Quantum } from '@athena/core/model/base-model'

import { useIdentity } from '@apollo/client/hook/auth'
import { useFeedbackBySourceAndTarget, useFeedbackByTargetArray } from '@apollo/client/hook/engagement'
import { useSAPSearch } from '@apollo/client/hook/sap-search'
import { useEmployeeByIds } from '@artemis/client/hook'
import PeerToPeerReviews from '@future-view/frontend/component/peer-to-peer-reviews'
import ResourceCard from '@future-view/frontend/component/resource-card'
import ResourceDetailCard from '@future-view/frontend/component/resource-detail-card'

import Props, { AuthorDetail, PeerToPeer } from './interface'
import Style from './style.module.scss'
import { getColor } from './util'

const PeerSpectiveMatrix: React.FC<Props> = ({ 'data-test': dataTest = 'PeerSpectiveMatrix' }) => {
  const feedbackBySourceAndTarget = useFeedbackBySourceAndTarget()
  const [selectedActivity, setSelectedActivity] = useState<Quantum<AuthorDetail>>(undefined)
  const [showPeerToPeer, setPeerToPeer] = useState<Quantum<PeerToPeer>>()
  const navigate = useNavigate()

  const handleCardClicked = (data: AuthorDetail) => {
    return (event: React.MouseEvent) => {
      if (!data.isDirectReport) {
        setSelectedActivity(data)
      } else {
        navigate(`peerdetails/${data.id}`)
      }
      event.preventDefault()
    }
  }

  const handleColorCardClicked = (data: PeerToPeer) => {
    return (event: React.MouseEvent) => {
      setPeerToPeer(data)
      event.preventDefault()
    }
  }

  const handleClose = () => {
    setSelectedActivity(undefined)
    setPeerToPeer(undefined)
  }

  const identity = useIdentity()
  const loggedInUserEmail = identity?.data?.data?.email

  const { data: resourceResponse } = useSAPSearch(loggedInUserEmail?.toLowerCase() || '', '', '', '', '', '')
  const employeeId =
    resourceResponse?.data && resourceResponse?.data?.length > 0 ? resourceResponse.data[0].employeeId : ''
  const peersData = useSAPSearch('', '', '', employeeId, '', '')

  const peersList = peersData?.data?.data || [],
    authorsList: AuthorDetail[] = [],
    subjectsList: AuthorDetail[] = [],
    authorIds: string[] = []

  const subjectIds = peersList.map((item) => {
    if (item.employeeId) {
      const obj = {
        id: item.employeeId,
        email: item.groupEmailAddress,
        firstName: item.preferredFirstName,
        lastName: item.preferredLastName,
        name: `${item.preferredFirstName} ${item.preferredLastName}`,
        role: '',
        isDirectReport: item.directMgrEmployeeId === employeeId,
      }
      subjectsList.push(obj)
    }
    return item.employeeId
  })
  const [filteredSubjectsList, setFilteredSubjectsList] = useState<Quantum<AuthorDetail[]>>(subjectsList)
  useEffect(() => {
    if (subjectsList && subjectsList.length && filteredSubjectsList && filteredSubjectsList.length === 0) {
      onSubjectChange([])
    }
  }, [subjectsList])

  const feedbackData = useFeedbackByTargetArray(subjectIds)
  const feedback = feedbackData.data?.data || []
  const feedbacks: { [key: string]: Feedback } = {}
  const feedbacksSubject: { [key: string]: Feedback } = {}

  feedback.map((item: Feedback) => {
    const rel = item.rel
    if (rel) {
      authorIds.push(rel.source)
      feedbacks[`${rel.source}|${rel.target}`] = item
      feedbacksSubject[`${rel.target}`] = item
    }
    return item
  })
  const isFeedbackPresent = Object.keys(feedbacks).length === 0 ? false : true

  const authorsData = useEmployeeByIds(authorIds)
  authorsData?.data?.data?.map((item) => {
    if (item.employeeId) {
      authorsList.push({
        id: item.employeeId,
        email: item.groupEmailAddress,
        firstName: item.preferredFirstName,
        lastName: item.preferredLastName,
        name: `${item.preferredFirstName} ${item.preferredLastName}`,
        role: '',
        isDirectReport: item.directMgrEmployeeId === employeeId,
      })
    }
    return item
  })

  const onSubjectChange = (subject: Option[]) => {
    if (subject.length === 0) {
      setFilteredSubjectsList(subjectsList)
    } else {
      setFilteredSubjectsList(
        subjectsList.filter((item) => {
          return item.name === subject[0]
        })
      )
    }
  }

  return (
    <div className={Style.wrapper}>
      {feedbackBySourceAndTarget.isLoading && (
        <div className={Style.blockingOpaqueBlock}>
          <div className={Style.loadingContainer}>
            <Loading data-test={`peer-to-peer-review.loading`} />
          </div>
        </div>
      )}
      {!authorsData.isLoading && !peersData.isLoading && (
        <>
          <div className={Style.header}>
            <div className={Style.searchInput}>
              <Typeahead
                id="peerspective-search"
                labelKey="value"
                placeholder="Search Subject"
                options={subjectsList.map((item) => {
                  return `${item.name}`
                })}
                onChange={onSubjectChange}
              />
              <Icon
                customClasses="mr-[5px]"
                data-test={`${dataTest}-icon`}
                name={IconName.MagnifyingGlass}
                size={IconSize.Small}
              />
            </div>
            <div className={Style.ratingLegend}>
              <div className={classnames(Style.low, Style.legends)}>Low</div>
              <div className={classnames(Style.med, Style.legends)}>Med</div>
              <div className={classnames(Style.high, Style.legends)}>High</div>
            </div>
          </div>
          <div className={Style.peerspectiveGroupBlock}>
            <div className={Style.authors}>
              <p>Author ({authorsList.length})</p>
              {isFeedbackPresent &&
                authorsList.map((i: AuthorDetail) => {
                  return (
                    <div key={i.id} className={Style.cardWrapper}>
                      <ResourceCard
                        data-test={dataTest}
                        fname={i.firstName}
                        id={i.id}
                        lname={i.lastName}
                        name={''}
                        role={''}
                        wrapperCustomClasses={Style.peerCard}
                      ></ResourceCard>
                      <div className={Style.button} onClick={handleCardClicked(i)}>
                        <Icon
                          customClasses="mr-[5px]"
                          data-test={`${dataTest}-icon`}
                          name={IconName.ArrowTopRightOnSquare}
                          size={IconSize.Small}
                        />
                        View Details
                      </div>
                    </div>
                  )
                })}
            </div>
            <div key={loggedInUserEmail} className={Style.subjects}>
              <p>Subjects ({filteredSubjectsList && filteredSubjectsList.length})</p>
              <div className={'inline-flex w-[100%]'}>
                {filteredSubjectsList &&
                  filteredSubjectsList.map((i: AuthorDetail) => {
                    return (
                      <div key={i.id} className={Style.cardWrapper}>
                        <ResourceCard
                          data-test={`${dataTest}-source-name`}
                          fname={i.firstName}
                          id={i.id}
                          lname={i.lastName}
                          name={''}
                          role={''}
                          wrapperCustomClasses={Style.peerCard}
                        ></ResourceCard>
                        <div className={Style.button} onClick={handleCardClicked(i)}>
                          <Icon
                            customClasses="mr-[5px]"
                            data-test={`${dataTest}-icon`}
                            name={IconName.ArrowTopRightOnSquare}
                            size={IconSize.Small}
                          />
                          View Details
                        </div>
                      </div>
                    )
                  })}
              </div>
              {authorsList.map((i: AuthorDetail) => {
                return (
                  <div key={i.id} className={'inline-flex w-[100%]'}>
                    {filteredSubjectsList &&
                      filteredSubjectsList.map((j: AuthorDetail) => {
                        return (
                          <div
                            key={`${i.id}|${j.id}`}
                            className={
                              Style.cardWrapper +
                              ' ' +
                              ((i.id === j.id || !isFeedbackPresent ? Style.disabledCard : '') +
                                ' ' +
                                (!feedbacks[`${i.id}|${j.id}`] ? Style.noFeedback : ''))
                            }
                          >
                            <ResourceCard
                              data-test={dataTest}
                              id={''}
                              name={i.id === j.id || !isFeedbackPresent ? '-' : ''}
                              role={''}
                              wrapperCustomClasses={classnames(
                                Style.colorCard,
                                getColor(feedbacks[`${i.id}|${j.id}`], Style)
                              )}
                            ></ResourceCard>
                            <div
                              className={Style.button}
                              onClick={handleColorCardClicked({
                                source: i,
                                target: j,
                              })}
                            >
                              <Icon
                                customClasses="mr-[5px]"
                                data-test={`${dataTest}-icon`}
                                name={IconName.ArrowTopRightOnSquare}
                                size={IconSize.Small}
                              />
                              View Peer Reviews
                            </div>
                          </div>
                        )
                      })}
                  </div>
                )
              })}
            </div>
          </div>
          <Modal
            closeIcon={IconNames.XCircle}
            data-test={dataTest}
            headerText={''}
            isShown={!!selectedActivity}
            wrapperCustomClasses={Style.resourceDetailCard}
            onHide={handleClose}
          >
            <ResourceDetailCard
              data-test={'ResourceDetailCard'}
              email={selectedActivity ? selectedActivity.email : ''}
              id={selectedActivity?.id || ''}
              name={selectedActivity ? selectedActivity.name : ''}
              role={''}
              wrapperCustomClasses={''}
            ></ResourceDetailCard>
          </Modal>
          <Modal
            closeIcon={IconNames.XCircle}
            data-test={dataTest}
            headerText={''}
            isShown={!!showPeerToPeer}
            wrapperCustomClasses={Style.peerToPeerModal}
            onHide={handleClose}
          >
            <PeerToPeerReviews
              data-test={'PeerToPeerReviews'}
              source={showPeerToPeer?.source}
              target={showPeerToPeer?.target}
              wrapperCustomClasses={''}
            ></PeerToPeerReviews>
          </Modal>
        </>
      )}
      {authorsData.isLoading && !peersData.isLoading && (
        <div className={Style.blockingOpaqueBlock}>
          <div className={Style.loadingContainer}>
            <Loading show data-test={`peerspective-matrix.loading`} />
          </div>
        </div>
      )}
    </div>
  )
}

export default PeerSpectiveMatrix
