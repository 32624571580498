import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import React, { useEffect, useRef, useState } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

import { useGetReviewCompletionReport } from '@apollo/client/hook/reports'
import {
  attritionOptions,
  motivationOptions,
  potentialOptions,
  ratingValues,
} from '@future-view/frontend/component/survey-form/survey-utils'
import useEmployeeMap from '@future-view/frontend/hook/use-employee-map'
import { formatDate } from '@future-view/frontend/util'

import { columnDef } from './column-def'
import Props, { ReportData } from './interface'
import Style from './style.module.scss'

const ReviewCompletionReport: React.FC<Props> = ({ 'data-test': dataTest }) => {
  const resourceList = useEmployeeMap()
  const reportResponse = useGetReviewCompletionReport()
  const [reportData, setReportData] = useState<ReportData[]>()
  const [gridApi, setGridApi] = useState<GridApi>()

  const gridStyle = {
    height: '89%',
  }
  const gridRef = useRef<AgGridReact>(null)
  const gridOptions: GridOptions = {
    columnDefs: columnDef,
    suppressHorizontalScroll: false,
    defaultColDef: {
      resizable: true,
      flex: 1,
      filter: 'agSetColumnFilter',
    },
  }

  const onFilterTextBoxChanged = (event: any) => {
    const value = event.target.value
    gridApi && gridApi.setQuickFilter(value)
  }

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
    event.api.sizeColumnsToFit()
  }

  useEffect(() => {
    if (resourceList && reportResponse) {
      const tdata = reportResponse.data?.data
      const rdata =
        resourceList &&
        Object.keys(resourceList).length &&
        tdata?.map((item) => {
          if (item.feedback) {
            item.feedback.strengths.sort((a: any, b: any) => (a.justification < b.justification ? 1 : -1))
            item.feedback.improvementAreas.sort((a: any, b: any) => (a.justification < b.justification ? 1 : -1))
          }
          return {
            id: item.feedbackRequest?.id,
            submitterFname: item.feedbackRequest ? resourceList[item.feedbackRequest.rel.source]?.firstName : '',
            submitterLname: item.feedbackRequest ? resourceList[item.feedbackRequest.rel.source]?.lastName : '',
            submitterEmail: item.feedbackRequest ? resourceList[item.feedbackRequest.rel.source]?.email : '',
            managerEmail: item.feedbackRequest ? resourceList[item.feedbackRequest.rel.source]?.directMgrEmail : '',
            subjectFname: item.feedbackRequest ? resourceList[item.feedbackRequest.rel.target]?.firstName : '',
            subjectLname: item.feedbackRequest ? resourceList[item.feedbackRequest.rel.target]?.lastName : '',
            description: item.feedbackRequest?.description || '',
            due: item.feedbackRequest?.due ? formatDate(new Date(item.feedbackRequest.due)) : '',
            state: item.feedbackRequest?.state || '',
            feedback: item.feedback && {
              potential: getLeadValues(item.feedback.potential, potentialOptions),
              attrition: getLeadValues(item.feedback.attrition, attritionOptions),
              motivation: getLeadValues(item.feedback.motivation, motivationOptions),
              delivery: getLikertsValue(item.feedback.delivery),
              positiveImpact: getLikertsValue(item.feedback.positiveImpact),
              teamCollaboration: getLikertsValue(item.feedback.teamCollaboration),
              clientCollaboration: getLikertsValue(item.feedback.clientCollaboration),
              comment: item.feedback.comment,
              encore: item.feedback.encore ? 'Yes' : 'No',
              strengths: {
                strength1: getArrayItem([...item.feedback.strengths], 0),
                strength2: getArrayItem([...item.feedback.strengths], 1),
                strength3: getArrayItem([...item.feedback.strengths], 2),
                strength4: getArrayItem([...item.feedback.strengths], 3),
              },
              improvements: {
                improvement1: getArrayItem([...item.feedback.improvementAreas], 0),
                improvement2: getArrayItem([...item.feedback.improvementAreas], 1),
                improvement3: getArrayItem([...item.feedback.improvementAreas], 2),
                improvement4: getArrayItem([...item.feedback.improvementAreas], 3),
              },
            },
          }
        })
      if (rdata && !reportData) {
        setReportData(rdata)
      }
    }
  }, [resourceList, reportResponse])

  const getLikertsValue = (val: string | undefined) => {
    if (!val) {
      return ''
    }
    return ratingValues[Number(val) - 1]
  }

  const getLeadValues = (val: string | undefined, collection: any) => {
    if (!val) {
      return ''
    }
    return collection.filter((item: any) => item.value === val)[0]?.shortLabel
  }

  const getArrayItem = (array: any[], index: number) => {
    if (array.length === 0) {
      return ''
    }
    if (index < 3 && index < array.length) {
      array = [array[index]]
    } else {
      array = array.splice(3)
    }
    return array
      .map((item: any) => {
        return `${item.skill}: ${item.justification}`
      })
      .join('\n')
  }

  const onBtExport = () => {
    const params = {
      fileName: 'Peer Review Report.csv',
    }
    gridApi && gridApi.exportDataAsCsv(params)
  }

  return (
    <>
      <div className={`${Style.wrapper}`} data-test={`${dataTest}.container`}>
        <div className={Style.header}>
          <div className={Style.filterInput}>
            <input
              className={`${Style.input} mb-[10px]`}
              id="filter-text-box"
              placeholder="Filter"
              type="text"
              onKeyUp={onFilterTextBoxChanged}
            />
            <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.0001 3C14.7548 3 17.4552 3.23205 20.0831 3.67767C20.6159 3.76803 21 4.23355 21 4.77402V5.81802C21 6.41476 20.7629 6.98705 20.341 7.40901L14.909 12.841C14.4871 13.2629 14.25 13.8352 14.25 14.432V17.3594C14.25 18.2117 13.7685 18.9908 13.0062 19.3719L9.75 21V14.432C9.75 13.8352 9.51295 13.2629 9.09099 12.841L3.65901 7.40901C3.23705 6.98705 3 6.41476 3 5.81802V4.77404C3 4.23357 3.38408 3.76805 3.91694 3.67769C6.54479 3.23206 9.24533 3 12.0001 3Z"
                stroke="#232832"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              />
            </svg>
          </div>
          <button className={Style.exportButton} onClick={onBtExport}>
            <svg fill="none" height="20" viewBox="0 0 18 20" width="18" xmlns="http://www.w3.org/2000/svg">
              <path
                clipRule="evenodd"
                d="M9 0.5C9.41421 0.5 9.75 0.835787 9.75 1.25L9.75 13.8401L11.7004 11.7397C11.9823 11.4361 12.4568 11.4186 12.7603 11.7004C13.0639 11.9823 13.0814 12.4568 12.7996 12.7603L9.54959 16.2603C9.40768 16.4132 9.20855 16.5 9 16.5C8.79145 16.5 8.59231 16.4132 8.4504 16.2603L5.2004 12.7603C4.91855 12.4568 4.93613 11.9823 5.23966 11.7004C5.54319 11.4186 6.01774 11.4361 6.29959 11.7397L8.25 13.8401L8.25 1.25C8.25 0.835787 8.58578 0.5 9 0.5Z"
                fill="#232832"
                fillRule="evenodd"
              />
              <path d="M1 5.5V19.5H17V5.5" stroke="#232832" strokeLinecap="round" />
            </svg>
            &nbsp;&nbsp;Export to CSV
          </button>
        </div>
        <AgGridReact
          ref={gridRef}
          className={'ag-theme-alpine'}
          containerStyle={gridStyle}
          enableRangeSelection={false}
          gridOptions={gridOptions}
          headerHeight={40}
          rowData={reportData}
          rowHeight={48}
          onGridReady={onGridReady}
        />
      </div>
    </>
  )
}

export default ReviewCompletionReport
export type { Props }
