import { assignIn, map } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, Quantum, Schema } from '@athena/core/model/base-model'
import EngagementSeat from '@athena/core/model/engagement-seat'

export type EngagementSerialized = z.infer<typeof Engagement.schema>

export enum EngagementState {
  Draft = 'draft',
  Published = 'published',
}

export enum Location {
  Budapest = 'budapest',
  London = 'london',
  NewYork = 'new-york',
  Noida = 'noida',
  Sydney = 'sydney',
  WashingtonDC = 'washington-dc',
}

export type DateRange = {
  startDate: string
  endDate: string
}

export type EngagementStore = BaseModelStore & {
  id_activity: string
  title: string
  location: string[]
  description: string
  state: string
  start_date: string | null
  end_date: string | null
}

export default class Engagement extends BaseModel {
  rel = {
    activity: '',
  }
  title = ''
  location: Location[] = []
  description = ''
  state = EngagementState.Draft
  seats: EngagementSeat[] = []
  startDate: Quantum<Date>
  endDate: Quantum<Date>

  constructor(id = '', activity = '') {
    super(id)
    this.rel = { activity }
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({ activity: z.string().max(31) }),
    title: z.string().max(255).trim(),
    location: z.array(z.nativeEnum(Location)),
    description: z.string().max(1000).trim(),
    state: z.nativeEnum(EngagementState),
    seats: z.array(EngagementSeat.schema),
    startDate: Schema.DateTime,
    endDate: Schema.DateTime,
  })

  static override fromSerialized(recordInput: EngagementSerialized): Engagement {
    const record = Engagement.schema.parse(recordInput)
    const model = new Engagement(record.id, record.rel.activity)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.title = record.title || ''
    model.location = map(record.location, (l) => l as Location)
    model.description = record.description || ''
    model.state = record.state as EngagementState
    model.seats = map(record.seats, EngagementSeat.fromSerialized)
    model.startDate = BaseModel.parseDate(record.startDate)
    model.endDate = BaseModel.parseDate(record.endDate)
    return model
  }

  static override fromDataStore(record: EngagementStore): Engagement {
    const model = new Engagement(record.id, record.id_activity)
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.title = record.title || ''
    model.location = map(record.location, (l) => l as Location)
    model.description = record.description || ''
    model.state = record.state as EngagementState
    model.startDate = BaseModel.parseDate(record.start_date)
    model.endDate = BaseModel.parseDate(record.end_date)
    return model
  }

  override toSerialized(): EngagementSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      seats: map(this.seats, (s) => s.toSerialized()),
      startDate: BaseModel.formatDate(this.startDate),
      endDate: BaseModel.formatDate(this.endDate),
    } as EngagementSerialized
  }

  override toDataStore(): EngagementStore {
    return {
      ...super.toDataStore(),
      id_activity: this.rel.activity,
      title: this.title,
      location: this.location,
      description: this.description,
      state: this.state,
      start_date: BaseModel.formatDate(this.startDate),
      end_date: BaseModel.formatDate(this.endDate),
    }
  }
}
