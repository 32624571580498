import React, { useEffect, useState, ChangeEvent } from 'react'

import { Quantum } from '@athena/core/model'

import { useAvatar } from '@artemis/client/hook'
import UserPlaceholder from '@future-view/frontend/asset/user-placeholder.svg'

import Props from './interface'

const Avatar: React.FC<Props> = ({ 'data-test': dataTest = 'avatar', cid, wrapperCustomClasses }) => {
  const avatarData = useAvatar(cid)
  const [avatar, setAvatar] = useState<Quantum<string>>(undefined)

  useEffect(() => {
    setAvatar(avatarData.data?.data)
  }, [avatarData])

  const addDefaultSrc = (ev: ChangeEvent<HTMLImageElement>) => {
    ev.target.src = UserPlaceholder
  }

  return (
    <img
      className={wrapperCustomClasses}
      data-test={`${dataTest}.manager-avatar`}
      src={avatar || ''}
      onError={addDefaultSrc}
    />
  )
}

export default Avatar
