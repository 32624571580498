const Path = {
  Root: '/',
  Logout: '/logout',
  Activity: '/activity',
  NewEngagement: '/engagement',
  ExistingEngagement: '/engagement/:id',
  Engagements: '/engagements',
  PeerReview: '/peer-review',
  PeersPective: '/peerspective',
  FeedbackReview: '/peerspective/feedback/:id',
  PeerDetails: '/peerspective/peerdetails/:id',
  Page404: '/404',
  RevueDashboard: '/revue',
  Suggestions: '/suggestions',
}

export default Path
