import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { IconName as IconNames } from '@athena/component/atom/button'
import Loading from '@athena/component/atom/loading'
import Modal from '@athena/component/molecule/modal'
import Employee from '@athena/core/model/employee'
import Feedback from '@athena/core/model/feedback'

import { useIdentity } from '@apollo/client/hook/auth'
import { useFeedbackByTargetYear } from '@apollo/client/hook/engagement'
import { useSAPSearch } from '@apollo/client/hook/sap-search'
import { useEmployee } from '@artemis/client/hook'
import FeedbackCard from '@future-view/frontend/component/feedback-card'
import Heatmap from '@future-view/frontend/component/heatmap'
import { calculateHeatmap, sourceType } from '@future-view/frontend/component/heatmap/heatmap-item'
import StrengthImprovementTraits from '@future-view/frontend/component/strength-improvement-trait'
import useEmployeeMap from '@future-view/frontend/hook/use-employee-map'
import Path from '@future-view/frontend/router/path'

import { EngagementsBlock } from './engagements'
import Props, { ReviewersBlockProps } from './interface'
import LikeartBar from './likeart-bar'
import MapLine from './map-line'
import ReviewersBlock from './reviewers'
import Style from './style.module.scss'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const PeerMetrics: React.FC<Props> = ({ 'data-test': dataTest = 'peer-metrics', year }: Props): JSX.Element => {
  const identity = useIdentity()
  const loggedInUserEmail = identity?.data?.data?.email
  const { data: resourceResponse } = useSAPSearch(loggedInUserEmail?.toLowerCase() || '', '', '', '', '', '')
  const employeeId =
    resourceResponse?.data && resourceResponse?.data?.length > 0 ? resourceResponse.data[0].employeeId : ''
  const peersData = useSAPSearch('', '', '', employeeId, '', '')

  const { id } = useParams()
  const isDirect = peersData.data?.data
    ? peersData.data?.data?.find((item: Employee) => {
        return item.employeeId === id
      })
    : 'NA'
  if (!isDirect) {
    window.location.href = Path.Root
  }

  const employee = useEmployee(id || '')?.data?.data
  const resourceList = useEmployeeMap()

  const [feedbacksOnEmployee, setFeedbacksOnEmployee] = useState<Feedback[]>([])
  const [leadFeedbacksOnEmployee, setLeadFeedbacksOnEmployee] = useState<Feedback[]>([])
  const [commentsOnEmployee, setCommentsOnEmployee] = useState<Feedback[]>([])
  const [showTraitsModal, setShowTraits] = useState<{ [key: string]: ReviewersBlockProps }>()

  const getFilteredFeedbacks = useFeedbackByTargetYear(id as string, year === 'all' ? null : year)

  useEffect(() => {
    if (getFilteredFeedbacks.data) {
      const feedbacks = getFilteredFeedbacks.data.data as Feedback[]
      setFeedbacksOnEmployee(feedbacks)
      setCommentsOnEmployee(feedbacks.filter((feedback) => feedback.comment))
      setLeadFeedbacksOnEmployee(feedbacks.filter((feedback) => feedback.sourceIsLead === true))
    }

    if (getFilteredFeedbacks.error) {
      toast.error('Error loading feedbacks')
    }
  }, [getFilteredFeedbacks.data, getFilteredFeedbacks.error])

  const handleClose = () => {
    setShowTraits(undefined)
  }

  const showTraits = (key: string, value: number) => {
    setShowTraits({
      data: {
        idResource: employee?.employeeId || '',
        resourceFirstName: employee?.firstName || '',
        resourceLastName: employee?.lastName || '',
        'data-test': '',
        year: '',
        value,
        key,
      },
    })
    return key
  }

  return (
    <div data-test={`${dataTest}.container`}>
      {!peersData.isLoading && (
        <>
          <div>
            <div className={Style.metricWrapper}>
              <div className={Style.leftPart}>
                <div className={Style.sectionWrapper}>
                  <div className={Style.metricBlock}>
                    {!getFilteredFeedbacks.isLoading && leadFeedbacksOnEmployee.length === 0 && (
                      <div
                        className={Style.noContent}
                        data-test={`${dataTest}.no-items`}
                      >{`No data for time period selected`}</div>
                    )}
                    {!getFilteredFeedbacks.isLoading && leadFeedbacksOnEmployee.length > 0 && (
                      <MapLine
                        data-test={`${dataTest}.map-line`}
                        empId={id as string}
                        year={year === 'all' ? null : year}
                      ></MapLine>
                    )}
                  </div>
                </div>
                <div className={Style.sectionWrapper}>
                  <div className={Style.metricBlock}>
                    {!getFilteredFeedbacks.isLoading && feedbacksOnEmployee.length === 0 && (
                      <div
                        className={Style.noContent}
                        data-test={`${dataTest}.no-items`}
                      >{`No data for time period selected`}</div>
                    )}
                    {!getFilteredFeedbacks.isLoading && feedbacksOnEmployee.length > 0 && (
                      <LikeartBar empId={id as string} year={year === 'all' ? null : year}></LikeartBar>
                    )}
                  </div>
                </div>
                <div className={Style.reviewersEngagementsWrapper}>
                  <div className={Style.reviewersBlock}>
                    <ReviewersBlock
                      data-test={`${dataTest}.reviewers`}
                      idResource={employee?.id || ''}
                      resourceFirstName={employee?.firstName || ''}
                      resourceLastName={employee?.lastName || ''}
                      year={year === 'all' ? null : year}
                    />
                  </div>
                  <div className={Style.engagementsBlock}>
                    <EngagementsBlock
                      dataTestContainer={`${dataTest}.engagements`}
                      idResource={employee?.id || ''}
                      year={year}
                    ></EngagementsBlock>
                  </div>
                </div>
                <div className={Style.sectionWrapper}>
                  <div className={Style.metricBlock}>
                    <p className={Style.title}>Feedback</p>
                    {commentsOnEmployee.length === 0 && (
                      <div className={Style.noContent} data-test={`${dataTest}.no-items`}>{`No feedbacks`}</div>
                    )}
                    {commentsOnEmployee.map((i: Feedback) => {
                      return (
                        <FeedbackCard
                          key={`${i.id}`}
                          data-test={'general-feedback'}
                          date={i.created}
                          engagement={i.name}
                          feedback={i.comment}
                          id={i.id}
                          name={resourceList[i.rel.source]?.name}
                          role={resourceList[id || '']?.directMgrEmployeeId === i.rel.source ? 'Lead' : 'Peer'}
                          source={{
                            id: resourceList[i.rel.source]?.id,
                            firstName: resourceList[i.rel.source]?.firstName || '',
                            lastName: resourceList[i.rel.source]?.lastName || '',
                            name: resourceList[i.rel.source]?.name,
                            email: '',
                            role: '',
                          }}
                          target={{
                            id: resourceList[i.rel.target]?.id,
                            firstName: resourceList[i.rel.target]?.firstName || '',
                            lastName: resourceList[i.rel.target]?.lastName || '',
                            name: resourceList[i.rel.target]?.name,
                            email: '',
                            role: '',
                          }}
                        ></FeedbackCard>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className={Style.rightPart}>
                <div className={Style.heatmapBlock}>
                  <span>Strengths and Improvements</span>
                  {getFilteredFeedbacks.isLoading && (
                    <div className={Style.loadingContainer}>
                      <Loading show data-test={`peer-heatmap.loading`} />
                    </div>
                  )}
                  {!getFilteredFeedbacks.isLoading && feedbacksOnEmployee.length === 0 && (
                    <div
                      className={(classnames(Style.noContent), 'mt-[50px]')}
                      data-test={`${dataTest}.no-items`}
                    >{`No data`}</div>
                  )}
                  {!getFilteredFeedbacks.isLoading && (
                    <div>
                      <div>
                        <Heatmap
                          data-test="heatmap-lead"
                          openModal={(key: string, value: number) => showTraits(key, value)}
                          title="Lead"
                          values={calculateHeatmap(
                            sourceType.lead,
                            feedbacksOnEmployee,
                            employee?.directMgrEmployeeId || ''
                          )}
                        />
                      </div>
                      <div className="mt-[50px]">
                        <Heatmap
                          data-test="heatmap-peers"
                          openModal={(key: string, value: number) => showTraits(key, value)}
                          title="Peer"
                          values={calculateHeatmap(
                            sourceType.peer,
                            feedbacksOnEmployee,
                            employee?.directMgrEmployeeId || ''
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            closeIcon={IconNames.XCircle}
            data-test={'Strength-Improvement-Traits-Modal'}
            headerText={''}
            isShown={!!showTraitsModal}
            wrapperCustomClasses={Style.traitsModal}
            onHide={handleClose}
          >
            {showTraitsModal && (
              <StrengthImprovementTraits
                data={showTraitsModal.data}
                data-test={'StrengthImprovementTraits'}
                wrapperCustomClasses={''}
              ></StrengthImprovementTraits>
            )}
          </Modal>
        </>
      )}
    </div>
  )
}
export default PeerMetrics
