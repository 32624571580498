import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import Response from '@athena/core/client/response'
import RevueObjective from '@athena/core/model/revue-objective'

import {
  addRevueObjective,
  getRevueNotesBySourceTarget,
  getRevueObjectiveById,
  getRevueObjectiveBySourceTarget,
  getDistinctRevueObjectiveCreationYears,
  removeRevueObjective,
  removeRevueObjectiveGoal,
  updateRevueNotes,
  updateRevueObjective,
  updRevueObjectiveShowRatings,
  createBulkObjectives,
  checkIfObjectivesCreated,
  updateBulkObjectives,
} from '@apollo/client/api/revue'

export const QueryKey = {
  ObjectiveById: 'objective-by-id',
  DistinctRevueObjectiveCreationYears: 'revue-objective-creation-years',
  CheckIfObjectivesCreated: 'check-if-objectives-created',
}

const useInvalidate = (queryClient: QueryClient) => (resp?: Response<RevueObjective>) => {
  queryClient.invalidateQueries({ queryKey: [QueryKey.ObjectiveById, resp?.data?.id] })
}

export const useAddRevueObjective = () => {
  return useMutation({
    mutationFn: addRevueObjective,
    onError: (err) => toast.error(`Error adding Revue Objective: ${err}`),
  })
}

export const useUpdateRevueObjective = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: updateRevueObjective,
    onSuccess: useInvalidate(client),
    onError: (err) => toast.error(`Error updating Revue Objective: ${err}`),
  })
}

export const useRevueObjectiveBySourceTarget = () => {
  return useMutation({
    mutationFn: getRevueObjectiveBySourceTarget,
    onError: (err) => toast.error(`Error fetching Revue Objectives ${err}`),
  })
}

export const useRevueObjectiveById = (id: string) =>
  useQuery({
    queryKey: [QueryKey.ObjectiveById, id],
    queryFn: () => getRevueObjectiveById(id),
    enabled: !!id,
  })

export const useRemoveRevueObjective = () => {
  return useMutation({
    mutationFn: removeRevueObjective,
    onError: (err) => toast.error(`Error removing revue objective ${err}`),
  })
}

export const useRevueNotesBySourceTarget = () => {
  return useMutation({
    mutationFn: getRevueNotesBySourceTarget,
    onError: (err) => toast.error(`Error fetching Revue Notes ${err}`),
  })
}

export const useRemoveRevueObjectiveGoal = () => {
  return useMutation({
    mutationFn: removeRevueObjectiveGoal,
    onError: (err) => toast.error(`Error removing revue objective goal ${err}`),
  })
}

export const useRevueUpdateNotes = () => {
  return useMutation({
    mutationFn: updateRevueNotes,
    onError: (err) => toast.error(`Error updating Revue Notes ${err}`),
  })
}

export const useGetDistinctRevueObjectiveCreationYears = (target: string) =>
  useQuery({
    queryKey: [QueryKey.DistinctRevueObjectiveCreationYears, target],
    queryFn: () => getDistinctRevueObjectiveCreationYears(target),
    enabled: !!target,
  })

export const useUpdRevueObjectiveShowRatings = () => {
  return useMutation({
    mutationFn: updRevueObjectiveShowRatings,
    onError: (err) => toast.error(`Error updating Revue Objective: ${err}`),
  })
}

export const useCreateBulkObjectives = () => {
  return useMutation({
    mutationFn: createBulkObjectives,
    onError: (err) => toast.error(`Error creating Revue Objective: ${err}`),
  })
}

export const useCheckIfObjectivesCreated = () => {
  return useMutation({
    mutationFn: checkIfObjectivesCreated,
    onError: (err) => toast.error(`Error checking Revue Objective: ${err}`),
  })
}

export const useUpdateBulkObjectives = () => {
  return useMutation({
    mutationFn: updateBulkObjectives,
    onError: (err) => toast.error(`Error updating Revue Objective: ${err}`),
  })
}
