import React from 'react'

import Button, { IconName } from '@athena/component/atom/button'
import Icon from '@athena/component/atom/icon'
import Typography, { TypographySize } from '@athena/component/atom/typography'

import Props from './interface'
import Style from './style.module.scss'

const ConfirmationDialog: React.FC<Props> = ({
  onConfirm,
  'data-test': dataTest,
  confirmationMessage = 'Are you sure?',
  description = '',
  confirmButtonText = 'Confirm',
  returnButtonText = '',
  disableConfirm = false,
}) => (
  <div className={Style.container} data-test={`${dataTest}.container`}>
    <div className="flex flex-col items-center">
      <Typography customClass={Style.title} data-test={`${dataTest}.title`} size={TypographySize.Header1}>
        {confirmationMessage}
      </Typography>
      {description && (
        <div className={Style.description} data-test={`${dataTest}.description-remove`}>
          {description}
        </div>
      )}

      <div className={Style.buttonContainer}>
        <Button customClasses={Style.return} data-test={`${dataTest}.return`} onClick={() => onConfirm(false)}>
          {returnButtonText ? (
            returnButtonText
          ) : (
            <Icon data-test={`${dataTest}.confirmation.close-icon`} name={IconName.XMark} />
          )}
        </Button>
        <Button
          customClasses={Style.confirm}
          data-test={`${dataTest}.confirm`}
          disabled={disableConfirm}
          onClick={() => onConfirm(true)}
        >
          {confirmButtonText}
        </Button>
      </div>
    </div>
  </div>
)

export default ConfirmationDialog
export type { Props }
