import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import Response from '@athena/core/client/response'
import Promotion from '@athena/core/model/promotion'

import { updatePromotion, getPromotionByObjectiveId, addPromotion, removePromotion } from '@apollo/client/api/promotion'

export const QueryKey = {
  PromotionById: 'promotion-by-id',
  PromotionByObjectiveId: 'promotion-by-objective-id',
}

const useInvalidate = (queryClient: QueryClient) => (resp?: Response<Promotion>) => {
  queryClient.invalidateQueries({ queryKey: [QueryKey.PromotionById, resp?.data?.id] })
  queryClient.invalidateQueries({ queryKey: [QueryKey.PromotionByObjectiveId, resp?.data?.rel.objective] })
}

const useInvalidateAll = (queryClient: QueryClient) => () => {
  queryClient.invalidateQueries({ queryKey: [QueryKey.PromotionById] })
  queryClient.invalidateQueries({ queryKey: [QueryKey.PromotionByObjectiveId] })
}

export const useAddPromotion = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: addPromotion,
    onSuccess: useInvalidate(client),
    onError: (err) => toast.error(`Error adding Promotion: ${err}`),
  })
}

export const useUpdatePromotion = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: updatePromotion,
    onSuccess: useInvalidate(client),
    onError: (err) => toast.error(`Error updating Promotion: ${err}`),
  })
}

export const usePromotionByObjectiveId = (id: string, isManager: boolean) =>
  useQuery({
    queryKey: [QueryKey.PromotionByObjectiveId, id],
    queryFn: () => getPromotionByObjectiveId(id),
    enabled: !!id && isManager,
  })

export const useRemovePromotion = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: removePromotion,
    onSuccess: useInvalidateAll(client),
    onError: (err) => toast.error(`Error removing Promotion: ${err}`),
  })
}
