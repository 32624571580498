import { assignIn, pick } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelWarehouse } from './base-model'

export type TraitsSerialized = z.infer<typeof Traits.schema>

export type TraitsWarehouse = BaseModelWarehouse & {
  id: string
  justification: string
  created: string
  id_source: string
  title: string
  key: string
}

export default class Traits extends BaseModel {
  justification = ''
  id_source = ''
  title = ''
  key = ''

  constructor(id: string) {
    super(id, '', null, null)
  }

  static override readonly schema = BaseModel.schema.extend({
    justification: z.string().max(500).default(''),
    id_source: z.string().max(10).default(''),
    title: z.string().max(100).default(''),
    key: z.string().max(10).default(''),
  })

  static override fromSerialized(recordInput: TraitsSerialized): Traits {
    const record = Traits.schema.parse(recordInput)
    const model = new Traits(recordInput.id)
    model.justification = record.justification || ''
    model.created = BaseModel.parseDate(record.created)
    model.id_source = record.id_source || ''
    model.title = record.title || ''
    model.key = record.key || ''

    return model
  }

  static override fromWarehouse(record: TraitsWarehouse): Traits {
    const model = new Traits(record.id)
    model.justification = record.justification || ''
    model.created = BaseModel.parseDate(record.created)
    model.id_source = record.id_source || ''
    model.title = record.title || ''
    model.key = record.key || ''

    return model
  }

  override toSerialized(): TraitsSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
    } as unknown as TraitsSerialized
  }

  project(projection: string[]): Partial<TraitsSerialized> {
    const serialized = this.toSerialized()
    return projection?.length ? pick(serialized, projection) : serialized
  }
}
