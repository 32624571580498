import { z } from 'zod'

const DefaultOffset = 0
const DefaultPageSize = 20

export interface PagerData {
  offset?: number
  pageSize?: number
}

export type PagerSerialized = {
  offset: number
  'page-size': number
}

export default class Pager {
  offset = DefaultOffset
  pageSize = DefaultPageSize

  constructor(offset?: number, pageSize?: number) {
    this.offset = offset || DefaultOffset
    this.pageSize = pageSize || DefaultPageSize
  }

  static schema = z.object({
    offset: z.number().min(0).max(100000000).default(DefaultOffset),
    pageSize: z.number().min(0).max(1000000).default(DefaultPageSize),
  })

  static fromData(data?: PagerData): Pager {
    return new Pager(data?.offset, data?.pageSize)
  }

  static fromSerialized(recordInput: PagerSerialized): Pager {
    const record = Pager.schema.parse(recordInput)
    return new Pager(record.offset, record.pageSize)
  }

  toSerialized(): PagerSerialized {
    return {
      offset: this.offset,
      'page-size': this.pageSize,
    }
  }
}
