import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { useEffect, useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'

import Feedback from '@athena/core/model/feedback'

import { useFeedbackByTargetYear } from '@apollo/client/hook/engagement'

import { FeedbackCount, FeedbackGroups } from './../interface'
import Style from './../style.module.scss'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const labels = [
  'Delivers what is required',
  'Consistent positive impact',
  'Collaborates well with Lab49',
  'Collaborates well with client',
]

export interface MapProps {
  empId: string
  year: string | null
}

// eslint-disable-next-line react/function-component-definition
export default function LikeartBar({ empId, year }: MapProps) {
  const getFilteredFeedbacks = useFeedbackByTargetYear(empId, year)
  const feedbackData = getFilteredFeedbacks?.data?.data as Feedback[]
  const [counts, setCounts] = useState<FeedbackCount>({
    total: 0,
  })

  const [ranks, setRanks] = useState<FeedbackGroups>({
    disagree: [0, 0, 0, 0],
    ratherDisagree: [0, 0, 0, 0],
    ratherAgree: [0, 0, 0, 0],
    agree: [0, 0, 0, 0],
  })

  const data = {
    labels,
    datasets: [
      {
        label: 'Disagree',
        data: ranks.disagree,
        backgroundColor: 'rgb(110, 110, 175)',
      },
      {
        label: 'Rather Disagree',
        data: ranks.ratherDisagree,
        backgroundColor: 'rgb(0, 165, 230)',
      },
      {
        label: 'Rather Agree',
        data: ranks.ratherAgree,
        backgroundColor: 'rgb(245, 140, 70)',
      },
      {
        label: 'Agree',
        data: ranks.agree,
        backgroundColor: 'rgb(150, 200, 105)',
      },
    ],
  }

  const options = {
    indexAxis: 'y' as const,
    plugins: {
      title: {
        display: true,
        align: 'start' as const,
        text: `Peer Reviews (${counts.total})`,
        color: '#ffffff',
        font: { weight: 'bold', size: 16 },
      },
      legend: {
        position: 'top' as const,
        align: 'end' as const,
        labels: {
          color: '#ffffff',
          boxWidth: 16,
          boxHeight: 16,
          textAlign: 'center' as const,
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        datalabels: {
          color: 'white',
          font: {
            weight: 'bold',
          },
        },
        suggestedMin: 0,
        suggestedMax: 100,
        border: {
          color: '#ffffff',
        },
        stacked: true,
        ticks: {
          color: '#E6E6E6',
          callback(value: any): string | null {
            if (value % 25 === 0) return value + '%'
            return null
          },
        },
        grid: {
          color: '#E6E6E6',
          drawOnChartArea: false,
          tickColor: '#E6E6E6',
        },
      },
      y: {
        border: {
          color: '#E6E6E6',
        },
        stacked: true,
        ticks: {
          color: '#E6E6E6',
        },
        grid: {
          display: false,
          color: '#E6E6E6',
          drawOnChartArea: false,
          tickColor: '#E6E6E6',
        },
      },
    },
  }

  const dataSet = useMemo(() => {
    return {
      delivery: {
        disagree: 0,
        ratherDisagree: 0,
        ratherAgree: 0,
        agree: 0,
      },
      positiveImpact: {
        disagree: 0,
        ratherDisagree: 0,
        ratherAgree: 0,
        agree: 0,
      },
      teamCollaboration: {
        disagree: 0,
        ratherDisagree: 0,
        ratherAgree: 0,
        agree: 0,
      },
      clientCollaboration: {
        disagree: 0,
        ratherDisagree: 0,
        ratherAgree: 0,
        agree: 0,
      },
    }
  }, [])

  const getRankStatus = (score: string) => {
    if (score === '1' || score === '2') {
      return 'disagree'
    } else if (score === '3') {
      return 'ratherDisagree'
    } else if (score === '4') {
      return 'ratherAgree'
    } else if (score === '5' || score === '6') {
      return 'agree'
    }
    return null
  }

  const rankDataSet: FeedbackGroups = {
    disagree: [],
    ratherDisagree: [],
    ratherAgree: [],
    agree: [],
  }

  const getRankPercent = (rankCount: number, totalCount: number) => {
    if (rankCount) {
      return Math.round((rankCount / totalCount) * 100)
    }

    return 0
  }

  useEffect(() => {
    const parseDataSet = () => {
      const totalDelivery: number = Object.values(dataSet.delivery).reduce((accumulator, value) => {
        return accumulator + value
      }, 0)
      const totalPositiveImpact: number = Object.values(dataSet.positiveImpact).reduce((accumulator, value) => {
        return accumulator + value
      }, 0)
      const totalTeamCollaboration: number = Object.values(dataSet.teamCollaboration).reduce((accumulator, value) => {
        return accumulator + value
      }, 0)
      const totalClientCollaboration: number = Object.values(dataSet.clientCollaboration).reduce(
        (accumulator, value) => {
          return accumulator + value
        },
        0
      )

      rankDataSet.disagree = [
        getRankPercent(dataSet.delivery.disagree, totalDelivery),
        getRankPercent(dataSet.positiveImpact.disagree, totalPositiveImpact),
        getRankPercent(dataSet.teamCollaboration.disagree, totalTeamCollaboration),
        getRankPercent(dataSet.clientCollaboration.disagree, totalClientCollaboration),
      ]
      rankDataSet.ratherDisagree = [
        getRankPercent(dataSet.delivery.ratherDisagree, totalDelivery),
        getRankPercent(dataSet.positiveImpact.ratherDisagree, totalPositiveImpact),
        getRankPercent(dataSet.teamCollaboration.ratherDisagree, totalTeamCollaboration),
        getRankPercent(dataSet.clientCollaboration.ratherDisagree, totalClientCollaboration),
      ]
      rankDataSet.ratherAgree = [
        getRankPercent(dataSet.delivery.ratherAgree, totalDelivery),
        getRankPercent(dataSet.positiveImpact.ratherAgree, totalPositiveImpact),
        getRankPercent(dataSet.teamCollaboration.ratherAgree, totalTeamCollaboration),
        getRankPercent(dataSet.clientCollaboration.ratherAgree, totalClientCollaboration),
      ]
      rankDataSet.agree = [
        getRankPercent(dataSet.delivery.agree, totalDelivery),
        getRankPercent(dataSet.positiveImpact.agree, totalPositiveImpact),
        getRankPercent(dataSet.teamCollaboration.agree, totalTeamCollaboration),
        getRankPercent(dataSet.clientCollaboration.agree, totalClientCollaboration),
      ]
      setRanks(rankDataSet)
    }

    if (feedbackData) {
      const feedbacks = feedbackData

      setCounts({
        total: feedbacks.length,
      })

      feedbacks.forEach((feedback) => {
        const delivery = getRankStatus(feedback.delivery as string)
        if (delivery) {
          dataSet['delivery'][delivery] += 1
        }
        const positiveImpact = getRankStatus(feedback.positiveImpact as string)
        if (positiveImpact) {
          dataSet['positiveImpact'][positiveImpact] += 1
        }
        const teamCollaboration = getRankStatus(feedback.teamCollaboration as string)
        if (teamCollaboration) {
          dataSet['teamCollaboration'][teamCollaboration] += 1
        }
        const clientCollaboration = getRankStatus(feedback.clientCollaboration as string)
        if (clientCollaboration) {
          dataSet['clientCollaboration'][clientCollaboration] += 1
        }
      })

      parseDataSet()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackData])

  return (
    <>
      <div className={Style.metricBlock}>
        <Bar data={data} height={'90%'} options={options} />
      </div>
    </>
  )
}
