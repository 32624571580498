import Response from '@athena/core/client/response'
import { HttpStatus } from '@athena/core/http'
import {
  BaseModel,
  DateRange,
  Engagement,
  EngagementSeat,
  Feedback,
  FeedbackRequest,
  Quantum,
  Resource,
} from '@athena/core/model'
import { EngagementGroups, EngagementGroupType } from '@athena/core/model/engagement-grouped-list'
import Traits from '@athena/core/model/traits'

import Environment from '@apollo/client/environment'
import { getAxiosInstance } from '@apollo/client/util/interceptor'

const client = getAxiosInstance({
  baseURL: `${Environment.apollo.host}/engagement`,
  timeout: Environment.apollo.timeout,
  validateStatus: (s) => s > 199 && s < 300,
  withCredentials: true,
})

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //

export const getEngagement = async (idInput: string): Promise<Response<Engagement>> => {
  const id = Engagement.schema.shape.id.parse(idInput)
  const resp = await client.get(`/${id}`)
  return Response.single(resp, Engagement.fromSerialized)
}

export const getEngagementList = async (): Promise<Response<Engagement[]>> => {
  const resp = await client.get('/')
  return Response.list(resp, Engagement.fromSerialized)
}

export const getEngagementByActivity = async (activityInput: string): Promise<Response<Engagement[]>> => {
  const customer = Engagement.schema.shape.rel.shape.activity.parse(activityInput)
  const resp = await client.get(`/customer/${customer}`)
  return Response.list(resp, Engagement.fromSerialized)
}

export const getEngagements = async (
  group: EngagementGroupType,
  limit: number,
  offset: number,
  returnCount: boolean
): Promise<Response<EngagementGroups>> => {
  const resp = await client.get(`/group/${group}?limit=${limit}&offset=${offset}&returnCount=${returnCount}`)
  const data = EngagementGroups.fromSerialized(resp.data)
  return new Response(resp.status, data)
}

export const addEngagement = async (engagement: Engagement): Promise<Response<Engagement>> => {
  const serialized = Engagement.schema.parse(engagement.toSerialized())
  const resp = await client.post('/', serialized)
  return Response.single(resp, Engagement.fromSerialized)
}

export const updateEngagement = async (engagement: Engagement): Promise<Response<Engagement>> => {
  const serialized = Engagement.schema.parse(engagement.toSerialized())
  const resp = await client.put(`/${engagement.id}`, serialized)
  return Response.single(resp, Engagement.fromSerialized)
}

export const removeEngagement = async (idInput: string): Promise<Response<boolean>> => {
  const id = Engagement.schema.shape.id.parse(idInput)
  const resp = await client.delete(`/${id}`)
  return Response.deletion(resp)
}

export const getEngagementsByResource = async (idResource: string, year: string): Promise<Response<Engagement[]>> => {
  const id = Resource.schema.shape.id.parse(idResource)
  const resp = await client.get(`/resource/${id}`, { params: { year } })
  return Response.list(resp, Engagement.fromSerialized)
}

export const getEngagementsDateRange = async (idEmployee: string): Promise<Response<DateRange>> => {
  const id = Resource.schema.shape.id.parse(idEmployee)
  const resp = await client.get(`/daterange/${id}`)
  return resp
}
// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //

export const getFeedbackRequest = async (idInput: string): Promise<Response<FeedbackRequest>> => {
  const id = FeedbackRequest.schema.shape.id.parse(idInput)
  const resp = await client.get(`/feedback-request/${id}`)
  return Response.single(resp, FeedbackRequest.fromSerialized)
}

export const getFeedbackRequestByEngagement = async (idInput: string): Promise<Response<FeedbackRequest[]>> => {
  const id = FeedbackRequest.schema.shape.rel.shape.engagement.parse(idInput)
  const resp = await client.get(`/${id}/feedback-request`)
  return Response.list(resp, FeedbackRequest.fromSerialized)
}
//TODO review if this is needed because it is not used in the UI
export const getFeedbackRequestBySource = async (idInput: string): Promise<Response<FeedbackRequest[]>> => {
  const id = FeedbackRequest.schema.shape.rel.shape.source.parse(idInput)
  const resp = await client.get(`/feedback-request/source/${id}`)
  return Response.list(resp, FeedbackRequest.fromSerialized)
}

export const getFeedbackRequestByTarget = async (idInput: string): Promise<Response<FeedbackRequest[]>> => {
  const id = FeedbackRequest.schema.shape.rel.shape.target.parse(idInput)
  const resp = await client.get(`/feedback-request/target/${id}`)
  return Response.list(resp, FeedbackRequest.fromSerialized)
}

export const getFeedbackRequests = async (requestData: {
  ids: string[]
  states: string[]
  source: string
  isForManager: boolean
}): Promise<Response<FeedbackRequest[]>> => {
  let resp
  if (requestData.isForManager) {
    const formData = { ids: requestData.ids, states: requestData.states }
    resp = await client.post(`/feedback-request/target-array`, formData)
  } else {
    resp = await client.get(`/feedback-request/source/${requestData.source}`)
  }
  return Response.list(resp, FeedbackRequest.fromSerialized)
}

export const addFeedbackRequest = async (reequest: FeedbackRequest): Promise<Response<FeedbackRequest>> => {
  const serialized = FeedbackRequest.schema.parse(reequest.toSerialized())
  const resp = await client.post('/feedback-request', serialized)
  return Response.single(resp, FeedbackRequest.fromSerialized)
}

export const addFeedbackRequestsForEngagement = async (idInput: string): Promise<Response<null>> => {
  const id = Feedback.schema.shape.rel.shape.engagement.parse(idInput)
  const resp = await client.post(`/${id}/feedback-request`)
  return new Response(resp.status)
}

export const updateFeedbackRequest = async (request: FeedbackRequest): Promise<Response<FeedbackRequest>> => {
  const serialized = FeedbackRequest.schema.parse(request.toSerialized())
  const resp = await client.put(`/feedback-request/${request.id}`, serialized)
  return Response.single(resp, FeedbackRequest.fromSerialized)
}

export const removeFeedbackRequest = async (idInput: string): Promise<Response<boolean>> => {
  const id = FeedbackRequest.schema.shape.id.parse(idInput)
  const resp = await client.delete(`/feedback-request/${id}`)
  return Response.deletion(resp)
}

// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //

export const getFeedback = async (idInput: string): Promise<Response<Feedback>> => {
  const id = Feedback.schema.shape.id.parse(idInput)
  const resp = await client.get(`/feedback/${id}`)
  return Response.single(resp, Feedback.fromSerialized)
}

export const getFeedbackList = async (): Promise<Response<Feedback[]>> => {
  const resp = await client.get('/feedback')
  return Response.list(resp, Feedback.fromSerialized)
}

export const getFeedbackByEngagement = async (idInput: string): Promise<Response<Feedback[]>> => {
  const id = Feedback.schema.shape.rel.shape.engagement.parse(idInput)
  const resp = await client.get(`/${id}/feedback`)
  return Response.list(resp, Feedback.fromSerialized)
}

export const getFeedbackByFeedbackRequest = async (idInput: string): Promise<Response<Feedback>> => {
  const id = Feedback.schema.shape.rel.shape.engagement.parse(idInput)
  const resp = await client.get(`/feedback/feedback-request/${id}`)
  return Response.single(resp, Feedback.fromSerialized)
}

export const getFeedbackBySource = async (idInput: string): Promise<Response<Feedback[]>> => {
  const id = Feedback.schema.shape.rel.shape.source.parse(idInput)
  const resp = await client.get(`/feedback/source/${id}`)
  return Response.list(resp, Feedback.fromSerialized)
}

export const getFeedbackByTargetArray = async (idsInput: string[]): Promise<Response<Feedback[]>> => {
  const formData = { ids: idsInput }
  const resp = await client.post(`/feedback/target`, formData)
  return Response.list(resp, Feedback.fromSerialized)
}

export const getFeedbackBySourceAndTarget = async (feedback: Feedback): Promise<Response<Feedback[]>> => {
  const sourceId = Feedback.schema.shape.rel.shape.source.parse(feedback.rel.source)
  const targetId = Feedback.schema.shape.rel.shape.target.parse(feedback.rel.target)
  const resp = await client.get(`/feedback/source-target/${sourceId}&${targetId}`)
  return Response.list(resp, Feedback.fromSerialized)
}

export const getFeedbackByTargetYear = async (
  idInput: string,
  feedbackYear: string | null
): Promise<Response<Feedback[]>> => {
  const id = Feedback.schema.shape.rel.shape.target.parse(idInput)
  const year = feedbackYear ? parseInt(feedbackYear, 10) : null
  const resp = await client.get(`/feedback-target/${id}/${year}`)
  return Response.list(resp, Feedback.fromSerialized)
}

export const getFeedbackByTrait = async (trait: string, target: string): Promise<Response<Traits[]>> => {
  const resp = await client.get(`/feedback/by-trait/${target}&${trait}`)
  return Response.list(resp, Traits.fromSerialized)
}

export const addFeedback = async (feedback: Feedback): Promise<Response<Feedback>> => {
  const serialized = Feedback.schema.parse(feedback.toSerialized())
  const resp = await client.post('/feedback', serialized)
  return Response.single(resp, Feedback.fromSerialized)
}

export const updateFeedback = async (feedback: Feedback): Promise<Response<Feedback>> => {
  const serialized = Feedback.schema.parse(feedback.toSerialized())
  const resp = await client.put(`/feedback/${feedback.id}`, serialized)
  return Response.single(resp, Feedback.fromSerialized)
}
//TODO review if this is needed because it is not used in the UI
export const removeFeedback = async (idInput: string): Promise<Response<boolean>> => {
  const id = Feedback.schema.shape.id.parse(idInput)
  const resp = await client.delete(`/feedback/${id}`)
  return Response.deletion(resp)
}

export const getFeedbackRequestMaxCreatedDateForActiveSeats = async (
  idInput: string
): Promise<Response<Quantum<Date>>> => {
  const id = Engagement.schema.shape.id.parse(idInput)
  const resp = await client.get(`/${id}/feedback-request-max-created-date-for-active-seats`)
  return resp.status === HttpStatus.NoContent ? new Response(resp.status) : Response.single(resp, BaseModel.parseDate)
}

export const canBeDeleted = async (idInput: string): Promise<Response<boolean>> => {
  const id = Engagement.schema.shape.id.parse(idInput)
  const resp = await client.get(`/${id}/can-be-deleted`)
  return Response.single(resp, BaseModel.parseBoolean)
}

export const getRoleById = async (idsInput: string[]): Promise<Response<EngagementSeat[]>> => {
  const formData = { ids: idsInput }
  const resp = await client.post('/role-by-ids', formData)
  return Response.list(resp, EngagementSeat.fromSerialized)
}
// ---------- ---------- ---------- ---------- ---------- ---------- ---------- ----------  //
