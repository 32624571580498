import classnames from 'classnames'
import React from 'react'

import Icon, { IconName } from '@athena/component/atom/icon'

import Props, { ButtonExpand, ButtonFill, ButtonSize, ButtonColor } from './interface'
import Style from './style.module.scss'

const cx = classnames.bind(Style)

const Button: React.FC<Props> = ({
  'data-test': dataTest = 'button',
  basicOutline = false,
  children,
  color = ButtonColor.Dark,
  customClasses,
  disabled,
  expand = ButtonExpand.Block,
  fill = ButtonFill.Solid,
  icon,
  size = ButtonSize.Default,
  onClick,
  onMouseDown,
  onMouseUp,
  onMouseLeave,
}) => {
  const containerStyle = cx([
    Style.btn,
    Style[`color-${color}`],
    { [Style[`expand-full`]]: expand === ButtonExpand.Full },
    Style[`fill-${fill}`],
    Style[`size-${size}`],
    { [Style.disabled]: disabled },
    { [Style.hasFocusHalo]: !basicOutline },
    customClasses,
  ])

  return (
    <button
      className={containerStyle}
      data-test={`${dataTest}.element`}
      disabled={disabled}
      type="button"
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
    >
      {icon && <Icon customClasses={Style.icon} data-test={`${dataTest}.icon`} name={icon} />}
      {children}
    </button>
  )
}

export default Button
export type { Props }
export { ButtonColor, ButtonExpand, ButtonFill, ButtonSize, IconName }
