import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import Response from '@athena/core/client/response'
import AssessmentWindow from '@athena/core/model/assessment-window'

import { addAssessmentWindow, getAssessmentWindow, updateAssessmentWindow } from '@apollo/client/api/assessment-window'

export const QueryKey = {
  AssessmentWindow: 'assessment-window',
}

const useInvalidateAssessmentWindow = (client: QueryClient) => (resp?: Response<AssessmentWindow>) => {
  client.invalidateQueries({ queryKey: [QueryKey.AssessmentWindow, resp?.data?.targetYear] })
}

export const useAssessmentWindow = (year: number) =>
  useQuery({
    queryKey: [QueryKey.AssessmentWindow, year],
    queryFn: () => getAssessmentWindow(year),
    enabled: !!year,
  })

export const useAddAssessmentWindow = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: addAssessmentWindow,
    onSuccess: useInvalidateAssessmentWindow(client),
    onError: (err) => toast.error(`Error adding assessment window ${err}`),
  })
}

export const useUpdateAssessmentWindow = () => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: updateAssessmentWindow,
    onSuccess: useInvalidateAssessmentWindow(client),
    onError: (err) => toast.error(`Error updating assessment window ${err}`),
  })
}
