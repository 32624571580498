import Response from '@athena/core/client/response'
import AssessmentWindow from '@athena/core/model/assessment-window'

import Environment from '@apollo/client/environment'
import { getAxiosInstance } from '@apollo/client/util/interceptor'

const client = getAxiosInstance({
  baseURL: `${Environment.apollo.host}/assessmentWindow`,
  timeout: Environment.apollo.timeout,
  validateStatus: (s) => s > 199 && s < 300,
  withCredentials: true,
})

export const getAssessmentWindow = async (yearInput: number): Promise<Response<AssessmentWindow>> => {
  const year = AssessmentWindow.schema.shape.targetYear.parse(yearInput)
  const resp = await client.get(`/${year}`)
  return Response.single(resp, AssessmentWindow.fromSerialized)
}

export const addAssessmentWindow = async (assessmentWindow: AssessmentWindow): Promise<Response<AssessmentWindow>> => {
  const serialized = AssessmentWindow.schema.parse(assessmentWindow.toSerialized())
  const resp = await client.post('/', serialized)
  return Response.single(resp, AssessmentWindow.fromSerialized)
}

export const updateAssessmentWindow = async (
  assessmentWindow: AssessmentWindow
): Promise<Response<AssessmentWindow>> => {
  const serialized = AssessmentWindow.schema.parse(assessmentWindow.toSerialized())
  const resp = await client.put(`/${assessmentWindow.id}`, serialized)
  return Response.single(resp, AssessmentWindow.fromSerialized)
}
