import classnames from 'classnames'
import { isEmpty, isFunction } from 'lodash'
import React, { useMemo, useState } from 'react'
import Select, { GroupBase, OptionProps } from 'react-select'

import Props, { Option } from './interface'
import Style from './style.module.scss'

const cx = classnames.bind(Style)

const noop = () => null

// NOTE: These styles are defined in JS instead of SCSS to deal with idiosynchrasies of a
//       third-party compnent that is being integrated as a child.
const portalStyle = { zIndex: 800 }
const menuStyle = { fontSize: '14px', padding: '8px 4px', maxHeight: '200px' }
const optionStyle = (state: OptionProps<Option, boolean, GroupBase<Option>>) => {
  let background = 'transparent'
  if (state.isSelected) {
    background = '#e6e6e6'
  } else if (state.isFocused) {
    background = '#f2f2f2'
  }
  return {
    paddingLeft: '16px',
    cursor: 'pointer',
    borderRadius: '8px',
    color: 'black',
    background,
  }
}

const defaultComponents = { ClearIndicator: noop, IndicatorSeparator: noop }

const Combobox: React.FC<Props> = ({
  'data-test': dataTest = 'combobox',
  customClasses = '',
  disabled = false,
  help = '',
  invalid = false,
  isMulti = false,
  isSearchable = true,
  label = '',
  options = [],
  placeholder = '',
  value = null,
  enableChevron = true,
  onBlur,
  onChange,
}) => {
  const [hasFocus, setHasFocus] = useState(false)
  const selectComponents = enableChevron ? defaultComponents : { ...defaultComponents, DropdownIndicator: noop }
  const handleChange = (selected: Option | Option[]) => {
    if (disabled) {
      return
    }
    isFunction(onChange) && onChange(selected)
  }

  // always displays the placeholder when there is no label
  const localPlaceholder = useMemo(
    () => ((hasFocus || !label) && placeholder ? placeholder : ''),
    [hasFocus, label, placeholder]
  )

  return (
    <div
      className={cx([Style.container, customClasses])}
      data-test={`${dataTest}.combobox`}
      onClick={() => setHasFocus(true)}
      onFocus={() => setHasFocus(true)}
    >
      {label && (
        <label className={cx([Style.label, { [Style.focused]: disabled || hasFocus || !isEmpty(value) }])}>
          {label}
        </label>
      )}
      <Select
        className={cx([Style.combobox, { [Style.invalid]: invalid, [Style.withLabel]: label }])}
        classNamePrefix="combobox"
        components={selectComponents}
        isDisabled={disabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        menuPortalTarget={document.body}
        noOptionsMessage={noop}
        options={options}
        placeholder={localPlaceholder}
        value={value || undefined}
        styles={{
          menuPortal: (base) => ({ ...base, ...portalStyle }),
          menuList: (base) => ({ ...base, ...menuStyle }),
          option: (base, state) => ({
            ...base,
            ...optionStyle(state),
          }),
        }}
        onBlur={() => {
          setHasFocus(false)
          isFunction(onBlur) && onBlur()
        }}
        onChange={(option) => {
          const optionValue = isMulti ? (option as Option[]) : (option as Option)
          handleChange(optionValue)
        }}
      />
      {help && (
        <div>
          <span className={cx([Style.helperText, { [Style.invalid]: invalid }])} data-test={`${dataTest}.help`}>
            {help}
          </span>
        </div>
      )}
    </div>
  )
}

export default Combobox
export type { Props, Option }
