import { toLower, toNumber, trim } from 'lodash'

const Environment = {
  isDev: toLower(trim(process.env['NODE_ENV'])) !== 'production',
  api: {
    host: process.env['ART_API_HOST'],
    timeout: toNumber(process.env['ART_API_TIMEOUT']),
  },
}

export default Environment
