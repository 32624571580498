import classNames from 'classnames'
import { map, isFunction } from 'lodash'
import React, { useEffect, useState } from 'react'

import RadioButton from '@athena/component/atom/radio-button'

import Props, { RadioGroupItem } from './interface'
import Style from './style.module.scss'

const cx = classNames.bind(Style)

const RadioGroup: React.FC<Props> = ({
  'data-test': dataTest = 'radio-group',
  customClasses = '',
  disabled = false,
  equalWidth = false,
  items = [],
  label = '',
  value = '',
  onChange,
}) => {
  const [radioItems, setRadioItems] = useState(items)

  useEffect(() => {
    if (!value) {
      return
    }
    setRadioItems((existingItems) => existingItems.map((item) => ({ ...item, selected: item.value === value })))
  }, [value])

  const handleClick = (index: number) => {
    if (disabled) {
      return
    }
    const updatedItems = radioItems.map((item, i) => ({ ...item, selected: i === index }))
    setRadioItems(updatedItems as unknown as RadioGroupItem[])
    isFunction(onChange) && onChange(radioItems[index])
  }
  return (
    <div className={cx([Style.radioGroup, 'flex w-full', customClasses])} data-test={`${dataTest}.container`}>
      {label && (
        <div className={cx([Style.label])} data-test={`${dataTest}.label`}>
          {label}
        </div>
      )}
      {map(radioItems, ({ value: itemValue, selected = false }, index) => (
        <div
          key={itemValue}
          className={cx([{ [Style.equalWidth]: equalWidth }, { [Style.fixedWidth]: !equalWidth }])}
          data-test={`${dataTest}.list-element`}
        >
          <RadioButton
            data-test={`${dataTest}.radio-group`}
            disabled={disabled}
            value={selected}
            onChange={() => handleClick(index)}
          />
        </div>
      ))}
    </div>
  )
}

export default RadioGroup
export type { Props }
