import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import Combobox, { Option } from '@athena/component/atom/combobox'
import { Icon, IconName, IconSize, IconType } from '@athena/component/atom/icon'
import Loading from '@athena/component/atom/loading'
import { Feedback, Quantum, StrengthImprov } from '@athena/core/model'

import { useFeedbackBySourceAndTargetCall } from '@apollo/client/hook/engagement'
import { getColor, formatDate } from '@future-view/frontend/component/peerspective-matrix/util'
import ResourceCard from '@future-view/frontend/component/resource-card'
import {
  potentialOptions,
  attritionOptions,
  motivationOptions,
  pointSurveyItems,
} from '@future-view/frontend/component/survey-form/survey-utils'
import { generateOptionsFromArray } from '@future-view/frontend/util'

import Props from './interface'
import Style from './style.module.scss'

const notObserved = 'Not observed'
const notApplicable = 'N/A'

const PeerToPeerReviews: React.FC<Props> = ({
  'data-test': dataTest = 'peer-to-peer-reviews',
  source,
  target,
  wrapperCustomClasses,
}) => {
  const feedbacksData = useFeedbackBySourceAndTargetCall(new Feedback('', '', '', source?.id, target?.id))
  const feedbacks = feedbacksData.data?.data || []
  const [selectedFeedback, setSelectedFeedback] = useState<Quantum<Feedback>>(feedbacks ? feedbacks[0] : new Feedback())
  const [strengthsWithJustification, setStrengthsWithJustification] = useState([] as StrengthImprov[])
  const [strengthsWithoutJustification, setStrengthsWithoutJustification] = useState([] as Option[])
  const [improvementAreasWithJustification, setImprovementAresWithJustification] = useState([] as StrengthImprov[])
  const [improvementAreasWithoutJustification, setImprovementAreasWithoutJustification] = useState([] as Option[])
  useEffect(() => {
    if (feedbacks) {
      setSelectedFeedback(feedbacks ? feedbacks[0] : new Feedback())
    }
  }, [feedbacks])

  useEffect(() => {
    const strengthPartitionedArray: [StrengthImprov[], StrengthImprov[]] = (selectedFeedback?.strengths ?? []).reduce(
      (acc, curr) => {
        acc[curr.justification ? 0 : 1].push(curr)
        return acc
      },
      [[], []] as [StrengthImprov[], StrengthImprov[]]
    )
    setStrengthsWithJustification(strengthPartitionedArray[0])
    setStrengthsWithoutJustification(generateOptionsFromArray(strengthPartitionedArray[1].map((item) => item.skill)))

    const improvPartitionedArray = (selectedFeedback?.improvementAreas ?? []).reduce(
      (acc, curr) => {
        acc[curr.justification ? 0 : 1].push(curr)
        return acc
      },
      [[], []] as [StrengthImprov[], StrengthImprov[]]
    )
    setImprovementAresWithJustification(improvPartitionedArray[0])
    setImprovementAreasWithoutJustification(
      generateOptionsFromArray(improvPartitionedArray[1].map((item) => item.skill))
    )
  }, [selectedFeedback])

  return (
    <>
      {feedbacksData.isLoading && (
        <div className={Style.blockingOpaqueBlock}>
          <div className={Style.loadingContainer}>
            <Loading data-test={`peer-to-peer-review.loading`} />
          </div>
        </div>
      )}
      {!feedbacksData.isLoading && (
        <div className={classNames(wrapperCustomClasses, Style.wrapper)} data-test={`${dataTest}.container`}>
          <div className={Style.textWrapper}>
            <div className={Style.header}>
              <ResourceCard
                data-test={`${dataTest}.source`}
                fname={source?.firstName}
                id={source?.id || ''}
                lname={source?.lastName}
                name={''}
                role={''}
                wrapperCustomClasses={Style.peerCard}
              ></ResourceCard>
              <Icon
                customClasses="mx-[50px]"
                data-test={`${dataTest}-icon`}
                name={IconName.ArrowRightCircle}
                size={IconSize.Small}
                type={IconType.Solid}
              />
              <ResourceCard
                data-test={`${dataTest}.target`}
                fname={target?.firstName}
                id={target?.id || ''}
                lname={target?.lastName}
                name={''}
                role={''}
                wrapperCustomClasses={Style.peerCard}
              ></ResourceCard>
            </div>
            <div className={classNames(Style.separator, 'my-[20px]')}></div>
            <div className={Style.content}>
              <div className={Style.left}>
                {feedbacks.map((i: Feedback) => {
                  return (
                    <div key={i.id} className={Style.card} data-test={`${dataTest}.feedback-card.${i.id}.container`}>
                      <div className={classNames(Style.colorCard, getColor(i, Style))}></div>
                      <div
                        className={classNames(Style.text, selectedFeedback?.id === i.id ? Style.active : '')}
                        onClick={() => {
                          setSelectedFeedback(i)
                        }}
                      >
                        <p className={Style.date} data-test={`${dataTest}.feedback-card.${i.id}.date`}>
                          {formatDate(i.created)}
                        </p>
                        <p className={Style.company} data-test={`${dataTest}.feedback-card.${i.id}.engagement`}>
                          {i.name}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className={Style.right}>
                <div data-test={`${dataTest}.feedback-details`}>
                  <div className={Style.header}>
                    <Icon
                      customClasses="mx-[10px]"
                      data-test={`${dataTest}-icon`}
                      name={IconName.ArrowLeftOnRectangle}
                      size={IconSize.Small}
                    />
                    <div>
                      <p className={Style.date} data-test={`${dataTest}.feedback-details.date`}>
                        {formatDate(selectedFeedback?.created)}
                      </p>
                      <p className={Style.company} data-test={`${dataTest}.feedback-details.engagement`}>
                        {selectedFeedback?.name}
                      </p>
                    </div>
                  </div>
                  {selectedFeedback?.sourceIsLead ? (
                    <div className={Style.section}>
                      <p className={Style.heading}>Leadership</p>
                      <div className={Style.content}>
                        <div className={Style.leftDiv}>
                          <p>Lead</p>
                          <p>Potential</p>
                          <p>Attrition</p>
                          <p>Motivation</p>
                        </div>
                        <div className={Style.rightDiv}>
                          <p>{selectedFeedback?.sourceIsLead ? 'True' : 'False'}</p>
                          <p>
                            {!selectedFeedback?.potential
                              ? notObserved
                              : potentialOptions.find((item) => item.value === selectedFeedback.potential)?.shortLabel}
                          </p>
                          <p>
                            {!selectedFeedback.attrition
                              ? notObserved
                              : attritionOptions.find((item) => item.value === selectedFeedback.attrition)?.shortLabel}
                          </p>
                          <p>
                            {!selectedFeedback.motivation
                              ? notObserved
                              : motivationOptions.find((item) => item.value === selectedFeedback.motivation)
                                  ?.shortLabel}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className={Style.section}>
                    <p className={Style.heading}>Project</p>
                    <div className={Style.content}>
                      <div className={Style.leftDiv}>
                        <p>Delivers what is required?</p>
                        <p>Consistent positive impact?</p>
                        <p>Collaborates well with Lab49?</p>
                        <p>Collaborates well with Client?</p>
                      </div>
                      <div className={Style.rightDiv}>
                        <p>
                          {!selectedFeedback?.delivery
                            ? notApplicable
                            : pointSurveyItems.find((item) => item.value === selectedFeedback.delivery)?.displayLabel}
                        </p>
                        <p>
                          {!selectedFeedback?.positiveImpact
                            ? notApplicable
                            : pointSurveyItems.find((item) => item.value === selectedFeedback.positiveImpact)
                                ?.displayLabel}
                        </p>
                        <p>
                          {!selectedFeedback?.teamCollaboration
                            ? notApplicable
                            : pointSurveyItems.find((item) => item.value === selectedFeedback.teamCollaboration)
                                ?.displayLabel}
                        </p>
                        <p>
                          {!selectedFeedback?.clientCollaboration
                            ? notApplicable
                            : pointSurveyItems.find((item) => item.value === selectedFeedback.clientCollaboration)
                                ?.displayLabel}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.section}>
                    <p className={Style.heading}>Profile</p>
                    <div className={classNames(Style.content, Style.block)}>
                      <div className="flex">
                        <div className={Style.leftDiv}>
                          <p>Strengths</p>
                        </div>
                        <div className={Style.rightDiv}>
                          <p data-test="strengths-without-justification">
                            {strengthsWithJustification
                              .map((item) => {
                                return `${item.skill}: ${item.justification}`
                              })
                              .join('\n')}
                          </p>
                          {strengthsWithoutJustification.length > 1 && (
                            <Combobox
                              customClasses={Style.combobox}
                              data-test="strengths-without-justification-combobox"
                              options={strengthsWithoutJustification}
                              placeholder="Additional strengths"
                              value={null}
                            />
                          )}
                          {strengthsWithoutJustification.length === 1 && (
                            <p>{strengthsWithoutJustification[0].label}</p>
                          )}
                        </div>
                      </div>
                      <div className="flex my-[5px]">
                        <div className={Style.leftDiv}>
                          <p>Areas of Improvement</p>
                        </div>
                        <div className={Style.rightDiv}>
                          <p data-test="improvs-without-justification">
                            {improvementAreasWithJustification
                              .map((item) => {
                                return `${item.skill}: ${item.justification}`
                              })
                              .join('\n')}
                          </p>
                          {improvementAreasWithoutJustification.length > 1 && (
                            <Combobox
                              customClasses={Style.combobox}
                              data-test="improvs-without-justification-combobox"
                              options={improvementAreasWithoutJustification}
                              placeholder="Additional areas of improvement"
                              value={null}
                            />
                          )}
                          {improvementAreasWithoutJustification.length === 1 && (
                            <p>{improvementAreasWithoutJustification[0].label}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={Style.section}>
                    <p className={Style.heading}>feedback</p>
                    <p>{selectedFeedback?.comment}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PeerToPeerReviews
export type { Props }
