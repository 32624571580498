import classNames from 'classnames'
import { map } from 'lodash'
import { useMemo } from 'react'
import { toast } from 'react-toastify'

import Loading from '@athena/component/atom/loading'
import { Engagement } from '@athena/core/model'

import { useGetEngagementsByResource } from '@apollo/client/hook/engagement'
import { EngagementsBlockProps } from '@future-view/frontend/page/peer-metrics/interface'
import { formatDate } from '@future-view/frontend/util'

import Style from './style.module.scss'

export const EngagementsBlock: React.FC<EngagementsBlockProps> = ({
  idResource,
  year,
  dataTestContainer,
}: EngagementsBlockProps): JSX.Element => {
  const engagementsByResource = useGetEngagementsByResource(idResource, year)

  const engagements = useMemo<Engagement[]>(() => {
    if (engagementsByResource.isError) {
      toast.error('Could not load engagements')
    }
    return engagementsByResource.data?.data || []
  }, [engagementsByResource])

  return (
    <div className={Style.container} data-test={`${dataTestContainer}.container`}>
      <div className={Style.title} data-test={`${dataTestContainer}.title`}>
        {`Engagements` + (engagements ? ` (${engagements.length})` : '')}
      </div>
      <div className={Style.table}>
        <header className={Style.row}>
          <div className={Style.col}>Title</div>
          <div className={classNames(Style.col, Style.cellRight)}>End Date</div>
        </header>
        {engagementsByResource.isLoading && (
          <div className={Style.loadingContainer}>
            <Loading show data-test={`${dataTestContainer}.loading`} />
          </div>
        )}
        {!engagementsByResource.isLoading &&
          map(engagements, (engagement) => (
            <div key={engagement.id} className={Style.row} data-test={`${dataTestContainer}.row`}>
              <div className={Style.col}>{engagement.title}</div>
              <div className={classNames(Style.col, Style.cellRight)}>{formatDate(engagement.endDate)}</div>
            </div>
          ))}
        {!engagementsByResource.isLoading && (!engagements || engagements.length === 0) && (
          <div className={Style.noContent} data-test={`${dataTestContainer}.no-items`}>{`No engagements`}</div>
        )}
      </div>
    </div>
  )
}
