import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import Combobox from '@athena/component/atom/combobox'
import Textarea from '@athena/component/atom/textarea'
import Typography, { TypographySize } from '@athena/component/atom/typography'
import Datepicker, { DatePickerMode } from '@athena/component/molecule/date-picker'
import { DateRange } from '@athena/component/molecule/date-picker/interface'
import { Location } from '@athena/core/model'

import { useEngagementList } from '@apollo/client/hook/engagement'
import { useActivityReference } from '@artemis/client/hook'

import { DetailsProps, Option, transformToOptions } from './interface'
import Style from './style.module.scss'
import { locationOptions } from './utils'

export const Details = ({
  dataTestContainer,
  isEditable,
  primaryDetails,
  setPrimaryDetails,
  engagementState,
}: DetailsProps): JSX.Element => {
  const [activityOptions, setActivityOptions] = useState<Option[]>([])
  const engagementList = useEngagementList()
  const activityReference = useActivityReference()

  useEffect(() => {
    if (primaryDetails.id) {
      if (activityReference) {
        setActivityOptions(transformToOptions(activityReference?.data?.data || []))
      }
    } else {
      // const availableActivityReferences = (activityReference?.data?.data || []).filter(
      //   (r) => !engagementList.data?.data?.map((e) => e.rel.activity).includes(r.id)
      // )
      activityReference?.data?.data && setActivityOptions(transformToOptions(activityReference?.data?.data))
    }

    if (activityReference.error) {
      toast.error('Failed to load activities')
    }
    if (engagementList.error) {
      toast.error('Failed to load engagements')
    }
  }, [
    activityReference.error,
    engagementList.error,
    activityReference?.data?.data,
    engagementList.data?.data,
    primaryDetails.id,
  ]) // DO NOT change the dependencies elseway it will cause infinite loop

  const diffDays =
    primaryDetails.startDate && primaryDetails.endDate
      ? Math.ceil(
          Math.abs(primaryDetails.endDate.getTime() + 1 - primaryDetails.startDate.getTime()) / (1000 * 3600 * 24)
        )
      : 0

  return (
    <div>
      <div className={Style.form}>
        <Typography
          customClass={Style.formHeader}
          data-test={`${dataTestContainer}.form-header`}
          size={TypographySize.Large}
        >
          Project Details
        </Typography>
        <div className={Style.formData}>
          <div className={Style.leftBlock}>
            <Combobox
              customClasses={Style.input}
              data-test="title-input"
              disabled={!!primaryDetails.title && !!engagementState}
              label="Engagement Title"
              options={activityOptions}
              value={activityOptions.find((val) => val.value === primaryDetails.activityId) || null}
              onChange={(value) =>
                setPrimaryDetails({
                  ...primaryDetails,
                  activityId: (value as Option).value,
                  title: (value as Option).label,
                })
              }
            />
            <div className={Style.dateRangeBlock}>
              <Datepicker
                customClasses={Style.datepicker}
                data-test={`${dataTestContainer}.date-range`}
                disabled={!isEditable}
                end={primaryDetails.endDate || undefined}
                endLabel="End Date"
                endPlaceholder="Select date"
                mode={DatePickerMode.Range}
                start={primaryDetails.startDate || undefined}
                startLabel="Start Date"
                startPlaceholder="Select date"
                onClearRange={() => setPrimaryDetails({ ...primaryDetails, startDate: null, endDate: null })}
                onPickRange={(range: DateRange) =>
                  setPrimaryDetails({ ...primaryDetails, startDate: range.start, endDate: range.end })
                }
              />
              <div className={Style.duration}>
                <span>DURATION</span>
                <span>
                  {diffDays === 0 && '---'}
                  {diffDays !== 0 && `${diffDays} ${diffDays === 1 ? 'day' : 'days'}`}
                </span>
              </div>
            </div>
            <Combobox
              isMulti
              customClasses={Style.input}
              data-test={`${dataTestContainer}.locations`}
              disabled={!isEditable}
              label="Lab49 Location"
              options={locationOptions}
              value={locationOptions.filter((val) => primaryDetails.locations?.includes(val.value))}
              onChange={(value) => {
                setPrimaryDetails({
                  ...primaryDetails,
                  locations: (value as Option[]).map((item) => item.value as Location),
                })
              }}
            />
          </div>
          <div className={Style.rightBlock}>
            <Textarea
              customClasses={Style.description}
              data-test={`${dataTestContainer}.description`}
              disabled={!isEditable}
              label="Description"
              maxSymbols={1000}
              value={primaryDetails.description}
              onChange={(value) => setPrimaryDetails({ ...primaryDetails, description: value })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
