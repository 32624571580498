import { assignIn } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelWarehouse, Quantum, Schema } from './base-model'

export type DeliveryGroupSerialized = z.infer<typeof DeliveryGroup.schema>

export type DeliveryGroupWarehouse = BaseModelWarehouse & {
  KC_EngagementOwnerEmail__c: string
  KC_EngagementOwnerName__c: string
  KimbleOne__ShortName__c: string
  KC_Contract_Type__c: string
  Region__c: string
  KC_BU__c: string
  KimbleOne__ExpectedStartDate__c: string
  KimbleOne__ExpectedEndDate__c: string
  Baseline_Estimated_Project_End_Date__c: string
  Revised_Estimated_Project_End_Date__c: string
  KimbleOne__SharedWithCustomer__c: string
  KC_Ready_for_Financial_Review__c: string
  PS_Review_Required__c: string
  KC_Drawdown_Agreement__c: string
}

export default class DeliveryGroup extends BaseModel {
  rel = {}
  ownerEmail = ''
  ownerName = ''
  shortName = ''
  contractType = ''
  region = ''
  businessUnit = ''
  expectedStart: Quantum<Date>
  expectedEnd: Quantum<Date>
  baselineEnd: Quantum<Date>
  revisedEnd: Quantum<Date>
  sharedWithCustomer = false
  financialReview = false
  reviewRequired = false
  drawdownAgreement = false

  constructor(
    id: string,
    name: string,
    created: Quantum<Date>,
    updated: Quantum<Date>,
    ownerEmail: string,
    ownerName: string
  ) {
    super(id, name, created, updated)
    this.ownerEmail = ownerEmail || ''
    this.ownerName = ownerName || ''
  }

  static override readonly schema = BaseModel.schema.extend({
    ownerEmail: z.string().max(64).default(''),
    ownerName: z.string().max(32).default(''),
    shortName: z.string().max(128).default(''),
    contractType: z.string().max(64).default(''),
    region: z.string().max(16).default(''),
    businessUnit: z.string().max(64).default(''),
    expectedStart: Schema.Date,
    expectedEnd: Schema.Date,
    baselineEnd: Schema.Date,
    revisedEnd: Schema.Date,
    sharedWithCustomer: z.boolean().default(false),
    financialReview: z.boolean().default(false),
    reviewRequired: z.boolean().default(false),
    drawdownAgreement: z.boolean().default(false),
  })

  static override fromSerialized(recordInput: DeliveryGroupSerialized): DeliveryGroup {
    const record = DeliveryGroup.schema.parse(recordInput)
    const model = new DeliveryGroup(
      record.id,
      record.name,
      BaseModel.parseDate(record.created),
      BaseModel.parseDate(record.updated),
      record.ownerEmail,
      record.ownerName
    )
    model.shortName = record.shortName || ''
    model.contractType = record.contractType || ''
    model.region = record.region || ''
    model.businessUnit = record.businessUnit || ''
    model.expectedStart = BaseModel.parseDate(record.expectedStart)
    model.expectedEnd = BaseModel.parseDate(record.expectedEnd)
    model.baselineEnd = BaseModel.parseDate(record.baselineEnd)
    model.revisedEnd = BaseModel.parseDate(record.revisedEnd)
    model.sharedWithCustomer = !!record.sharedWithCustomer
    model.financialReview = !!record.financialReview
    model.reviewRequired = !!record.reviewRequired
    model.drawdownAgreement = !!record.drawdownAgreement

    return model
  }

  static override fromWarehouse(record: DeliveryGroupWarehouse): DeliveryGroup {
    const model = new DeliveryGroup(
      record.Id,
      record.Name,
      BaseModel.parseDate(record.CreatedDate),
      BaseModel.parseDate(record.LastModifiedDate),
      record.KC_EngagementOwnerEmail__c,
      record.KC_EngagementOwnerName__c
    )
    model.shortName = record.KimbleOne__ShortName__c
    model.contractType = record.KC_Contract_Type__c
    model.region = record.Region__c
    model.businessUnit = record.KC_BU__c
    model.expectedStart = BaseModel.parseDate(record.KimbleOne__ExpectedStartDate__c)
    model.expectedEnd = BaseModel.parseDate(record.KimbleOne__ExpectedEndDate__c)
    model.baselineEnd = BaseModel.parseDate(record.Baseline_Estimated_Project_End_Date__c)
    model.revisedEnd = BaseModel.parseDate(record.Revised_Estimated_Project_End_Date__c)
    model.sharedWithCustomer = BaseModel.parseBoolean(record.KimbleOne__SharedWithCustomer__c)
    model.financialReview = BaseModel.parseBoolean(record.KC_Ready_for_Financial_Review__c)
    model.reviewRequired = BaseModel.parseBoolean(record.PS_Review_Required__c)
    model.drawdownAgreement = BaseModel.parseBoolean(record.KC_Drawdown_Agreement__c)

    return model
  }

  override toSerialized(): DeliveryGroupSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      expectedStart: BaseModel.formatDate(this.expectedStart),
      expectedEnd: BaseModel.formatDate(this.expectedEnd),
      baselineEnd: BaseModel.formatDate(this.baselineEnd),
      reviseEnd: BaseModel.formatDate(this.revisedEnd),
    } as DeliveryGroupSerialized
  }
}
