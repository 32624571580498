import { z } from 'zod'

import { FeedbackRank } from '@athena/core/model'
import { Skill } from '@athena/core/model/skill'

export const maxPoint = 6
export const totalLikerts = 4

export const ratingValues = [
  'Strongly Disagree',
  'Disagree',
  'Rather Disagree',
  'Rather Agree',
  'Agree',
  'Strongly Agree',
] as const
export const personalAttributeValues = ['Collaboration', 'Positive Attitude', 'Proactivity', 'Communication'] as const

export const getOptions = (values: readonly string[]) => {
  return values.map((value) => ({ label: value, value }))
}

export const getOptionsFromEnum = (e: any) => {
  return Object.keys(e).map((key) => ({ label: e[key], value: e[key] }))
}

export const potentialOptions = [
  {
    label: 'Top Talent - this individual is ready to take the next career step at Lab',
    value: FeedbackRank.Four.toString(),
    shortLabel: 'Top Talent',
  },
  {
    label: 'High Potential - given training and time, this individual is ready to take the next career step at Lab',
    value: FeedbackRank.Three.toString(),
    shortLabel: 'High Potential',
  },
  {
    label: 'Core Talent - this individual is well placed to deliver against their current responsibilities',
    value: FeedbackRank.Two.toString(),
    shortLabel: 'Core Talent',
  },
  {
    label: 'Limited - this individual would be more successful at another engagement',
    value: FeedbackRank.One.toString(),
    shortLabel: 'Limited',
  },
  {
    label: 'N/A',
    value: FeedbackRank.Unset.toString(),
    shortLabel: 'N/A',
  },
]

export const attritionOptions = [
  {
    label: 'Low - this individual will likely be at Lab49 after 12 months',
    value: FeedbackRank.One.toString(),
    shortLabel: 'Low',
  },
  {
    label: 'Medium - this individual will likely be at Lab for at least the next 6 - 12 months',
    value: FeedbackRank.Two.toString(),
    shortLabel: 'Medium',
  },
  {
    label: 'High - this individual is likely to leave Lab in the next six months',
    value: FeedbackRank.Three.toString(),
    shortLabel: 'High',
  },
  {
    label: 'Critical - this individual is likely to leave Lab within the next three months',
    value: FeedbackRank.Four.toString(),
    shortLabel: 'Critical',
  },
  {
    label: 'N/A',
    value: FeedbackRank.Unset.toString(),
    shortLabel: 'N/A',
  },
]

export const motivationOptions = [
  {
    label: 'High - this individual is excited about the opportunities at Lab and to progress here',
    value: FeedbackRank.Four.toString(),
    shortLabel: 'High',
  },
  {
    label: 'Medium - this individual will take opportunities when presented',
    value: FeedbackRank.Three.toString(),
    shortLabel: 'Medium',
  },
  {
    label: 'Low - this individual is well placed to deliver on their engagement ',
    value: FeedbackRank.Two.toString(),
    shortLabel: 'Low',
  },
  {
    label: 'Very low - this individual is disengaged on their engagement',
    value: FeedbackRank.One.toString(),
    shortLabel: 'Very Low',
  },
  {
    label: 'N/A',
    value: FeedbackRank.Unset.toString(),
    shortLabel: 'N/A',
  },
]

export const pointSurveyItems = [
  {
    label: 'Strongly Disagree',
    value: FeedbackRank.One,
    selected: false,
    displayLabel: 'Strongly Disagree',
  },
  {
    label: 'Disagree',
    value: FeedbackRank.Two,
    selected: false,
    displayLabel: 'Disagree',
  },
  {
    label: 'Rather Disagree',
    value: FeedbackRank.Three,
    selected: false,
    displayLabel: 'Rather Disagree',
  },
  {
    label: 'Rather Agree',
    value: FeedbackRank.Four,
    selected: false,
    displayLabel: 'Rather Agree',
  },
  {
    label: 'Agree',
    value: FeedbackRank.Five,
    selected: false,
    displayLabel: 'Agree',
  },
  {
    label: 'Strongly Agree',
    value: FeedbackRank.Six,
    selected: false,
    displayLabel: 'Strongly Agree',
  },
]

export const strengthOptions = getOptionsFromEnum(Skill)
export const improvOptions = getOptionsFromEnum(Skill)

// frontend form data validation
export const surveyDataSchema = (isLead: boolean) => {
  const potential = isLead ? z.nativeEnum(FeedbackRank) : z.nativeEnum(FeedbackRank).optional()
  const attrition = isLead ? z.nativeEnum(FeedbackRank) : z.nativeEnum(FeedbackRank).optional()
  const motivation = isLead ? z.nativeEnum(FeedbackRank) : z.nativeEnum(FeedbackRank).optional()
  return z.object({
    sourceIsLead: z.boolean(),
    potential,
    attrition,
    motivation,
    delivery: z.nativeEnum(FeedbackRank),
    positiveImpact: z.nativeEnum(FeedbackRank),
    teamCollaboration: z.nativeEnum(FeedbackRank),
    clientCollaboration: z.nativeEnum(FeedbackRank),
    comment: z.string().max(1000),
    encore: z.boolean(),
  })
}
