import Style from './style.module.scss'

export const columnDef = [
  {
    headerName: 'Id',
    field: 'id',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.id,
    headerClass: Style.customHeader,
    minWidth: 150,
  },
  {
    headerName: "Submitter's First Name",
    field: 'submitterFname',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.submitterFname,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: "Submitter's Last Name",
    field: 'submitterLname',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.submitterLname,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: "Submitter's Email",
    field: 'submitterEmail',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.submitterEmail,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: "People Manager's Email",
    field: 'managerEmail',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.managerEmail,
    headerClass: Style.customHeader,
    minWidth: 300,
  },
  {
    headerName: 'Subject First Name',
    field: 'subjectFname',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.subjectFname,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Subject Last Name',
    field: 'subjectLname',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.subjectLname,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Peer Review Status',
    field: 'state',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.state,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Title',
    field: 'description',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.description,
    headerClass: Style.customHeader,
    minWidth: 350,
  },
  {
    headerName: 'Peer Review Due Date',
    field: 'due',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.due,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Potential',
    field: 'potential',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.potential,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Attrition',
    field: 'attrition',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.attrition,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Motivation',
    field: 'motivation',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.motivation,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Delivers what is required?',
    field: 'delivery',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.delivery,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Consistent positive impact?',
    field: 'positiveImpact',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.positiveImpact,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Collaborates well with Lab49?',
    field: 'teamCollaboration',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.teamCollaboration,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Collaborates well with client?',
    field: 'clientCollaboration',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.clientCollaboration,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Additional Feedback',
    field: 'comment',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.comment,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Would like to work this individual in the future',
    field: 'encore',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.encore,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Strength 1',
    field: 'strength1',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.strengths.strength1,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Strength 2',
    field: 'strength2',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.strengths.strength2,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Strength 3',
    field: 'strength3',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.strengths.strength3,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Other Strengths',
    field: 'strength4',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.strengths.strength4,
    headerClass: Style.customHeader,
    minWidth: 200,
    cellStyle: { whiteSpace: 'pre' },
  },
  {
    headerName: 'Improvement 1',
    field: 'improvement1',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.improvements.improvement1,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Improvement 2',
    field: 'improvement2',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.improvements.improvement2,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Improvement 3',
    field: 'improvement3',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.improvements.improvement3,
    headerClass: Style.customHeader,
    minWidth: 200,
  },
  {
    headerName: 'Other Improvements',
    field: 'improvement4',
    lockPinned: true,
    autoHeight: true,
    wrapText: true,
    sortable: true,
    valueGetter: (e: any) => e.data.feedback.improvements.improvement4,
    headerClass: Style.customHeader,
    minWidth: 200,
    cellStyle: { whiteSpace: 'pre' },
  },
]
