import axios, { AxiosRequestConfig } from 'axios'

export const getAxiosInstance = (axiosInstanceConfig: AxiosRequestConfig) => {
  const axiosInstance = axios.create(axiosInstanceConfig)
  axiosInstance.interceptors.request.use(
    (config) => {
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        window.sessionStorage.setItem('intendedDestination', window.location.pathname)
        return (window.location.href = '/')
      }
      return Promise.reject(error)
    }
  )
  return axiosInstance
}
