import { useEffect, useState } from 'react'

import Employee from '@athena/core/model/employee'

import { useEmployeeReference } from '@artemis/client/hook/employee'

const useEmployeeMap = () => {
  const [employeeMap, setEmployeeMap] = useState<Record<string, Employee>>({})

  const { data } = useEmployeeReference()

  useEffect(() => {
    if (!data?.data) {
      return
    }
    data.data.forEach((employee) => {
      setEmployeeMap((prev) => ({ ...prev, [employee.id]: employee }))
    })
  }, [data?.data])

  return employeeMap
}

export default useEmployeeMap
