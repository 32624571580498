import { useCallback, useMemo, useState } from 'react'

import Modal from '@athena/component/molecule/modal'
import { FeedbackRequest, FeedbackRequestState } from '@athena/core/model'

import { useIdentity } from '@apollo/client/hook/auth'
import { useFeedbackRequests } from '@apollo/client/hook/engagement'
import { useSAPSearch } from '@apollo/client/hook/sap-search'
import { useEmployeeByEmail } from '@artemis/client/hook'
import PeerSpectiveMatrix from '@future-view/frontend/component/peerspective-matrix'
import RequestList from '@future-view/frontend/component/request-list'
import useEmployeeMap from '@future-view/frontend/hook/use-employee-map'

import FeedbackReviewPage from './feedback-review'
import Style from './style.module.scss'

const PeersPectivePage = () => {
  const resourceList = useEmployeeMap()
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [currentRequest, setCurrentRequest] = useState<FeedbackRequest | null>(null)
  const [filterIds, setFilterIds] = useState<string[]>([])

  const handleClick = useCallback((request: FeedbackRequest) => {
    setShowFeedbackModal(true)
    setCurrentRequest(request)
  }, [])

  const handleSurveyModalClose = useCallback(
    (feedbackRequestId?: string) => {
      feedbackRequestId && setFilterIds([...filterIds, feedbackRequestId])
      setShowFeedbackModal(false)
      setCurrentRequest(null)
    },
    [currentRequest]
  )

  const { data: identityData } = useIdentity()
  const { data: resourceResponse } = useEmployeeByEmail(identityData?.data?.email?.toLowerCase() || '')
  // get the feedbacks given for the direct reports of the logged-in user
  const types = [FeedbackRequestState.AwaitingApproval, FeedbackRequestState.SkipRequested]
  const { data: sapSearch } = useSAPSearch('', '', '', resourceResponse?.data?.employeeId || '', '', '')
  const feedbackRequestResponse = useFeedbackRequests(
    sapSearch?.data
      ?.filter((item) => item.directMgrEmployeeId === resourceResponse?.data?.employeeId)
      .map((item) => {
        return item.employeeId
      }) || [],
    types,
    resourceResponse?.data?.employeeId || '',
    true
  )

  const { data: feedbackRequestResponseData } = feedbackRequestResponse
  const feedbacks = feedbackRequestResponseData?.data

  const requestList = useMemo<FeedbackRequest[]>(() => {
    if (!feedbacks) {
      return []
    }
    return feedbacks.filter((request) => types.includes(request.state) && !filterIds.includes(request.id)) ?? []
  }, [feedbacks, types])

  return (
    <div className={Style.dashboard}>
      <div data-test="peerspective-page.container">
        <RequestList
          data-test="peerspective-page.awaiting-approval-list"
          feedbackData={requestList || []}
          filterIds={filterIds}
          handleClick={handleClick}
          isForManager={true}
          loading={feedbackRequestResponse.isLoading}
          resourceList={resourceList}
          types={[FeedbackRequestState.AwaitingApproval, FeedbackRequestState.SkipRequested]}
        />
        <>
          <div className={Style.peerspectiveGroupBlock}>
            <PeerSpectiveMatrix />
          </div>
        </>
      </div>
      {currentRequest && (
        <Modal
          hideCloseButton
          data-test="peer-review-page.modal"
          isShown={showFeedbackModal}
          modalCustomClasses={Style.surveyModalContentWrapper}
          wrapperCustomClasses={Style.surveyModalWrapper}
        >
          <FeedbackReviewPage
            data-test="feedback-survey-form"
            feedbackRequestId={currentRequest.id}
            hideModal={handleSurveyModalClose}
          />
        </Modal>
      )}
    </div>
  )
}

export default PeersPectivePage
