import { MutateOptions } from '@tanstack/react-query'
import classNames from 'classnames'
import { isEqual } from 'lodash'
import React, { useState, useMemo, useEffect, Fragment } from 'react'
import { toast } from 'react-toastify'

import Button, { ButtonFill } from '@athena/component/atom/button'
import Icon, { IconName } from '@athena/component/atom/icon'
import Textarea from '@athena/component/atom/textarea'
import Tooltip from '@athena/component/atom/tooltip'
import Typography, { TypographySize } from '@athena/component/atom/typography'
import Modal from '@athena/component/molecule/modal'
import RevueObjective, { RevueObjectiveTitle } from '@athena/core/model/revue-objective'

import { useAddRevueObjective, useUpdRevueObjectiveShowRatings } from '@apollo/client/hook/revue'
import ConfirmationDialog from '@future-view/frontend/component/confirm-dialog'
import PerformanceObjectiveDetail from '@future-view/frontend/component/performance-objective-detail'
import PerformanceObjectiveList from '@future-view/frontend/component/performance-objective-list'
import RevueNotes from '@future-view/frontend/component/revue_notes'

import Props, { ObjectiveData } from './interface'
import Style from './style.module.scss'

const cx = classNames.bind(Style)
const emptyObjectiveData: ObjectiveData = {
  id: '',
  title: '',
  description: '',
  source: '',
  target: '',
  kpi: '',
}

const ManagerRevue: React.FC<Props> = ({
  'data-test': dataTest = 'revue',
  year,
  source,
  target,
  employeeName,
  showNewObjective = false,
}: Props): JSX.Element => {
  const dataTestCreateObjectiveModal = `${dataTest}.create-objective-modal`
  const dataTestCancelModal = `${dataTest}.cancel-modal`
  const dataTestCreateObjectiveConfirmationModal = `${dataTest}.create-objective-confirmation-modal`
  const addObjective = useAddRevueObjective()
  const [showCreateObjectiveDialog, setShowCreateObjectiveDialog] = useState(false)
  const [selectedObjective, setSelectedObjective] = useState<string>()
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [showCreateObjectiveConfirmationDialog, setShowCreateObjectiveConfirmationDialog] = useState(false)
  const [isChanged, setIsChanged] = useState<boolean>(false)
  const [objectiveData, setObjectiveData] = useState<ObjectiveData>(emptyObjectiveData)
  const [shouldRefresh, setShouldRefresh] = useState('initial')
  const [ratings, setRatings] = useState<RevueObjective[]>()
  const [showRatingsPopup, setShowRatingsPopup] = useState<boolean>(false)
  const isCreateButtonDisabled = useMemo(() => {
    return !objectiveData.title.trim() || !objectiveData.description.trim()
  }, [objectiveData])
  const updObjectiveShowRatings = useUpdRevueObjectiveShowRatings()

  const handleCancel = () => {
    if (isChanged) {
      setShowCancelDialog(true)
    } else {
      setShowCreateObjectiveDialog(false)
    }
  }

  const handleDialogOpen = () => {
    setObjectiveData(emptyObjectiveData)
    setShowCreateObjectiveDialog(true)
  }

  const handleObjectiveAdded = () => {
    toast.success('Objective Created')
    setShowCreateObjectiveConfirmationDialog(false)
    setShowCreateObjectiveDialog(false)
    setShouldRefresh(Math.random().toString())
  }

  const onHideObjectiveModal = (refresh?: boolean) => {
    setSelectedObjective(undefined)
    if (refresh) {
      setShouldRefresh(Math.random().toString())
    }
  }

  const buildObjective = (form: ObjectiveData) => {
    const savedObjective = new RevueObjective('')
    savedObjective.source = source
    savedObjective.target = target
    savedObjective.title = form.title
    savedObjective.kpi = form.kpi
    savedObjective.description = form.description
    return savedObjective
  }

  const saveObjective = (onSuccess?: MutateOptions<unknown, unknown, RevueObjective>['onSuccess']) => {
    const savedObjective = buildObjective(objectiveData)
    addObjective.mutate(savedObjective, {
      onSuccess,
    })
  }

  useEffect(() => {
    if (isEqual(emptyObjectiveData, objectiveData)) {
      setIsChanged(false)
    } else {
      setIsChanged(true)
    }
  }, [objectiveData])

  useEffect(() => {
    const showPopUp = ratings?.filter((objective: RevueObjective) => objective.show_details_popup === true)
    if (showPopUp && showPopUp.length > 0) {
      setShowRatingsPopup(true)
    }
  }, [ratings])

  const handleClose = () => {
    updObjectiveShowRatings.mutateAsync(target)
    setShowRatingsPopup(false)
  }

  const handleSummaryOpen = () => {
    setShowRatingsPopup(true)
  }

  const showRatingSummaryButton = useMemo(() => {
    if (
      ratings &&
      ratings.length > 0 &&
      new Date(ratings[0].created || '').getFullYear() === Number(year) &&
      ratings
        .filter((objective: RevueObjective) => objective.title !== RevueObjectiveTitle.SUMMARY_OF_ACCOMPLISHMENTS)
        .every((objective: RevueObjective) => objective.old_manager_rating && objective.manager_rating)
    ) {
      return true
    }
    return false
  }, [ratings, year])

  return (
    <div className={Style.container} data-test={`${dataTest}.container`}>
      <div data-test="content">
        <div className={'mb-[16px]'}>
          {showNewObjective && (
            <Button data-test={`${dataTest}.new-objective`} icon={IconName.PlusCircle} onClick={handleDialogOpen}>
              New objective
            </Button>
          )}
        </div>
        <div className={'flex flex-nowrap flex-row'}>
          <div className={'flex flex-col flex-1 basis-1/2 gap-[10px] h-full'}>
            <div className={Style.buttonContainer}>
              {showRatingSummaryButton && (
                <Button
                  customClasses={Style.reportButton}
                  data-test={`${dataTest}.ratings-summary`}
                  onClick={handleSummaryOpen}
                >
                  Final Rating Summary
                </Button>
              )}
            </div>
            <PerformanceObjectiveList
              key={shouldRefresh}
              data-test={`${dataTest}.objective-list`}
              isManager={true}
              ratings={setRatings}
              source={source}
              target={target}
              year={year}
              onClicked={setSelectedObjective}
            ></PerformanceObjectiveList>
          </div>
          <div
            className={'flex flex-col flex-1 basis-1/2 gap-[10px] h-[calc(100vh-220px)]'}
            data-test={`${dataTest}.general`}
          >
            <RevueNotes
              data-test={`${dataTest}.objective-list`}
              employeeName={employeeName}
              source={source}
              target={target}
              year={year}
              onClicked={setSelectedObjective}
            ></RevueNotes>
          </div>
        </div>
      </div>
      <Modal
        hideCloseButton
        data-test={`${dataTestCreateObjectiveModal}`}
        isShown={showCreateObjectiveDialog}
        modalCustomClasses={Style.createObjectiveModalContentWrapper}
        wrapperCustomClasses={Style.createObjectiveModalWrapper}
        onHide={() => setShowCreateObjectiveDialog(false)}
      >
        <div className={'bg-white'} data-test={`${dataTestCreateObjectiveModal}.wrapper`}>
          <div className={'flex items-center flex-wrap mb-[26px]'} data-test={`${dataTestCreateObjectiveModal}.header`}>
            <Typography data-test={`${dataTestCreateObjectiveModal}.new-objective`} size={TypographySize.Header3}>
              New Objective
            </Typography>
            <div className="flex items-center ml-auto gap-[10px]">
              <p className={Style.instruction}>Please complete all fields to create an objective.</p>
              <Button
                basicOutline={true}
                customClasses={Style.cancel}
                data-test={`${dataTestCreateObjectiveModal}.cancel`}
                fill={ButtonFill.Outline}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                basicOutline={true}
                customClasses={cx(Style.createObjective, { [Style.disabled]: isCreateButtonDisabled })}
                data-test={`${dataTestCreateObjectiveModal}.create-objective`}
                disabled={isCreateButtonDisabled}
                icon={IconName.PlusCircle}
                onClick={() => setShowCreateObjectiveConfirmationDialog(true)}
              >
                Create Objective
              </Button>
            </div>
          </div>
        </div>
        <div className={'flex flex-col gap-[10px]'}>
          <section className={'flex w-full gap-[10px]'}>
            <Textarea
              key={'title'}
              customClasses={'flex-[8_1_0%]'}
              data-test={`${dataTestCreateObjectiveModal}.title`}
              isAutogrow={false}
              label={'Title'}
              maxSymbols={200}
              rows={1}
              valid={!!objectiveData.title.length}
              value={objectiveData.title}
              onChange={(value) =>
                setObjectiveData({
                  ...objectiveData,
                  title: value,
                })
              }
            />
            <Textarea
              key={'target'}
              customClasses={'flex-[2_1_0%]'}
              data-test={`${dataTestCreateObjectiveModal}.target`}
              isAutogrow={false}
              label={'Target'}
              maxSymbols={10}
              rows={1}
              value={objectiveData.kpi}
              onChange={(value) =>
                setObjectiveData({
                  ...objectiveData,
                  kpi: value,
                })
              }
            />
          </section>
          <Textarea
            data-test={`${dataTestCreateObjectiveModal}.description`}
            isAutogrow={false}
            label="Description"
            maxSymbols={1000}
            rows={5}
            valid={!!objectiveData.description.length}
            value={objectiveData.description}
            onChange={(value) =>
              setObjectiveData({
                ...objectiveData,
                description: value,
              })
            }
          />
        </div>
      </Modal>
      <Modal
        hideCloseButton
        data-test={`${dataTestCancelModal}`}
        isShown={showCancelDialog}
        onHide={() => setShowCancelDialog(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTestCancelModal}.confirm-dialog`}
          description="Changes will not be saved"
          onConfirm={(confirmed: boolean) => {
            if (confirmed) {
              setShowCancelDialog(false)
              setShowCreateObjectiveDialog(false)
            } else {
              setShowCancelDialog(false)
            }
          }}
        />
      </Modal>
      <Modal
        hideCloseButton
        data-test={`${dataTestCreateObjectiveConfirmationModal}`}
        isShown={showCreateObjectiveConfirmationDialog}
        onHide={() => setShowCreateObjectiveConfirmationDialog(false)}
      >
        <ConfirmationDialog
          confirmButtonText="Yes"
          data-test={`${dataTestCreateObjectiveConfirmationModal}.dialog`}
          description={'Objective will be created'}
          disableConfirm={addObjective.isLoading}
          returnButtonText=""
          onConfirm={(confirmed: boolean) => {
            if (confirmed) {
              saveObjective(handleObjectiveAdded)
            } else {
              setShowCreateObjectiveConfirmationDialog(false)
            }
          }}
        />
      </Modal>
      <Modal
        hideCloseButton
        data-test={`${dataTest}.modal`}
        isShown={!!selectedObjective}
        wrapperCustomClasses={Style.modalHeight}
      >
        <PerformanceObjectiveDetail
          data-test={`interest-confirmation`}
          handleClose={onHideObjectiveModal}
          id={selectedObjective || ''}
          isManager={true}
        />
      </Modal>
      <Modal
        data-test={dataTest}
        headerText={''}
        isShown={!!showRatingsPopup}
        wrapperCustomClasses={Style.ratingsModal}
        onHide={handleClose}
      >
        <div className={Style.ratingsTable}>
          <h3>Final Performance ratings of your team</h3>
          <br />
          <p className={Style.content}>
            As part of the performance development cycle, the leadership team dives into all submitted ratings to
            calibrate and normalize to Lab49’s high standards. This is an action that level sets and standardizes the
            measurement of a rating across practices and corporate areas.
            <br />
            <br />
            As a result of this, some of your ratings may have been adjusted to reflect the firm’s benchmarks and wider
            approach. The final ratings across all your direct/s are:
            <br />
            <br />
          </p>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>Name</th>
                {ratings &&
                  ratings.map(
                    (i, idx) =>
                      i.title !== RevueObjectiveTitle.SUMMARY_OF_ACCOMPLISHMENTS && (
                        <th key={`${i.id}-${i.title}-${idx}`} colSpan={2}>
                          {i.title}
                        </th>
                      )
                  )}
              </tr>
              <tr>
                {ratings &&
                  ratings.map(
                    (i, idx) =>
                      i.title !== RevueObjectiveTitle.SUMMARY_OF_ACCOMPLISHMENTS && (
                        <Fragment key={`${i.id}-${i.title}-${idx}-Submmitted`}>
                          <th className="bg-[#d74641]">Submmitted</th>
                          <th className="bg-[#d74641]">Final</th>
                        </Fragment>
                      )
                  )}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{employeeName}</td>
                {ratings &&
                  ratings.map(
                    (i, idx) =>
                      i.title !== RevueObjectiveTitle.SUMMARY_OF_ACCOMPLISHMENTS && (
                        <Fragment key={`${i.id}-${i.title}-${idx}-omr`}>
                          <td>{i.old_manager_rating}</td>
                          <td>
                            <div className="flex gap-2">
                              {i.manager_rating}
                              {i.comment && (
                                <>
                                  <Tooltip content={i.comment} data-test={`${dataTest}.tooltip-comment`}>
                                    <Icon data-test={`tooltip.info-icon`} name={IconName.InformationCircle} />
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </td>
                        </Fragment>
                      )
                  )}
              </tr>
            </tbody>
          </table>
          <br />
          <h3>Note:</h3>
          <ul>
            <li>
              <b>
                The ratings are updated in Lab360 and visible in your manager tab. It will be published to your direct
                report at 9am EST on February 12.&nbsp;
              </b>
              You do not need to make any manual changes to your input.
            </li>
          </ul>
          <p className={Style.content}>
            <br />
            Ensure the scheduled conversation with your direct is only held with understanding the reasons for any new
            rating.
            <br />
            <br />
            Kind regards,
            <br />
            Helena and Pratibha
          </p>
        </div>
      </Modal>
    </div>
  )
}

export default ManagerRevue
