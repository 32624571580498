import classNames from 'classnames'

import logo from '@athena/component/asset/futureview-logo.svg'

import Props, { LogoSize } from './interface'
import Style from './logo.module.scss'

export const Label = 'Lab360'

export const Logo: React.FC<Props> = ({
  'data-test': dataTest = 'logo',
  size = LogoSize.Normal,
  customClasses,
  label = false,
}: Props) => {
  return (
    <span
      className={classNames(Style[`logo-${size}`], 'inline-block', 'flex', 'items-center', customClasses)}
      data-test={`${dataTest}.container`}
    >
      <img data-test={`${dataTest}.image`} src={logo} />
      {label && (
        <span className="pl-[10px] text-[24px] font-semibold" data-test={`${dataTest}.label`}>
          {Label}
        </span>
      )}
    </span>
  )
}

export default Logo
export type { Props }
export { LogoSize }
