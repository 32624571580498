import { Matcher } from 'react-day-picker'

export type DateRange = {
  start: Date
  end: Date
}

export enum DatePickerMode {
  Single = 'single',
  Range = 'range',
}

export enum InputSelection {
  None = 'none',
  Start = 'start',
  End = 'end',
}

export default interface Props {
  'data-test': string
  customClasses?: string
  disabled?: boolean
  disabledDate?: Matcher | Matcher[] | undefined
  end?: Date
  endLabel?: string
  endPlaceholder?: string
  help?: string
  invalid?: boolean
  mode: DatePickerMode
  start?: Date
  startLabel: string
  startPlaceholder: string
  onClearRange?: () => void
  onPickDay?: (date: Date, selection?: InputSelection) => void
  onPickRange?: (dateRange: DateRange) => void
}
