import { assignIn } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelWarehouse, Quantum } from './base-model'

export type CapabilityGroupSerialized = z.infer<typeof CapabilityGroup.schema>

export type CapabilityGroupWarehouse = BaseModelWarehouse & {
  KimbleOne__IsRestricted__c: string
}

export default class CapabilityGroup extends BaseModel {
  rel = {}
  isRestricted = false

  constructor(id: string, name: string, created: Quantum<Date>, updated: Quantum<Date>) {
    super(id, name, created, updated)
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({}).default({}),
    isRestricted: z.boolean().default(false),
  })

  static override fromSerialized(recordInput: CapabilityGroupSerialized): CapabilityGroup {
    const record = CapabilityGroup.schema.parse(recordInput)
    const model = new CapabilityGroup(
      record.id,
      record.name,
      BaseModel.parseDate(record.created),
      BaseModel.parseDate(record.updated)
    )
    model.rel = record.rel || {}
    model.isRestricted = !!record.isRestricted

    return model
  }

  static override fromWarehouse(record: CapabilityGroupWarehouse): CapabilityGroup {
    const model = new CapabilityGroup(
      record.Id,
      record.Name,
      BaseModel.parseDate(record.CreatedDate),
      BaseModel.parseDate(record.LastModifiedDate)
    )
    model.isRestricted = BaseModel.parseBoolean(record.KimbleOne__IsRestricted__c)

    return model
  }

  override toSerialized(): CapabilityGroupSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      isRestricted: this.isRestricted,
    } as CapabilityGroupSerialized
  }
}
