import { assignIn, pick } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelWarehouse, Quantum, Schema } from './base-model'

export type UserSerialized = z.infer<typeof User.schema>

export type UserWarehouse = BaseModelWarehouse & {
  UserManager__c: string
  Connection_ID__c: string
  Email: string
  ManagerId: string
  FirstName: string
  LastName: string
  CompanyName: string
  Division: string
  Department: string
  Title: string
  State: string
  Country: string
  TimeZoneSidKey: string
}

export const UserProjection = {
  Embed: [
    'id',
    'name',
    'created',
    'updated',
    'rel.manager',
    'manager',
    'connectionId',
    'email',
    'firstName',
    'lastName',
    'timeZone',
    'isDirectReport',
  ],
}

export default class User extends BaseModel {
  rel = {
    manager: '',
  }
  manager = ''
  connectionId = ''
  email = ''
  firstName = ''
  lastName = ''
  company = ''
  division = ''
  department = ''
  title = ''
  state = ''
  country = ''
  timeZone = ''
  isDirectReport = false

  constructor(
    id: string,
    name: string,
    created: Quantum<Date>,
    updated: Quantum<Date>,
    manager: string,
    connectionId: string,
    email: string
  ) {
    super(id, name, created, updated)
    this.manager = manager || ''
    this.connectionId = connectionId || ''
    this.email = email || ''
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      manager: Schema.Id,
    }),
    manager: Schema.Name,
    connectionId: z.string().max(16).default(''),
    email: z.string().email().max(64).default(''),
    firstName: z.string().max(32).default(''),
    lastName: z.string().max(32).default(''),
    company: z.string().max(64).default(''),
    division: z.string().max(64).default(''),
    department: z.string().max(128).default(''),
    title: z.string().max(64).default(''),
    state: z.string().max(64).default(''),
    country: z.string().max(32).default(''),
    timeZone: z.string().max(32).default(''),
    isDirectReport: z.boolean().default(false),
  })

  static override fromSerialized(recordInput: UserSerialized): User {
    const record = User.schema.parse(recordInput)
    const model = new User(
      record.id,
      record.name,
      BaseModel.parseDate(record.created),
      BaseModel.parseDate(record.updated),
      record.manager,
      record.connectionId,
      record.email
    )
    model.rel = record.rel || {}
    model.connectionId = record.connectionId || ''
    model.email = record.email || ''
    model.firstName = record.firstName || ''
    model.lastName = record.lastName || ''
    model.company = record.company || ''
    model.division = record.division || ''
    model.department = record.department || ''
    model.title = record.title || ''
    model.state = record.state || ''
    model.country = record.country || ''
    model.timeZone = record.timeZone || ''
    model.isDirectReport = record.isDirectReport || false

    return model
  }

  static override fromWarehouse(record: UserWarehouse): User {
    const model = new User(
      record.Id,
      record.Name,
      BaseModel.parseDate(record.CreatedDate),
      BaseModel.parseDate(record.LastModifiedDate),
      record.UserManager__c,
      record.Connection_ID__c,
      record.Email
    )
    model.rel = { manager: record.ManagerId }
    model.firstName = record.FirstName || ''
    model.lastName = record.LastName || ''
    model.company = record.CompanyName || ''
    model.division = record.Division || ''
    model.department = record.Department || ''
    model.title = record.Title || ''
    model.state = record.State || ''
    model.country = record.Country || ''
    model.timeZone = record.TimeZoneSidKey || ''
    model.isDirectReport = false

    return model
  }

  override toSerialized(): UserSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
    } as unknown as UserSerialized
  }

  project(projection: string[]): Partial<UserSerialized> {
    const serialized = this.toSerialized()
    return projection?.length ? pick(serialized, projection) : serialized
  }
}
