import { assignIn, map } from 'lodash'
import { z } from 'zod'

import BaseModel, { BaseModelStore, Schema } from '@athena/core/model/base-model'

import StrengthImprov from './strength-improv'

export type FeedbackSerialized = z.infer<typeof Feedback.schema>

export type FeedbackStore = BaseModelStore & {
  id_engagement: string
  id_feedback_request: string
  id_source: string
  id_target: string
  source_is_lead: boolean
  collaborative: boolean
  encore: boolean | null
  potential?: string
  attrition?: string
  motivation?: string
  delivery?: string
  positive_impact?: string
  team_collaboration?: string
  client_collaboration?: string
  comment: string
}

export type FeedbackStoreWithName = FeedbackStore & {
  name: string
}

export enum FeedbackRank {
  Unset = '',
  Zero = '0',
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
}

export default class Feedback extends BaseModel {
  rel = {
    engagement: '',
    feedbackRequest: '',
    source: '',
    target: '',
  }
  sourceIsLead = false
  collaborative = false
  encore: boolean | null = null
  potential?: FeedbackRank
  attrition?: FeedbackRank
  motivation?: FeedbackRank
  delivery?: FeedbackRank
  positiveImpact?: FeedbackRank
  teamCollaboration?: FeedbackRank
  clientCollaboration?: FeedbackRank
  strengths: StrengthImprov[] = []
  improvementAreas: StrengthImprov[] = []
  comment = ''

  constructor(id = '', engagement = '', feedbackRequest = '', source = '', target = '') {
    super(id)
    this.rel = { engagement, feedbackRequest, source, target }
  }

  static override readonly schema = BaseModel.schema.extend({
    rel: z.object({
      engagement: Schema.Id,
      feedbackRequest: Schema.Id,
      source: Schema.Id,
      target: Schema.Id,
    }),
    sourceIsLead: z.boolean(),
    collaborative: z.boolean(),
    encore: z.boolean(),
    potential: z.nativeEnum(FeedbackRank).optional(),
    attrition: z.nativeEnum(FeedbackRank).optional(),
    motivation: z.nativeEnum(FeedbackRank).optional(),
    delivery: z.nativeEnum(FeedbackRank).optional(),
    positiveImpact: z.nativeEnum(FeedbackRank).optional(),
    teamCollaboration: z.nativeEnum(FeedbackRank).optional(),
    clientCollaboration: z.nativeEnum(FeedbackRank).optional(),
    strengths: z.array(StrengthImprov.schema).optional(),
    improvementAreas: z.array(StrengthImprov.schema).optional(),
    comment: z.string().max(1000).trim(),
  })

  static override fromSerialized(recordInput: FeedbackSerialized): Feedback {
    const record = Feedback.schema.parse(recordInput)
    const model = new Feedback(
      record.id,
      record.rel.engagement,
      record.rel.feedbackRequest,
      record.rel.source,
      record.rel.target
    )
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.sourceIsLead = !!record.sourceIsLead
    model.collaborative = !!record.collaborative
    model.encore = !!record.encore
    model.potential = record.potential
    model.attrition = record.attrition
    model.motivation = record.motivation
    model.delivery = record.delivery
    model.positiveImpact = record.positiveImpact
    model.teamCollaboration = record.teamCollaboration
    model.clientCollaboration = record.clientCollaboration
    model.strengths = map(record.strengths, StrengthImprov.fromSerialized)
    model.improvementAreas = map(record.improvementAreas, StrengthImprov.fromSerialized)
    model.comment = record.comment || ''
    model.name = record.name || ''
    return model
  }

  static override fromDataStore(record: FeedbackStoreWithName): Feedback {
    const model = new Feedback(
      record.id,
      record.id_engagement,
      record.id_feedback_request,
      record.id_source,
      record.id_target
    )
    model.created = BaseModel.parseDate(record.created)
    model.updated = BaseModel.parseDate(record.updated)
    model.sourceIsLead = !!record.source_is_lead
    model.collaborative = !!record.collaborative
    model.encore = !!record.encore
    model.potential = record.potential ? (record.potential as FeedbackRank) : undefined
    model.attrition = record.attrition ? (record.attrition as FeedbackRank) : undefined
    model.motivation = record.motivation ? (record.motivation as FeedbackRank) : undefined
    model.delivery = record.delivery ? (record.delivery as FeedbackRank) : undefined
    model.positiveImpact = record.positive_impact ? (record.positive_impact as FeedbackRank) : undefined
    model.teamCollaboration = record.team_collaboration ? (record.team_collaboration as FeedbackRank) : undefined
    model.clientCollaboration = record.client_collaboration ? (record.client_collaboration as FeedbackRank) : undefined
    model.comment = record.comment || ''
    model.name = record.name || ''
    return model
  }

  override toSerialized(): FeedbackSerialized {
    return {
      ...assignIn({}, this),
      ...super.toSerialized(),
      strengths: map(this.strengths, (s) => s.toSerialized()),
      improvementAreas: map(this.improvementAreas, (s) => s.toSerialized()),
    } as FeedbackSerialized
  }

  override toDataStore(): FeedbackStore {
    return {
      ...super.toDataStore(),
      id_engagement: this.rel.engagement,
      id_feedback_request: this.rel.feedbackRequest,
      id_source: this.rel.source,
      id_target: this.rel.target,
      source_is_lead: !!this.sourceIsLead,
      collaborative: !!this.collaborative,
      encore: !!this.encore,
      potential: this.potential?.toString(),
      attrition: this.attrition?.toString(),
      motivation: this.motivation?.toString(),
      delivery: this.delivery?.toString(),
      positive_impact: this.positiveImpact?.toString(),
      team_collaboration: this.teamCollaboration?.toString(),
      client_collaboration: this.clientCollaboration?.toString(),
      comment: this.comment || '',
    }
  }
}
