import * as outline from '@heroicons/react/24/outline'
import * as solid from '@heroicons/react/24/solid'
import classNames from 'classnames'

import Style from './icon.module.scss'
import Props, { IconName, IconSize, IconType } from './interface'

const cx = classNames.bind(Style)

export const Icon: React.FC<Props> = ({
  'data-test': dataTest = 'icon',
  customClasses,
  name,
  size = IconSize.Normal,
  type = IconType.Outline,
}: Props) => {
  const collection = type === IconType.Solid ? solid : outline
  const Element = collection[name]
  return Element ? <Element className={cx(`icon-${size}`, customClasses)} data-test={`${dataTest}.element`} /> : null
}

export default Icon
export type { Props }
export { IconName, IconSize, IconType }
